import { del, patch, post } from "helpers/api_helper"

//add new announcement
export const addAnnouncement = async ({
  eventId,
  parsedMessage,
  values,
  socketClientId,
}) => {
  let response

  try {
    response = await post(`/admin/events/${eventId}/announcements`, {
      title: values.title,
      message: values.message,
      notifications: values.notifications,
      competitions: ['true', true].includes(values.notifications) ? (values.selectedCompetitions || []).map(x => x.value) : [],
      parsedMessage,
      socketClientId,
    })
  } catch (error) {
    console.log(error)
  }
  return response
}

export const deleteAnnouncement = async (eventId, id, socketClientId) => {
  let response
  try {
    response = await del(
      `/admin/events/${eventId}/announcements/${id}/status`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

//change status
export const changeStatus = async (eventId, id, type, socketClientId) => {
  let response

  try {
    response = await patch(
      `/admin/events/${eventId}/announcements/${id}/status/${type}`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

//edit announcement
export const editAnnouncement = async ({
  eventId,
  announcementId,
  values,
  parsedMessage,
  socketClientId,
}) => {
  let response

  try {
    response = await patch(
      `/admin/events/${eventId}/announcements/${announcementId}`,
      {
        title: values.title,
        message: values.message,
        parsedMessage,
        notifications: values.notifications,
        competitions: ['true', true].includes(values.notifications) ? (values.selectedCompetitions || []).map(x => x.value) : [],
        socketClientId,
      }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}
