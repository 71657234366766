import {Form, Formik} from "formik"
import React, {useEffect, useState, useContext} from "react"
import {useToasts} from "react-toast-notifications"
import {connect} from "react-redux"
import DatePicker from "react-datepicker"

import {store as socketStore} from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
} from "reactstrap"

import {getMatches, getMainDraw} from "./actions"
import {updateMatches} from "./api"
import ScoreForm from "./components/ScoreForm"
import MatchesGroups from "./components/MatchesGroups"
import MatchesEliminatory from "./components/MatchesEliminatory"
import Input from "components/Common/Input"

const NoMatches = () => (
  <Card className="mt-3">
    <CardBody>No matches yet</CardBody>
  </Card>
)

const playerActions = [
  {label: "None", value: {type: "NONE", reason: "", score: []}},
  {label: "Didn't show up", value: {reason: "NO_SHOW", type: "BYE"}},
  {label: "Abandoned", value: {reason: "ABANDONED", type: "BYE"}},
]

const MatchesList = ({
  competitionDetails = {},
  matches = [],
  mainDraw = [],
  drawType,
  modal,
  setModal,
  socketClientId,
  eventDetails,
}) => {
  const [selectedMatch] = matches.filter(({_id}) => _id === modal)

  const {
    player: selectedPlayer = {},
    opponent: selectedOpponent = {},
    playerPartner: playerPartner = {},
    opponentPartner: opponentPartner = {},
  } = selectedMatch || {}

  const [playingDay, setPlayingDay] = useState(null);
  const[playingTime, setPlayingTime] = useState(null);

  useEffect(() => {
    if (selectedMatch) {
      setPlayingDay(selectedMatch?.scheduler?.playingDay ? new Date(selectedMatch?.scheduler?.playingDay) : null);
      setPlayingTime(selectedMatch?.scheduler?.playingTime ? new Date(selectedMatch?.scheduler?.playingTime) : null);
    }
  }, [selectedMatch]);

  return (
    <>
      {drawType === "eliminatory" ? (
        <MatchesEliminatory
          eventDetails={eventDetails}
          {...{matches, mainDraw, competitionDetails, modal, setModal}}
        />
      ) : (
        <MatchesGroups
          eventDetails={eventDetails}
          {...{mainDraw, modal, competitionDetails, setModal}}
        />
      )}
      <Modal size="lg" isOpen={modal} toggle={() => setModal(false)}>
        <ModalHeader toggle={() => setModal(false)}>Edit match</ModalHeader>
        <Formik
          initialValues={{
            player: playerActions?.filter(
              action => action?.value?.reason === selectedPlayer?.reason
            )[0]?.value,
            opponent: playerActions?.filter(
              action => action?.value?.reason === selectedOpponent?.reason
            )[0]?.value,
            scheduler: {
              court: selectedMatch?.scheduler?.court,
            }
          }}
          onSubmit={async values => {
            setModal(false)
            updateMatches(modal, competitionDetails, {
              socketClientId,
              ...values,
              scheduler: {
                ...values.scheduler,
                playingDay,
                playingTime,
              },
            })
          }}
        >
          {({
              setFieldValue,
              handleChange,
              handleBlur,
              initialValues,
              values,
            }) => (
            <Form>
              <ModalBody>
                <div className="Match__score">
                  <Row>
                    <Col>
                      <div className="d-flex">
                        <h5>
                          {selectedPlayer?.firstName +
                            " " +
                            selectedPlayer?.lastName}
                        </h5>
                        <h5 className="ml-1">
                          {playerPartner?.userId &&
                            " - " +
                            playerPartner?.firstName +
                            " " +
                            playerPartner?.lastName}
                        </h5>
                      </div>
                      <ScoreForm
                        noOfSets={selectedMatch && selectedMatch.noOfSets}
                        reason={selectedPlayer?.reason}
                        scoreValues={values}
                        maxPointsOnSet={
                          selectedMatch && selectedMatch.maxPointsOnSet
                        }
                        {...{
                          userKey: "player",
                          setFieldValue,
                          ...selectedPlayer,
                        }}
                      />
                    </Col>
                    <Col>
                      <div className="d-flex">
                        <h5>
                          {selectedOpponent?.firstName +
                            " " +
                            selectedOpponent?.lastName}
                        </h5>
                        <h5 className="ml-1">
                          {opponentPartner?.userId &&
                            " - " +
                            opponentPartner?.firstName +
                            " " +
                            opponentPartner?.lastName}
                        </h5>
                      </div>
                      <ScoreForm
                        noOfSets={selectedMatch && selectedMatch.noOfSets}
                        scoreValues={values}
                        reason={selectedOpponent?.reason}
                        maxPointsOnSet={
                          selectedMatch && selectedMatch.maxPointsOnSet
                        }
                        {...{
                          userKey: "opponent",
                          setFieldValue,
                          ...selectedOpponent,
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                  <FormGroup>
                    <label className="mb-1">Day</label>
                    <DatePicker
                      selected={playingDay}
                      onChange={value => setPlayingDay(value)}
                      dateFormat="P"
                      className="form-control"
                      minDate={new Date(eventDetails?.phases?.playing?.startDate)}
                      maxDate={new Date(eventDetails?.phases?.playing?.endDate)}
                    />
                  </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <label className="mb-1">Time</label>
                    <DatePicker
                      selected={playingTime}
                      onChange={value => setPlayingTime(value)}
                      className="form-control"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption="Time"
                      dateFormat="hh:mm aa"
                    />
                  </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <label className="mb-1">Court</label>
                    <Input
                      type="text"
                      defaultValue={selectedMatch?.scheduler?.court}
                      name="scheduler.court"
                      {...{
                        handleChange,
                        handleBlur,
                        initialValues,
                        values,
                      }}
                    />
                  </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Update
                </Button>{" "}
                <Button color="secondary" onClick={() => setModal(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

const Matches = ({
  fetchMatches,
  fetchMainDraw,
  matches,
  mainDraw,
  competitionDetails,
  match: {params: {competitionId} = {}} = {},
  eventDetails,
}) => {
  const [modal, setModal] = useState(false)
  const [modalPhase, setModalPhase] = useState(false)
  const {addToast} = useToasts()
  const globalState = useContext(socketStore)
  const {state: {socket: {socketClientId} = {}} = {}} = globalState
  const [refetchData, setRefetchData] = useState(false)

  const mlNumber = competitionDetails?.phases[0]?.mlNumber;

  useEffect(() => {
    fetchMatches({competitionId})
    fetchMainDraw(competitionId, mlNumber)
    setRefetchData(false)
  }, [fetchMatches, fetchMainDraw, competitionId, mlNumber, refetchData])

  const listenForResponse = async ({success, message}) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    setRefetchData(true)
    setModal(false)
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  let phaseDrawType;
  if (mainDraw?.phases) {
    phaseDrawType = mainDraw?.phases?.[0]?.[0]?.competition?.drawType;
  }

  return (
    <div>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="matches-response"
      />
      {!mainDraw?.matches?.length ? (
        <NoMatches/>
      ) : (
        <>
          <br/>
          {/* Competition Info (Groups / Eliminatory */}
          <h3>Competition ({competitionDetails?.drawModel})</h3>
          <MatchesList
            matches={matches}
            drawType={competitionDetails?.drawModel}
            mainDraw={mainDraw}
            eventDetails={eventDetails}
            {...{
              modal: modal,
              setModal: setModal,
              competitionDetails,
              socketClientId,
            }}
          />

          {/* Competition Groups with Phases */}
          {mainDraw?.phases?.length ? (<>
            <h3>Phase ({phaseDrawType})</h3>

            <MatchesList
              matches={matches}
              drawType={phaseDrawType}
              mainDraw={mainDraw}
              eventDetails={eventDetails}
              {...{
                modal: modalPhase,
                setModal: setModalPhase,
                competitionDetails,
                socketClientId,
              }}
            />
          </>) : ''}
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({
  matches: {
    matches = [],
    mainDraw = {},
  } = {},
  session: {userDetails} = {},
}) => ({
  matches,
  mainDraw,
  userDetails,
})

export default connect(
  mapStateToProps,
  {
    fetchMatches: getMatches,
    fetchMainDraw: getMainDraw
  })(Matches)
