import React, { useState } from "react"
import { Row, Col, FormGroup, Nav, NavItem, NavLink, Label } from "reactstrap"
import { Field } from "formik"
import Input from "components/Common/Input"

const packageTabs = [
  { value: "ro", label: "Ro" },
  { value: "en", label: "En" },
]

const Package = ({
  errors,
  touched,
  values,
  initialValues,
  settings: { playerPackages } = {},
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [activeTab, setActiveTab] = useState("ro")
  return (
    <div>
      <div className="tabs">
        <Nav className="mt-2 mb-3 pl-0" pills>
          {packageTabs?.map(({ label, value }) => {
            return (
              <NavItem className="mr-2" key={label}>
                <NavLink
                  onClick={() => {
                    setActiveTab(value)
                  }}
                  active={activeTab === value ? true : false}
                >
                  {label}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
      </div>
      <Row>
        <Col>
          <FormGroup>
            <Input
              type="text"
              placeholder="Player package title"
              label="Player package title"
              name={
                activeTab === "en"
                  ? "playerPackageTitleEn"
                  : "playerPackageTitle"
              }
              value={
                activeTab === "en"
                  ? values?.playerPackageTitleEn
                  : values?.playerPackageTitle
              }
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              placeholder="Player package summary"
              label="Player package summary"
              name={
                activeTab === "en"
                  ? "playerPackageSummaryEn"
                  : "playerPackageSummary"
              }
              value={
                activeTab === "en"
                  ? values?.playerPackageSummaryEn
                  : values?.playerPackageSummary
              }
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              placeholder="Player package description"
              label="Player package description"
              name={
                activeTab === "en"
                  ? "playerPackageDescriptionEn"
                  : "playerPackageDescription"
              }
              value={
                activeTab === "en"
                  ? values?.playerPackageDescriptionEn
                  : values?.playerPackageDescription
              }
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </FormGroup>
          <Label className="mb-1">Player tShirt</Label>
          <FormGroup>
            <Field type="checkbox" name="tShirt" />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Package
