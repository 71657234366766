import Details from "./Details"
import Competitions from "./Competitions"
import Announcements from "./Annoncements/index"

const EventTabs = [
  { url: "details", label: "Event details", Component: Details },
  { url: "competitions", label: "Competitions", Component: Competitions },
  { url: "announcements", label: "Announcements", Component: Announcements },
]

export default EventTabs
