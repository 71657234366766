import React, {useContext, useEffect, useState} from "react";
import { Container } from "reactstrap";
import toastr from "toastr";

import { store as socketStore } from "components/Common/Socket/store"
import { createAdmin } from "./api";
import FormData from "./FormData";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const CreateAdmin = props => {
  const {
    history,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } = {} } = {} } = globalState;

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message);
        setIsLoading(false);
        return;
      }

      toastr.success(message);
      history.goBack();
    };

    if (socket) {
      socket.removeAllListeners(
        "accounts-response"
      );
      socket.on(
        "accounts-response",
        listenForResponse
      );

      return () => {
        socket.removeAllListeners(
          "accounts-response"
        );
      }
    }
  }, [socketClientId]); // eslint-disable-line

  const handleSubmit = async (event, values) => {
    setIsLoading(true);

    const response = await createAdmin({
      socketClientId,
      status: 'activated',
      ...values,
    });

    if (!response.success) {
      toastr.error(response.message || 'Account could not be created.');
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs path="/accounts/admins" title="Admins" breadcrumbItem="Create admin" />
          <FormData
            handleSubmit={handleSubmit}
            history={props.history}
            loading={isLoading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateAdmin;
