import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import { GET_PENALTIES } from "./actionTypes"
import { apiError, getPenaltiesSuccess } from "./actions"

function* getPenalties({ payload: { competitionId } }) {
  try {
    const { data } = yield call(api.getPenalties, competitionId)
    yield put(getPenaltiesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* penaltiesSaga() {
  yield takeEvery(GET_PENALTIES, getPenalties)
}

export default penaltiesSaga
