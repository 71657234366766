import { get, put, post, patch, del } from "helpers/api_helper"
import toastr from "toastr"

export const getClubsLeadsLocations = async filter => {
  let response

  try {
    const filters = new URLSearchParams(Object.assign(filter)).toString()
    response = await get(`/clubs-leads/locations?${filters}`)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}

export const getClubsLeads = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/clubs-leads?${filters}`)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}

export const getClubLeadInfo = async id => {
  let response
  try {
    response = await get(`/clubs-leads/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}

export const updateClubLead = async (id, data) => {
  let response
  try {
    response = await put(`/clubs-leads/${id}`, data)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}

export const updateClubLeadStatus = async (id, data) => {
  let response
  try {
    response = await patch(`/clubs-leads/${id}`, data)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}

export const deleteClubLead = async id => {
  let response
  try {
    response = await del(`/clubs-leads/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)
  }
  return response
}
