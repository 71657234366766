import {
  GET_CLUBS,
  GET_CLUBS_SUCCESS,
  GET_CLUB_INFO,
  GET_CLUB_INFO_SUCCESS,
  GET_CLUB_OWNER,
  GET_CLUB_OWNER_SUCCESS,
  GET_CLUB_ORGANIZERS,
  GET_CLUB_ORGANIZERS_SUCCESS,
  GET_CLUB_LEAD_INFO,
  GET_CLUB_LEAD_INFO_SUCCESS,
  GET_CLUB_ARCHIVE_INFO,
  GET_CLUB_ARCHIVE_INFO_SUCCESS,
  API_ERROR,
  GET_CLUB_LOCATIONS,
  GET_CLUB_LOCATIONS_SUCCESS,
  UNSET_CLUB_OWNER,
} from "./actionTypes"

export const getClubsLocations = filters => {
  return {
    type: GET_CLUB_LOCATIONS,
    filters: filters,
  }
}

export const getClubsLocationsSuccess = data => {
  return {
    type: GET_CLUB_LOCATIONS_SUCCESS,
    data,
  }
}

export const getClubArchiveInfo = id => {
  return {
    type: GET_CLUB_ARCHIVE_INFO,
    id,
  }
}

export const getClubArchiveInfoSuccess = clubArchiveInfo => {
  return {
    type: GET_CLUB_ARCHIVE_INFO_SUCCESS,
    payload: clubArchiveInfo,
  }
}

export const getClubInfo = id => {
  return {
    type: GET_CLUB_INFO,
    id,
  }
}

export const getClubInfoSuccess = clubInfo => {
  return {
    type: GET_CLUB_INFO_SUCCESS,
    payload: clubInfo,
  }
}

export const getClubOwner = id => {
  return {
    type: GET_CLUB_OWNER,
    id,
  }
}

export const getClubOwnerSuccess = clubOwner => {
  return {
    type: GET_CLUB_OWNER_SUCCESS,
    payload: clubOwner,
  }
}

export const getClubOrganizers = id => {
  return {
    type: GET_CLUB_ORGANIZERS,
    id,
  }
}

export const getClubOrganizersSuccess = organizers => {
  return {
    type: GET_CLUB_ORGANIZERS_SUCCESS,
    payload: organizers,
  }
}

export const getClubLeadInfo = id => {
  return {
    type: GET_CLUB_LEAD_INFO,
    id,
  }
}

export const getClubLeadInfoSuccess = clubInfo => {
  return {
    type: GET_CLUB_LEAD_INFO_SUCCESS,
    payload: clubInfo,
  }
}

export const getClubs = (filters, options) => {
  return {
    type: GET_CLUBS,
    filters,
    options,
    payload: [],
  }
}

export const getClubsSuccess = clubs => {
  return {
    type: GET_CLUBS_SUCCESS,
    payload: clubs,
  }
}

export const unsetClubOwner = () => {
  return {
    type: UNSET_CLUB_OWNER,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
