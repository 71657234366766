import {
  GET_CLUBS_LEADS,
  GET_CLUBS_LEADS_SUCCESS,
  GET_CLUB_LEAD_INFO,
  GET_CLUB_LEAD_INFO_SUCCESS,
  GET_CLUBS_LEADS_LOCATIONS_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  clubsLeads: {},
  clubLeadInfo: {},
  clubLeadsLocations: [],
  loading: false,
}

const clubsLeads = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUBS_LEADS_LOCATIONS_SUCCESS:
      state = {
        ...state,
        clubLeadsLocations: action?.data,
      }
      break
    case GET_CLUBS_LEADS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUBS_LEADS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubsLeads: action.payload,
      }
      break
    case GET_CLUB_LEAD_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUB_LEAD_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubLeadInfo: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default clubsLeads
