import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from "reactstrap"
import { Formik, Form } from "formik"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { isEmpty } from "lodash"
import { getReports } from "./actions"
import AdvancedFilters from "./AdvancedFilters"
import listColumns from "./listColumns"
import { updateReport, getReport } from "./api"

const ReportsManagement = ({
  fetchReports,
  reportsState,
}) => {
  const initialOptions = {
    page: 1,
    limit: 15,
  }
  const urlParams = new URLSearchParams(window.location.search);
  const reportId = urlParams.get("reportId")
  const reportedId = urlParams.get("reportedId")

  const [viewReportModal, setViewReportModal] = useState(false)
  const [reportModalContent, setReportModalContent] = useState({});
  const [options, setOptions] = useState(initialOptions)

  const toggle = () => setViewReportModal(!viewReportModal)

  const pageOptions = {
    page: options?.page,
    sizePerPage: 15,
    totalSize: reportsState?.totalLength,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const [activeFilters, setActiveFilters] = useState({})

  useEffect(() => {
    if (!isEmpty(activeFilters)) {
      fetchReports(options, activeFilters)
    }
  }, [options, activeFilters, fetchReports])

  useEffect(() => {
    async function getReportData(reportId) {
      const response = await getReport(reportId);
      if (response?.success === true) {
        setReportModalContent(response?.data);
        setViewReportModal(true);
      }      
    }
    if (reportId) {
     getReportData(reportId);
    }
  }, [reportId])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <h4>Reports Management</h4>
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={reportsState?.reports || []}
                        columns={listColumns({fetchReports, options, activeFilters, setViewReportModal, setReportModalContent})}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <div className="filter-area">
                              <div
                                className={'justify-content-between d-flex'}
                              >
                                <Formik
                                  initialValues={{
                                    search: reportedId ? reportedId : ''
                                  }}
                                >
                                  {({
                                    touched,
                                    initialValues,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    values,
                                  }) => {
                                    setActiveFilters(values)
                                    return (
                                      <Form className="w-100">
                                        <AdvancedFilters
                                          touched={touched}
                                          initialValues={initialValues}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          values={values}
                                        />
                                      </Form>
                                    )
                                  }}
                                </Formik>
                              </div>
                            </div>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={(type, data) =>
                                      setOptions({
                                        ...options,
                                        page: data?.page,
                                      })
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>

                                {reportsState?.reports?.length == 0 && (
                                  <NoReports />
                                )}
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
              <ViewReportModal
                viewReportModal={viewReportModal}
                reportModalContent={reportModalContent}
                fetchReports={fetchReports}
                options={options}
                activeFilters={activeFilters}
                toggle={toggle}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

const NoReports = () => {
  return (
    <Card>
      <CardBody>
        <p className="mb-0">There are no reports yet</p>
      </CardBody>
    </Card>
  )
}

const ViewReportModal = ({
  toggle,
  viewReportModal,
  reportModalContent,
  fetchReports,
  options,
  activeFilters,
}) => {
  const report = reportModalContent;
  return (
    <Modal
      isOpen={viewReportModal}
      toggle={() => {
        toggle()
      }}
      size="lg"
    >
      <ModalHeader
        toggle={() => {
          toggle()
        }}
      >
        <h5>Report Details</h5>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <h5>Reported Entity</h5>
            {report?.reported?.userId
              && <Table className="table-nowrap">
              <tr><th>Name</th><td>{report?.reported?.firstName} {report?.reported?.lastName}</td></tr>
              <tr><th>Email</th><td>{report?.reported?.email}</td></tr>
              <tr><th>User Id</th>
                <td>
                  <Link to={`/accounts/players/${report?.reported?.id}`} target="_blank">
                    {report?.reported?.userId}
                  </Link>
                </td>
              </tr>
            </Table>}
            {report?.reported?.matchId
              && <Table className="table-nowrap">
              <tr><th>Match Id</th><td>{`#FM${report?.reported?.matchId.toString().padStart(5, '0')}`}</td></tr>
            </Table>}
          </Col>
          <Col md={6}>
            <h5>Reporter</h5>
            <Table className="table-nowrap">
              <tr><th>Name</th><td>{report?.reporter?.firstName} {report?.reporter?.lastName}</td></tr>
              <tr><th>Email</th><td>{report?.reporter?.email}</td></tr>
              <tr><th>User Id</th><td>{report?.reporter?.userId}</td></tr>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Reason</h5>
            <p>{report?.reason}</p>
          </Col>
          <Col>
            {report?.reasonDescription && (
            <>
              <h5>Comments</h5>
              <p>{report?.reasonDescription}</p>
            </>
          )}
          </Col>
        </Row>
        <Row>
          {report?.reported?.userId && <Col>
            <h5>Reported blocked by player:</h5> {report?.reportedBlocked ? 'Yes' : 'No'}
          </Col>}
          <Col>
            <h5>Report status:</h5>
            <p className={report?.status === 'resolved' ? 'text-success text-capitalize' : 'text-primary text-capitalize'}>{report?.status}</p> 
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color={report?.status === 'resolved' ? 'primary' : 'success'}
          onClick={async () => {
              await updateReport({reportId: report?._id, status: report?.status === 'unresolved' ? 'resolved' : 'unresolved'})
              await fetchReports(options, activeFilters)
              toggle()
          }}
        >
          {report?.status === 'unresolved' ? 'Resolve' : 'Unresolve'}
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            toggle()
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStatetoProps = ({
  reports: reportsState = {},
}) => ({
  reportsState,
})

export default connect(mapStatetoProps, {
  fetchReports: (filters, options) => getReports(filters, options),
})(ReportsManagement)
