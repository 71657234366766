import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {GET_MATCHES, GET_LOGS, GET_MAIN_DRAW} from "./actionTypes"
import {apiError, getMatchesSuccess, getLogsSuccess, getMainDrawSuccess} from "./actions"

function* getLogs({ payload: { comptId, filters, activePage } }) {
  try {
    const { data } = yield call(api.getLogs, comptId, filters, activePage)
    yield put(getLogsSuccess(data))
  } catch (error) {
    console.log("error")
  }
}

function* getMatches({ payload: { competitionId } }) {
  try {
    const { data } = yield call(api.getMatches, competitionId)
    yield put(getMatchesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getMainDraw({ payload: { competitionId, mlNumber } }) {
  try {
    const { data } = yield call(api.getMainDraw, competitionId, mlNumber)
    yield put(getMainDrawSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* matchesSaga() {
  yield takeEvery(GET_MATCHES, getMatches)
  yield takeEvery(GET_MAIN_DRAW, getMainDraw)
  yield takeEvery(GET_LOGS, getLogs)
}

export default matchesSaga
