import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

import {
  GET_FRIENDLY_MATCHES,
  GET_FRIENDLY_MATCH,
} from "./actionTypes"
import {
  apiError,
  getFriendlyMatchesSuccess,
  getFriendlyMatchSuccess,
} from "./actions"

function* getFriendlyMatches({ payload }) {
  try {
    const { data } = yield call(api.getFriendlyMatches, { payload })
    yield put(getFriendlyMatchesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getFriendlyMatch({ payload }) {
  try {
    const { data } = yield call(api.getFriendlyMatch, { payload })
    yield put(getFriendlyMatchSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* friendlyMatchesSaga() {
  yield takeEvery(GET_FRIENDLY_MATCHES, getFriendlyMatches)
  yield takeEvery(GET_FRIENDLY_MATCH, getFriendlyMatch)
}

export default friendlyMatchesSaga;
