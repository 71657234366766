import React, { useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import { Link } from "react-router-dom"
import { connect } from "react-redux"
import classnames from "classnames"

import CompetitionTabs from "./Tabs"
import { getEventDetails as getEventDetailsAction } from "../../../actions"
import { deleteCompetition, enableCompetition } from "../../../api"

const Competition = ({ getEventDetails, eventDetails, ...props }) => {
  const [activeTabState, setActiveTab] = useState("details")
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const { id, competitionId, history } = props.match.params

  const activeTab = props.match.params.tab || activeTabState
  const changeTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    getEventDetails(id)
  }, [getEventDetails, id])

  const [event] = eventDetails || []

  const [competitionDetails] =
    (event && event.competitions?.filter(({ _id }) => _id === competitionId)) ||
    []

  const propsHistory = props.history

  const ActiveTab = CompetitionTabs?.filter(({ url }) => url === activeTab)[0]
    .Component
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/events-management">Events</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/events/${event?._id}/competitions`}>
              Event: {event?.name}{" "}
            </Link>
          </BreadcrumbItem>

          {competitionDetails?._id ? (
            <BreadcrumbItem active>
              Competition: {competitionDetails?.competitionId}{" "}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem active>Create New Competition</BreadcrumbItem>
          )}
        </Breadcrumb>

        <div className="d-flex justify-content-between">
          <div>
            {competitionDetails ? (
              <h4>
                {"Edit Competition" +
                  " Id: " +
                  competitionDetails?.competitionId}{" "}
                {` - Level ${competitionDetails?.level} - ${competitionDetails?.gameType} `}
                (
                {competitionDetails?.status === "ACCEPTED" ||
                competitionDetails?.status === "CREATED"
                  ? "ACTIVE"
                  : competitionDetails?.status === "MAINTENANCE"
                  ? "MAINTENANCE"
                  : "INACTIVE"}
                )
              </h4>
            ) : (
              <h4>Create Competition</h4>
            )}
          </div>

          <h4>
            {id
              ? event?.currentPhase?.status
                ? event?.currentPhase?.status
                : "No Current Stage"
              : ""}
          </h4>
        </div>
      
        {/* if we have a competition id display tabs for details and entries*/}
        <div className="tabs">
          {competitionId && (
            <Nav className="mb-0 d-flex justify-content-between" pills>
              <div className="d-flex">
                {CompetitionTabs.map(({ label, url }) => {
                  return (
                    <NavItem key={label}>
                      <NavLink
                        className={classnames({
                          active: activeTab === url,
                        })}
                        onClick={() => {
                          props.match.params.tab
                            ? props.history.push(
                                `/events/${id}/competitions/${competitionId}/${url}` //eslint-disable-line
                              ) //eslint-disable-line
                            : changeTab(props.match.params.tab)
                        }}
                      >
                        {label}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </div>
              <div>
                <Button
                  onClick={async () => {
                    await enableCompetition(
                      id,
                      competitionId,
                      competitionDetails?.status === "MAINTENANCE"
                        ? "enable"
                        : "maintenance"
                    )
                    await getEventDetails(id)
                  }}
                  color="warning"
                  className="ml-1"
                >
                  {competitionDetails?.status === "MAINTENANCE"
                    ? "Resume Registrations"
                    : "Quick Pause"}
                </Button>
                <Button
                  onClick={async () => {
                    let type
                    competitionDetails?.status === "ACCEPTED" ||
                    competitionDetails?.status === "CREATED"
                      ? (type = "disable")
                      : (type = "enable")

                    await enableCompetition(id, competitionId, type)
                    await getEventDetails(id)
                  }}
                  color="primary"
                  className="ml-1"
                >
                  {competitionDetails?.status === "ACCEPTED" ||
                  competitionDetails?.status === "CREATED"
                    ? "Disable Competition"
                    : "Enable Competition"}
                </Button>
                <Button
                  onClick={() => toggle()}
                  color="danger"
                  className="ml-1"
                >
                  Delete Competition
                </Button>
              </div>
            </Nav>
          )}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Row>
                <Col sm="12">
                  <ActiveTab
                    eventDetails={eventDetails[0]}
                    competitionDetails={competitionDetails}
                    getEventDetails={getEventDetails}
                    id={id}
                    match={props.match}
                    history={history}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        {/* DELETE COMPETITION CONFIRMATION MODAL*/}
        <Modal isOpen={modal} toggle={() => toggle()}>
          <ModalHeader toggle={toggle}>
            Remove competition confirmation
          </ModalHeader>
          <ModalBody>
            All information entered will be lost if you delete the competition.
            Are you sure?
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={async () => {
                await deleteCompetition(event?._id, competitionId)
                propsHistory.push(`/events/${event?._id}/competitions`)
              }}
            >
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={() => toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  )
}

const mapStateToProps = ({ events: { eventDetails = [] } = {} }) => ({
  eventDetails,
})

export default connect(mapStateToProps, {
  getEventDetails: getEventDetailsAction,
})(Competition)
