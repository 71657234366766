import React, { useState } from "react"

import { Row, Col } from "reactstrap"
import Input from "components/Common/Input"
import Select from "react-select"

const playerActions = [
  { label: "None", value: { type: "NONE", reason: "", score: [] } },
  { label: "Didn't show up", value: { reason: "NO_SHOW", type: "BYE" } },
  { label: "Abandoned", value: { reason: "ABANDONED", type: "BYE" } },
]

const ScoreForm = ({
  noOfSets = 3,
  setFieldValue,
  maxPointsOnSet = 0,
  userKey,
  score = [],
  reason,
  scoreValues,
}) => {
  const [userScore, setUserScore] = useState(score)
  const [thirdSet] = score.filter(({ set }) => set === 3)

  const setsScores = [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
  ]

  return (
    <div>
      {new Array(noOfSets - 1).fill().map((el, index) => {
        const [setScore] = userScore.filter(({ set }) => set === index + 1)
        return (
          <Row>
            <Col className="mb-2 mt-1">
              <label className="mt-1">
                {`Set ${index + 1} points`}
                <Select
                  defaultValue={{
                    value: setScore?.points,
                    label: setScore?.points,
                  }}
                  className="width-full-width"
                  onChange={({ value }) => {
                    const currentScore = userScore.filter(
                      ({ set }) => set !== index + 1
                    )

                    setUserScore([
                      ...currentScore,
                      {
                        set: index + 1,
                        points: value,
                        tie: setScore?.tie || "",
                      },
                    ])
                    setFieldValue(userKey, {
                      score: [
                        ...currentScore,
                        {
                          set: index + 1,
                          points: value,
                          tie: setScore?.tie || "",
                        },
                      ],
                    })
                  }}
                  options={setsScores}
                />
              </label>
              <label className="mt-0">
                {`Set ${index + 1} tie`}
                <Input
                  defaultValue={setScore?.tie}
                  onChange={e => {
                    const value = e.target.value
                    const currentScore = userScore.filter(
                      ({ set }) => set !== index + 1
                    )

                    setUserScore([
                      ...currentScore,
                      {
                        set: index + 1,
                        points: setScore?.points || 0,
                        tie: value,
                      },
                    ])
                    setFieldValue(userKey, {
                      score: [
                        ...currentScore,
                        {
                          set: index + 1,
                          points: setScore?.points || 0,
                          tie: value,
                        },
                      ],
                    })
                  }}
                  placeholder="Set tie break score"
                  type="number"
                />
              </label>
            </Col>
          </Row>
        )
      })}
      <label className="mt-1">
        {`Match tie-break`}
        <Input
          type="number"
          defaultValue={thirdSet?.points}
          className="width-full-width"
          onChange={e => {
            const currentScore = userScore.filter(({ set }) => set !== 3)

            setUserScore([
              ...currentScore,
              {
                set: 3,
                points: Number(e.target.value),
              },
            ])
            setFieldValue(userKey, {
              score: [
                ...currentScore,
                {
                  set: 3,
                  points: Number(e.target.value),
                },
              ],
            })
          }}
        />
      </label>
      <label className="mt-1">
        {`Player actions`}
        <Select
          className="width-full-width"
          defaultValue={playerActions?.filter(
            option => option?.value?.reason === reason
          )}
          onChange={({ value }) => {
            setFieldValue(userKey, {
              score: scoreValues[userKey]?.score,
              ...value,
            })
          }}
          options={playerActions}
        />
      </label>
    </div>
  )
}

export default ScoreForm
