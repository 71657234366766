import React, { useEffect, useState, useReducer } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map, isEmpty } from "lodash"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap"

import defaultProfilePicture from "../../../../assets/images/users/default-profile-picture.png"
import Breadcrumbs from "components/Common/Breadcrumb"
import MiniCards from "./mini-card"
import { getOrganizerInfo, getOwnerClub, getTournaments } from "../actions"
import { getClubs } from "../../ClubAccounts/api"

const OrganizerInfo = props => {
  const {
    organizerInfo,
    userId,
    impersonateKey,
    getOrganizerInfo,
    clubInfo,
    getOwnerClub,
    getTournaments,
    tournaments,
    loading,
    match: { params },
  } = props

  const [stats, setStats] = useState([
    {
      title: "Assigned locations",
      iconClass: "bx-check-circle",
      text: organizerInfo?.organizerInfo?.assignedClubs?.length || "0",
    },
    {
      title: "Organized tournaments",
      iconClass: "bx-package",
      text: "0",
    },
  ])
  const [assignedClubs, setAssignedClubs] = useState()

  useEffect(() => {
    getOrganizerInfo(params.id)
  }, [getOrganizerInfo, params.id])

  useEffect(() => {
    if (organizerInfo?.role === "club") {
      getOwnerClub(organizerInfo.id)
    }

    if (!isEmpty(organizerInfo?.organizerInfo?.assignedClubs)) {
      const getAssignedClubs = async () => {
        const {
          data: { results },
        } = await getClubs({
          ids: organizerInfo?.organizerInfo?.assignedClubs,
        })

        stats[0].text = results.length.toString()
        setStats(stats)

        setAssignedClubs(results)
      }

      getAssignedClubs()
    }
  }, [getOwnerClub, organizerInfo, stats])

  useEffect(() => {
    getTournaments(params.id)
  }, [getTournaments, params.id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Players"
            path="/accounts/organizers"
            breadcrumbItem="Organizer Profile"
          />
          {isEmpty(clubInfo) && isEmpty(organizerInfo) ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="d-flex justify-content-between align-items-center">
                    {organizerInfo.role === "player" ? (
                      <>
                        <h4 className="mb-0">
                          #{organizerInfo.userId} - {organizerInfo.firstName}{" "}
                          {organizerInfo.lastName}
                        </h4>
                      </>
                    ) : (
                      <>
                        <h4 className="mb-0">
                          #{organizerInfo.userId} -{" "}
                          {clubInfo?.clubInfo?.businessName}
                        </h4>
                      </>
                    )}
                    <div>
                      <Button
                        onClick={async () => {
                          try {
                            const req = new XMLHttpRequest()
                            req.onreadystatechange = function () {
                              if (
                                this.readyState == 4 &&
                                this.status == 200
                              ) {
                                window.open(process.env.REACT_APP_OS_URL)
                              }
                            }
                            req.open(
                              "POST",
                              `${process.env.REACT_APP_OS_URL}/api/auth`,
                              true
                            )
                            req.withCredentials = true;
                            req.setRequestHeader(
                              "Content-type",
                              "application/x-www-form-urlencoded"
                            )
                            req.send(
                             `hash=${impersonateKey}&userId=${userId}&email=${organizerInfo?.email}`
                            )
                          } catch {}
                        }}
                        className="btn btn-secondary waves-effect waves-light btn-md ml-3"
                        target="_blank"
                      >
                        Impersonate
                      </Button>

                      <Link
                        to={`/accounts/${
                          organizerInfo.role === "player"
                            ? `players/${organizerInfo.id}`
                            : `clubs/${clubInfo?.id}`
                        }/edit`}
                        className="btn btn-light waves-effect waves-light btn-md ml-3"
                      >
                        Edit
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3"></div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        {/* <img src={profile1} alt="" className="img-fluid" /> */}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm={4}>
                        <div className="avatar-lg profile-user-wid mb-4">
                          <img
                            src={`${
                              organizerInfo.profilePicture
                                ? `${process.env.REACT_APP_API_URL}/file/${organizerInfo.profilePicture}`
                                : defaultProfilePicture
                            }`}
                            alt=""
                            className="img-thumbnail rounded-circle"
                          />
                        </div>
                        <h5 className="font-size-15 text-truncate">
                          {organizerInfo.role === "player"
                            ? `${organizerInfo.firstName} ${organizerInfo.lastName}`
                            : organizerInfo?.clubInfo?.businessName}
                        </h5>
                      </Col>

                      <Col sm={8}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="4">
                              <h5 className="font-size-15">Role</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {organizerInfo.role}
                              </p>
                            </Col>
                            <Col xs="4">
                              <h5 className="font-size-15">Status</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {organizerInfo.status}
                              </p>
                            </Col>
                            <Col xs="4">
                              <h5 className="font-size-15">Membership</h5>
                              <p className="text-muted mb-0 text-uppercase">
                                {organizerInfo.membership?.plan || "FREE"}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <p className="text-muted mb-0 text-truncate">
                          ID: {organizerInfo.userId}
                        </p>
                        <p className="text-muted mb-0 text-truncate">
                          E-mail:{" "}
                          <a href={"mailto:" + organizerInfo.email}>
                            {organizerInfo.email}
                          </a>
                        </p>
                      </Col>
                      <Col sm={4} className="text-right">
                        <div className="mt-3">
                          <a
                            className="btn btn-primary waves-effect waves-light btn-sm"
                            href={`${process.env.REACT_APP_WEB_URL}/organizers/${organizerInfo.id}`}
                            target="_blank"
                          >
                            View Profile{" "}
                            <i className="mdi mdi-arrow-right ms-1" />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {organizerInfo.role === "player" && (
                  <>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          Player profile info
                        </CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Manage Player:</th>
                                <td>
                                  <Link
                                    to={`/accounts/players/${organizerInfo.id}`}
                                  >
                                    #{organizerInfo.userId}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Birth date:</th>
                                <td>
                                  {moment(organizerInfo.birthDate).format(
                                    "D MMM YYYY"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Location:</th>
                                <td>{organizerInfo.location?.name}</td>
                              </tr>
                              <tr>
                                <th scope="row">Phone:</th>
                                <td>{organizerInfo.phone}</td>
                              </tr>
                              <tr>
                                <th scope="row">Gender:</th>
                                <td className="text-capitalize">
                                  {organizerInfo.gender}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Level single:</th>
                                <td>{organizerInfo.gameLevelSingle}</td>
                              </tr>
                              <tr>
                                <th scope="row">Level double:</th>
                                <td>{organizerInfo.gameLevelDouble}</td>
                              </tr>
                              <tr>
                                <th scope="row">Height:</th>
                                <td>{organizerInfo.height || "n/a"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Weight:</th>
                                <td>{organizerInfo.weight || "n/a"}</td>
                              </tr>
                              <tr>
                                <th scope="row">Dominant Hand:</th>
                                <td>{organizerInfo.dominantHand}</td>
                              </tr>
                              <tr>
                                <th scope="row">T-shirt size:</th>
                                <td>{organizerInfo.tShirtSize}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Organizer info</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">First name:</th>
                                <td>
                                  {organizerInfo.organizerInfo?.firstName}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Last name:</th>
                                <td>{organizerInfo.organizerInfo?.lastName}</td>
                              </tr>
                              <tr>
                                <th scope="row">Email:</th>
                                <td>{organizerInfo.organizerInfo?.email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Phone:</th>
                                <td>{organizerInfo.organizerInfo?.phone}</td>
                              </tr>
                              <tr>
                                <th scope="row">Location:</th>
                                <td>
                                  {organizerInfo.organizerInfo?.location?.name}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </>
                )}

                {organizerInfo.role === "club" && (
                  <>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Club owner info</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Manage club:</th>
                                <td>
                                  <Link to={`/accounts/clubs/${clubInfo?.id}`}>
                                    #{clubInfo?.clubId}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">First name:</th>
                                <td>{organizerInfo.firstName}</td>
                              </tr>
                              <tr>
                                <th scope="row">Last name:</th>
                                <td>{organizerInfo.lastName}</td>
                              </tr>
                              <tr>
                                <th scope="row">Email:</th>
                                <td>{organizerInfo.email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Phone:</th>
                                <td>{organizerInfo.phone}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">Organizer info</CardTitle>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Company name:</th>
                                <td>
                                  {organizerInfo.organizerInfo?.companyName}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Unique registration number:</th>
                                <td>
                                  {
                                    organizerInfo.organizerInfo
                                      ?.uniqueRegistrationNumber
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Representative first name:</th>
                                <td>
                                  {organizerInfo.organizerInfo?.firstName}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Representative last name:</th>
                                <td>{organizerInfo.organizerInfo?.lastName}</td>
                              </tr>
                              <tr>
                                <th scope="row">Email:</th>
                                <td>{organizerInfo.organizerInfo?.email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Location:</th>
                                <td>
                                  {organizerInfo.organizerInfo?.location?.name}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </>
                )}
              </Col>

              <Col xl="6">
                <Row>
                  {map(stats, (card, key) => (
                    <MiniCards
                      title={card.title}
                      text={card.text}
                      iconClass={card.iconClass}
                      key={"_card_" + key}
                    />
                  ))}
                </Row>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Assigned locations</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th>Club name</th>
                            <th>Location</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(assignedClubs, (location, key) => (
                            <tr key={key}>
                              <td className="text-capitalize">
                                <Link to={`/accounts/clubs/${location.id}`}>
                                  {location.clubName}
                                </Link>
                              </td>
                              <td>{location.location?.name || "n/a"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Tournaments</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th>Tournament name</th>
                            <th>Start date</th>
                            <th>End date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(tournaments?.results, (item, key) => (
                            <tr key={key}>
                              <td className="text-capitalize">
                                <Link to={`/events/${item._id}/details`}>
                                  {item.name}
                                </Link>
                              </td>
                              <td>
                                {moment(item.startDate).format("D MMM YYYY")}
                              </td>
                              <td>
                                {moment(item.endDate).format("D MMM YYYY")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

OrganizerInfo.propTypes = {
  organizerInfo: PropTypes.object,
  getOrganizerInfo: PropTypes.func,
  match: PropTypes.object,
  clubInfo: PropTypes.object,
  getOwnerClub: PropTypes.func,
  getTournaments: PropTypes.func,
  tournaments: PropTypes.object,
}

const mapStateToProps = ({ organizers, session: { userDetails } = {}, }) => ({
  loading: organizers.loading,
  organizerInfo: organizers.organizerInfo,
  clubInfo: organizers.organizerClubInfo,
  tournaments: organizers.tournaments,
  userId: userDetails.userId,
  impersonateKey: userDetails.impersonateKey,
})

const mapDispatchToProps = dispatch => ({
  getOrganizerInfo: id => dispatch(getOrganizerInfo(id)),
  getOwnerClub: id => dispatch(getOwnerClub(id)),
  getTournaments: id => dispatch(getTournaments(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrganizerInfo))
