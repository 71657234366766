import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAILS,
  GET_EVENT_DETAILS_SUCCESS,
  API_ERROR,
  GET_PENALTIES,
  GET_PENALTIES_SUCCESS,
  GET_EVENTS_LOCATIONS_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  events: [],
  eventDetails: [],
  eventsLocations: [],
  penaltiesManager: [],
  loading: false,
}

const events = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_LOCATIONS_SUCCESS:
      state = {
        ...state,
        eventsLocations: action?.payload,
      }
      break

    case GET_PENALTIES:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PENALTIES_SUCCESS:
      state = {
        ...state,
        penaltiesManager: action.payload,
        loading: false,
      }
      break
    case GET_EVENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_EVENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        events: action.payload,
      }
      break
    case GET_EVENT_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_EVENT_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        eventDetails: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default events
