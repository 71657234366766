import React, { useState, useEffect } from "react"
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabPane,
  TabContent,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import * as actions from "../actions"
import { Link } from "react-router-dom"
import Tabs from "./Tabs"
import { updateEventStatus, deleteEvent } from "../api"

const Event = ({
  getEvents,
  getEventDetails,
  eventDetails = {},
  history,
  match: { params: { id, tab: urlTab } = {} } = {},
}) => {
  const [activeTabState, setActiveTab] = useState("details")

  const activeTab = urlTab || activeTabState

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const [activeEvent] = eventDetails

  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const modalToggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (id) {
      getEventDetails(id)
    } else {
      getEventDetails("empty")
    }
    return () => getEventDetails("empty")
  }, [getEventDetails, id])

  const ActiveTab = Tabs.filter(({ url }) => url === activeTab)[0].Component

  const formatEventStatus = str => {
    return str
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
      .split(" ")
      .join("-")
      .toUpperCase()
  }

  const checkMaintenance = () => {
    if (activeEvent?.competitions?.length > 0) {
      for (var i = 0; i < activeEvent?.competitions?.length; i++) {
        const competition = activeEvent?.competitions[i]
        if (competition?.status !== "MAINTENANCE") {
          return false
        }
      }
      return true
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/events-management">Events</Link>
          </BreadcrumbItem>
          {activeEvent?._id ? (
            <BreadcrumbItem active>Event: {activeEvent?.name}</BreadcrumbItem>
          ) : (
            <BreadcrumbItem active>Create New Event</BreadcrumbItem>
          )}
        </Breadcrumb>
        <div className="d-flex justify-content-between">
          <div>
            {activeEvent?.name ? (
              <h4>
                {activeEvent?.name +
                  " - " +
                  activeEvent?.category?.charAt(0)?.toUpperCase() +
                  activeEvent?.category?.slice(1) +
                  " - Id: " +
                  activeEvent?.eventId}{" "}
                {checkMaintenance()
                  ? "(MAINTENANCE)"
                  : activeEvent?.status === "DISABLED"
                  ? "(INACTIVE)"
                  : "(ACTIVE)"}
              </h4>
            ) : (
              <h4>New Event</h4>
            )}
          </div>

          <h4>
            {id
              ? activeEvent?.currentPhase?.status
                ? formatEventStatus(activeEvent?.currentPhase?.status)
                : "No Current Stage"
              : ""}
          </h4>
        </div>
        <div className="tabs">
          {id && (
            <Nav className="mb-0 d-flex justify-content-between" pills>
              <div className="d-flex justify-content-between">
                {Tabs.map(({ label, url }) => {
                  return (
                    <NavItem key={label}>
                      <NavLink
                        className={classnames({
                          active: activeTab === url,
                        })}
                        onClick={() => {
                          urlTab
                            ? history.push(`/events/${id}/${url}`)
                            : toggle(url)
                        }}
                      >
                        {label}
                      </NavLink>
                    </NavItem>
                  )
                })}
              </div>
              {activeEvent?._id && (
                <div>
                  <Button
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_WEB_URL}/events/${activeEvent?._id}`
                      )
                    }
                  >
                    View <i className="mdi mdi-arrow-right ms-1" />
                  </Button>
                  <Button
                    onClick={async () => {
                      checkMaintenance()
                        ? await updateEventStatus(
                            activeEvent?._id,
                            "resumeMaintenance"
                          )
                        : await updateEventStatus(
                            activeEvent?._id,
                            "maintenance"
                          )
                      // resumeMaintenance || maintenance
                      await getEventDetails(id)
                    }}
                    color="warning"
                    className="ml-1"
                  >
                    {checkMaintenance()
                      ? "Disable Maintenance"
                      : "Enable Maintenance"}
                  </Button>
                  <Button
                    onClick={async () => {
                      setDeleteModal(false)
                      modalToggle()
                    }}
                    className="ml-1"
                    color="primary"
                  >
                    {activeEvent?.status === "DISABLED" ? "Enable" : "Disable"}
                  </Button>
                  <Button
                    onClick={async () => {
                      setDeleteModal(true)
                      modalToggle()
                    }}
                    className="ml-1"
                    color="danger"
                  >
                    Delete Event
                  </Button>
                </div>
              )}
            </Nav>
          )}
          <ConfirmModal
            modalToggle={modalToggle}
            modal={modal}
            deleteModal={deleteModal}
            activeEvent={activeEvent?._id}
            status={activeEvent?.status}
            getEvents={getEvents}
            history={history}
            getEventDetails={getEventDetails}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Row>
                <Col sm="12">
                  <ActiveTab
                    id={id}
                    getEvents={getEvents}
                    history={history}
                    activeEvent={activeEvent}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </div>
  )
}

const ConfirmModal = ({
  modal,
  modalToggle,
  activeEvent,
  getEvents,
  history,
  deleteModal,
  getEventDetails,
  status,
}) => {
  return (
    <Modal isOpen={modal} onClick={modalToggle}>
      <ModalHeader toggle={modalToggle}>
        {deleteModal
          ? "Delete Confirmation"
          : status === "DISABLED"
          ? "ENABLE EVENT"
          : "DISABLE EVENT"}
      </ModalHeader>
      <ModalBody>
        {deleteModal
          ? "All information entered will be lost if you delete the event. Are you sure?"
          : status === "DISABLED"
          ? "Are you sure you want to ENABLE this event? (ACTIVE events are visible on the Website)"
          : "Are you sure you want to DISABLE this event? (INACTIVE events are not visible on the Website)"}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={
            deleteModal
              ? async () => {
                  await deleteEvent(activeEvent)
                  await getEvents()
                  history.push("/events-management")
                }
              : async () => {
                  await updateEventStatus(
                    activeEvent,
                    status === "DISABLED" ? "accepted" : "disabled"
                  )
                  await getEventDetails(activeEvent)
                }
          }
        >
          Yes
        </Button>{" "}
        <Button color="secondary" onClick={modalToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStatetoProps = ({
  events: { events = [], eventDetails = {} } = {},
}) => ({
  events,
  eventDetails,
})

export default connect(mapStatetoProps, {
  getEvents: actions.getEvents,
  getEventDetails: actions.getEventDetails,
})(Event)
