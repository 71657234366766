import {
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  LOGIN,
  LOGOUT,
  API_ERROR
} from "./actionTypes"

const initialState = {
  error: "",
  userDetails: null,
  settings: null,
  loading: false,
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSION:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGOUT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SESSION_SUCCESS:
      state = {
        ...state,
        loading: false,
        userDetails: action.payload,
      }
      break
    case GET_SETTINGS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, userDetails: {}, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default session
