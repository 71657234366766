import React, { useContext, useEffect } from "react"
import { store as socketStore } from "./store"

const ListenerComponent = ({ topic, listenFunction }) => {
  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  useEffect(() => {
    if (socket) {
      socket.removeAllListeners(topic)
      if (typeof topic === "object") {
        return topic.forEach(tp => {
          socket.on(tp, listenFunction)
        })
      }
      return socket.on(topic, listenFunction)
    }
  }, [socketClientId]) // eslint-disable-line
  return <div></div>
}

export default ListenerComponent
