import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row, Spinner,
  Table,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb"
import { getAdminInfo } from "./actions";
import { updateStatus, resetPassword, deleteAdmin } from "./api";

const AdminProfile = props => {
  const {
    getAdminInfo,
    adminInfo,
    loading,
    match: { params },
    userDetails: { role },
  } = props;

  const [blockAlert, setBlockAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [activateAlert, setActivateAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [resetPasswordAlert, setResetPasswordAlert] = useState(false);
  const [alertTitle, setAlertTitle] = useState();
  const [alertDescription, setAlertDescription] = useState();

  useEffect(() => {
    getAdminInfo(params.id);
  }, [getAdminInfo, params.id]);

  const handleBlock = async () => {
    const result = await updateStatus(params.id, 'blocked');

    if (result?.success) {
      setBlockAlert(false);
      setSuccessAlert(true);
      setAlertTitle("Blocked");
      setAlertDescription("Account was successfully blocked.");
      getAdminInfo(params.id);
    } else {
      toastr.error(result?.message || 'Player could not be blocked.');
    }
  }

  const handleDelete = async () => {
    const result = await deleteAdmin(params.id);

    if (result?.success) {
      setDeleteAlert(false);
      toastr.success("Account was successfully deleted.");
      props.history.push('/accounts/admins');
    } else {
      toastr.error(result?.message || 'Account could not be deleted.');
    }
  }

  const handleActivate = async () => {
    const result = await updateStatus(params.id, 'activated');

    if (result?.success) {
      setActivateAlert(false);
      setSuccessAlert(true);
      setAlertTitle("Activated");
      setAlertDescription("Account was successfully activated.");
      getAdminInfo(params.id);
    } else {
      toastr.error(result?.message || 'Account could not be activated.');
    }
  }

  const handleResetPassword = async () => {
    const result = await resetPassword(adminInfo.email);

    if (result?.success) {
      setResetPasswordAlert(false);
      setSuccessAlert(true);
      setAlertTitle("Password reset");
      setAlertDescription("Password was successfully been reset.");
      getAdminInfo(params.id);
    } else {
      toastr.error(result?.message || 'Password could not be reset.');
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs path="/accounts/admins" title="Admins" breadcrumbItem="Admin Profile" />
          {loading ?
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary"/>
            </div>
            :
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="d-flex justify-content-between align-items-center">
                    <h4 className='mb-0'>#{adminInfo.userId} - {adminInfo.firstName} {adminInfo.lastName}</h4>
                    <div>
                      <Button
                        className="btn btn-dark waves-effect waves-light btn-md ml-3"
                        onClick={() => {
                          setResetPasswordAlert(true)
                        }}
                      >
                        Reset Password
                      </Button>

	                    {role === 'superAdmin' &&
	                    <Link
		                    to={`/accounts/admins/${adminInfo.id}/edit`}
		                    className="btn btn-light waves-effect waves-light btn-md ml-3"
	                    >
		                    Edit
	                    </Link>
	                    }

                      {adminInfo.status !== 'blocked' && role === 'superAdmin' &&
                      <Button
                        className="btn btn-warning waves-effect waves-light btn-md ml-3"
                        onClick={() => {
                          setBlockAlert(true)
                        }}
                      >
                        Block
                      </Button>
                      }

                      {adminInfo.status !== 'activated' && role === 'superAdmin' &&
                      <Button
                        className="btn btn-success waves-effect waves-light btn-md ml-3"
                        onClick={() => {
                          setActivateAlert(true)
                        }}
                      >
                        {adminInfo.status === 'blocked' ? 'Unblock' : 'Activate'}
                      </Button>
                      }

	                    {adminInfo.status !== 'deleted' && role === 'superAdmin' &&
	                    <Button
		                    className="btn btn-danger waves-effect waves-light btn-md ml-3"
		                    onClick={() => {
			                    setDeleteAlert(true)
		                    }}
	                    >
		                    Delete
	                    </Button>
	                    }
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Admin</CardTitle>
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">ID :</th>
                            <td>{adminInfo.userId}</td>
                          </tr>
                          <tr>
                            <th scope="row">E-mail :</th>
                            <td><a href={"mailto:" + adminInfo.email}>{adminInfo.email}</a></td>
                          </tr>
                          <tr>
                            <th scope="row">Full Name :</th>
                            <td>{adminInfo.firstName} {adminInfo.lastName}</td>
                          </tr>
                          <tr>
                            <th scope="row">Role :</th>
                            <td className="text-capitalize">{adminInfo.role}</td>
                          </tr>
                          <tr>
                            <th scope="row">Status:</th>
                            <td className="text-capitalize">{adminInfo.status}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>

        {successAlert ? (
          <SweetAlert
            success
            title={alertTitle || 'Success'}
            onConfirm={() => {
              setSuccessAlert(false)
            }}
          >
            {alertDescription}
          </SweetAlert>
        ) : null}

        {blockAlert ? (
          <SweetAlert
            title="Block admin?"
            warning
            showCancel
            confirmButtonText="Yes, block it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={ handleBlock }
            onCancel={() => setBlockAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {deleteAlert ? (
          <SweetAlert
            title="Delete admin?"
            warning
            showCancel
            confirmButtonText="Yes, delete it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={ handleDelete }
            onCancel={() => setDeleteAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {activateAlert ? (
          <SweetAlert
            title="Activate admin?"
            warning
            showCancel
            confirmButtonText="Yes, activate it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={ handleActivate }
            onCancel={() => setActivateAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {resetPasswordAlert ? (
          <SweetAlert
            title="Reset password?"
            warning
            showCancel
            confirmButtonText="Yes, reset it!"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={ handleResetPassword }
            onCancel={() => setResetPasswordAlert(false)}
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

      </div>
    </React.Fragment>
  )
}

AdminProfile.propTypes = {
  adminInfo: PropTypes.object,
  getAdminInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ admins, session }) => ({
  adminInfo: admins.adminInfo,
  loading: admins.loading,
  userDetails: session.userDetails,
})

const mapDispatchToProps = dispatch => ({
  getAdminInfo: (id) => dispatch(getAdminInfo(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminProfile))
