import {
  GET_CLUBS,
  GET_CLUBS_SUCCESS,
  GET_CLUB_INFO,
  GET_CLUB_INFO_SUCCESS,
  GET_CLUB_OWNER,
  GET_CLUB_OWNER_SUCCESS,
  GET_CLUB_ORGANIZERS,
  GET_CLUB_ORGANIZERS_SUCCESS,
  GET_CLUB_LEAD_INFO,
  GET_CLUB_LEAD_INFO_SUCCESS,
  GET_CLUB_ARCHIVE_INFO,
  GET_CLUB_ARCHIVE_INFO_SUCCESS,
  GET_CLUB_LOCATIONS_SUCCESS,
  UNSET_CLUB_OWNER,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  clubs: [],
  clubInfo: {},
  clubOwner: {},
  clubLeadInfo: {},
  clubArchiveInfo: {},
  clubsLocations: [],
  clubOrganizers: {},
  loading: false,
}

const clubs = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLUB_LOCATIONS_SUCCESS:
      state = {
        ...state,
        clubsLocations: action?.data,
      }
    case GET_CLUBS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUBS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubs: action.payload,
      }
      break
    case GET_CLUB_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUB_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubInfo: action.payload,
      }
      break
    case GET_CLUB_OWNER:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_CLUB_OWNER_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubOwner: action.payload,
      }
      break
    case GET_CLUB_ORGANIZERS:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_CLUB_ORGANIZERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubOrganizers: action.payload,
      }
      break
    case GET_CLUB_LEAD_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUB_LEAD_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubLeadInfo: action.payload,
      }
      break
    case GET_CLUB_ARCHIVE_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_CLUB_ARCHIVE_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        clubArchiveInfo: action.payload,
      }
      break
    case UNSET_CLUB_OWNER:
      state = {
        ...state,
        clubOwner: {},
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default clubs
