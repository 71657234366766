import React from "react";
import { ListGroupItem, Badge } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

const Log = ({ log }) => {
  const listTypes = {
    'mainList': 'MAIN LIST',
    'waitingList': 'WAITING LIST',
    'preRegistrationList': 'SORTING LIST',
  };
  const badgeStatusColor = {
    'MOVED': 'warning',
    'UNSUBSCRIBED': 'danger',
    'SUBSCRIBED': 'success',
  };

  const items = {
    createdAt: moment(log?.createdAt).format("MMMM D, YYYY, H:mm:ss"),
    status: log?.status,
    userId: `#${log?.player?.userId}${log?.partner?.userId ? ` & #${log?.partner?.userId}` : ''}`,
    fullName: `${log?.player?.firstName} ${log?.player?.lastName}${log?.partner ? ` & ${log?.partner?.firstName} ${log?.partner?.lastName}` : ''}`,
    gameLevel: `Level: ${log?.partner ? (log?.player?.gameLevelDouble + log?.partner?.gameLevelDouble) / 2 : log?.player?.gameLevelSingle}`,
    listAdded: (
      log?.status === "MOVED" && !log?.admin
      ? `${listTypes[log?.list]} to ${listTypes[log?.to]}`
      : `to ${listTypes[log?.list]}`
    ),
    addedBy: log?.admin?.role === 'subclub' ? 'CLUB' : (((log?.admin?.role === 'player') && (log?.admin?.userId !== log?.player?.userId)) ? 'ORGANIZER' : log?.admin?.role?.toUpperCase()),
  };

  return (
    <ListGroupItem className="d-flex align-items-center">
      <ul className="align-items-center d-flex list-group-item list-unstyled p-0 border-0 activity-logs">
        {Object.keys(items).map((item, key) => (
          <li id={key} className="pr-3">
            {item === 'createdAt' && (
              <Badge color="primary" className="p-2">{items[item]}</Badge>
            )}
            {item === 'status' && (
              <Badge color={badgeStatusColor[log?.status] ?? 'info'} className="p-2">{items[item]}</Badge>
            )}
            {item === 'userId' && (
              <Link to={`/accounts/players/${log?.player?.id}`} target="_blank">
                <Badge className="p-2" color="info">{items[item]}</Badge>
              </Link>
            )}
            {!['createdAt', 'status', 'userId', 'addedBy'].includes(item) && (<span>{items[item]}</span>)}
            {item === 'addedBy' && (
              <>
                Added by: {items[item]}
                <span>&nbsp;
                  (<Link to={`/accounts/players/${log?.admin?.id}`} target="_blank">
                    #{log?.admin?.userId} - {log?.admin?.firstName} {log?.admin?.lastName}
                  </Link>)
                </span>
              </>
            )}
          </li>
        ))}
      </ul>
    </ListGroupItem>
  )
}

export default Log;
