import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { FormGroup, Label, Row, Col } from "reactstrap"
import Select from "react-select"

const currentYear = new Date().getFullYear()
const years = []
for (let i = currentYear - 10; i <= currentYear; i++) {
  years.push({
    label: i,
    value: i,
  })
}
years.reverse()

const months = [
  { value: 1, label: "january" },
  { value: 2, label: "february" },
  { value: 3, label: "march" },
  { value: 4, label: "april" },
  { value: 5, label: "may" },
  { value: 6, label: "june" },
  { value: 7, label: "july" },
  { value: 8, label: "august" },
  { value: 9, label: "september" },
  { value: 10, label: "october" },
  { value: 11, label: "november" },
  { value: 12, label: "december" },
]

const scoresValue = [
  { value: 'none', label: 'No Scores' },
  { value: 'partial', label: 'Incomplete' },
  { value: 'partialWithoutFinal', label: 'Complete without Finals' },
  { value: 'complete', label: 'Complete' },
];

const pointsValue = [
  { value: 'none', label: 'Points Not Saved' },
  { value: 'partial', label: 'Points Partially Saved' },
  { value: 'complete', label: 'Points Saved' },
];

const EventsFilter = ({ settings, filters, setFilters, eventsLocations }) => {
  //filter fields from settings
  const levels = settings?.levels
  const gameTypes = settings?.gameTypes
  const genders = settings?.genders
  const locations = eventsLocations
  const categories = settings?.categories

  const formatOptionsForSelect = options => {
    const values = []
    options.map(item => {
      values.push({ label: item.name, value: item.key })
    })
    return values
  }

  const locationOptions = locations?.map(location => {
    return {
      label: location.name
        ? location.name
        : location?.city +
          ", " +
          location?.country +
          " (" +
          location?.total +
          ")",
      value: `${location.city ?? "null"}, ${location.country}`,
    }
  })

  function handleFilters(data, element) {
    if (data) {
      if (element?.name === "location") {
        if (data?.value && data.value.name?.includes("-All")) {
          data.value = `null, ${data.value?.name?.replace("-All", "")}`
        }
      }

      setFilters({ ...filters, [element?.name]: data?.value })
    } else {
      const updatedFilters = { ...filters }

      delete updatedFilters[element.name]

      setFilters(updatedFilters)
    }
  }

  return (
    <div className="w-100">
      {settings && (
        <Row className="w-100">
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Game Type</Label>
              <Select
                placeholder="Game Type"
                name="gameType"
                label="gameType"
                onChange={handleFilters}
                options={formatOptionsForSelect(gameTypes)}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Gender</Label>
              <Select
                placeholder="Gender"
                name="gender"
                onChange={handleFilters}
                options={formatOptionsForSelect(genders)}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Location</Label>
              <Select
                placeholder="Location"
                name="location"
                onChange={handleFilters}
                options={locationOptions}
                isClearable={true}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Categories</Label>
              <Select
                placeholder="Category"
                name="category"
                onChange={handleFilters}
                options={formatOptionsForSelect(categories)}
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Month</Label>
              <Select
                name="month"
                placeholder="Month"
                onChange={handleFilters}
                options={months}
                label="month"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Year</Label>
              <Select
                name="year"
                placeholder="Year"
                onChange={handleFilters}
                options={years}
                label="year"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Level</Label>
              <Select
                name="level"
                placeholder="Level"
                onChange={handleFilters}
                options={formatOptionsForSelect(levels)}
                label="level"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Scores</Label>
              <Select
                name="scoresCompletion"
                placeholder="Scores"
                onChange={handleFilters}
                options={scoresValue}
                label="scoresCompletion"
                isClearable={true}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Points</Label>
              <Select
                name="pointsSaved"
                placeholder="Points"
                onChange={handleFilters}
                options={pointsValue}
                label="pointsSaved"
                isClearable={true}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </div>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({ settings })

export default connect(mapStatetoProps, null)(EventsFilter)
