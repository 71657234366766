import {
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  LOGIN,
  LOGOUT,
  API_ERROR
} from "./actionTypes"

export const getSession = () => {
  return {
    type: GET_SESSION,
    payload: [],
  }
}

export const getSessionSuccess = session => {
  return {
    type: GET_SESSION_SUCCESS,
    payload: session,
  }
}

export const login = values => {
  return {
    type: LOGIN,
    payload: values,
  }
}

export const logout = values => {
  return {
    type: LOGOUT,
    payload: values,
  }
}

export const getSettings = () => {
  return {
    type: GET_SETTINGS,
    payload: [],
  }
}

export const getSettingsSuccess = settings => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: settings,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
