import React, { useEffect } from "react"
import { connect } from "react-redux"
import { getPenalties } from "./actions"

import {
  Card,
  CardBody,
  Col,
  Row,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import moment from "moment"

import { getMatches } from "../Matches/actions"
import { DeletePenalties, updatePenaltiesStatus } from "./api"

import { Link } from "react-router-dom"

const PenaltiesList = ({
  penalties,
  fetchPenalties,
  competitionId,
  id,
  penaltiesList,
  suggestedPlayers,
}) => {
  let listColumns
  penaltiesList
    ? (listColumns = () => [
        {
          dataField: "UserId",
          text: "UserId",
          formatter: (cellContent, user) => (
            <Link target="_blank" to={`/accounts/players/${user?.playerId}`}>
              <span className="badge badge-primary font-size-12 m-1">
                <h5 className="mt-0 mb-0 text-white">{user?.userId || "-"}</h5>
              </span>
            </Link>
          ),
        },
        {
          dataField: "Name",
          text: "Name",
          formatter: (cellContent, user) => (
            <Link target="_blank" to={`/accounts/players/${user?.playerId}`}>
              <h5 className="mt-0 mb-0 ">{user?.playerName || "-"}</h5>
            </Link>
          ),
        },
        {
          dataField: "Status",
          text: "Status",
          formatter: (cellContent, user) => (
            <h6 className="mb-0">{user?.status || "-"}</h6>
          ),
        },
        {
          dataField: "Type",
          text: "Type",
          formatter: (cellContent, user) => (
            <h6 className="mb-0">{user?.type || "-"}</h6>
          ),
        },
        {
          dataField: "Reason",
          text: "Reason",
          formatter: (cellContent, user) => (
            <h6 className="mb-0">{user?.reason || "-"}</h6>
          ),
        },
        {
          dataField: "StartDate",
          text: "StartDate",
          formatter: (cellContent, user) => (
            <h6 className="mb-0">
              {moment(user?.startDate).format("YYYY-MM-DD HH:mm:ss")}
            </h6>
          ),
        },
        {
          dataField: "EndDate",
          text: "EndDate",
          formatter: (cellContent, user) => (
            <h6 className="mb-0">
              {moment(user?.endDate).format("YYYY-MM-DD HH:mm:ss")}
            </h6>
          ),
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: (cellContent, data) => {
            return (
              <>
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="card-drop" tag="i">
                    <i className="mdi mdi-dots-horizontal font-size-18" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      href={`/events/${id}/competitions/${competitionId}/${data?._id}/add-penalties`}
                      className="text-center p-0"
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem className="text-center p-0">
                      <Link
                        onClick={async () => {
                          await DeletePenalties(data?.userId, data?._id)
                          await fetchPenalties({ competitionId })
                        }}
                        className="d-block dropdown-item p-0"
                      >
                        Remove
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="text-center p-0">
                      <Link
                        onClick={async () => {
                          const type =
                            data?.status === "DISABLED" ? "enabled" : "disabled"
                          await updatePenaltiesStatus(
                            data?.userId,
                            data?._id,
                            type
                          )
                          await fetchPenalties({ competitionId })
                        }}
                        className="d-block dropdown-item p-0"
                      >
                        {data?.status === "DISABLED" ? "Enable" : "Disable"}
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )
          },
        },
      ])
    : (listColumns = () => [
        {
          dataField: "id",
          text: "Id",
          formatter: (cellContent, user) => (
            <span className="badge badge-primary font-size-12 m-1">
              <h5 className="mt-0 mb-0 text-white">{user?.userId || "-"}</h5>
            </span>
          ),
        },
        {
          dataField: "name",
          text: "Name",
          formatter: (cellContent, user) => (
            <Link target="_blank" to={`/accounts/players/${user?.id}`}>
              <h5 className="mt-0 mb-0 ">
                {user?.firstName + " " + user?.lastName}
              </h5>
            </Link>
          ),
        },
        {
          dataField: "location",
          text: "Location",
          formatter: (cellContent, user) => <h6>{user?.location?.name}</h6>,
        },
        {
          dataField: "action",
          text: "Action",
          formatter: (cellContent, user) => (
            <Link
              to={`/events/${id}/competitions/${competitionId}/${user?.userId}/add-penalties`}
              className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
            >
              <i className="mdi mdi-plus mr-1" />
              Add Penalty
            </Link>
          ),
        },
      ])

  return (
    <ToolkitProvider
      keyField="id"
      data={penaltiesList ? penalties?.penalties : suggestedPlayers || []}
      columns={listColumns()}
      bootstrap4
    >
      {toolkitProps => (
        <div className="table-responsive">
          <BootstrapTable
            responsive
            remote
            bordered={false}
            striped={false}
            classes={"table table align-middle table-nowrap table-hover"}
            headerWrapperClasses={"thead-light"}
            {...toolkitProps.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

const NoPenalties = ({ suggested }) => {
  return <p>{suggested ? "No suggested players" : "No penalties yet"}</p>
}

const Penalties = ({
  penalties = [],
  fetchMatches,
  matches,
  fetchPenalties,
  match: { params: { competitionId, id } = {} } = {},
}) => {
  useEffect(() => {
    fetchPenalties({ competitionId })
    fetchMatches({ competitionId })
  }, [fetchPenalties, competitionId, fetchMatches])

  let suggestedPlayers = []

  matches?.map(match => {
    if (match?.player?.type === "BYE") {
      match?.player?.firstName && suggestedPlayers.push(match?.player)
      match?.playerPartner && suggestedPlayers.push(match?.playerPartner)
    }
    if (match?.opponent?.type === "BYE") {
      match?.opponent?.firstName && suggestedPlayers.push(match?.opponent)
      match?.opponentPartner && suggestedPlayers.push(match?.opponentPartner)
    }

    suggestedPlayers = suggestedPlayers?.filter(
      player =>
        !penalties?.penalties?.find(({ userId }) => player?.userId === userId)
    )
  })

  return (
    <Card className="mt-2">
      <CardBody>
        <div>
          <Row className="mb-3">
            <Col>
              <h1>Penalties</h1>
            </Col>
            <Col>
              <div className="text-sm-right">
                <Link
                  to={`/events/${id}/competitions/${competitionId}/add-penalties`}
                  className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
                >
                  <i className="mdi mdi-plus mr-1" />
                  Add Penalty
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Active Penalties</h3>
              {penalties.length ? (
                <PenaltiesList
                  penaltiesList={true}
                  fetchPenalties={fetchPenalties}
                  penalties={penalties}
                  competitionId={competitionId}
                  id={id}
                />
              ) : (
                <NoPenalties />
              )}
              <h3>Suggested Players</h3>
              {suggestedPlayers?.length > 0 ? (
                <PenaltiesList
                  fetchPenalties={fetchPenalties}
                  penalties={penalties}
                  competitionId={competitionId}
                  suggestedPlayers={suggestedPlayers?.filter(
                    ({ userId }) => !penalties?.penalties?.includes(userId)
                  )}
                  id={id}
                />
              ) : (
                <NoPenalties suggested={true} />
              )}
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  )
}

const mapStatetoProps = ({
  penalties: { penalties = [] } = {},
  matches: { matches = [] } = {},
  events: { eventDetails = [] } = {},
  session: { userDetails } = {},
}) => ({
  penalties,
  matches,
  eventDetails,
  userDetails,
})

export default connect(mapStatetoProps, {
  fetchPenalties: getPenalties,
  fetchMatches: getMatches,
})(Penalties)
