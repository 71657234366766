import React, { useState, useContext } from "react"
import moment from "moment"
import { store as socketStore } from "components/Common/Socket/store"
import Slot from "../../../components/Slot"
import AddUser from "../../../../../../../../assets/images/addUser.png"
import Select from "react-select"
import { Formik, Form } from "formik"
import { createMatch, updateMatches } from "../api"

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
} from "reactstrap"

import { connect } from "react-redux"
import { getMatches } from "../actions"

const AddPlayer = ({
  mainList,
  currentEvent,
  competitionDetails,
  fetchMatches,
  index,
  phaseLength,
  player,
  opponent,
  matchId,
  matches = [],
}) => {
  const [addMatchModal, setAddMatchModal] = useState(false)

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const formatPayloadForUpdate = data => {
    const roleData = data?.player ? data?.player : data?.opponent
    const roleName = data?.player ? "player" : "opponent"

    let payload

    roleData?.type !== "BYE"
      ? (payload = {
          [roleName]: {
            ignorePoints: roleData?.user?.ignorePoints,
            pointsAdjustment: roleData?.user?.pointsAdjustment,
            profilePicture: roleData?.user?.profilePicture,
            userPoints: roleData?.user?.userPoints,
            score: [],
            points: roleData?.user?.points,
            userId: roleData?.user?.userId,
            rank: roleData?.user?.rank,
            firstName: roleData?.user?.firstName,
            lastName: roleData?.user?.lastName,
            location: {
              name: roleData?.location?.name,
              coords: {
                lat: roleData?.location?.coords?.lat,
                lng: roleData?.location?.coords?.lng,
              },
              city: roleData?.location?.city,
              country: roleData?.location?.country,
            },
            competitionRank: getCompetitionRank(roleData?.user?.id),
            level: !data[roleName]?.partner
              ? roleData?.user?.gameLevelSingle
              : roleData?.user?.gameLevelDouble,
          },
          [`${roleName}Partner`]: {
            id: data[roleName]?.partner?.id,
            score: [],
            points: data[roleName]?.partner?.points,
            userId: data[roleName]?.partner?.userId,
            rank: data[roleName]?.partner?.rank,
            firstName: data[roleName]?.partner?.firstName,
            lastName: data[roleName]?.partner?.lastName,
            level: data[roleName]?.partner?.gameLevelDouble,
          },
        })
      : (payload = {
          [roleName]: {
            type: "BYE",
          },
          [`${roleName}Partner`]: {
            type: "BYE",
          },
        })

    return payload
  }

  const displayConditions = player => {
    if (player?.type === "BYE") {
      return false
    } else if (player?.userId && player?.type !== "BYE") {
      return false
    } else {
      return true
    }
  }

  const getCompetitionRank = (playerId) => {
    let competitionRank = 0;
    matches.forEach(match => {
      if (match.player.id === playerId) {
        competitionRank = match.player.competitionRank;
      }
      if (match.opponent.id === playerId) {
        competitionRank = match.opponent.competitionRank;
      }
    });
    return competitionRank;
  };

  return (
    <div onClick={() => setAddMatchModal(true)} className="addPlayer">
      {addMatchModal && (
        <Modal isOpen={addMatchModal} toggle={() => setAddMatchModal(false)}>
          <ModalHeader toggle={() => setAddMatchModal(false)}>
            Create Match
          </ModalHeader>
          <Formik
            initialValues={{
              player: "",
              opponent: "",
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <>
                  <ModalBody>
                    {displayConditions(player) && (
                      <>
                        <Label>Player</Label>
                        <FormGroup>
                          <Select
                            placeholder="Player"
                            name="player"
                            options={[
                              { label: "BYE", value: { type: "BYE" } },
                              ...mainList,
                            ]?.map(player =>
                              player?.value?.type !== "BYE"
                                ? {
                                    value: player,
                                    label: `${player?.user?.firstName} ${
                                      player?.user?.lastName
                                    } id: ${player?.user?.userId} Single: ${
                                      player?.user?.gameLevelSingle
                                    } Double: ${
                                      player?.user?.gameLevelDouble
                                    } ${
                                      player?.user?.location?.name
                                    } account type: ${
                                      player?.user?.membership?.plan
                                    } 
              ${
                player?.partner
                  ? " / " +
                    player?.partner?.firstName +
                    " " +
                    player?.partner?.lastName +
                    " id: " +
                    player?.partner?.userId +
                    " Single: " +
                    player?.partner?.gameLevelSingle +
                    " Double: " +
                    player?.partner?.gameLevelDouble +
                    " " +
                    player?.partner?.location?.name +
                    " account type: " +
                    player?.partner?.membership?.plan
                  : ""
              }
              `,
                                  }
                                : {
                                    label: "BYE",
                                    value: { user: { type: "BYE" } },
                                  }
                            )}
                            onChange={({ value }) =>
                              setFieldValue("player", value)
                            }
                          />
                        </FormGroup>
                      </>
                    )}
                    {displayConditions(opponent) && (
                      <>
                        <Label>Opponent</Label>
                        <FormGroup>
                          <Select
                            placeholder="Opponent"
                            name="opponent"
                            options={[
                              { label: "BYE", value: { type: "BYE" } },
                              ...mainList,
                            ]?.map(player =>
                              player?.value?.type !== "BYE"
                                ? {
                                    value: player,
                                    label: `${player?.user?.firstName} ${
                                      player?.user?.lastName
                                    } id: ${player?.user?.userId} Single: ${
                                      player?.user?.gameLevelSingle
                                    } Double: ${
                                      player?.user?.gameLevelDouble
                                    } ${
                                      player?.user?.location?.name
                                    } account type: ${
                                      player?.user?.membership?.plan
                                    } 
              ${
                player?.partner
                  ? " / " +
                    player?.partner?.firstName +
                    " " +
                    player?.partner?.lastName +
                    " id: " +
                    player?.partner?.userId +
                    " Single: " +
                    player?.partner?.gameLevelSingle +
                    " Double: " +
                    player?.partner?.gameLevelDouble +
                    " " +
                    player?.partner?.location?.name +
                    " account type: " +
                    player?.partner?.membership?.plan
                  : ""
              }
              `,
                                  }
                                : {
                                    label: "BYE",
                                    value: { user: { type: "BYE" } },
                                  }
                            )}
                            onChange={({ value }) =>
                              setFieldValue("opponent", value)
                            }
                          />
                        </FormGroup>
                      </>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      color="secondary"
                      onClick={() => setAddMatchModal(false)}
                    >
                      Cancel
                    </Button>{" "}
                    <Button
                      color="primary"
                      disabled={
                        values?.player || values?.opponent ? false : true
                      }
                      onClick={async () => {
                        const { player, opponent } = values
                        if (matchId) {
                          await updateMatches(
                            matchId,
                            competitionDetails,
                            formatPayloadForUpdate(values),
                            "updateOnly",
                            socketClientId
                          )
                        } else {
                          if (player?.user) {
                            player.user.competitionRank =  getCompetitionRank(player?.user?.id);
                          }
                          if (opponent?.user) {
                            opponent.user.competitionRank =  getCompetitionRank(opponent?.user?.id);
                          }
                          await createMatch(
                            player,
                            opponent,
                            currentEvent,
                            competitionDetails,
                            index,
                            phaseLength,
                            socketClientId
                          )
                        }
                        await fetchMatches({
                          competitionId: competitionDetails?._id,
                        })

                        setAddMatchModal(false)
                      }}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </>
              )
            }}
          </Formik>
        </Modal>
      )}
      <img src={AddUser} />
      <h3>Add Player</h3>
    </div>
  )
}

const Match = ({
  draw,
  className,
  competition: { position } = {},
  noOfSets,
  setModal,
  gameType,
  player = {},
  opponent = {},
  playerPartner = {},
  opponentPartner = {},
  winner,
  _id,
  scheduler,
  mainList,
  isQualification,
  index,
  eventDetails,
  addPlayer,
  competitionDetails,
  fetchMatches,
  firstStageLength,
  matchId,
  addMatchPosition,
  secondQualification,
  qualIndex,
  sortedQualifications,
  type,
  qualificationMatches,
  firstStage,
  matches,
}) => {
  const playerSlot =
    (player?.type === "PENDING" && !player.level) || player?.type === "BYE"
      ? { ...player }
      : player
  const opponentSlot =
    (player?.type === "PENDING" && !player.level) || opponent?.type === "BYE"
      ? { ...opponent }
      : opponent

  const schedulerHandler = (scheduler, isQualification, qualInfo) => {
    if (scheduler || isQualification) {
      const displayScheduler = {
        day: scheduler?.playingDay ? moment(scheduler.playingDay).format('dddd (DD.MM), ') : '',
        time: scheduler?.playingTime ? moment(scheduler.playingTime).format('[not before ]HH:mm') : '',
        court: scheduler?.court ? `${scheduler?.court}, ` : ''
      };
      return (
        <p className="text-white mb-0 p-1">
          {isQualification
            ? `Qualifying ${getqualificationMatchIdentifier(qualInfo)} | ${
                scheduler ? `${displayScheduler.day} ${displayScheduler.court} ${displayScheduler.time}` : ''
              }`
            : isQualification === undefined && `${displayScheduler.day} ${displayScheduler.court} ${displayScheduler.time}`}
        </p>
      )
    }
  }

  const getCurrentPlayerEntries = playerId => {
    let numberOfRegistrations = 0
    let allEntries = []
    eventDetails?.competitions?.map(competition => {
      allEntries = [
        ...allEntries,
        ...competition?.mainList,
        ...competition?.waitingList,
        ...competition?.preRegistrationList,
      ]
    })

    allEntries?.map(entry => {
      if (playerId === entry?.user?.userId || playerId === entry?.partnerId) {
        numberOfRegistrations = numberOfRegistrations + 1
      }
    })

    return numberOfRegistrations
  }

  const getqualificationMatchIdentifier = (qualificationMatch, nextStage) => {
    for (let i = 0; i < sortedQualifications?.length; i++) {
      if (sortedQualifications[i] == qualificationMatch) {
        if (nextStage) {
          return i + 2
        } else {
          return i + 1
        }
      }
    }
  }

  let currentQualMatches = qualificationMatches?.filter(
    y =>
      y?.competition?.nextStagePosition === position * 2 ||
      y?.competition?.nextStagePosition === position * 2 - 1
  )

  return (
    <>
      {/* Qualification matches */}
      {currentQualMatches
        ?.sort((a, b) => b?.competition?.position - a?.competition?.position)
        ?.map((qualInfo, idx) => {
          const {
            player,
            competition,
            playerPartner,
            opponent,
            opponentPartner,
            winner,
            _id,
          } = qualInfo

          const matchId = _id

          return firstStage ? (
            <div
              className={`Match ${
                currentQualMatches?.length > 1 && idx === 0
                  ? "firstQualMatch"
                  : "secondQualMatch"
              } qualMatch ${
                (secondQualification &&
                  qualIndex === 0 &&
                  "multipleQualificationsFirstMatch ") ||
                (secondQualification &&
                  qualIndex === 1 &&
                  "multipleQualificationsSecondMatch ")
              } ${className}`}
            >
              <span className="position">
                {isQualification
                  ? getqualificationMatchIdentifier(qualInfo)
                  : position
                  ? position
                  : addMatchPosition || index + 1}
              </span>
              <div className="Match__content">
                {/* display court details */}
                {schedulerHandler(scheduler, isQualification, qualInfo)}
                <div className="flex align-items-center">
                  <Slot
                    partnerId={playerPartner?.userId}
                    matchId={matchId}
                    competitionDetails={competitionDetails}
                    winner={winner === "player"}
                    bye={winner === "BYE"}
                    user={player}
                    seeded={player?.seeded}
                    competitionPosition={player?.competitionRank}
                    partner={playerPartner?.level && playerPartner}
                    changePlayerValues={mainList}
                    replacePlayer={true}
                    gameType={gameType}
                    playerEntries={getCurrentPlayerEntries(player?.userId)}
                    partnerEntries={getCurrentPlayerEntries(
                      playerPartner?.userId
                    )}
                    fetchMatches={fetchMatches}
                    role="player"
                    competitionId={competitionDetails?._id}
                    matches={matches}
                  />
                  {!addPlayer && (
                    <div className="d-flex flex-column">
                      <div className="score flex">
                        {new Array(noOfSets).fill().map((el, index) => {
                          const [score] = (player.score || []).filter(
                            ({ set }) => set === index + 1
                          )
                          return (
                            <div>
                              <div>
                                <span>{(score && score.points) || 0}</span>
                              </div>
                              {score && score.tie && (
                                <span className="tie">
                                  {score && score.tie}
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {player?.reason === "ABANDONED" ? "Ab." : ""}
                      </div>
                      <p className="text-white m-auto mb-0">
                        {player?.reason === "NO_SHOW" && "WO"}
                      </p>
                    </div>
                  )}
                </div>
                {/* opponent */}
                <div className="flex align-items-center">
                  {
                    <Slot
                      competitionDetails={competitionDetails}
                      partnerId={opponentPartner?.userId}
                      winner={winner === "opponent"}
                      partner={opponentPartner?.level && opponentPartner}
                      bye={winner === "BYE"}
                      seeded={opponent?.seeded}
                      competitionPosition={opponent?.competitionRank}
                      user={opponent}
                      changePlayerValues={mainList}
                      replacePlayer={true}
                      gameType={gameType}
                      playerEntries={getCurrentPlayerEntries(opponent?.userId)}
                      partnerEntries={getCurrentPlayerEntries(
                        opponentPartner?.userId
                      )}
                      fetchMatches={fetchMatches}
                      matchId={matchId}
                      role="opponent"
                      competitionId={competitionDetails?._id}
                      matches={matches}
                    />
                  }

                  {!addPlayer && (
                    <div className="d-flex flex-column">
                      <div className="score flex">
                        {new Array(noOfSets).fill().map((el, index) => {
                          const [score] = (opponent.score || []).filter(
                            ({ set }) => set === index + 1
                          )
                          return (
                            <div>
                              <div>
                                <span>{(score && score.points) || 0}</span>
                              </div>
                              {score && score.tie && (
                                <span className="tie">
                                  {score && score.tie}
                                </span>
                              )}
                            </div>
                          )
                        })}
                        {opponent?.reason === "ABANDONED" ? "Ab." : ""}
                      </div>{" "}
                      <p className="text-white m-auto mb-0">
                        {opponent?.reason === "NO_SHOW" && "WO"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* {!addPlayer && ( */}
              <span
                onClick={() => setModal(_id)}
                className={`settings ${addPlayer && "disabledSettings"}`}
              >
                <i className="bx bxs-cog" />
              </span>
              {/* )} */}
            </div>
          ) : (
            ""
          )
        })}

      {/* match */}
      <div
        className={`Match ${
          (secondQualification &&
            qualIndex === 0 &&
            "multipleQualificationsFirstMatch ") ||
          (secondQualification &&
            qualIndex === 1 &&
            "multipleQualificationsSecondMatch ")
        } ${className}`}
      >
        <span className="position">
          {position ? position : addMatchPosition || index + 1}
        </span>
        {type === "TBA" ? (
          <div className="tbaMatch">
            <h1>TBA</h1>
          </div>
        ) : (
          <div className="Match__content">
            {/* display court details */}
            {schedulerHandler(scheduler)}
            <div className="flex align-items-center">
              {/* -CREATE MATCH COMPONENT- */}
              {addPlayer ? (
                <AddPlayer
                  competitionDetails={competitionDetails}
                  currentEvent={eventDetails}
                  mainList={mainList}
                  fetchMatches={fetchMatches}
                  index={index}
                  phaseLength={firstStageLength}
                  player={player}
                  opponent={opponent}
                  matchId={matchId}
                  matches={matches}
                />
              ) : (
                // player
                <>
                  {!player?.userId &&
                  player?.type !== "BYE" &&
                  player?.type !== "PENDING" ? (
                    <AddPlayer
                      competitionDetails={competitionDetails}
                      currentEvent={eventDetails}
                      mainList={mainList}
                      fetchMatches={fetchMatches}
                      index={index}
                      phaseLength={firstStageLength}
                      player={player}
                      opponent={opponent}
                      matchId={matchId}
                      matches={matches}
                    />
                  ) : (
                    <Slot
                      qualPlayerNumber={getqualificationMatchIdentifier(
                        currentQualMatches?.length > 0 &&
                          currentQualMatches?.sort(
                            (a, b) =>
                              a?.competition?.position -
                              b?.competition?.position
                          )[0]
                      )}
                      partnerId={playerPartner?.userId}
                      matchId={matchId}
                      competitionDetails={competitionDetails}
                      winner={winner === "player"}
                      bye={winner === "BYE"}
                      user={playerSlot}
                      seeded={player?.seeded || (competitionDetails?.drawModel === 'groups' && player?.competitionRank <= competitionDetails?.numberOfGroups)}
                      competitionPosition={player?.competitionRank}
                      partner={playerPartner?.level && playerPartner}
                      changePlayerValues={mainList}
                      replacePlayer={true}
                      gameType={gameType}
                      playerEntries={getCurrentPlayerEntries(
                        playerSlot?.userId
                      )}
                      partnerEntries={getCurrentPlayerEntries(
                        playerPartner?.userId
                      )}
                      fetchMatches={fetchMatches}
                      role="player"
                      competitionId={competitionDetails?._id}
                      matches={matches}
                    />
                  )}
                </>
              )}
              {!addPlayer && (
                <div className="d-flex flex-column">
                  <div className="score flex">
                    {new Array(noOfSets).fill().map((el, index) => {
                      const [score] = (player.score || []).filter(
                        ({ set }) => set === index + 1
                      )
                      return (
                        <div>
                          <div>
                            <span>{(score && score.points) || 0}</span>
                          </div>
                          {score && score.tie && (
                            <span className="tie">{score && score.tie}</span>
                          )}
                        </div>
                      )
                    })}
                    {player?.reason === "ABANDONED" ? "Ab." : ""}
                  </div>
                  <p className="text-white m-auto mb-0">
                    {player?.reason === "NO_SHOW" && "WO"}
                  </p>
                </div>
              )}
            </div>
            {/* opponent */}
            <div className="flex align-items-center">
              {addPlayer ? (
                <AddPlayer
                  competitionDetails={competitionDetails}
                  currentEvent={eventDetails}
                  mainList={mainList}
                  fetchMatches={fetchMatches}
                  index={index}
                  phaseLength={firstStageLength}
                  player={player}
                  opponent={opponent}
                  matchId={matchId}
                  matches={matches}
                />
              ) : (
                <>
                  {!opponent?.userId &&
                  opponent?.type !== "BYE" &&
                  opponent?.type !== "PENDING" ? (
                    <AddPlayer
                      competitionDetails={competitionDetails}
                      currentEvent={eventDetails}
                      mainList={mainList}
                      fetchMatches={fetchMatches}
                      index={index}
                      phaseLength={firstStageLength}
                      player={player}
                      opponent={opponent}
                      matchId={matchId}
                      matches={matches}
                    />
                  ) : (
                    <Slot
                      qualPlayerNumber={getqualificationMatchIdentifier(
                        currentQualMatches?.length > 0 &&
                          currentQualMatches?.sort(
                            (a, b) =>
                              a?.competition?.position -
                              b?.competition?.position
                          )[0],
                        currentQualMatches?.length > 0 && "nextStage"
                      )}
                      competitionDetails={competitionDetails}
                      partnerId={opponentPartner?.userId}
                      winner={winner === "opponent"}
                      partner={opponentPartner?.level && opponentPartner}
                      bye={winner === "BYE"}
                      seeded={opponent?.seeded || (competitionDetails?.drawModel === 'groups' && opponent?.competitionRank <= competitionDetails?.numberOfGroups)}
                      competitionPosition={opponent?.competitionRank}
                      user={opponentSlot}
                      changePlayerValues={mainList}
                      replacePlayer={true}
                      gameType={gameType}
                      playerEntries={getCurrentPlayerEntries(
                        opponentSlot?.userId
                      )}
                      partnerEntries={getCurrentPlayerEntries(
                        opponentPartner?.userId
                      )}
                      fetchMatches={fetchMatches}
                      matchId={matchId}
                      role="opponent"
                      competitionId={competitionDetails?._id}
                      matches={matches}
                    />
                  )}
                </>
              )}

              {!addPlayer && (
                <div className="d-flex flex-column">
                  <div className="score flex">
                    {new Array(noOfSets).fill().map((el, index) => {
                      const [score] = (opponent.score || []).filter(
                        ({ set }) => set === index + 1
                      )
                      return (
                        <div>
                          <div>
                            <span>{(score && score.points) || 0}</span>
                          </div>
                          {score && score.tie && (
                            <span className="tie">{score && score.tie}</span>
                          )}
                        </div>
                      )
                    })}
                    {opponent?.reason === "ABANDONED" ? "Ab." : ""}
                  </div>{" "}
                  <p className="text-white m-auto mb-0">
                    {opponent?.reason === "NO_SHOW" && "WO"}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}

        {/* {!addPlayer && ( */}
        <span
          onClick={() => setModal(_id)}
          className={`settings ${addPlayer && "disabledSettings"}`}
        >
          <i className="bx bxs-cog" />
        </span>
        {/* )} */}
      </div>
    </>
  )
}

const mapStateToProps = ({
  matches: {
    matches = [],
  } = {},
}) => ({
  matches,
})

export default connect(mapStateToProps, { fetchMatches: getMatches })(Match)
