import {
  GET_FRIENDLY_MATCHES,
  GET_FRIENDLY_MATCHES_SUCCESS,
  GET_FRIENDLY_MATCH,
  GET_FRIENDLY_MATCH_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getFriendlyMatches = (activeFilters, options) => {
  return {
    type: GET_FRIENDLY_MATCHES,
    payload: [activeFilters, options],
  }
}

export const getFriendlyMatchesSuccess = friendlyMatches => {
  return {
    type: GET_FRIENDLY_MATCHES_SUCCESS,
    payload: friendlyMatches,
  }
}

export const getFriendlyMatch = (matchId) => {
  return {
    type: GET_FRIENDLY_MATCH,
    payload: [matchId],
  }
}

export const getFriendlyMatchSuccess = friendlyMatch => {
  return {
    type: GET_FRIENDLY_MATCH_SUCCESS,
    payload: friendlyMatch,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
