export const GET_PLAYERS = "GET_PLAYERS"
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS"

export const GET_PLAYERS_LOCATIONS = "GET_PLAYERS_LOCATIONS"
export const GET_PLAYERS_LOCATIONS_SUCCESS = "GET_PLAYERS_LOCATIONS_SUCCESS"

export const GET_PLAYER_TRANSACTIONS = "GET_PLAYER_TRANSACTIONS"
export const GET_PLAYER_TRANSACTIONS_SUCCESS = "GET_PLAYER_TRANSACTIONS_SUCCESS"

export const GET_PLAYER_REFERRAL_REWARDS = "GET_PLAYER_REFERRAL_REWARDS"
export const GET_PLAYER_REFERRAL_REWARDS_SUCCESS = "GET_PLAYER_REFERRAL_REWARDS_SUCCESS"

export const GET_PLAYER_FIRST_TASK_REWARDS = "GET_PLAYER_FIRST_TASK_REWARDS"
export const GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS = "GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS"

export const GET_PLAYER_WALLET_TRANSACTIONS = "GET_PLAYER_WALLET_TRANSACTIONS"
export const GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS =
  "GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS"

export const GET_PLAYER_MEMBERSHIP_LIST = "GET_PLAYER_MEMBERSHIP_LIST"
export const GET_PLAYER_MEMBERSHIP_LIST_SUCCESS =
  "GET_PLAYER_MEMBERSHIP_LIST_SUCCESS"

export const GET_PLAYER_ACTIVITY = "GET_PLAYER_ACTIVITY"
export const GET_PLAYER_ACTIVITY_SUCCESS = "GET_PLAYER_ACTIVITY_SUCCESS"

export const GET_LEVEL_CHANGE = "GET_LEVEL_CHANGE"
export const GET_LEVEL_CHANGE_SUCCESS = "GET_LEVEL_CHANGE_SUCCESS"

export const GET_PLAYER_ENTRIES = "GET_PLAYER_ENTRIES"
export const GET_PLAYER_ENTRIES_SUCCESS = "GET_PLAYER_ENTRIES_SUCCESS"

export const GET_PLAYER_HISTORY_ENTRIES = "GET_PLAYER_HISTORY_ENTRIES"
export const GET_PLAYER_HISTORY_ENTRIES_SUCCESS =
  "GET_PLAYER_HISTORY_ENTRIES_SUCCESS"

export const GET_PLAYERS_LIST = "GET_PLAYERS_LIST"
export const GET_PLAYERS_LIST_SUCCESS = "GET_PLAYERS_LIST_SUCCESS"

export const GET_PLAYER_INFO = "GET_PLAYER_INFO"
export const GET_PLAYER_INFO_SUCCESS = "GET_PLAYER_INFO_SUCCESS"

export const CHANGE_PLAYERS_FILTERS = "CHANGE_PLAYERS_FILTERS"

export const API_ERROR = "CLUBS_API_ERROR"
