import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

import events from "../pages/EventsManagement/reducer"

import matches from "../pages/EventsManagement/components/Tabs/Competitions/Tabs/Matches/reducer"

import penalties from "../pages/EventsManagement/components/Tabs/Competitions/Tabs/Penalties/reducer"

import clubsLeads from "../pages/UserAccounts/ClubBusinessLeads/reducer"

import clubs from "../pages/UserAccounts/ClubAccounts/reducer"

import players from "../pages/UserAccounts/PlayerAccounts/reducer"

import organizers from "../pages/UserAccounts/OrganizerAccounts/reducer"

import admins from "../pages/UserAccounts/PlatformAdmins/reducer"

import transactions from "../pages/PaymentsManagement/reducer"

import session from "../pages/Authentication/reducer"

import leaderboards from "../pages/LeaderboardsManagement/reducer"

import reports from "pages/ReportsManagement/reducer"

import friendlyMatches from "pages/FriendlyMatches/reducer"

import bookings from "pages/BookingsManagement/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  ecommerce,
  calendar,
  matches,
  penalties,
  chat,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  events,
  clubsLeads,
  clubs,
  players,
  organizers,
  admins,
  transactions,
  session,
  leaderboards,
  reports,
  friendlyMatches,
  bookings,
})

export default rootReducer
