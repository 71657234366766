import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import { GET_SESSION, GET_SETTINGS, LOGIN, LOGOUT } from "./actionTypes"
import { apiError, getSessionSuccess, getSettingsSuccess } from "./actions"

function* getSession({ payload: [] }) {
  try {
    const { data } = yield call(
      api.getSession,
    )
    yield put(getSessionSuccess(data))

  } catch (error) {
    yield put(apiError(error))
  }
}

const allowedRoles = ["admin", "superAdmin"]

function* login({ payload: { values, addToast, history} }) {
  try {
    const { data } = yield call(
      api.login,
      values
    )

    if (
      !data ||
      (data && data.error) ||
      (data && !allowedRoles.includes(data.role))
    ) {
      const message = data && data.message || "AUTH_FAILED"
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      });
    }

    yield put(getSessionSuccess(data))
    return history.push("/")

  } catch (error) {
    yield put(apiError(error))
  }
}

function* logout({ payload: [] }) {
  try {
    const { data } = yield call(
      api.logout,
      values
    )

    console.log(data);

    return history.push("/");

  } catch (error) {
    yield put(apiError(error))
  }
}

function* getSettings({ payload: [] }) {
  try {
    const { data } = yield call(
      api.getSettings,
    )
    yield put(getSettingsSuccess(data))

  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_SESSION, getSession)
  yield takeEvery(GET_SETTINGS, getSettings)
  yield takeEvery(LOGIN, login)
  yield takeEvery(LOGOUT, logout)
}

export default eventsSaga
