import React, { useState, useEffect, useContext } from "react"
import {
  Container,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import { connect } from "react-redux"
import moment from "moment"
import { store as socketStore } from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"

import { getLeaderboards } from "./actions"
import { generateLeaderboard, rollbackLeaderboard } from "./api"

import "../../assets/scss/custom/pages/_rankingsManagement.scss"

const LeaderboardsManagement = ({ fetchLeaderboards, leaderboards }) => {
  const { date, week } = leaderboards

  const [modalType, setModalType] = useState("") // 2 types => calculate / undo
  const [modal, setModal] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  var weekStart = moment(new Date(), "MMDDYYYY").isoWeek()

  useEffect(() => {
    fetchLeaderboards()
  }, [fetchLeaderboards])

  const listenForResponse = async ({ success, message }) => {
    await fetchLeaderboards()
    if (success && message === "LEADERBOARD_GENERATED_SUCCESSFULLY") {
      setDisableButton(false)
    }
  }

  return (
    <React.Fragment>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="leaderboards-response"
      />
      <div className="page-content">
        <Container fluid>
          <h4>Rankings Management</h4>
          <Row>
            <Col>
              <Card className="mh-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-0">Current Rankings: Week {week}</p>
                  <p className="mb-0">
                    <small>
                      (Last update:{" "}
                      {moment(date).format("MMMM D, YYYY, HH:mm:ss")})
                    </small>
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mh-200">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p>
                    Rankings are automatically calculated every Monday, at
                    18:00
                  </p>
                  <Button
                    color="primary mb-3"
                    className="d-flex align-items-center"
                    onClick={() => {
                      setModalType("calculate")
                      setModal(true)
                    }}
                    disabled={disableButton ? true : false}
                  >
                    Calculate Ranking
                    {modalType === "calculate" && disableButton && (
                      <Spinner
                        className="ml-2"
                        size="sm"
                        animation="grow"
                        variant="dark"
                      />
                    )}
                  </Button>
                  <p>Rankings for Week {weekStart} will be calculated</p>
                </CardBody>
              </Card>
            </Col>{" "}
            <Col>
              <Card className="mh-200">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <Button
                    color="primary mb-3"
                    className="d-flex align-items-center"
                    onClick={() => {
                      setModalType("undo")
                      setModal(true)
                    }}
                    disabled={disableButton ? true : false}
                  >
                    Undo{" "}
                    {modalType === "undo" && disableButton && (
                      <Spinner
                        className="ml-2"
                        size="sm"
                        animation="grow"
                        variant="dark"
                      />
                    )}
                  </Button>
                  <p>
                    Rankings will be reverted to Week {weekStart - 1} version
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {modal ? (
            <SweetAlert
              warning
              title={modalType === "calculate" ? "Calculate" : "Undo"}
              showCancel
              confirmBtnText="Yes"
              confirmBtnBsStyle="primary"
              onConfirm={async () => {
                modalType === "calculate"
                  ? await generateLeaderboard(socketClientId)
                  : await rollbackLeaderboard(socketClientId)

                setDisableButton(true)
                await fetchLeaderboards()
                setModal(false)
              }}
              onCancel={() => setModal(false)}
            >
              {modalType === "calculate"
                ? "Are you sure you want to calculate the leaderboard"
                : "Are you sure you want to undo the leaderboard"}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = () => ({
  leaderboards: { leaderboards = {} } = {},
}) => ({
  leaderboards,
})

export default connect(mapStatetoProps, {
  fetchLeaderboards: () => getLeaderboards(),
})(LeaderboardsManagement)
