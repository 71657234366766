import React, { useContext, useEffect } from "react"
import { Formik, Form } from "formik"
import { connect } from "react-redux"
import {
  Container,
  FormGroup,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
import Input from "components/Common/Input"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import { useToasts } from "react-toast-notifications"
import Select from "react-select"
import { getEventDetails as getEventDetailsAction } from "store/actions"
import moment from "moment"

import * as Yup from "yup"
import { createPhase, updatePhase } from "../api"

import { Link } from "react-router-dom"

const phaseSchema = Yup.object().shape({
  drawModel: Yup.string().required("Required"),
  mlNumber: Yup.number().required("Required"),
  elo: Yup.string().required("Required"),
})

const eloOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
]

const Details = ({
  settings = {},
  noPageClass,
  phaseDetails = {},
  eventDetails = [],
  getEventDetails,
  history,
  values,
  match: { params: { id: eventId, competitionId, phaseId } = {} } = {},
}) => {
  const { addToast } = useToasts()

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const [event] = eventDetails || []

  const [competitionDetails] =
    (event && event.competitions?.filter(({ _id }) => _id === competitionId)) ||
    []

  useEffect(() => {
    getEventDetails(eventId)
  }, [getEventDetails, eventId])

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    getEventDetails(eventId)

    if (!phaseId) {
      history.push(`/events/${eventId}/competitions/${competitionId}/phases`)
    }

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const eloValue = [false, true].includes(phaseDetails?.elo)
    ? phaseDetails?.elo
    : competitionDetails?.elo

  const { drawModels = [] } = settings || {}
  return (
    <div className={`flex-grow-1 ${!noPageClass && "page-content"}`}>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/events-management">Events</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/events/${event?._id}/competitions`}>
              Event: {event?.name}{" "}
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link
              to={`/events/${eventDetails[0]?._id}/competitions/${competitionDetails?._id}/phases`}
            >
              Competition: {competitionDetails?.competitionId}{" "}
            </Link>
          </BreadcrumbItem>
          {phaseId ? (
            <BreadcrumbItem active>
              Phase: {phaseDetails?.phaseId}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem active>Create New Phase</BreadcrumbItem>
          )}
        </Breadcrumb>

        <Card>
          <CardBody>
            <div className="d-flex justify-content-between">
              <CardTitle>Phase details</CardTitle>
              <CardTitle>
                {eventId
                  ? eventDetails[0]?.currentPhase?.status
                    ? eventDetails[0]?.currentPhase?.status
                    : "No Current Stage"
                  : ""}
              </CardTitle>
            </div>
            <Formik
              key={phaseDetails.gameType || ""}
              initialValues={{
                gameType: competitionDetails?.gameType,
                drawModel: phaseDetails.drawModel || "",
                mlNumber: phaseDetails.mlNumber || "",
                elo:
                  eloValue === true
                    ? "true"
                    : (eloValue === false ? "false" : "") || "",
                startDate: competitionDetails?.startDate,
              }}
              onSubmit={async values => {
                const phaseAction = phaseId ? updatePhase : createPhase
                const eloReq = values.elo === true ? true : false
                await phaseAction({
                  socketClientId,
                  eventId,
                  phaseId,
                  competitionId,
                  elo: eloReq,
                  ...values,
                })
              }}
              validationSchema={phaseSchema}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
                setFieldValue,
                setFieldTouched,
              }) => {
                return (
                  <Form>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Input
                            placeholder="Select draw model"
                            label="Select draw model*"
                            type="select"
                            name="drawModel"
                            {...{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              initialValues,
                              values,
                            }}
                          >
                            <option value="" selected disabled>
                              Select draw model
                            </option>

                            {drawModels.map(({ key, name }) => (
                              <option value={key}>{name}</option>
                            ))}
                          </Input>
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder="Set # of entries on MainList"
                            label="Set # of entries on MainList*"
                            type="number"
                            name="mlNumber"
                            {...{
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              initialValues,
                              values,
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label className="mb-1">Select Elo*</Label>
                          <Select
                            name="elo"
                            placeholder="Select Elo"
                            defaultValue={
                              initialValues?.elo && {
                                label:
                                  initialValues.elo === "true" ? "Yes" : "No",
                                value:
                                  initialValues.elo === "true" ? true : false,
                              }
                            }
                            options={eloOptions?.map(elo => ({
                              label: elo?.label,
                              value: elo?.value,
                            }))}
                            onChange={e => {
                              setFieldValue("elo", e.value)
                            }}
                            onBlur={setFieldTouched}
                            {...{ errors, touched }}
                          />
                          {errors &&
                          errors["elo"] &&
                          touched &&
                          touched["elo"] ? (
                            <div className="error">{errors["elo"]}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <br />
                    <div className="d-flex justify-content-between">
                      <Button color="primary">Submit</Button>
                      {phaseId && (
                        <p className="text-muted">
                          Created on{" "}
                          {moment(phaseDetails?.createdAt).format(
                            "MMMM Do YYYY"
                          )}
                          , by admin{" "}
                          {phaseDetails?.createdBy?.firstName +
                            " " +
                            phaseDetails?.createdBy?.lastName +
                            " #" +
                            phaseDetails?.createdBy?.userId}
                        </p>
                      )}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

const mapStatetoProps = ({
  session: { settings } = {},
  events: { eventDetails = [] } = {},
}) => ({
  settings,
  eventDetails,
})

export default connect(mapStatetoProps, {
  getEventDetails: getEventDetailsAction,
})(Details)
