import React, { useState, useContext } from "react"
import { store as socketStore } from "components/Common/Socket/store"
import {
  Card,
  CardBody,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
} from "reactstrap"

import { Formik } from "formik"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"
import Select from "react-select"
import { connect } from "react-redux"
import { updateMatches } from "../Tabs/Matches/api"

const Slot = ({
  Actions,
  className,
  position,
  status,
  user = {},
  partner,
  isWildecard,
  bye,
  positionClick,
  slotType,
  gameType,
  winner,
  createdBy,
  createdById,
  key,
  seeded,
  paid,
  competitionPosition,
  changePlayerValues,
  replacePlayer,
  displayTeamAvg,
  bottomSpace,
  playerEntries,
  partnerEntries,
  partnerId,
  matchId,
  fetchMatches,
  role,
  competitionId,
  emptyWC,
  matches = [],
  competitionDetails,
  qualPlayerNumber,
  currency,
}) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  //decide if the competition is for solo or duo
  const isTypeSolo = gameType === "doubles" ? "D" : "S"

  const getCompetitionRank = (playerId) => {
    let competitionRank = 0;
    matches.forEach(match => {
      if (match.player.id === playerId) {
        competitionRank = match.player.competitionRank;
      }
      if (match.opponent.id === playerId) {
        competitionRank = match.opponent.competitionRank;
      }
    });
    return competitionRank;
  };

  const formatPayloadForUpdate = data => {
    const roleData = data?.player ? data?.player : data?.opponent
    const roleName = data?.player ? "player" : "opponent"

    let payload

    if (gameType === "doubles") {
      roleData?.type !== "BYE"
        ? (payload = {
            [roleName]: {
              ignorePoints: roleData?.user?.ignorePoints,
              pointsAdjustment: roleData?.user?.pointsAdjustment,
              profilePicture: roleData?.user?.profilePicture,
              userPoints: roleData?.user?.userPoints,
              id: roleData?.user?.id,
              score: [],
              points: roleData?.user?.points,
              userId: roleData?.user?.userId,
              rank: roleData?.user?.rank,
              firstName: roleData?.user?.firstName,
              lastName: roleData?.user?.lastName,
              location: {
                name: roleData?.location?.name,
                coords: {
                  lat: roleData?.location?.coords?.lat,
                  lng: roleData?.location?.coords?.lng,
                },
                city: roleData?.location?.city,
                country: roleData?.location?.country,
              },
              competitionRank: getCompetitionRank(roleData?.user?.id),
              level: !data[roleName]?.partner
                ? roleData?.user?.gameLevelSingle
                : roleData?.user?.gameLevelDouble,
            },
            [`${roleName}Partner`]: {
              id: data[roleName]?.partner?.id,
              score: [],
              points: data[roleName]?.partner?.points,
              userId: data[roleName]?.partner?.userId,
              rank: data[roleName]?.partner?.rank,
              firstName: data[roleName]?.partner?.firstName,
              lastName: data[roleName]?.partner?.lastName,
              level: data[roleName]?.partner?.gameLevelDouble,
            },
          })
        : (payload = {
            [roleName]: {
              type: "BYE",
            },
            [`${roleName}Partner`]: {
              type: "BYE",
            },
          })
    } else {
      roleData?.type !== "BYE"
        ? (payload = {
            [roleName]: {
              ignorePoints: roleData?.user?.ignorePoints,
              pointsAdjustment: roleData?.user?.pointsAdjustment,
              profilePicture: roleData?.user?.profilePicture,
              userPoints: roleData?.user?.userPoints,
              id: roleData?.user?.id,
              score: [],
              points: roleData?.user?.points,
              userId: roleData?.user?.userId,
              rank: roleData?.user?.rank,
              firstName: roleData?.user?.firstName,
              lastName: roleData?.user?.lastName,
              location: {
                name: roleData?.location?.name,
                coords: {
                  lat: roleData?.location?.coords?.lat,
                  lng: roleData?.location?.coords?.lng,
                },
                city: roleData?.location?.city,
                country: roleData?.location?.country,
              },
              competitionRank: getCompetitionRank(roleData?.user?.id),
              level: !data[roleName]?.partner
                ? roleData?.user?.gameLevelSingle
                : roleData?.user?.gameLevelDouble,
            },
          })
        : (payload = {
            [roleName]: {
              type: "BYE",
            },
          })
    }

    return payload
  }

  const returnUserName = user => {
    const name = []
    const { firstName, lastName, type } = user

    if (type === "BYE" && !firstName && !lastName) {
      return "BYE"
    } else {
      firstName ? name?.push(firstName) : name?.push("")
      lastName ? name?.push(lastName) : name?.push("")
    }

    return name?.join(" ")
  }

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  let allCompetitionEntries =
    (competitionDetails?.mainList || []).concat(
      competitionDetails?.waitingList
    ) || []

  const handldeWIndicator = (userId, role) => {
    const currentEntry = allCompetitionEntries?.find(
      x => x?.userId === userId || x?.partnerId === userId
    )
    if (currentEntry?.[role]?.w === true) {
      return (
        <Badge className="mr-1" color="success">
          <p className="text-dark mb-0">W </p>
        </Badge>
      )
    }
  }

  if (competitionPosition) {
    user.competitionRank = competitionPosition
  }

  return (
    <div className="flex-grow-1">
      <Card
        className={`slot ${bottomSpace && `pb-4`} ${
          emptyWC && `emptySlotHeight`
        } ${className} ${winner && "winner"} ${bye && "bye"}`}
        key={key}
      >
        <CardBody className="d-flex align-items-center">
          {position && (
            <div className="position" onClick={positionClick}>
              {position}
            </div>
          )}
          <div className="d-flex flex-column justify-content-center flex-grow-1">
            <div className="d-flex justify-content-between">
              {!emptyWC &&
                (user?.userId || user?.type === "BYE" ? (
                  <div>
                    <p className="mb-0">
                      <Link
                        target="_blank"
                        to={`/accounts/players/${user?.id}`}
                      >
                        <span className="color-purple mr-1">
                          (id: {user.userId})
                        </span>
                      </Link>{" "}
                      {user.userId && playerEntries > 1 && (
                        <Badge
                          color="primary"
                          className="mr-1"
                        >{`${playerEntries} `}</Badge>
                      )}
                      {handldeWIndicator(user?.userId, 'user')}
                      <strong onClick={() => replacePlayer && toggle()}>
                        {returnUserName(user)}
                      </strong>{" "}
                      {seeded &&
                        user?.competitionRank &&
                        `(${user?.competitionRank}) `}{" "}
                      from {user?.location?.name}
                    </p>
                  </div>
                ) : (
                  qualPlayerNumber && (
                    <p className="mb-0">Q {qualPlayerNumber}</p>
                  )
                ))}

              {Actions && <Actions />}
            </div>
            <CardText className="fs-10 text-muted mb-0 d-flex">
              <p className="mb-0">{user && user.email}</p>
              <p className="mb-0  ml-2">{user && user.phone}</p>
            </CardText>
            {!emptyWC && (
              <CardText className="mb-0">
                <div className="flex">
                  <div className="mr-2">
                    <i className="bx bx-hash color-darkblue mr-1"/>
                    {isTypeSolo}:{" "}
                    {(user &&
                      user[gameType === "doubles" ? "rankDouble" : "rank"]) ||
                      user?.rank}
                  </div>
                  <div className="mr-2">
                    <i className="bx bx-trophy mr-1 color-gold"/>
                    Points-{isTypeSolo}:{" "}
                    {(user &&
                      user[
                        gameType === "doubles" ? "pointsDouble" : "points"
                      ]) ||
                      user?.points}
                  </div>
                  <div className="mr-2">
                    <i className="bx bxs-flag mr-1 color-orange"/>
                    Level-{isTypeSolo}:{" "}
                    {(user &&
                      user[
                        gameType === "doubles"
                          ? "gameLevelDouble"
                          : "gameLevelSingle"
                      ]) ||
                      user?.level}
                  </div>
                  <div className="mr-2">
                    <i className="bx bxl-pocket mr-1 color-darkblue"/>
                    Plan: {user && user?.membership?.plan}
                  </div>

                  {user?.registrationFee >= 0 && (
                    <div className="ml-2">
                      <i className="bx bxs-shield-alt-2 mr-1 color-darkblue r"/>
                      SSF: {user.registrationFee} {currency && currency}
                      {user.registrationFee > 0 && (
                        <span className={`ml-1 badge badge-${[undefined, true].includes(paid) ? 'success' : 'danger'}`}>
                          {[undefined, true].includes(paid) ? 'PAID' : 'UNPAID'}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex">
                  {competitionPosition && (
                    <div className="mr-2">
                      <i className="bx bx-hash color-darkblue mr-1"/>
                      Competition position: {competitionPosition}
                    </div>
                  )}

                  {/* {seeded && (
                  <div className="mr-2">
                    <i class="bx bxs-badge-check mr-1 color-gold"></i>
                    Favorite
                  </div>
                )} */}
                </div>
              </CardText>
            )}
            {partner && (
              <div className="partner">
                <div className="d-flex justify-content-between">
                  {partner?.userId || partner?.type === "BYE" ? (
                    <div>
                      <Link
                        target="_blank"
                        to={`/accounts/players/${partner?.id}`}
                      >
                        <span className="color-purple mr-1">
                          (id: {partnerId && partnerId})
                        </span>
                      </Link>
                      {partnerId && partnerEntries > 1 && (
                        <Badge color="primary" className="mr-1">
                          {partnerEntries}
                        </Badge>
                      )}
                      {handldeWIndicator(partner?.userId, 'partner')}
                      <strong onClick={() => replacePlayer && toggle()}>
                        {returnUserName(partner)}
                      </strong>{" "}
                      {seeded &&
                        user?.competitionRank &&
                        `(${user?.competitionRank}) `}{" "}
                      from {partner && partner?.location?.name}
                    </div>
                  ) : (
                    qualPlayerNumber && (
                      <p className="mb-0">Q {qualPlayerNumber}</p>
                    )
                  )}
                </div>
                <CardText className="fs-10 text-muted mb-0 d-flex">
                  <p className="mb-0">{partner && partner.email}</p>
                  <p className="mb-0  ml-2">{partner && partner.phone}</p>
                </CardText>
                <CardText className="mb-0">
                  <div className="flex">
                    <div className="mr-2">
                      <i className="bx bx-hash color-darkblue mr-1"/>
                      {isTypeSolo}:{" "}
                      {(partner &&
                        partner[
                          gameType === "doubles" ? "rankDouble" : "rank"
                        ]) ||
                        partner?.rank}
                    </div>
                    <div className="mr-2">
                      <i className="bx bx-trophy mr-1 color-gold"/>
                      Points-{isTypeSolo}:{" "}
                      {(partner &&
                        partner[
                          gameType === "doubles" ? "pointsDouble" : "points"
                        ]) ||
                        partner?.points}
                    </div>
                    <div>
                      <i className="bx bxs-flag mr-2 color-orange"/>
                      Level-{isTypeSolo}:{" "}
                      {(partner &&
                        partner[
                          gameType === "doubles"
                            ? "gameLevelDouble"
                            : "gameLevelSingle"
                        ]) ||
                        partner?.level}
                    </div>
                    <div className="ml-1">
                      <i className="bx bxl-pocket mr-1 color-darkblue"/>
                      Plan: {partner && partner?.membership?.plan}
                    </div>
                    {partner?.registrationFee >= 0 && (
                      <div className="ml-2">
                        <i className="bx bxs-shield-alt-2 mr-1 color-darkblue"/>
                        SSF: {partner.registrationFee} {currency && currency}
                        {partner.registrationFee > 0 && (
                          <span className={`ml-1 badge badge-${[undefined, true].includes(paid) ? 'success' : 'danger'}`}>
                            {[undefined, true].includes(paid) ? 'PAID' : 'UNPAID'}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </CardText>
              </div>
            )}
            {slotType === "STANDARD" && createdBy && (
              <CardText className="addedBy">
                <span className="createdByTxt">Created by: </span> {createdBy}{createdById ? ` #${createdById}` : ''}
              </CardText>
            )}
            {status === "PENDING" && (
              <CardText className="pendingStatus">
                <p>{status}</p>
              </CardText>
            )}
            <CardText>
              <span className="wildCard">
                {isWildecard && (
                  <span>{slotType === "WILD_CARD" && "Wild card"}</span>
                )}
              </span>
            </CardText>

            {emptyWC && (
              <CardText>
                <span className="wildCard">
                  <span>Wild card</span>
                </span>
              </CardText>
            )}
          </div>
          {partner && displayTeamAvg && (
            <div>
              <p className="mb-0">
                Avg. Level:{" "}
                {(user?.gameLevelDouble + partner?.gameLevelDouble) / 2}
              </p>
              <p className="mb-0">
                Avg. Points: {(user?.pointsDouble + partner?.pointsDouble) / 2}
              </p>
              <p className="mb-0">
                Avg. Rank: {(user?.rankDouble + partner?.rankDouble) / 2}
              </p>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={modal} toggle={toggle}>
        {changePlayerValues?.length > 0 &&
          (changePlayerValues[0]?.partner?.userId ? (
            <ModalHeader toggle={toggle}>Change Players</ModalHeader>
          ) : (
            <ModalHeader toggle={toggle}>Change Player</ModalHeader>
          ))}
        <Formik
          initialValues={{
            player: "",
            opponent: "",
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <>
                <ModalBody>
                  {changePlayerValues[0]?.partner?.userId ? (
                    <Label>Select players</Label>
                  ) : (
                    <Label>Select player</Label>
                  )}
                  <Select
                    options={[{ type: "BYE" }, ...changePlayerValues]?.map(
                      player =>
                        player?.type === "BYE"
                          ? { label: "BYE", value: { type: "BYE" } }
                          : {
                              value: player,
                              label: `${player?.user?.firstName} ${
                                player?.user?.lastName
                              } id: ${player?.user?.userId} Single: ${
                                player?.user?.gameLevelSingle
                              } Double: ${player?.user?.gameLevelDouble} ${
                                player?.user?.location?.name
                              } account type: ${player?.user?.membership?.plan} 
              ${
                player?.partner
                  ? " / " +
                    player?.partner?.firstName +
                    " " +
                    player?.partner?.lastName +
                    " id: " +
                    player?.partner?.userId +
                    " Single: " +
                    player?.partner?.gameLevelSingle +
                    " Double: " +
                    player?.partner?.gameLevelDouble +
                    " " +
                    player?.partner?.location?.name +
                    " account type: " +
                    player?.partner?.membership?.plan
                  : ""
              }
              `,
                            }
                    )}
                    onChange={({ value }) =>
                      setFieldValue(
                        role === "player" ? "player" : "opponent",
                        value
                      )
                    }
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>{" "}
                  <Button
                    disabled={values?.player || values?.opponent ? false : true}
                    color="primary"
                    onClick={async () => {

                      await updateMatches(
                        matchId,
                        competitionDetails,
                        formatPayloadForUpdate(values),
                        "updateOnly",
                        socketClientId
                      )

                      await fetchMatches({
                        competitionId: competitionId,
                      })
                      toggle()
                    }}
                  >
                    Save
                  </Button>
                </ModalFooter>
              </>
            )
          }}
        </Formik>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ matches: { matches = [] } = {} }) => ({
  matches,
})

export default connect(mapStateToProps, null)(Slot)
