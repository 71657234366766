import React, { useState } from "react"

import Input from "components/Common/Input"
import {
  Row,
  Col,
  FormGroup,
  Nav,
  TabPane,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert"

const feeFields = [
  { key: "free", label: "Free" },
  { key: "freeDiscounted", label: "Free discounted" },
  { key: "premium", label: "Premium" },
  { key: "clubEntryFee", label: "Club Entry Fee" },
]

const FeeDetails = ({
  errors,
  touched,
  handleChange,
  handleBlur,
  initialValues,
  values,
  setFieldValue,
  eventDetails: { category } = {},
  ...props
}) => {
  const [activeCountry, setActiveCountry] = useState("ro")
  const [resetFeesModal, setResetFeesModal] = useState(false)
  const [defaultFees, setDefaultFees] = useState(false)

  const { eligibleCountries = [], fees = [] } = props?.settings || {}

  let [targetSettings] = fees.filter(
    ({ category: settingsCategory }) => category === settingsCategory
  )

  targetSettings = ((targetSettings || fees[0] || {})[
    values?.gameType || initialValues?.gameType
  ] || {})[values?.drawModel || initialValues?.drawModel]

  const currency = [
    ...eligibleCountries,
    {
      key: "BHD-USD",
      country: "USDBHD",
      currency: "BHD-USD",
      name: "BHD-USD",
    },
    {
      key: "AMD-USD",
      country: "USDAMD",
      currency: "AMD-USD",
      name: "AMD-USD",
    },
  ]
    .filter(({ key }) => key === activeCountry)
    .map(({ currency }) => currency)

  return (
    <div>
      {/* Set sportya fees to 0 modal */}
      {resetFeesModal && (
        <SweetAlert
          warning
          showCancel
          title="Sportya Service Fees"
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            ;[...eligibleCountries, { key: "USDBHD" }, { key: "USDAMD" }].map(
              ({ key }) => {
                setFieldValue(`fee.${key}.free`, 0)
                setFieldValue(`fee.${key}.freeDiscounted`, 0)
                setFieldValue(`fee.${key}.premium`, 0)
              }
            )
            setResetFeesModal(false)
          }}
          onCancel={() => setResetFeesModal(false)}
        >
          Are you sure you want to change all Sportya Service Fees to 0 (ZERO)
          for the selected competition?
        </SweetAlert>
      )}

      {/* Set sportya fees to default modal */}
      {defaultFees && (
        <SweetAlert
          warning
          showCancel
          title="Sportya Service Fees"
          confirmButtonText="Yes, delete it!"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            ;[...eligibleCountries, { key: "USDBHD" }, { key: "USDAMD" }].map(
              ({ key }) => {
                const country = key !== null ? key.toUpperCase() : key
                setFieldValue(`fee.${key}.free`, targetSettings?.free[country])
                setFieldValue(
                  `fee.${key}.freeDiscounted`,
                  targetSettings?.freeDiscounted[country]
                )
                setFieldValue(
                  `fee.${key}.premium`,
                  targetSettings?.premium[country]
                )
              }
            )
            setDefaultFees(false)
          }}
          onCancel={() => setDefaultFees(false)}
        >
          Are you sure you want to change all Sportya Service Fees to default
          for the selected competition?
        </SweetAlert>
      )}

      <p
        onClick={() => setResetFeesModal(true)}
        className="text-danger position-absolute removeFees pointer"
      >
        Set SSF to 0 (ZERO)
      </p>
      <p
        onClick={() => setDefaultFees(true)}
        className="text-primary position-absolute defaultFees pointer"
      >
        Reset SSF to Default
      </p>
      <div className="tabs">
        <Nav className="mt-2 mb-3 pl-0" pills>
          {[
            ...eligibleCountries,
            {
              key: "BHD-USD",
              country: "USDBHD",
              currency: "BHD-USD",
              name: "BHD-USD",
            },
            {
              key: "AMD-USD",
              country: "USDAMD",
              currency: "AMD-USD",
              name: "AMD-USD",
            },
          ].map(({ name, key }) => {
            return (
              <NavItem key={name}>
                <NavLink
                  onClick={() => setActiveCountry(key)}
                  active={activeCountry === key}
                >
                  {name}
                </NavLink>
              </NavItem>
            )
          })}
        </Nav>
        <TabContent>
          <TabPane></TabPane>
          <Row>
            <Col xs={6}>
              <FormGroup>
                <FormGroup>
                  <Input
                    disabled
                    key={currency}
                    label={`Currency`}
                    type="text"
                    defaultValue={currency}
                    name="currency"
                  />
                </FormGroup>
              </FormGroup>
            </Col>
            {feeFields.map(({ key: feeKey, label }) => {
              const storedFees = values?.fee

              const usdTypeCountry =
                activeCountry.split("-")?.length > 1
                  ? activeCountry.split("-")[1] + activeCountry.split("-")[0]
                  : activeCountry

              return (
                <Col key={`${feeKey}-${activeCountry}`} xs={6}>
                  <FormGroup>
                    <Input
                      label={`Fee for ${label}*`}
                      type="number"
                      value={
                        storedFees[usdTypeCountry] &&
                        storedFees[usdTypeCountry][feeKey] >= 0
                          ? storedFees[usdTypeCountry][feeKey]
                          : targetSettings
                          ? targetSettings[feeKey][currency]
                          : null
                      }
                      name={feeKey}
                      onChange={e => {
                        setFieldValue(
                          `fee.${usdTypeCountry}.${feeKey}`,
                          Number(e.target.value)
                        )
                      }}
                      {...{
                        errors,
                        touched,
                        handleBlur,
                        initialValues,
                        values,
                      }}
                    />
                  </FormGroup>
                </Col>
              )
            })}
          </Row>
        </TabContent>
      </div>
    </div>
  )
}

export default FeeDetails
