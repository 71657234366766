import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Bookings Redux States
import {
  GET_BOOKINGS,
  GET_BOOKING_DETAILS,
} from "./actionTypes"
import {
  apiError,
  getBookingsSuccess,
  getBookingDetailsSuccess,
} from "./actions"


function* getBookings({ filters, options, time, payload: [] }) {
  try {
    const { data } = yield call(api.getBookings, filters, options, time)
    yield put(getBookingsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getBookingDetails({ payload: { id } }) {
  try {
    let response
    if (id !== "empty") {
      response = yield call(api.getBookingDetails, id)
    }
    yield put(getBookingDetailsSuccess(id === "empty" ? [] : response?.data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_BOOKINGS, getBookings)
  yield takeEvery(GET_BOOKING_DETAILS, getBookingDetails)
}

export default eventsSaga
