import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  CHANGE_ADMINS_FILTERS,
  GET_ADMIN_INFO,
  GET_ADMIN_INFO_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getTransactions = (filters, options) => {
  return {
    type: GET_TRANSACTIONS,
    filters,
    options,
    payload: [],
  }
}

export const getTransactionsSuccess = transactions => {
  return {
    type: GET_TRANSACTIONS_SUCCESS,
    payload: transactions,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
