import { patch, post, del } from "helpers/api_helper"

export const createPhase = async ({
  competitionId,
  eventId,
  socketClientId,
  phaseId,
  ...details
}) => {
  let response

  try {
    response = await post(
      `/admin/events/${eventId}/competitions/${competitionId}/phases`,
      { socketClientId, ...details }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const updatePhase = async ({
  competitionId,
  eventId,
  phaseId,
  socketClientId,
  ...details
}) => {
  let response

  try {
    response = await patch(
      `/admin/events/${eventId}/competitions/${competitionId}/phases/${phaseId}`,
      { socketClientId, ...details }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const deletePhase = async (
  id,
  competitionId,
  phaseId,
  socketClientId
) => {
  let response

  try {
    response = await del(
      `/admin/events/${id}/competitions/${competitionId}/phases/${phaseId}/status`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const enablePhase = async (
  id,
  competitionId,
  phaseId,
  type,
  socketClientId
) => {
  let response

  try {
    response = await patch(
      `/admin/events/${id}/competitions/${competitionId}/phases/${phaseId}/status/${type}`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}
