import React, { useState } from "react"

import Input from "components/Common/Input"
import { Row, Col, FormGroup, Label } from "reactstrap"

const Restrictions = ({
  errors,
  touched,
  handleChange,
  handleBlur,
  initialValues,
  values,
  settings,
}) => {
  const [gender] = useState([
    { text: "Male", value: "male" },
    { text: "Female", value: "female" },
  ])
  const { genders = [], accountTypes = [], accountActivity = [] } =
    settings || {}
  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <Input
              placeholder="Account type"
              label="Account type"
              type="select"
              name="accountType"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Account type
              </option>

              {accountTypes.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Account activity"
              label="Account activity"
              type="select"
              name="accountActivity"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Account activity
              </option>

              {accountActivity.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Rank"
              label="Rank"
              type="number"
              name="rank"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Gender"
              label="Gender"
              type="select"
              name="gender"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected>
                Any Gender
              </option>

              {genders.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              placeholder="Min age"
              label="Min age"
              type="number"
              name="minAge"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Max age"
              label="Max age"
              type="number"
              name="maxAge"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>

          <FormGroup className="mt-3">
            <Label for="teamAverageLevel">
              Team Average Level for Doubles (Limit to Competition Level -0.5)
            </Label>
            <Input
              type="checkbox"
              className="form-group ml-0 mb-0 mt-0"
              name="teamAverageLevel"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
              checked={values.teamAverageLevel}
            ></Input>
          </FormGroup>
          <FormGroup className="mt-5 pt-1">
            <Label for="forbiddenToTeams">
              Forbidden to Teams for Doubles - Player Level cannot be higher
              than Competition Level (ex. N5+N7 team cannot enter N6
              competition)
            </Label>
            <Input
              type="checkbox"
              name="forbiddenToTeams"
              className="form-group ml-0 mb-0 mt-0"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
              checked={values.forbiddenToTeams}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Restrictions
