import React, {useContext, useEffect, useState} from "react";
import { Container } from "reactstrap";
import toastr from "toastr";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { updateAccount } from "./api";
import { getAdminInfo } from "./actions";
import FormData from "./FormData";
import {store as socketStore} from "../../../components/Common/Socket/store";

const EditAdmin = props => {
  const {
    getAdminInfo,
    adminInfo,
    match: { params },
    history,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } = {} } = {} } = globalState;

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message);
        setIsLoading(false);
        return;
      }

      toastr.success(message);
      history.goBack();
    };

    if (socket) {
      socket.removeAllListeners(
        "accounts-response"
      );
      socket.on(
        "accounts-response",
        listenForResponse
      );

      return () => {
        socket.removeAllListeners(
          "accounts-response"
        );
      }
    }
  }, [socketClientId]); // eslint-disable-line

  useEffect(() => {
    getAdminInfo(params.id);
  }, [getAdminInfo, params.id]);

  const handleSubmit = async (event, values) => {
    setIsLoading(true);

    const response = await updateAccount(params.id, {
      socketClientId,
      ...values,
    });

    if (!response.success) {
      toastr.error(response.message || 'Admin could not be created.');
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs path="/accounts/admins" title="Admins" breadcrumbItem="Edit admin" />
          <FormData
            handleSubmit={handleSubmit}
            adminInfo={adminInfo}
            history={history}
            loading={isLoading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

EditAdmin.propTypes = {
  adminInfo: PropTypes.object,
  getAdminInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ admins }) => ({
  adminInfo: admins.adminInfo,
})

const mapDispatchToProps = dispatch => ({
  getAdminInfo: (id) => dispatch(getAdminInfo(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditAdmin))
