import {
  GET_CLUBS_LEADS,
  GET_CLUBS_LEADS_SUCCESS,
  GET_CLUB_LEAD_INFO,
  GET_CLUB_LEAD_INFO_SUCCESS,
  GET_CLUBS_LEADS_LOCATIONS,
  GET_CLUBS_LEADS_LOCATIONS_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getClubLeadsLocationsSuccess = data => {
  return {
    type: GET_CLUBS_LEADS_LOCATIONS_SUCCESS,
    data,
  }
}

export const getClubLeadsLocations = filters => {
  return {
    type: GET_CLUBS_LEADS_LOCATIONS,
    filters,
  }
}

export const getClubLeadInfo = id => {
  return {
    type: GET_CLUB_LEAD_INFO,
    id,
  }
}

export const getClubLeadInfoSuccess = clubLeadInfo => {
  return {
    type: GET_CLUB_LEAD_INFO_SUCCESS,
    payload: clubLeadInfo,
  }
}

export const getClubsLeads = (filters, options) => {
  return {
    type: GET_CLUBS_LEADS,
    filters,
    options,
    payload: [],
  }
}

export const getClubsLeadsSuccess = clubsLeads => {
  return {
    type: GET_CLUBS_LEADS_SUCCESS,
    payload: clubsLeads,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
