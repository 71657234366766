import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"
import moment from "moment"
import { getBookings } from "./actions"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { store as socketStore } from "components/Common/Socket/store"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import Input from "components/Common/Input"
import Filters from "./Filters";
import timeToString from "../../helpers/timeToString";
import { get } from "../../helpers/api_helper";
import { useToasts } from "react-toast-notifications";

const filterTabs = [
  { label: "Future", value: "future" },
  { label: "Current", value: "current" },
  { label: "Past", value: "past" },
  { label: "All / New", value: "" },
]

const BookingsManagement = (props) => {
  const { addToast } = useToasts();

  const {
    bookings,
    getBookings,
    userDetails: { role },
  } = props;
  const [filters, setFilters] = useState({ timeframe: "current" })
  const [generatingCsv, setGeneratingCsv] = useState(false);

  const initialOptions = {
    page: 1,
    limit: 15,
  }

  const [options, setOptions] = useState(initialOptions)

  const pageOptions = {
    page: bookings.page,
    sizePerPage: bookings.limit,
    totalSize: bookings.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  useEffect(() => {
    getBookings(filters, options)
  }, [getBookings, filters, options])

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      search: e,
      timeframe: "",
    })
  }, 700)

  const listColumns = () => [
    {
      dataField: "id",
      text: "Id",
      formatter: (x, y) => (
        <Link className="text-dark" to={`/bookings/${y._id}`}>
          <Badge color="primary">{y.bookingId}</Badge>
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Club Name",
      formatter: (x, y) => (
        <Link className="text-dark" to={`/bookings/${y._id}`}>
          {y.clubInfo.clubName}
        </Link>
      ),
    },
    {
      dataField: "location",
      text: "Booking Type",
      formatter: (x, y) => <>{y?.internalBooking?.type ? `Internal Booking (${y?.internalBooking?.type})` : 'Player Booking'} </>,
    },
    {
      dataField: "customerName",
      text: "Customer Name",
      formatter: (x, y) => <>{y?.userInfo.firstName} {y?.userInfo.lastName} (#{y?.userInfo.userId})</>,
    },
    {
      dataField: "startDate",
      text: "Start Date",
      formatter: (cellContent, date) => <>
        {moment(date.startDate).format("YYYY-MM-DD")} {timeToString(date.startHour)}
      </>,
    },
    {
      dataField: "duration",
      text: "Duration",
      formatter: (x, y) => <>{y?.duration} min</>,
    },
    {
      dataField: "courtInfo.size",
      text: "Court Size",
    },
    {
      dataField: "courtInfo.surface",
      text: "Court Surface",
    },
    {
      dataField: "courtInfo.type",
      text: "Court Type",
    },
    {
      dataField: "status",
      text: "Booking Status",
    },
    {
      dataField: "paymentInfo.status",
      text: "Payment Status",
    },
    {
      dataField: "amount",
      text: "Price",
      formatter: (x, y) => <>{y?.amount} {y.currency}</>,
    },
  ]

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.name) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      default:
        break
    }
  }

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "DISABLED") {
      return "disabledRow"
    }
  }

  const downloadExport = async () => {
    setGeneratingCsv(true);
    await get(`${process.env.REACT_APP_API_URL}/exports/clubs/bookings`, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
      params: {
        ...filters,
      },
    }).then(data => {
      const url = window?.URL?.createObjectURL(new Blob([data]));
      const link = document?.createElement('a');
      link.href = url;
      link.setAttribute('download', `${moment(Date.now()).format('YYYY-MM-DD')}-bookings.csv`);
      document.body.appendChild(link);
      link.click();

      setGeneratingCsv(false);
    }).catch(e => {
      setGeneratingCsv(false);
      return addToast(e?.message ?? 'Could not generate .CSV file.', {
        appearance: "error",
        autoDismiss: true,
      });
    });
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem active>Bookings</BreadcrumbItem>
        </Breadcrumb>
        <Nav className="mb-0 d-flex justify-content-between" pills>
          <div className="d-flex ">
            {filterTabs?.map(tab => {
              return (
                <div key={tab?.label}>
                  <NavItem>
                    <NavLink
                      onClick={() => setFilters({ ...filters, timeframe: tab?.value })}
                      className={filters.timeframe === tab?.value && "active"}
                    >
                      {tab?.label}
                    </NavLink>
                  </NavItem>
                </div>
              )
            })}
          </div>
        </Nav>
        <Card>
          <CardBody>
            <div className="bookings">
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={bookings.results || []}
                        columns={listColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <div className="filter-area">
                              <Row className="mb-3 d-flex justify-content-between">
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <i className="bx bx-search-alt search-icon" />
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        placeholder="Search by Booking ID, Customer Name, Customer ID, Customer Phone, Club Name and Club ID"
                                      />
                                    </div>
                                  </div>
                                </Col>

                                {role === 'superAdmin' && (
                                  <Col>
                                    <div className="text-sm-right">
                                      <Button
                                        onClick={downloadExport}
                                        className="btn-rounded waves-effect waves-light mb-2  btn btn-warning"
                                        disabled={generatingCsv}
                                      >
                                        <i className="mdi  mdi-briefcase-download mr-1" />
                                        Export Bookings
                                      </Button>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              <Filters
                                {...{ filters, setFilters }}
                              />
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    rowClasses={rowClasses}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            {bookings?.totalResults === 0 && <h5 className="text-center mt-4">No results found!</h5>}

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

const mapStateToProps = ({
  bookings: { bookings = [] } = {},
  session: { userDetails } = {},
}) => ({
  bookings,
  userDetails,
})

const mapDispatchToProps = dispatch => ({
  getBookings: (filters, options, tabTimeFilter) =>
    dispatch(getBookings(filters, options, tabTimeFilter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingsManagement))
