import { get, post, del, put } from "helpers/api_helper"

export const getSession = async () => {
  let response
  try {
    response = await get("/admin/accounts/auth")
  } catch (error) {
    response = error.response;
  }
  return response;
}

export const getSettings = async () => {
  let response
  try {
    response = await get("/settings")
  } catch (error) {
    response = error.response;
  }
  return response;
}


export const login = async (values) => {
  let response
  try {
    response = await post("/admin/accounts/auth", values);
    response = await get("/admin/accounts/auth");
  } catch (error) {
    response = error.response;
  }
  return response
}

export const logout = async () => {
  let response
  try {
    response = await del("/admin/accounts/auth")
  } catch (error) {
    response = error.response;
  }
  return response
}
