import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAILS,
  GET_BOOKING_DETAILS_SUCCESS,
  API_ERROR,
  UPDATE_BOOKING_DETAILS,
} from "./actionTypes";


export const getBookings = (filters, options, tabTimeFilter) => {
  const filtersJSON = JSON.stringify(filters)
  const optionsJSON = JSON.stringify(options)

  return {
    type: GET_BOOKINGS,
    filters: filtersJSON,
    options: optionsJSON,
    time: tabTimeFilter,
    payload: [],
  }
}

export const getBookingsSuccess = data => {
  return {
    type: GET_BOOKINGS_SUCCESS,
    payload: data,
  }
}

export const getBookingDetails = id => {
  return {
    type: GET_BOOKING_DETAILS,
    payload: { id },
  }
}

export const getBookingDetailsSuccess = data => {
  return {
    type: GET_BOOKING_DETAILS_SUCCESS,
    payload: data,
  }
}

export const updateBookingDetails = payload => {
  return {
    type: UPDATE_BOOKING_DETAILS,
    payload,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
