import React from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Row,
} from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import FileUpload from "../../../components/Common/FileUpload";

const DefaultEventAssets = ({ defaultEventImages, setDefaultEventImages, clubId, category, show }) => {

  const setFieldValue = (name, value) => {
    setDefaultEventImages({
      ...defaultEventImages,
      [category]: {
        ...defaultEventImages?.[category],
        [name]: value
      }
    });
  }

  return (
    <Row hidden={!show}>
      <Row>
        <Col className="col-6">
          <Card>
            <CardBody>
              <CardTitle>
                Upload Logo (Recommended resolution 180 x 180)
              </CardTitle>
              <Form className="d-flex justify-content-center">
                <div className="logoWrap">
                  <AvField
                    name={`defaultEventImages[${category}[logo]]`}
                    type="hidden"
                    value={defaultEventImages?.[category]?.logo}
                  />
                  <FileUpload
                    fieldName="logo"
                    defaultPhoto={defaultEventImages?.[category]?.logo}
                    url={`clubs/${clubId}/${category}`}
                    value={defaultEventImages?.[category]?.logo ?? null}
                    setFieldValue={setFieldValue}
                    onChange={filename => setFieldValue("logo", filename)}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-6">
          <Card>
            <CardBody>
              <CardTitle>
                Upload featured (Recommended resolution 380 x 210)
              </CardTitle>
              <Form className="d-flex justify-content-center">
                <div className="featuredWrap">
                  <AvField
                    name={`defaultEventImages[${category}[featured]]`}
                    type="hidden"
                    value={defaultEventImages?.[category]?.featured}
                  />
                  <FileUpload
                    fieldName="featured"
                    defaultPhoto={defaultEventImages?.[category]?.featured}
                    url={`clubs/${clubId}/${category}`}
                    value={defaultEventImages?.[category]?.featured ?? null}
                    setFieldValue={setFieldValue}
                    onChange={filename => setFieldValue("featured", filename)}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card className="overflowScroll">
            <CardBody>
              <CardTitle>
                Upload cover (Recommended resolution 1200 x 500)
              </CardTitle>
              <Form className="d-flex justify-content-center">
                <div className="coverWrap">
                  <AvField
                    name={`defaultEventImages[${category}[cover]]`}
                    type="hidden"
                    value={defaultEventImages?.[category]?.cover}
                  />
                  <FileUpload
                    fieldName="cover"
                    defaultPhoto={defaultEventImages?.[category]?.cover}
                    url={`clubs/${clubId}/${category}`}
                    value={defaultEventImages?.[category]?.cover ?? null}
                    setFieldValue={setFieldValue}
                    onChange={filename => setFieldValue("cover", filename)}
                  />
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Row>
  );
}

export default DefaultEventAssets
