import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import * as actions from "./actions";

import { logout } from './api';

const Logout = props => {
  useEffect(() => {
    logout().then(r => props.history.push('/login'));
  })

  return <></>;
}

Logout.propTypes = {
  history: PropTypes.object,
  logout: PropTypes.func
}

const mapStatetoProps = ({ session: { userDetails, settings } = {} }) => ({ userDetails, settings })

export default withRouter(connect(mapStatetoProps, {
  logout: actions.logout,
})(Logout))
