import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_ADMINS,
  GET_ADMIN_INFO,
  CHANGE_ADMINS_FILTERS,
} from "./actionTypes";

import {
  apiError,
  getAdminsSuccess,
  getAdminInfoSuccess,
  changeAdminsFilters,
} from "./actions"

function* getAdmins({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(
      api.getAdmins,
      filters,
      options
    )
    yield put(getAdminsSuccess(data))

  } catch (error) {
    yield put(apiError(error))
  }
}

function* getAdminInfo({ id }) {
  try {
    const { data } = yield call(api.getAdminInfo, id)
    yield put(getAdminInfoSuccess(data))

  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_ADMINS, getAdmins)
  yield takeEvery(GET_ADMIN_INFO, getAdminInfo)
  yield takeEvery(CHANGE_ADMINS_FILTERS, changeAdminsFilters)
}

export default eventsSaga
