import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
  } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import moment from "moment"
import { Link } from "react-router-dom"

import { getPlayerRatings } from '../api'

const listColumns = () => [
  {
    dataField: "createdAt",
    text: "Timestamp",
    formatter: (cellContent, record) => {
      return (
        <p className="mb-0">{moment(record?.timestamp).format("YYYY-MM-DD HH:mm")}</p>
      )
    },
  },
  {
    dataField: "action",
    text: "Action",
    formatter: (cellContent, record) => {
      return (
        <p className="mb-0">{record?.editedAt ? 'Updated' : 'Created'}</p>
      )
    },
  },
  {
    dataField: "ratedBy",
    text: "Rated by",
    formatter: (cellContent, record) => {
      return record?.sender?.[0] ? <Link to={`/accounts/players/${record?.sender?.[0]?._id}`} className="text-dark" target="_blank">
      {record?.sender?.[0]?.firstName} {record?.sender?.[0]?.lastName} #{record?.sender?.[0]?.userId}
    </Link> : '';
    },
  },
  {
    dataField: "rating",
    text: "Rating",
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, record) => {
      return (
        <p className="mb-0 text-capitalize">{record?.status}</p>
      )
    },
  },
]

const RatingsModal = ({
    playerId,
    toggle,
    viewRatingsModal,
  }) => {
    const initialOptions = {
      page: 1,
      limit: 15,
    }
    const [options, setOptions] = useState(initialOptions)
    const [ratings, setRatings] = useState([])
    const [totalRatings, setTotalRatings] = useState(0)


    const pageOptions = {
      page: options?.page,
      sizePerPage: 15,
      totalSize: totalRatings,
      custom: true,
      hidePageListOnlyOnePage: true,
    }

    useEffect(() => {
      if (viewRatingsModal) {
        async function fetchRatings() {
          const { success, data } = await getPlayerRatings({
            id: playerId,
            page: options.page,
            limit: options.limit,
          });
          if (success) {
            setRatings(data.ratings);
            setTotalRatings(data.length);
          }          
        }
        fetchRatings();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewRatingsModal, options]);

    const closeModal = () => {
      toggle();
      setOptions(initialOptions);
    }

    return (
      <Modal
        isOpen={viewRatingsModal}
        toggle={() => {
          closeModal()
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            closeModal()
          }}
        >
          <h5>Raitings List</h5>
        </ModalHeader>
        <ModalBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={ratings || []}
                columns={listColumns()}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            responsive
                            remote
                            bordered={false}
                            striped={false}
                            classes={
                              "table table align-middle table-nowrap table-hover"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={(type, data) =>
                              setOptions({
                                ...options,
                                page: data?.page,
                              })
                            }
                            {...paginationTableProps}
                          />
                        </div>

                        {totalRatings.length === 0 && (
                          <Card>
                            <CardBody>
                              <p className="mb-0">There are no ratings yet</p>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              closeModal()
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  export default RatingsModal;
