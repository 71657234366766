import React, { useEffect, useState } from "react"
import { Field } from "formik"
import { connect } from "react-redux"
import Input from "components/Common/Input"
import Select from "react-select"
import { Row, Col, FormGroup, Label } from "reactstrap"
import { getClubs } from "../../../../../UserAccounts/ClubAccounts/actions"
import { getOrganizers } from "../../../../../UserAccounts/OrganizerAccounts/actions"
import { getClubOrganizers } from "../../../../../UserAccounts/ClubAccounts/actions"
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete"

import SelectGoogleAutocomplete from "components/Form/SelectGoogleAutocomplete"

const Info = ({
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  values,
  initialValues,
  setFieldTouched,
  fetchClubs,
  fetchOrganizers,
  clubs,
  organizers,
  fetchClubOrganizers,
  clubOrganizers,
  settings: { sponsors = [], coordinators = [], eligibleCountries = [] } = {},
}) => {
  const [inputClubValue, setInputClubValue] = useState("")

  useEffect(() => {
    fetchClubs(
      { page: 1, limit: 10 },
      { status: "activated", search: inputClubValue }
    )
    fetchOrganizers({ page: 1, limit: 10 }, { search: "" })
  }, [inputClubValue, fetchClubs, fetchOrganizers])

  useEffect(() => {
    fetchClubOrganizers(values?.club?.id)
  }, [fetchClubOrganizers, values?.club])

  const formattedClubValues = () => {
    const values = [
      { label: "TBA (To-Be-Announced)", value: { clubName: "TBA" } },
      { label: "No Club", value: { clubName: null } },
    ]
    clubs?.results?.map(club =>
      values.push({
        label: club?.clubName,
        value: club,
      })
    )
    return values
  }

  const [location, setLocation] = useState("")
  const [country, setCountry] = useState(
    values?.club?.location
      ? eligibleCountries?.filter(
          x => x?.name === values?.club?.location?.country
        )
      : ""
  )

  const handleLocation = async event => {
    const coords = await geocodeByPlaceId(event?.value?.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        return { lat, lng }
      })

    setFieldValue("location", {
      label: event?.label,
      value: {
        name: event?.label,
        city:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 2]?.value
            : null,
        country:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 1]?.value
            : null,
        coords,
      },
    })

    setLocation({
      label: event?.label,
      value: {
        name: event?.label,
        city:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 2]?.value
            : null,
        country:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 1]?.value
            : null,
        coords,
      },
    })
  }

  const formatNoClubOrganizers = () => {
    let org = []

    org = organizers?.results?.map(x => ({
      label:
        x?.organizerInfo?.clientType === "company"
          ? x?.organizerInfo?.companyName
          : `${x?.firstName} ${x?.lastName}`,
      value: x,
    }))

    org?.unshift({
      label: "No organizer",
      value: { firstName: "No", lastName: "organizer" },
    })
    return org
  }

  const handleOrganizers = ({ values, initialValues, name, e }) => {
    switch (name) {
      case "defaultValue":
        return values?.organizer !== null
          ? (values?.organizer && {
              value: values?.organizer,
              label:
                values?.organizer?.clientType === "company"
                  ? values?.organizer?.companyName
                  : `${values?.organizer?.firstName} ${values?.organizer?.lastName}`,
            }) ||
              (initialValues?.organizer && {
                value: initialValues?.organizer,
                label:
                  initialValues?.organizer?.clientType === "company"
                    ? initialValues?.organizer?.companyName
                    : `${initialValues?.organizer?.firstName} ${initialValues?.organizer?.lastName}`,
              })
          : null
      case "options":
        return values?.club?.clubName === null ||
          values?.club?.clubName === "TBA"
          ? formatNoClubOrganizers()
          : clubOrganizers?.results?.map(organizer => ({
              label:
                organizer?.organizerInfo?.clientType === "company"
                  ? organizer?.organizerInfo?.companyName
                  : `${organizer?.organizerInfo?.firstName} ${organizer?.organizerInfo?.lastName}`,
              value: organizer,
            }))
      case "onChange":
        if (e.value?.id) {
          const organizerData = {
            id: e.value.id,
            firstName: e.value?.organizerInfo?.firstName,
            lastName: e.value?.organizerInfo?.lastName,
            clientType: e.value?.organizerInfo?.clientType,
            companyName: e.value?.organizerInfo?.companyName,
            email: e.value?.organizerInfo?.email,
            phone: e.value?.role === "club" ? values?.club?.contactInfo?.phone : e.value?.organizerInfo?.phone,
          }
          setFieldValue("organizer", organizerData)
          setFieldValue("email", organizerData?.email)
          setFieldValue("phone", organizerData?.phone)
          setFieldValue(
            "directorName",
            `${organizerData.firstName} ${organizerData.lastName}`
          )
        } else {
          const organizerData = {
            firstName: e.value?.firstName,
            lastName: e.value?.lastName,
          }
          setFieldValue("organizer", organizerData)
        }
        break
      default:
    }
  }

  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <Label className="mb-1">Select coordinator*</Label>
            <Select
              name="coordinator"
              defaultValue={
                initialValues?.coordinator && {
                  value: initialValues?.coordinator,
                  label: initialValues?.coordinator?.name,
                }
              }
              placeholder="Select Coordinator"
              options={coordinators.map(coordinator => ({
                label: coordinator.name,
                value: coordinator,
              }))}
              onChange={e => setFieldValue("coordinator", e.value)}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors &&
            errors["coordinator"] &&
            touched &&
            touched["coordinator"] ? (
              <div className="error">{errors["coordinator"]}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Event name"
              label="Event name*"
              name="name"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label className="mb-1">Select club*</Label>
            <Select
              name="club"
              placeholder="Select club"
              onInputChange={e => setInputClubValue(e)}
              defaultValue={
                initialValues?.club && {
                  value: initialValues?.club && initialValues?.club,
                  label:
                    initialValues?.club &&
                    (initialValues?.club?.clubName !== null &&
                    initialValues?.club?.clubName !== "TBA"
                      ? initialValues?.club?.clubName
                      : initialValues?.club?.clubName === "TBA"
                      ? "TBA (To-Be-Announced)"
                      : "No Club"),
                }
              }
              options={formattedClubValues()}
              onChange={e => {
                setFieldValue("club", e.value)
                setFieldValue("phone", "")
                setFieldValue("refereeName", "")
                setFieldValue("email", "")
                setFieldValue("directorName", "")
                setFieldValue("location", "")
                setFieldValue("organizer", null)
              }}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors && errors["club"] && touched && touched["club"] ? (
              <div className="error">{errors["club"]}</div>
            ) : null}
          </FormGroup>
          {(values?.club?.clubName === null ||
            values?.club?.clubName === "TBA") && (
            <>
              <FormGroup>
                <Label className="mb-1">Select Country</Label>
                <Select
                  name="country"
                  placeholder="Select Country"
                  options={eligibleCountries?.map(x => ({
                    label: x?.name,
                    value: x?.key,
                  }))}
                  defaultValue={
                    country?.length > 0 && {
                      label: country[0]?.name,
                      value: country[0]?.key,
                    }
                  }
                  onChange={e => {
                    console.log(e, "tetest")
                    setCountry(e.value.toUpperCase())
                  }}
                  {...{ errors, touched }}
                />
              </FormGroup>
              <FormGroup>
                <Field
                  name="location"
                  component={SelectGoogleAutocomplete}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: [
                        country[0]?.key
                          ? country[0]?.key?.toUpperCase()
                          : country,
                      ],
                    },
                  }}
                  minLengthAutocomplete={3}
                  isClearable
                  label={"Add location"}
                  value={{
                    label: values?.location?.label
                      ? values?.location?.label
                      : values.club?.location?.name
                      ? values.club?.location?.name
                      : "",
                    value: values?.location?.label
                      ? values?.location?.value
                      : values.club?.location
                      ? values.club?.location
                      : "",
                  }}
                  {...{ errors, touched }}
                  onChange={countryValue => {
                    handleLocation(countryValue)
                  }}
                />
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label className="mb-1">Select organizer*</Label>
            <Select
              name="organizer"
              isDisabled={!values?.club}
              defaultValue={handleOrganizers({
                values,
                initialValues,
                name: "defaultValue",
              })}
              placeholder="Select Organizer"
              options={handleOrganizers({
                values,
                name: "options",
              })}
              key={values?.organizer}
              onChange={e =>
                handleOrganizers({
                  values,
                  e,
                  name: "onChange",
                })
              }
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors &&
            errors["organizer"] &&
            touched &&
            touched["organizer"] ? (
              <div className="error">{errors["organizer"]}</div>
            ) : null}
          </FormGroup>

          <Label className="mb-1">Events featured</Label>
          <FormGroup>
            <Field type="checkbox" name="featured" />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Input
              placeholder="Director name"
              label="Director name"
              name="directorName"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
              value={values?.directorName}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Email"
              label="Email"
              name="email"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
              type="email"
              value={values?.email}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Phone"
              label={
                (values?.club?.clubName === null ||
                  values?.club?.clubName === "TBA") &&
                values?.organizer?.firstName === "No"
                  ? "Phone"
                  : "Phone*"
              }
              name="phone"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
              value={values?.phone}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Referee name"
              label="Referee name"
              name="refereeName"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
              value={values?.refereeName}
            />
          </FormGroup>
          <FormGroup>
            <Input
              name="supervisorName"
              placeholder="Sportya Representative"
              label="Sportya Representative"
              {...{
                errors,
                initialValues,
                touched,
                handleChange,
                handleBlur,
              }}
              value={values?.supervisorName}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

const mapStatetoProps = ({
  clubs: { clubs = [], clubOrganizers } = {},
  organizers: { organizers = [] } = {},
}) => ({
  clubs,
  organizers,
  clubOrganizers,
})

export default connect(mapStatetoProps, {
  fetchClubs: getClubs,
  fetchOrganizers: () => getOrganizers({ page: 1, limit: 500 }, { search: "" }),
  fetchClubOrganizers: id => getClubOrganizers(id),
})(Info)
