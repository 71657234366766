import { GET_LEADERBOARDS, GET_LEADERBOARDS_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  leaderboards: {},
  loading: false,
}

const leaderboards = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARDS:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_LEADERBOARDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        leaderboards: action?.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default leaderboards
