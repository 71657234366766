// CREATE INSTANCE ACTION
export const type = "SOCKET";

export const socketState = { socket: {} };

export const socketReducer = (reducerState, action) => ({
  ...reducerState,
  socket: {
    ...reducerState.socket,
    ...action.data,
  },
});

export const socket = (data) => async (dispatch) => {
  dispatch({ type, data });
};
