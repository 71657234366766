import {
  GET_MATCHES,
  GET_MATCHES_SUCCESS,
  GET_MAIN_DRAW,
  GET_MAIN_DRAW_SUCCESS,
  API_ERROR,
  GET_LOGS_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  matches: [],
  mainDraw: {},
  logs: [],
  loading: false,
}

const matches = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGS_SUCCESS:
      state = {
        ...state,
        logs: action.payload,
      }
      break;
    case GET_MATCHES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MATCHES_SUCCESS:
      state = {
        ...state,
        loading: false,
        matches: action.payload,
      }
      break
    case GET_MAIN_DRAW:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MAIN_DRAW_SUCCESS:
      state = {
        ...state,
        loading: false,
        mainDraw: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default matches
