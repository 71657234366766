import React from "react"

import { Input } from "reactstrap"

const CommonInput = ({
  errors,
  touched,
  name,
  handleChange,
  handleBlur,
  values,
  initialValues,
  defaultValue,
  type,
  label,
  ...props
}) => {
  let value =
    type === "date"
      ? initialValues && initialValues[name].substr(0, 10)
      : (initialValues && initialValues[name]) || defaultValue
  if (initialValues && initialValues[name] === false) {
    value = false
  }
  return (
    <div>
      {label && <label className="mb-1">{label}</label>}
      <Input
        onWheel={event => event.currentTarget.blur()}
        type={type}
        defaultValue={value}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        {...props}
      />
      {errors && errors[name] && touched && touched[name] ? (
        <div className="error">{errors[name]}</div>
      ) : null}
    </div>
  )
}

export default CommonInput
