export const GET_EVENTS = "GET_EVENTS"
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS"
export const GET_EVENT_DETAILS = "GET_EVENT_DETAILS"
export const GET_EVENT_DETAILS_SUCCESS = " GET_EVENT_DETAILS_SUCCESS"

export const GET_PENALTIES = "GET_PENALTIES_MANAGEMENT"
export const GET_PENALTIES_SUCCESS = "GET_PENALTIES_MANAGEMENT_SUCCESS"

export const GET_EVENTS_LOCATIONS = "GET_EVENTS_LOCATIONS"
export const GET_EVENTS_LOCATIONS_SUCCESS = "GET_EVENTS_LOCATIONS_SUCCESS"

export const API_ERROR = "EVENTS_API_ERROR"
