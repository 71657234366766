import moment from "moment"

import {
  Badge,
} from "reactstrap"
import { Link } from "react-router-dom"
import { updateReport } from "./api"

const reasons = {
  harassment: 'Harassment or Bullying',
  spam: 'Spam',
  'fake-profile': 'Fake profile',
  'player-under-15-years': 'Player under 15 years old',
  'incorrect-score': 'Incorrect score',
  'undisputed-match': 'Undisputed match',
  other: 'Other',
};

const listColumns = ({fetchReports, options, activeFilters, setViewReportModal, setReportModalContent}) => [
  {
    dataField: "reportId",
    text: "Report ID",
    formatter: (cellContent, report) => <p className="mb-0">{report?._id}</p>,
  },
  {
    dataField: "createdAt",
    text: "Report Date",
    formatter: (cellContent, report) => {
      return (
        <div className="d-flex flex-column">
          <p className="mb-0">{moment(report.createdAt).format("YYYY-MM-DD")}</p>
          <p className="mb-0">{moment(report.createdAt).format("HH:mm:ss")}</p>
        </div>
      )
    },
  },
  {
    dataField: "reportedPlayer",
    text: "Reported Entity",
    formatter: (cellContent, report) => {
      return report?.reported?.userId 
        ? <Link to={`/accounts/players/${report?.reported?.id}`} className="text-dark">
            {report?.reported?.firstName} {report?.reported?.lastName} #{report?.reported?.userId}
          </Link>
        : <p className="mb-0">{report?.reported?.matchId ? `Match #FM${report?.reported?.matchId.toString().padStart(5, '0')}` : ''}</p>;
    },
  },
  {
    dataField: "reason",
    text: "Reason",
    formatter: (cellContent, report) => <p className="mb-0">{reasons[report?.reason]}</p>,
  },
  {
    dataField: "comments",
    text: "Comments",
    formatter: (cellContent, report) => <p className="contentEllipsis mb-0" style={{minWidth: '290px'}}>{report?.reasonDescription}</p>,
  },
  {
    dataField: "reporter",
    text: "Reporter",
    formatter: (cellContent, report) => {
      return (
        <Link to={`/accounts/players/${report?.reporter?.id}`} className="text-dark">
            {report?.reporter?.firstName} {report?.reporter?.lastName} #{report?.reporter?.userId}
        </Link>
      )
    },
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, report) => <p className={report?.status === 'resolved' ? 'text-success text-capitalize mb-0' : 'text-primary text-capitalize mb-0'}>{report?.status}</p>,
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (cellContent, report) => {
      return (
        <div className="d-flex justify-content-center">
          <a
            href="javascript:;"
            onClick={async () => {
              await setReportModalContent(report);
              setViewReportModal(true);
            }}
          >
            <i className="bx bx-show-alt font-size-18 mr-2"></i>
          </a>
          <Badge
            onClick={async () => {
              await updateReport({reportId: report?._id, status: report?.status === 'unresolved' ? 'resolved' : 'unresolved'})
              await fetchReports(options, activeFilters)
            }}
            color={report?.status === 'unresolved' ? 'success' : 'primary'}
            className="pointer"
          >
            <p className="p-1 mb-0">
              {report?.status === 'unresolved' ? 'Resolve' : 'Unresolve'}
            </p>
          </Badge>
        </div>
      )
    },
  },
]

export default listColumns
