import React, { useState } from "react"

import Input from "components/Common/Input"
import {
  Row,
  Col,
  FormGroup,
  Nav,
  TabPane,
  NavItem,
  NavLink,
  CardTitle,
} from "reactstrap"
import FileUpload from "components/Common/FileUpload"

const Prizes = ({
  id,
  errors,
  touched,
  handleChange,
  handleBlur,
  initialValues,
  values,
  setFieldValue,
}) => {
  const prizesTabs = [
    { label: "Ro", value: "ro" },
    { label: "En", value: "en" },
  ]

  const [activeLanguage, setActiveLanguage] = useState("ro")

  return (
    <div>
      <div className="tabs">
        <Nav className="pl-0 mt-2 mb-3" pills>
          {prizesTabs?.map((prizeTab, idx) => (
            <NavItem key={idx}>
              <NavLink
                onClick={() => setActiveLanguage(prizeTab.value)}
                active={activeLanguage === prizeTab.value}
              >
                {prizeTab.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Row>
          <Col>
            1st prize
            <FormGroup>
              <Input
                placeholder="Set 1st title"
                label="Set 1st title"
                type="text"
                name={activeLanguage === "ro" ? "prize1Title" : "prize1EnTitle"}
                value={
                  activeLanguage === "ro"
                    ? values?.prize1Title
                    : values?.prize1EnTitle
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Set 1st prize"
                label="Set 1st prize"
                type="text"
                name={activeLanguage === "ro" ? "prize1" : "prize1En"}
                value={
                  activeLanguage === "ro" ? values?.prize1 : values?.prize1En
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            2nd prize
            <FormGroup>
              <Input
                placeholder="Set 2nd title"
                label="Set 2nd title"
                type="text"
                name={activeLanguage === "ro" ? "prize2Title" : "prize2EnTitle"}
                value={
                  activeLanguage === "ro"
                    ? values?.prize2Title
                    : values?.prize2EnTitle
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Set 2nd prize"
                label="Set 2nd prize"
                type="text"
                name={activeLanguage === "ro" ? "prize2" : "prize2En"}
                value={
                  activeLanguage === "ro" ? values?.prize2 : values?.prize2En
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
          </Col>

          <Col>
            3rd prize
            <FormGroup>
              <Input
                placeholder="Set 3rd title"
                label="Set 3rd title"
                type="text"
                name={activeLanguage === "ro" ? "prize3Title" : "prize3EnTitle"}
                value={
                  activeLanguage === "ro"
                    ? values?.prize3Title
                    : values?.prize3EnTitle
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Set 3rd prize"
                label="Set 3rd prize"
                type="text"
                name={activeLanguage === "ro" ? "prize3" : "prize3En"}
                value={
                  activeLanguage === "ro" ? values?.prize3 : values?.prize3En
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            4th prize
            <FormGroup>
              <Input
                placeholder="Set 4th title"
                label="Set 4th title"
                type="text"
                name={activeLanguage === "ro" ? "prize4Title" : "prize4EnTitle"}
                value={
                  activeLanguage === "ro"
                    ? values?.prize4Title
                    : values?.prize4EnTitle
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Input
                placeholder="Set 4th prize"
                label="Set 4th prize"
                type="text"
                name={activeLanguage === "ro" ? "prize4" : "prize4En"}
                value={
                  activeLanguage === "ro" ? values?.prize4 : values?.prize4En
                }
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <CardTitle>Upload pdf with competition details</CardTitle>
      <FileUpload
        pdf={true}
        fieldName="competitionDetailsPdf"
        setFieldValue={setFieldValue}
        defaultPhoto={values?.competitionDetailsPdf}
        url={`events/${id}`}
        id={id}
        onChange={async filename => {
          setFieldValue("competitionDetailsPdf", filename)
        }}
      />
    </div>
  )
}

export default Prizes
