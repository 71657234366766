import {
  GET_REPORTS_MANAGEMENT,
  GET_REPORTS_MANAGEMENT_SUCCESS,
  API_ERROR,
} from "./actionTypes"

export const getReports = (activeFilters, options) => {
  return {
    type: GET_REPORTS_MANAGEMENT,
    payload: [activeFilters, options],
  }
}

export const getReportsSuccess = reports => {
  return {
    type: GET_REPORTS_MANAGEMENT_SUCCESS,
    payload: reports,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
