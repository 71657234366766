import { get, patch, post } from "helpers/api_helper";

export const getBookings = async (filters, options, time) => {
  let response

  try {
    var obj1 = JSON.parse(filters)
    var obj2 = JSON.parse(options)
    obj2 = {
      page: obj2?.page,
      limit: filters?.time === "current" ? 100 : 15,
    }
    const filterParams = new URLSearchParams(
      Object.assign(obj1, obj2)
    ).toString()
    response = await get(`/bookings?${filterParams}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getBookingDetails = async id => {
  let response
  try {
    response = await get(`/bookings/${id}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const cancelBooking = async (id, data) => {
  let response
  try {
    response = await post(`/admin/bookings/${id}/cancel`, data)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const rescheduleBooking = async (id, data) => {
  let response
  try {
    response = await patch(`/admin/bookings/${id}/reschedule`, data)
  } catch (error) {
    console.log(error)
  }
  return response
}
