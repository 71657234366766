import PropTypes from "prop-types"
import React, { useEffect } from "react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
    // eslint-disable-next-line
  }, [])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("Menu")} </li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-home-circle"></i>
              <span>{props.t("Dashboards")}</span>
            </Link>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="bx bxs-user-detail"></i>
              <span>{props.t("Users Management")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
              <li>
                <Link to="/accounts/players">{props.t("Players")}</Link>
              </li>
              <li>
                <Link to="/accounts/clubs">{props.t("Clubs")}</Link>
              </li>
              <li>
                <Link to="/accounts/clubs-leads">
                  {props.t("Club Business Leads")}
                </Link>
              </li>
              <li>
                <Link to="/accounts/organizers">{props.t("Organizers")}</Link>
              </li>
              <li>
                <Link to="/accounts/admins">{props.t("Admins")}</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/events-management" className=" waves-effect">
              <i className="bx bx-calendar-event"></i>
              <span>{props.t("Events Management")}</span>
            </Link>
          </li>

          <li>
            <Link to="/friendly-matches" className=" waves-effect">
              <i className="bx bx-tennis-ball"></i>
              <span>{props.t("Friendly Matches")}</span>
            </Link>
          </li>

          <li>
            <Link to="/bookings-management" className="waves-effect">
              <i className="bx bx-calendar"></i>
              <span>{props.t("Bookings Management")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/payments-management"
              className={` waves-effect ${
                props.location.pathname === "/payments-management" &&
                "mm-active"
              }`}
            >
              <i className="bx bx-credit-card"></i>
              <span>{props.t("Payments Management")}</span>
            </Link>
          </li>

          <li>
          <Link
              to="/extended-analytics"
              className={` waves-effect ${
                props.location.pathname === "/extended-analytics" &&
                "mm-active"
              }`}
            >
              <i className="bx bx-line-chart"></i>
              <span>{props.t("Extended Analytics")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/penalties-management"
              className={` waves-effect ${
                props.location.pathname === "/penalties-management" &&
                "mm-active"
              }`}
            >
              <i className="bx bx-block"></i>
              <span>{props.t("Penalties Management")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/reports-management"
              className={` waves-effect ${
                props.location.pathname === "/reports-management" &&
                "mm-active"
              }`}
            >
              <i className="bx bx-error-alt"></i>
              <span>{props.t("Reports Management")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/rankings-management"
              className={` waves-effect ${
                props.location.pathname === "/rankings-management" &&
                "mm-active"
              }`}
            >
              <i className="bx bx-list-ol"></i>
              <span>{props.t("Rankings")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/business-models"
              className={` waves-effect ${
                props.location.pathname === "/business-models" && "mm-active"
              }`}
            >
              <i className="bx bx-bar-chart-square"></i>
              <span>{props.t("Business Models")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/system-settings"
              className={` waves-effect ${
                props.location.pathname === "/system-settings" && "mm-active"
              }`}
            >
              <i className="bx bx-wrench"></i>
              <span>{props.t("System Settings")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/results-scores"
              className={` waves-effect ${
                props.location.pathname === "/results-scores" && "mm-active"
              }`}
            >
              <i className="bx bx-list-ul"></i>
              <span>{props.t("Results and Scores")}</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
