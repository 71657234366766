import React from "react"
import Match from "./Match"

const MatchesEliminatory = ({
  matches,
  mainDraw,
  competitionDetails,
  setModal,
  eventDetails,
}) => {
  const { mainList, waitingList, gameType } = competitionDetails

  const groupMatchesByStages = matches.reduce((acc, currentMatch) => {
    acc[currentMatch.competition.stage] = [
      ...(acc[currentMatch.competition.stage] || []),
      currentMatch,
    ]
    return acc
  }, {})

  let sortedQualifications = []

  const qualificationMatches = groupMatchesByStages[0]

  return (
    <>
      <div class={`Matches flex ${mainDraw.qualifications && "Matches--with-qualifications"}`}>
        <div className="tournament-brackets">
          {(mainDraw.phases || mainDraw.matches).map((stage, idx) => {
            return (
              <>
                <ul className={`bracket bracket-${idx}`}>
                  {stage?.map((matchInfo, index) => {
                      mainDraw.qualifications
                        ?.filter(
                          y =>
                            y?.competition?.nextStagePosition ===
                            matchInfo?.competition?.position * 2 ||
                            y?.competition?.nextStagePosition ===
                            matchInfo?.competition?.position * 2 - 1
                        )
                        ?.map(sortedMatch =>
                          sortedQualifications?.push(sortedMatch)
                        )

                      return (
                        <li className="team-item">
                          <Match
                            qualificationMatches={mainDraw.qualifications}
                            setModal={setModal}
                            draw="eliminatory"
                            gameType={gameType}
                            firstStage={idx === 0}
                            matchId={matchInfo?._id}
                            index={index}
                            eventDetails={eventDetails}
                            {...{mainList, waitingList}}
                            {...{...matchInfo, mainList, waitingList}}
                            competitionDetails={competitionDetails}
                            firstStageLength={matches?.length}
                            type={matchInfo?.type}
                            isQualification={qualificationMatches}
                            sortedQualifications={sortedQualifications}
                          />
                        </li>
                      )
                    })}
                </ul>
              </>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MatchesEliminatory
