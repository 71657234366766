import React, { useState } from "react";
import {
    Row,
    Col,
    Button,
} from "reactstrap";
import DatePicker from "react-datepicker"

const Export = ({
    title,
    link,
    datepickers,
}) => {

    const now = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const [startDate, setStartDate] = useState(oneYearAgo);
    const [endDate, setEndDate] = useState(now);

    const dateFormat = (date, oneYearAgo) => {
        if (!date) {
            date = new Date();
            if (oneYearAgo) {
            date.setFullYear(date.getFullYear() - 1)
            }
        }
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    }


    return (
        <Row className="mb-2 flex-row w-100 flex-justify-content-between">
            <Col className="exports-dates-pickers flex-grow-1">{title}</Col>
            <Col className="exports-dates-pickers flex-grow-1">
                {datepickers ? <>
                    From
                    <DatePicker
                    placeholderText="Not set"
                    selected={startDate}
                    onChange={(value) => {setStartDate(value);}}
                    dateFormat="P"
                    className="form-control"
                    isClearable
                    />
                    To
                    <DatePicker
                    placeholderText="Not set"
                    selected={endDate}
                    onChange={(value) => {setEndDate(value);}}
                    dateFormat="P"
                    className="form-control"
                    isClearable
                    />
                </> : 'The date and time of the query'}
            </Col>
            <Col className="flex-grow-0">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_URL}${link}${datepickers ? `?startDate=${dateFormat(startDate, true)}&endDate=${dateFormat(endDate)}&key=O3}W|M7}warR1sK` : '?key=O3}W|M7}warR1sK'}`
                  )
              }}
            >
            Export
            </Button>
            </Col>
        </Row>
    );
}

export default Export;
