import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Label,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useEffect, useState } from "react"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete"
import { isEmpty, map } from "lodash"
import AsyncSelect from "react-select/async"
import debounce from "debounce-promise"
import Select from "react-select"

import FileUpload from "../../../components/Common/FileUpload"
import { getClubs } from "../ClubAccounts/api"

const FormData = props => {
  const {
    handleSubmit,
    playerInfo,
    settings,
    history,
    loading,
    eligibleCountries,
  } = props
  const [profilePicture, setProfilePicture] = useState(
    playerInfo?.profilePicture || null
  )
  const [isOrganizer, setIsOrganizer] = useState(
    playerInfo?.isOrganizer || false
  )
  const [clientType, setClientType] = useState(
    playerInfo?.organizerInfo?.clientType || "physicalPerson"
  )
  const [location, setLocation] = useState("")
  const [organizerLocation, setOrganizerLocation] = useState("")
  const [assignedClubs, setAssignedClubs] = useState(null)
  const [country, setCountry] = useState(
    playerInfo?.location
      ? eligibleCountries?.filter(
          x => x?.name === playerInfo?.location?.country
        )
      : ""
  )
  const [organizerCountry, setOrganizerCountry] = useState(
    playerInfo?.organizerInfo?.location
      ? eligibleCountries?.filter(
          x => x?.name === playerInfo?.organizerInfo?.location?.country
        )
      : ""
  )

  useEffect(() => {
    if (!isEmpty(playerInfo)) {
      if (playerInfo.location) {
        setLocation({
          label: playerInfo.location.name,
          value: playerInfo.location,
        })
      }
      if (playerInfo.isOrganizer) {
        setIsOrganizer(playerInfo.isOrganizer)
        setOrganizerLocation({
          label: playerInfo.organizerInfo?.location?.name,
          value: playerInfo.organizerInfo?.location,
        })
      }
    }
  }, [playerInfo])

  const loadOptions = inputValue => getAsyncOptions(inputValue)
  const handleOrganizerClubs = debounce(loadOptions, 500)
  const onChange = value => setAssignedClubs(value)
  const getAsyncOptions = inputValue => {
    return new Promise(async (resolve, reject) => {
      const filters = {}
      let response = null

      if (!isEmpty(inputValue)) {
        filters.search = inputValue
      } else if (
        !isEmpty(playerInfo?.organizerInfo?.assignedClubs) &&
        isEmpty(assignedClubs)
      ) {
        filters.ids = playerInfo?.organizerInfo?.assignedClubs
      }

      const {
        data: { results },
      } = await getClubs(filters)
      if (!isEmpty(results)) {
        response = await results.map(club => {
          return {
            label: `${club.clubName} (${club.location?.name})`,
            value: club.id,
          }
        })

        if (
          !isEmpty(playerInfo?.organizerInfo?.assignedClubs) &&
          isEmpty(assignedClubs)
        ) {
          setAssignedClubs(response)
        }
      }

      resolve(response)
    })
  }

  const handleLocation = async event => {
    if (isEmpty(event)) {
      setLocation("")
      return false
    }

    const coords = await geocodeByPlaceId(event?.value?.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        return { lat, lng }
      })
    setLocation({
      label: event.label,
      value: {
        name: event.label,
        city:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 2]?.value
            : null,
        country:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 1]?.value
            : null,
        coords,
      },
    })
  }

  const handleOrganizerLocation = async event => {
    if (isEmpty(event)) {
      setOrganizerLocation("")
      return false
    }

    const coords = await geocodeByPlaceId(event?.value?.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        return { lat, lng }
      })
    setOrganizerLocation({
      label: event.label,
      value: {
        name: event.label,
        city:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 2]?.value
            : null,
        country:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 1]?.value
            : null,
        coords,
      },
    })
  }

  const handleWalletBalance = event => {
    setWalletBalance(event.target.value)
    event.target.value === "custom"
      ? setTotalWalletBalance(parseFloat(playerInfo?.walletBalance))
      : setTotalWalletBalance(
          parseFloat(playerInfo?.walletBalance) + parseFloat(event.target.value)
        )
  }

  const handleCustomWalletBalance = event => {
    setTotalWalletBalance(parseFloat(event.target.value || 0))
  }

  return (
    <React.Fragment>
      <AvForm onValidSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3 border-bottom pb-2 d-flex justify-content-between align-items-center">
                  Profile details
                  <FileUpload
                    thumbnail
                    className="profile-picture"
                    defaultPhoto={playerInfo?.profilePicture || null}
                    onChange={async filename => {
                      setProfilePicture(filename)
                    }}
                  />
                </CardTitle>
                <AvField
                  name="profilePicture"
                  type="hidden"
                  id="profilePicture"
                  value={profilePicture || null}
                />

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="firstName"
                        placeholder="First name"
                        label="First name*"
                        type="text"
                        errorMessage="Enter First Name"
                        className="form-control"
                        validate={{
                          pattern: {
                            value:
                              "/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ffs ]*)$/gi",
                            errorMessage:
                              "Please use only letters from the Latin alphabet. This restriction is necessary to allow other players to find you more easily in Sportya (ex: when registering a doubles team for an event).",
                          },
                          required: { value: true },
                        }}
                        value={playerInfo?.firstName}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="lastName"
                        placeholder="Last name"
                        label="Last name*"
                        type="text"
                        errorMessage="Enter Last name"
                        className="form-control"
                        validate={{
                          pattern: {
                            value:
                              "/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ffs ]*)$/gi",
                            errorMessage:
                              "Please use only letters from the Latin alphabet. This restriction is necessary to allow other players to find you more easily in Sportya (ex: when registering a doubles team for an event).",
                          },
                          required: { value: true },
                        }}
                        value={playerInfo?.lastName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="email"
                        label="E-Mail*"
                        placeholder="Enter Valid Email"
                        type="email"
                        errorMessage="Invalid Email"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          email: { value: true },
                        }}
                        value={playerInfo?.email}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="phone"
                        label="Phone*"
                        placeholder="Enter Phone Number"
                        type="text"
                        errorMessage="Enter Only Number"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Only Numbers",
                          },
                          maxLength: {
                            value: 12,
                            errorMessage: "Max 12 chars.",
                          },
                        }}
                        value={playerInfo?.phone}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <FormGroup htmlFor="country">
                      <Label className="mb-1">Select Country</Label>
                      <Select
                        name="country"
                        placeholder="Select Country"
                        defaultValue={
                          country?.length > 0 && {
                            label: country[0]?.name,
                            value: country[0]?.key,
                          }
                        }
                        options={eligibleCountries?.map(x => ({
                          label: x?.name,
                          value: x?.key,
                        }))}
                        onChange={e => setCountry(e.value.toUpperCase())}
                      />
                    </FormGroup>
                    <FormGroup htmlFor="location">
                      <label htmlFor="location">Location*</label>
                      <GooglePlacesAutocomplete
                        name="location"
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                        autocompletionRequest={{
                          types: ["(cities)"],
                          componentRestrictions: {
                            country: [
                              country[0]?.key
                                ? country[0]?.key?.toUpperCase()
                                : country,
                            ],
                          },
                        }}
                        minLengthAutocomplete={3}
                        selectProps={{
                          placeholder: "Select location",
                          value: location,
                          onChange: e => handleLocation(e),
                          isClearable: true,
                          required: true,
                        }}
                      />
                      <AvField
                        name="location"
                        placeholder="Location"
                        type="hidden"
                        errorMessage="Enter location"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={location}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="birthDate"
                        placeholder="Birthdate"
                        label="Birthdate*"
                        type="date"
                        errorMessage="Enter birth date"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={
                          playerInfo?.birthDate
                            ? moment(playerInfo?.birthDate).format("YYYY-MM-DD")
                            : ""
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="gender"
                        label="Gender*"
                        value={playerInfo?.gender || "male"}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                {!playerInfo && (
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="gameLevelSingle"
                          label="Game level single"
                          value={playerInfo?.gameLevelSingle || 4}
                        >
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                        </AvField>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <AvField
                          type="select"
                          name="gameLevelDouble"
                          label="Game level double"
                          value={
                            playerInfo?.gameLevelDouble ||
                            playerInfo?.gameLevelSingle ||
                            4
                          }
                        >
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="dominantHand"
                        label="Dominant hand*"
                        value={playerInfo?.dominantHand || "right"}
                      >
                        <option value="right">Right</option>
                        <option value="left">Left</option>
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="tShirtSize"
                        label="T-shirt size"
                        value={playerInfo?.tShirtSize?.toLowerCase() || "None"}
                      >
                        <option value="none">None</option>
                        <option value="xs">XS</option>
                        <option value="s">S</option>
                        <option value="m">M</option>
                        <option value="l">L</option>
                        <option value="xl">XL</option>
                        <option value="xxl">XXL</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle className="mb-3 border-bottom pb-2">
                  Account Information
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="status"
                        label="Status"
                        value={playerInfo?.status || "pending"}
                      >
                        <option value="activated">Active</option>
                        <option value="blocked">Blocked</option>
                        <option value="pending">Pending</option>
                        <option value="deleted">Deleted</option>
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="profileVisibility"
                        label="Profile visibility*"
                        value={playerInfo?.profileVisibility || "all"}
                      >
                        <option value="all">All Players</option>
                        <option value="friendsOfFriends">
                          Friends of Friends
                        </option>
                        <option value="friends">Only Friends</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle className="mb-3 border-bottom pb-2">
                  Organizer Info
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="isOrganizer"
                        label="Organizer?"
                        value={playerInfo?.isOrganizer || "false"}
                        onChange={() => setIsOrganizer(!isOrganizer)}
                      >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    {isOrganizer && (
                      <FormGroup>
                        <AvField
                          type="select"
                          name="organizerInfo[clientType]"
                          label="Client type"
                          value={
                            playerInfo?.organizerInfo?.clientType ||
                            "physicalPerson"
                          }
                          onChange={e => setClientType(e.target.value)}
                        >
                          <option value="physicalPerson">
                            Physical person
                          </option>
                          <option value="company">Company</option>
                        </AvField>
                      </FormGroup>
                    )}
                  </Col>
                </Row>

                {isOrganizer && (
                  <>
                    {clientType === "company" && (
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="organizerInfo[companyName]"
                              placeholder="Company name"
                              label="Company name"
                              type="text"
                              errorMessage="Enter company name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={playerInfo?.organizerInfo?.companyName}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <AvField
                              name="organizerInfo[uniqueRegistrationNumber]"
                              placeholder="Unique registration number"
                              label="Unique registration number"
                              type="text"
                              errorMessage="Enter Unique registration number"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              value={
                                playerInfo?.organizerInfo
                                  ?.uniqueRegistrationNumber
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="organizerInfo[firstName]"
                            placeholder={
                              clientType === "company"
                                ? "Representative first name"
                                : "First name"
                            }
                            label={
                              clientType === "company"
                                ? "Representative first name"
                                : "First name"
                            }
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={playerInfo?.organizerInfo?.firstName}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="organizerInfo[lastName]"
                            placeholder={
                              clientType === "company"
                                ? "Representative last name"
                                : "Last name"
                            }
                            label={
                              clientType === "company"
                                ? "Representative last name"
                                : "Last name"
                            }
                            type="text"
                            errorMessage="Enter Last name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={playerInfo?.organizerInfo?.lastName}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="organizerInfo[email]"
                            label="E-Mail"
                            placeholder="Enter Valid Email"
                            type="email"
                            errorMessage="Invalid Email"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            value={playerInfo?.organizerInfo?.email}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <AvField
                            name="organizerInfo[phone]"
                            label="Phone"
                            placeholder="Enter Phone Number"
                            type="number"
                            errorMessage="Enter Only Number"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "Only Numbers",
                              },
                              maxLength: {
                                value: 12,
                                errorMessage: "Max 12 chars.",
                              },
                            }}
                            value={playerInfo?.organizerInfo?.phone}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup htmlFor="country">
                          <Label className="mb-1">Select Country</Label>
                          <Select
                            name="country"
                            placeholder="Select Country"
                            options={eligibleCountries?.map(x => ({
                              label: x?.name,
                              value: x?.key,
                            }))}
                            defaultValue={
                              organizerCountry?.length > 0 && {
                                label: organizerCountry[0]?.name,
                                value: organizerCountry[0]?.key,
                              }
                            }
                            onChange={e =>
                              setOrganizerCountry(e.value.toUpperCase())
                            }
                          />
                        </FormGroup>

                        <FormGroup htmlFor="organizerLocation">
                          <label htmlFor="organizerLocation">Location*</label>
                          <GooglePlacesAutocomplete
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                            autocompletionRequest={{
                              componentRestrictions: {
                                country: [
                                  organizerCountry[0]?.key
                                    ? organizerCountry[0]?.key?.toUpperCase()
                                    : organizerCountry,
                                ],
                              },
                            }}
                            minLengthAutocomplete={3}
                            selectProps={{
                              name: "organizerInfo[location]",
                              placeholder: "Select location",
                              value: organizerLocation,
                              onChange: e => handleOrganizerLocation(e),
                              isClearable: true,
                              required: true,
                            }}
                          />
                          <AvField
                            name="organizerInfo[location]"
                            placeholder="Location"
                            type="hidden"
                            errorMessage="Enter location"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            value={organizerLocation}
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup htmlFor="assignedClubs">
                          <label htmlFor="assignedClubs">
                            Assigned clubs/locations
                          </label>
                          <AsyncSelect
                            cacheOptions
                            defaultOptions
                            value={assignedClubs}
                            isClearable
                            isMulti
                            name="organizerInfo[assignedClubs]"
                            loadOptions={inputValue =>
                              handleOrganizerClubs(inputValue)
                            }
                            onChange={onChange}
                          />
                          <AvField
                            name="organizerInfo[assignedClubs]"
                            type="hidden"
                            value={assignedClubs?.reduce(
                              (acc, location) => [...acc, location.value],
                              []
                            )}
                          />
                        </FormGroup>

                        <FormGroup>
                          <AvField
                            type="select"
                            name="organizerInfo[osAccess]"
                            label="SportyaOS Access"
                            value={playerInfo?.organizerInfo?.osAccess || "false"}
                          >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                          </AvField>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
            {!playerInfo && (
              <Card>
                <CardBody>
                  <CardTitle className="mb-3 border-bottom pb-2">
                    Membership
                  </CardTitle>
                  <Row>
                    <Col md="6">
                      <CardSubtitle className="mb-3">
                        Active membership
                      </CardSubtitle>
                      <div className="text-uppercase">
                        {playerInfo?.membership?.plan || "N/A"}
                      </div>
                    </Col>

                    <Col md="6">
                      <CardSubtitle className="mb-3">
                        Add membership
                      </CardSubtitle>
                      <FormGroup>
                        <AvField
                          type="select"
                          name="membership[id]"
                          label="Membership type"
                          value={playerInfo?.membership?.id || null}
                        >
                          <option value="">FREE</option>
                          {settings?.memberships &&
                            settings?.memberships
                              .filter(v => v.status === "active")
                              .map((membership, key) => (
                                <option key={key} value={membership.id}>
                                  {membership.name} [
                                  {moment(membership.startDate).format(
                                    "d MMM YYYY"
                                  )}{" "}
                                  -{" "}
                                  {moment(membership.endDate).format(
                                    "d MMM YYYY"
                                  )}
                                  ] - [{membership.price["RON"]} RON /{" "}
                                  {membership.price["BGN"]} BGN]
                                </option>
                              ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>

          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3 border-bottom pb-2 card-title">
                  Communication preferences
                </CardTitle>
                <Row>
                  <Col lg={6}>
                    <FormGroup>
                      <AvField
                        type="select"
                        name="communicationPreferences[newsletter]"
                        label="Newsletter"
                        value={
                          playerInfo?.communicationPreferences?.newsletter ||
                          "false"
                        }
                      >
                        <option value={true}>Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>
                    <FormGroup>
                      <AvField
                        type="select"
                        name="communicationPreferences[siteInbox]"
                        label="Site inbox"
                        value={
                          playerInfo?.communicationPreferences?.siteInbox?.toString() ||
                          true
                        }
                      >
                        <option value={true}>Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>

                    <FormGroup>
                      <AvField
                        type="select"
                        name="communicationPreferences[partnerMatches]"
                        label="Partner matches"
                        value={
                          playerInfo?.communicationPreferences?.partnerMatches?.toString() ||
                          true
                        }
                      >
                        <option value={true}>Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup>
                      <AvField
                        type="select"
                        name="communicationPreferences[playersTournaments]"
                        label="Players & tournaments"
                        value={
                          playerInfo?.communicationPreferences?.playersTournaments?.toString() ||
                          true
                        }
                      >
                        <option value={true}>Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>

                    <FormGroup>
                      <AvField
                        type="select"
                        name="communicationPreferences[privateMessages]"
                        label="Send private messages"
                        value={
                          playerInfo?.communicationPreferences?.privateMessages?.toString() ||
                          true
                        }
                      >
                        <option value={true}>Yes</option>
                        <option value="false">No</option>
                      </AvField>
                    </FormGroup>

                    <FormGroup>
                      <AvField
                        name="communicationPreferences[dailyLimit]"
                        label="Daily message limit"
                        placeholder="Ex: 100"
                        type="number"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9]+$",
                            errorMessage: "Only Numbers",
                          },
                        }}
                        value={
                          playerInfo?.communicationPreferences?.dailyLimit ||
                          100
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={loading}
            >
              Submit
            </Button>
            <Button type="reset" color="secondary" onClick={history.goBack}>
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </React.Fragment>
  )
}

export default FormData
