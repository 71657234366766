import {
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAILS,
  GET_EVENT_DETAILS_SUCCESS,
  API_ERROR,
  GET_PENALTIES,
  GET_PENALTIES_SUCCESS,
  GET_EVENTS_LOCATIONS,
  GET_EVENTS_LOCATIONS_SUCCESS,
} from "./actionTypes"

export const getPenalties = (activeFilters, options) => {
  return {
    type: GET_PENALTIES,
    payload: [activeFilters, options],
  }
}

export const getPenaltiesSuccess = penalties => {
  return {
    type: GET_PENALTIES_SUCCESS,
    payload: penalties,
  }
}

export const getEvents = (filters, options, eventTimeFilter) => {
  const filtersJSON = JSON.stringify(filters)
  const optionsJSON = JSON.stringify(options)

  return {
    type: GET_EVENTS,
    filters: filtersJSON,
    options: optionsJSON,
    time: eventTimeFilter,
    payload: [],
  }
}

export const getEventsLocations = (filters, time) => {
  return {
    type: GET_EVENTS_LOCATIONS,
    payload: { time: time, filters: filters },
  }
}

export const getEventsLocationsSuccess = locations => {
  return {
    type: GET_EVENTS_LOCATIONS_SUCCESS,
    payload: locations,
  }
}

export const getEventsSuccess = events => {
  return {
    type: GET_EVENTS_SUCCESS,
    payload: events,
  }
}

export const getEventDetails = id => {
  return {
    type: GET_EVENT_DETAILS,
    payload: { id },
  }
}

export const getEventDetailsSuccess = eventDetails => {
  return {
    type: GET_EVENT_DETAILS_SUCCESS,
    payload: [eventDetails],
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
