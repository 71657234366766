import { get, patch, del, post } from "helpers/api_helper"

export const getPenalties = async competitionId => {
  let response
  try {
    response = await get(`/penalties/${competitionId}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const addPenalties = async ({ socketClientId, id, penalty }) => {
  let response

  try {
    response = await post(`/admin/accounts/${id}/penalties`, {
      socketClientId,
      penalty,
    })
  } catch (error) {
    console.log(error)
  }

  return response
}

export const updatePenalties = async ({
  penaltyId,
  userId,
  penalty,
  socketClientId,
}) => {
  let response
  try {
    response = await patch(`/admin/accounts/${userId}/penalties/${penaltyId}`, {
      ...penalty,
      socketClientId: socketClientId,
    })
  } catch (error) {
    console.log(error)
  }
  return response
}

export const updatePenaltiesStatus = async (userId, id, type) => {
  let response
  try {
    response = await patch(`/admin/accounts/${userId}/penalties/${id}/${type}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const DeletePenalties = async (userId, id) => {
  let response
  try {
    response = await del(`/admin/accounts/${userId}/penalties/${id}/deleted`)
  } catch (error) {
    console.log(error)
  }
  return response
}
