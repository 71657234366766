import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Row,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"

const FormData = ({ handleSubmit, adminInfo, history, loading }) => {
  return (
    <React.Fragment>
      <AvForm onValidSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">
                  Admin Account Information
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="firstName"
                        placeholder="First name"
                        label="First name*"
                        type="text"
                        errorMessage="Enter Firstname"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={adminInfo?.firstName}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="lastName"
                        placeholder="Last name"
                        label="Last name*"
                        type="text"
                        errorMessage="Enter Lastname"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={adminInfo?.lastName}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="role"
                        label="Role*"
                        value={adminInfo?.role || "admin"}
                      >
                        <option value="admin">Admin</option>
                        <option value="superAdmin">Super Admin</option>
                      </AvField>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="email"
                        label="Email*"
                        placeholder="Enter Valid Email"
                        type="email"
                        errorMessage="Invalid Email"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          email: { value: true },
                        }}
                        value={adminInfo?.email}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={loading}
            >
              Submit
            </Button>
            <Button type="reset" color="secondary" onClick={history.goBack}>
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </React.Fragment>
  )
}

export default FormData
