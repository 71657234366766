export const GET_ORGANIZERS = "GET_ORGANIZERS"
export const GET_ORGANIZERS_SUCCESS = "GET_ORGANIZERS_SUCCESS"

export const GET_ORGANIZER_INFO = "GET_ORGANIZER_INFO"
export const GET_ORGANIZER_INFO_SUCCESS = "GET_ORGANIZER_INFO_SUCCESS"

export const GET_OWNER_CLUB = "GET_OWNER_CLUB"
export const GET_OWNER_CLUB_SUCCESS = "GET_OWNER_CLUB_SUCCESS"

export const GET_TOURNAMENTS = "GET_TOURNAMENTS"
export const GET_TOURNAMENTS_SUCCESS = "GET_TOURNAMENTS_SUCCESS"

export const GET_ORGANIZERS_LOCATIONS = "GET_ORGANIZERS_LOCATIONS"
export const GET_ORGANIZERS_LOCATIONS_SUCCESS =
  "GET_ORGANIZERS_LOCATIONS_SUCCESS"

export const API_ERROR = "CLUBS_API_ERROR"
