import React, {useRef} from "react"
import Match from "./Match"
import GroupList from "./GroupList"

const groupLetter = Array(26)
  .fill(null)
  .map((letter, idx) => (idx + 10).toString(36).toUpperCase())

const MatchesGroups = ({
  mainDraw: {
    groups,
    matches,
  },
  setModal,
  eventDetails,
  competitionDetails,
}) => {
  const matchesRef = useRef(null)

  const scrollMatchesHandler = direction => {
    if (direction === "left") {
      matchesRef.current.scrollLeft = matchesRef.current.scrollLeft - 460
    } else {
      matchesRef.current.scrollLeft = matchesRef.current.scrollLeft + 460
    }
  }

  return (
    <div className="d-flex">
      <div className="scrollButton">
        <p onClick={() => scrollMatchesHandler("left")}>{"<"}</p>
      </div>

      <div ref={matchesRef} className="Matches flex">
        {groups?.map((group, index) => (
            <div>
              <h3>Group {groupLetter[index]}</h3>
              <GroupList group={group} competition={competitionDetails} />

              {matches[index]?.map((matchInfo, index) => (
                  <div className="Match__group">
                    <Match
                      draw="groups"
                      gameType={matchInfo?.competition?.gameType}
                      competitionDetails={competitionDetails}
                      matchId={matchInfo?._id}
                      eventDetails={eventDetails}
                      mainList={competitionDetails?.mainList}
                      addMatchPosition={index + 1}
                      setModal={setModal}
                      {...matchInfo}
                    />
                  </div>
                )
              )}
            </div>
          )
        )}
      </div>

      <div className="scrollButton">
        <p onClick={() => scrollMatchesHandler("right")}>{">"}</p>
      </div>
    </div>
  )
}

export default MatchesGroups
