import React from "react"
import { Badge } from "reactstrap"

const badgeColors = ['success', 'warning', 'danger', 'primary']

const GroupList = ({ group, competition }) => {
  return (
    <div className="groupList">
      <div className="d-flex justify-content-between">
        <p className="mb-1">Player</p>
        <p className="mb-1">Wins</p>
      </div>
      {group.map((player, idx) => (
        <>
          <div
            className={`position-relative align-items-center d-flex justify-content-between mb-2 `}
            key={player?.id}
          >
            <Badge color={badgeColors[idx] ?? "primary"} className="pl-2 pt-1 pb-1 pr-2 groupCompetitionPlaceBadge">
              {idx + 1}
            </Badge>

            <div className="align-items-center">
              <p className="mb-0">
                {player?.userId
                  ? `${player?.firstName} ${player?.lastName}${(competition?.drawModel === 'groups' && player?.competitionRank <= competition.numberOfGroups) ? ` (${player?.competitionRank})` : ''}`
                  : "BYE"}
                <span> {player?.userId}</span>
              </p>
              {/* display partner for duo competitions */}
              {player?.partner && (
                <p className="mb-0">
                  {player?.partner?.firstName} {player?.partner?.lastName}{(competition?.drawModel === 'groups' && player?.competitionRank <= competition.numberOfGroups) ? ` (${player?.competitionRank})` : ''}
                  <span> {player?.partner?.userId}</span>
                </p>
              )}
            </div>
            <p className="mb-0 mr-2">{player?.wins}</p>
          </div>
        </>
      ))}
    </div>
  )
}

export default GroupList
