import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Reports Redux States
import {
  GET_REPORTS_MANAGEMENT
} from "./actionTypes"
import {
  apiError,
  getReportsSuccess,
} from "./actions"

function* getReports({ payload }) {
  try {
    const { data } = yield call(api.getReports, { payload })
    yield put(getReportsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* reportsSaga() {
  yield takeEvery(GET_REPORTS_MANAGEMENT, getReports)
}

export default reportsSaga;
