import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_EVENTS,
  GET_EVENT_DETAILS,
  GET_PENALTIES,
  GET_EVENTS_LOCATIONS,
} from "./actionTypes"
import {
  apiError,
  getEventsSuccess,
  getEventDetailsSuccess,
  getPenaltiesSuccess,
  getEventsLocationsSuccess,
} from "./actions"

function* getEventsLocations(filters) {
  try {
    const { data } = yield call(
      api.getEventsLocationsApi,
      filters?.payload?.filters,
      filters?.payload?.time
    )
    yield put(getEventsLocationsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getEvents({ filters, options, time, payload: [] }) {
  try {
    const { data } = yield call(api.getEvents, filters, options, time)
    yield put(getEventsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getEventDetails({ payload: { id } }) {
  try {
    let response
    if (id !== "empty") {
      response = yield call(api.getEventDetails, id)
    }
    yield put(getEventDetailsSuccess(id === "empty" ? [] : response?.data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPenalties({ payload }) {
  try {
    const { data } = yield call(api.getPenalties, { payload })
    yield put(getPenaltiesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_PENALTIES, getPenalties)
  yield takeEvery(GET_EVENTS_LOCATIONS, getEventsLocations)
  yield takeEvery(GET_EVENTS, getEvents)
  yield takeEvery(GET_EVENT_DETAILS, getEventDetails)
}

export default eventsSaga
