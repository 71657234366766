import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment";

const listColumns = (role) => [
  {
    dataField: "userId",
    text: "#",
    sort: true,
  },
  {
    dataField: "firstName",
    text: "Name",
    formatter: (cellContent, user) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/accounts/admins/${user.id}`} className="text-dark">
            {user.firstName} {user.lastName}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "role",
    text: "Role",
    formatter: (cellContent, user) => (
      <>
        <span className="text-capitalize">{user.role}</span>
      </>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, user) => (
      <>
        <span className={`badge badge-${user.status === 'activated' ? 'success' :
          (user.status === 'pending' ? 'info' :
            (user.status === 'blocked' ? 'warning' : 'danger')
          ) } font-size-12`}>{user.status}</span>
      </>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    sort: true,
    formatter: (cellContent, user) => {
      return (
        <>
          {moment(user.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </>
      )
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    formatter: (cellContent, data) => {
      return (
        <>
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="card-drop"
              tag="i"
            >
              <i className="mdi mdi-dots-horizontal font-size-18"/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="text-center p-0">
                <Link to={`/accounts/admins/${data.id}`} className="d-block dropdown-item">Account details</Link>
              </DropdownItem>
              {role === 'superAdmin' &&
              <DropdownItem className="text-center p-0">
                <Link to={`/accounts/admins/${data.id}/edit`} className="d-block dropdown-item">Edit</Link>
              </DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )
    },
  },
]

export default listColumns
