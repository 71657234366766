import React from "react"
import { Row, Col, CardBody, Card, Alert, Container, Label } from 'reactstrap';

// Redux
import { connect } from "react-redux"
import * as actions from "./actions"

import { Link, withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';

import { post } from "helpers/api_helper"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/sportya/ico.png"
import { useToasts } from "react-toast-notifications"

const handleLogin = async ({ login, values, addToast, history }) => {
  let loginResponse

  try {
    loginResponse = await login({ values, addToast, history })
  } catch ({ response: error }) {
    loginResponse = { error }
  }
}

const Login = ({ history, login }) => {
  const { addToast } = useToasts()

  const handleValidSubmit = (event, values) => {
    handleLogin({ login, values, addToast, history: history })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sportya Administration Area</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          value=""
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="">
                        <AvGroup check>
                          <Label check>
                            <AvInput type="checkbox" name="remember" /> Remember me
                          </Label>
                        </AvGroup>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = ({ session: { userDetails, settings } = {} }) => ({
  userDetails,
  settings,
})

export default withRouter(
  connect(mapStatetoProps, {
    login: actions.login,
  })(Login)
)
