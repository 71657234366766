import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_ORGANIZERS,
  GET_ORGANIZER_INFO,
  GET_OWNER_CLUB,
  GET_TOURNAMENTS,
  GET_ORGANIZERS_LOCATIONS,
} from "./actionTypes"

import {
  apiError,
  getOrganizersSuccess,
  getOrganizerInfoSuccess,
  getOwnerClubSuccess,
  getTournamentsSuccess,
  getOrganizersLocationsSuccess,
} from "./actions"

function* getOrganizersLocations({ payload }) {
  try {
    const { data } = yield call(api.getOrganizersLocations, payload)
    yield put(getOrganizersLocationsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getOrganizers({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getOrganizers, filters, options)
    yield put(getOrganizersSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getOrganizerInfo({ id }) {
  try {
    const { data } = yield call(api.getOrganizerInfo, id)
    yield put(getOrganizerInfoSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getOwnerClub({ id }) {
  try {
    const { data } = yield call(api.getOwnerClub, id)
    yield put(getOwnerClubSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getTournaments({ id }) {
  try {
    const { data } = yield call(api.getTournaments, id)
    yield put(getTournamentsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_ORGANIZERS_LOCATIONS, getOrganizersLocations)
  yield takeEvery(GET_ORGANIZERS, getOrganizers)
  yield takeEvery(GET_ORGANIZER_INFO, getOrganizerInfo)
  yield takeEvery(GET_OWNER_CLUB, getOwnerClub)
  yield takeEvery(GET_TOURNAMENTS, getTournaments)
}

export default eventsSaga
