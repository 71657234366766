import { Badge } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"

const orderColumns = (plans, hidePmColumn) => [
  {
    dataField: "paymentId",
    text: "Order Id",
    formatter: (cellContent, order) => <strong>{order?.paymentId}</strong>,
  },
  {
    dataField: "date",
    text: "Date",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        {moment(order?.createdAt).format("MMMM D, YYYY, H:mm:ss ")}
      </p>
    ),
  },
  {
    dataField: "type",
    text: "Order Type",
  },
  {
    dataField: "admin",
    text: "Owner",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        {cellContent?.id ? (
          <Link to={`/accounts/admins/${cellContent?.id}`}>
            {" "}
            {cellContent?.firstName} {cellContent?.lastName}
            {" - "}
            {cellContent?.userId}
          </Link>
        ) : (
          "-"
        )}
      </p>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cellContent, order) => (
      <Badge color="success">
        <p className="mb-0 p-2">{order?.amount + " " + order?.currency}</p>
      </Badge>
    ),
  },
  {
    dataField: hidePmColumn ? "balance" : "",
    text: hidePmColumn ? "Balance" : "",
    formatter: (cellContent, order) =>
      hidePmColumn ? (
        <Badge color="success">
          <p className="mb-0 p-2">{order?.user?.walletBalance || "-"}</p>
        </Badge>
      ) : (
        ""
      ),
  },
  {
    dataField: !hidePmColumn ? "paymentMethod" : "",
    text: !hidePmColumn ? "Payment Method" : "",
    formatter: (cellContent, order) =>
      !hidePmColumn ? <p className="mb-0">{order?.paymentType}</p> : "",
  },
  {
    dataField: "competitionId",
    text: "Competition Id",
    formatter: (cellContent, order) => {
      return (
        <Link
          to={`/events/${order.eventId}/competitions/${order.competitionId}`}
        >
          {order.competitionId}
        </Link>
      )
    },
  },
  {
    dataField: "eventName",
    text: "Order Details",
    formatter: (cellContent, order) => {
      return (
        <>
          {order?.eventId ? (
            order?.products?.map((prod, key) => (
              <>
                <p className="mb-0">Products:</p>
                <p>
                  <Link key={key} to={`/events/${order.eventId}/details`}>
                    {prod.name}
                  </Link>
                </p>
              </>
            ))
          ) : (
            <p className="mb-0">{order?.notes}</p>
          )}
        </>
      )
    },
  },
]

export default orderColumns
