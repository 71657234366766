import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Row,
  Badge,
  Label, Form
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import Select from "react-select"

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete"
import { isEmpty } from "lodash"
import debounce from "debounce-promise"
import { getClubs } from "./api"
import AsyncSelect from "react-select/async/dist/react-select.esm"
import moment from "moment"
import FileUpload from "../../../components/Common/FileUpload";
import TextEditor from "../../../components/Common/TextEditor";
import DefaultEventAssets from "./defaultEventAssets";

const FormData = ({
  handleSubmit,
  clubInfo,
  clubOwner,
  history,
  loading,
  selectedAmenities,
  setSelectedAmenities,
  selectedCourtTypes,
  setSelectedCourtTypes,
  openHours,
  setOpenHours,
  eligibleCountries,
  defaultBookingSettings,
  eventCategories,
}) => {
  const { facebookUrl, twitterUrl, instagramUrl, description } = clubInfo

  const [location, setLocation] = useState()
  const [country, setCountry] = useState()
  const [category, setCategory] = useState()
  const [organizerLocation, setOrganizerLocation] = useState()
  const [organizerCountry, setOrganizerCountry] = useState()
  const [isOrganizer, setIsOrganizer] = useState(
    clubOwner?.isOrganizer || false
  )
  const [assignedClubs, setAssignedClubs] = useState(null)
  const [defaultEventImages, setDefaultEventImages] = useState({})

  useEffect(() => {
    if (!isEmpty(clubInfo)) {
      if (clubInfo.location) {
        setLocation({
          label: clubInfo.location.name,
          value: clubInfo.location,
        })
        setCountry(eligibleCountries?.filter(
          x => x?.name === clubInfo.location?.country
        ).map(({ key, name }) => ({ label: name, value: key })).shift())
      }
      if (!isEmpty(clubOwner)) {
        setIsOrganizer(clubOwner.isOrganizer)
        if (clubOwner.organizerInfo?.location) {
          setOrganizerLocation({
            label: clubOwner.organizerInfo?.location?.name,
            value: clubOwner.organizerInfo?.location,
          })
          setOrganizerCountry(eligibleCountries?.filter(
            x => x?.name === clubOwner.organizerInfo.location.country
          ).map(({ key, name }) => ({ label: name, value: key })).shift())
        }
      }

      setDefaultEventImages(clubInfo?.defaultEventImages)
    }
  }, [clubInfo, clubOwner, eligibleCountries])

  const loadOptions = inputValue => getAsyncOptions(inputValue)
  const handleOrganizerClubs = debounce(loadOptions, 500)
  const onChange = value => setAssignedClubs(value)
  const getAsyncOptions = inputValue => {
    return new Promise(async (resolve, reject) => {
      const filters = {}
      let response = null

      if (!isEmpty(inputValue)) {
        filters.search = inputValue
      } else if (
        !isEmpty(clubOwner?.organizerInfo?.assignedClubs) &&
        isEmpty(assignedClubs)
      ) {
        filters.ids = clubOwner?.organizerInfo?.assignedClubs
      }

      const {
        data: { results },
      } = await getClubs(filters)
      if (!isEmpty(results)) {
        response = await results.map(club => {
          return {
            label: `${club.clubName} (${club.location?.name})`,
            value: club.id,
          }
        })

        if (
          !isEmpty(clubOwner?.organizerInfo?.assignedClubs) &&
          isEmpty(assignedClubs)
        ) {
          setAssignedClubs(response)
        }
      }

      resolve(response)
    })
  }

  const handleLocation = async event => {
    if (isEmpty(event)) {
      setLocation("")
      return false
    }

    const [geocode] = await geocodeByPlaceId(event?.value?.place_id)
    const coords = await getLatLng(geocode);

    const city = geocode.address_components
      .filter(({ types }) => types.find(type => type === 'locality'))
      .shift()?.long_name;

    const country = geocode.address_components
      .filter(({ types }) => types.find(type => type === 'country'))
      .shift()?.long_name;

    setLocation({
      label: event.label,
      value: {
        name: event.label,
        city: city ?? null,
        country: country ?? null,
        coords,
      },
    })
  }

  const handleOrganizerLocation = async event => {
    if (isEmpty(event)) {
      setOrganizerLocation()
      return false
    }

    const [geocode] = await geocodeByPlaceId(event?.value?.place_id)

    const coords = await getLatLng(geocode);

    const city = geocode.address_components
      .filter(({ types }) => types.find(type => type === 'locality'))
      .shift()?.long_name;

    const country = geocode.address_components
      .filter(({ types }) => types.find(type => type === 'country'))
      .shift()?.long_name;

    if (event.value) {
      setOrganizerLocation({
        label: event.label,
        value: {
          name: event.label,
          city: city ?? null,
          country: country ?? null,
          coords,
        },
      })
    }
  }

  const [courtTypes, setCourtTypes] = useState([]);
  useEffect(() => {
    setCourtTypes(['Clay', 'Hard', 'Artificial', 'Grass'].filter(x => !selectedCourtTypes?.includes(x)));
  }, [selectedCourtTypes])

  const [availableAmenities, setAvailableAmenities] = useState([]);
  useEffect(() => {
    setAvailableAmenities([
      {
        name: "Paid Parking",
        id: 1,
      },
      {
        name: "Free Parking",
        id: 2,
      },
      {
        name: "Equipment Rental",
        id: 3,
      },
  
      {
        name: "Store",
        id: 4,
      },
      {
        name: "Lockers",
        id: 5,
      },
      {
        name: "Showers",
        id: 6,
      },
      {
        name: "Restaurant",
        id: 7,
      },
      {
        name: "Cafeteria",
        id: 8,
      },
      {
        name: "Bar",
        id: 9,
      },
      {
        name: "Vending Machine",
        id: 10,
      },
      {
        name: "Special Access",
        id: 11,
      },
      {
        name: "Kids Playground",
        id: 12,
      },
    ].filter(x => !selectedAmenities?.some(a => a.id === x.id)));
  }, [selectedAmenities])


  const bookingInfo = {
    bookingEnabled: clubInfo?.bookingSettings?.bookingEnabled,
    applicationFee: clubInfo?.bookingSettings?.applicationFee ?? defaultBookingSettings?.applicationFee?.country[clubInfo?.location?.country]?.fee ?? defaultBookingSettings?.applicationFee?.defaultFee,
    bookInAdvance: clubInfo?.bookingSettings?.bookInAdvance ?? defaultBookingSettings?.bookInAdvance,
    minCancellationTime: clubInfo?.bookingSettings?.minCancellationTime ?? defaultBookingSettings?.minCancellationTime,
    notificationEmails: clubInfo?.bookingSettings?.notificationsEmails || clubInfo.contactInfo?.email,
  }

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">
                  Club Account Owner Information
                </CardTitle>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="ownerInfo.firstName"
                        placeholder="Owner Firstname"
                        label="Owner Firstname*"
                        type="text"
                        errorMessage="Enter Owner Firstname"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={
                          clubInfo.ownerInfo?.firstName || clubInfo.firstName
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="ownerInfo.lastName"
                        placeholder="Owner Lastname"
                        label="Owner Lastname*"
                        type="text"
                        errorMessage="Enter Owner Lastname"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={
                          clubInfo.ownerInfo?.lastName || clubInfo.lastName
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="ownerInfo.email"
                        label="Owner Email*"
                        placeholder="Enter Valid Email"
                        type="email"
                        errorMessage="Invalid Email"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          email: { value: true },
                        }}
                        value={clubInfo.ownerInfo?.email || clubInfo.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="ownerInfo.phone"
                        label="Owner Phone*"
                        placeholder="Enter Owner Phone"
                        type="number"
                        errorMessage="Enter Only Number"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: "^[0-9+]*$",
                            errorMessage: "Only Numbers",
                          },
                          maxLength: {
                            value: 15,
                            errorMessage: "Max 15 chars.",
                          },
                        }}
                        value={clubInfo.ownerInfo?.phone || clubInfo.phone}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        type="select"
                        name="partnerClub"
                        label="Sportya Partner Club"
                        value={clubInfo?.partnerClub || "false"}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Club Information</CardTitle>
                <FormGroup>
                  <AvField
                    name="clubName"
                    placeholder="Club Name / Brand Name"
                    label="Club Name / Brand Name*"
                    type="text"
                    errorMessage="Enter Club Name / Brand Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={clubInfo.clubName}
                  />
                </FormGroup>
                <FormGroup>
                  <AvField
                    name="sportType"
                    placeholder="Sport type"
                    label="Sport*"
                    type="text"
                    errorMessage="Enter sport type"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={clubInfo.sportType}
                  />
                </FormGroup>
                <FormGroup>
                  <AvField
                    name="businessName"
                    placeholder="Business Name"
                    label="Business Name*"
                    type="text"
                    errorMessage="Enter Business Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom02"
                    value={clubInfo.businessName}
                  />
                </FormGroup>

                <FormGroup htmlFor="country">
                  <Label className="mb-1">Select Country</Label>
                  <Select
                    name="country"
                    placeholder="Select Country"
                    value={country}
                    options={eligibleCountries?.map(x => ({
                      label: x?.name,
                      value: x?.key,
                    }))}
                    onChange={e => {
                      setCountry(e);
                      setLocation(null);
                    }}
                  />
                </FormGroup>

                <FormGroup htmlFor="location">
                  <label htmlFor="location">Location*</label>

                  <GooglePlacesAutocomplete
                    name="location"
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: [country?.value],
                      },
                    }}
                    minLengthAutocomplete={3}
                    isClearable
                    selectProps={{
                      placeholder: "Select location",
                      value: location,
                      onChange: e => handleLocation(e),
                      isClearable: true,
                      required: true,
                    }}
                  />

                  <AvField
                    name="location"
                    placeholder="Location"
                    type="hidden"
                    errorMessage="Enter location"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    value={location}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="mb-3">Contact Information</CardTitle>

                <FormGroup>
                  <AvField
                    name="contactInfo.firstName"
                    placeholder="First Name"
                    label="Contact Person First Name"
                    type="text"
                    errorMessage="Enter Contact Person First Name"
                    className="form-control"
                    id="validationCustom02"
                    value={clubInfo.contactInfo?.firstName}
                  />
                </FormGroup>

                <FormGroup>
                  <AvField
                    name="contactInfo.lastName"
                    placeholder="Last Name"
                    label="Contact Person Last Name"
                    type="text"
                    errorMessage="Enter Contact Person Last Name"
                    className="form-control"
                    id="validationCustom02"
                    value={clubInfo.contactInfo?.lastName}
                  />
                </FormGroup>

                <FormGroup>
                  <AvField
                    name="contactInfo.phone"
                    label="Contact Phone*"
                    placeholder="Enter Contact Phone"
                    type="number"
                    errorMessage="Enter Only Number"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9+]*$",
                        errorMessage: "Only Numbers",
                      },
                      maxLength: { value: 15, errorMessage: "Max 15 chars." },
                    }}
                    value={clubInfo.contactInfo?.phone}
                  />
                </FormGroup>

                <FormGroup>
                  <AvField
                    name="contactInfo.email"
                    label="Email*"
                    placeholder="Enter Valid Email"
                    type="email"
                    errorMessage="Invalid Email"
                    className="form-control ba"
                    validate={{
                      required: { value: true },
                      email: { value: true },
                    }}
                    value={clubInfo.contactInfo?.email}
                  />
                </FormGroup>

                <FormGroup>
                  <AvField
                    name="contactInfo.website"
                    placeholder="Website"
                    label="Website"
                    type="text"
                    className="form-control"
                    value={clubInfo.contactInfo?.website}
                  />
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-3 border-bottom pb-2">
                  Open Hours
                </CardTitle>{" "}
                {Object.keys(openHours)?.map(key => (
                  <div>
                    <Label
                      className={`mb-0 ${
                        !openHours[key]?.enabled
                          ? "text-secondary"
                          : "text-dark"
                      }`}
                    >
                      {key}
                    </Label>
                    <div className="d-flex align-items-center mb-2">
                      <FormGroup className="mb-0">
                        <Button
                          onClick={e =>
                            setOpenHours({
                              ...openHours,
                              [key]: {
                                ...openHours[key],
                                enabled: !openHours[key]?.enabled
                                  ? true
                                  : false,
                              },
                            })
                          }
                          color={
                            openHours[key]?.enabled === true
                              ? "success"
                              : "danger"
                          }
                          size="sm"
                        >
                          {openHours[key]?.enabled === true ? (
                            <i className="bx bx-check" />
                          ) : (
                            <i className="bx bx-x" />
                          )}
                        </Button>
                      </FormGroup>

                      <div className="d-flex">
                        {/* Start date */}
                        <DatePicker
                          className="hourDatePicker border ml-2 mr-5"
                          onChange={e =>
                            setOpenHours({
                              ...openHours,
                              [key]: { ...openHours[key], startDate: e },
                            })
                          }
                          timeIntervals={60}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          showTimeSelect
                          showTimeSelectOnly
                          selected={
                            openHours[key].enabled
                              ? openHours[key]?.startDate
                                ? moment(openHours[key]?.startDate).toDate()
                                : moment(8, "HH").toDate()
                              : null
                          }
                          disabled={!openHours[key]?.enabled && true}
                        />

                        {/* End date */}
                        <DatePicker
                          className="hourDatePicker border"
                          onChange={e =>
                            setOpenHours({
                              ...openHours,
                              [key]: { ...openHours[key], endDate: e },
                            })
                          }
                          timeIntervals={60}
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                          showTimeSelect
                          showTimeSelectOnly
                          selected={
                            openHours[key].enabled
                              ? openHours[key]?.endDate
                                ? moment(openHours[key]?.endDate).toDate()
                                : moment(22, "HH").toDate()
                              : null
                          }
                          disabled={!openHours[key]?.enabled && true}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                {" "}
                <CardTitle className="mb-3 border-bottom pb-2">
                  Courts Details
                </CardTitle>{" "}
                <FormGroup>
                  <AvField
                    name="courtsNumber"
                    label="Courts Number"
                    type="number"
                    errorMessage="Enter Only Number"
                    validate={{
                      required: { value: false },
                      pattern: {
                        value: "^[0-9+]*$",
                        errorMessage: "Only Positive Numbers",
                      },
                    }}
                    value={clubInfo?.noCourts || clubInfo?.courtsNumber}
                  />
                </FormGroup>
                <div>
                  <h5 className="mb-2 mt-2">Selected court types</h5>
                  <div>
                    {selectedCourtTypes?.length ? selectedCourtTypes.map((x, index) => (
                        <Badge
                          key={index}
                          className="m-1 p-1 pointer"
                          color="success"
                          onClick={() => {
                            setSelectedCourtTypes(selectedCourtTypes.filter(type => type !== x));
                          }}
                        >
                          <p className="mb-0">{x}</p>{" "}
                        </Badge>
                      )) : 'Select from bellow'}
                  </div>

                  <h5 className="mb-2 mt-2">Available court types</h5>
                  <div>
                    {courtTypes.map((x, index) => (
                        <Badge
                          key={index}
                          className="m-1 p-1 pointer"
                          onClick={() => {
                            setSelectedCourtTypes([...selectedCourtTypes, x]);
                          }}
                        >
                          <p className="mb-0">{x}</p>{" "}
                        </Badge>
                      ))}
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {" "}
                <CardTitle className="mb-3 border-bottom pb-2">
                  Amenities
                </CardTitle>{" "}
                <div>
                  <h5 className="mb-2 mt-2">Selected amenitites</h5>
                  <div>
                    {selectedAmenities?.length ?
                      selectedAmenities.map(x => (
                        <Badge
                          key={x.id}
                          className="m-1 p-1 pointer"
                          color="success"
                          onClick={() => {
                            setSelectedAmenities(selectedAmenities.filter(a => a.id != x.id))
                          }}
                        >
                          <p className="mb-0">{x.name}</p>{" "}
                        </Badge>
                      )) : 'Select from bellow'}
                  </div>

                  <h5 className="mb-2 mt-2">Available amenitites</h5>
                  <div>
                    {availableAmenities.map(x => (
                      <Badge
                        className="m-1 p-1 pointer"
                        onClick={() => {
                          setSelectedAmenities([...selectedAmenities, x])
                        }}
                        key={x.id}
                      >
                        <p className="mb-0">{x.name}</p>
                      </Badge>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <CardTitle className="mb-3 border-bottom pb-2">
              About (Club Description)
            </CardTitle>
            <FormGroup>
              <AvField
                type="textarea"
                name="description"
                id="description"
                placeholder="About (Club Description)"
                value={description}
              />
            </FormGroup>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-3 border-bottom pb-2">
              Social Media
            </CardTitle>
            <Row>
              <Col>
                <FormGroup>
                  <AvField
                    name="facebookUrl"
                    label="Facebook"
                    placeholder="Facebook"
                    type="text"
                    className="form-control"
                    value={facebookUrl}
                  />
                </FormGroup>
                <FormGroup>
                  <AvField
                    name="instagramUrl"
                    label="Instagram"
                    placeholder="Instagram"
                    type="text"
                    className="form-control"
                    value={instagramUrl}
                  />
                </FormGroup>
                <FormGroup>
                  <AvField
                    name="twitterUrl"
                    label="Twitter"
                    placeholder="Twitter"
                    type="text"
                    className="form-control"
                    value={twitterUrl}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-3 border-bottom pb-2">
              Organizer Info
            </CardTitle>
            <Row>
              <Col md="6">
                <FormGroup>
                  <AvField
                    type="select"
                    name="isOrganizer"
                    label="Organizer?*"
                    value={clubOwner?.isOrganizer || "false"}
                    onChange={() => setIsOrganizer(!isOrganizer)}
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </AvField>
                </FormGroup>
              </Col>
              <Col md="6">
                {isOrganizer && (
                  <FormGroup>
                    <AvField
                      name="organizerInfo[email]"
                      label="Contact e-mail"
                      placeholder="Enter valid email"
                      type="email"
                      errorMessage="Invalid Email"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        email: { value: true },
                      }}
                      value={clubOwner?.organizerInfo?.email}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>

            {isOrganizer && (
              <>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="organizerInfo[companyName]"
                        placeholder="Company name"
                        label="Company name"
                        type="text"
                        errorMessage="Enter company name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={clubOwner?.organizerInfo?.companyName}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="organizerInfo[uniqueRegistrationNumber]"
                        placeholder="Unique registration number"
                        label="Unique registration number"
                        type="text"
                        errorMessage="Enter Unique registration number"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={
                          clubOwner?.organizerInfo?.uniqueRegistrationNumber
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="organizerInfo[firstName]"
                        placeholder="Representative first name"
                        label="Representative first name"
                        type="text"
                        errorMessage="Enter representative first name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={clubOwner?.organizerInfo?.firstName}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <AvField
                        name="organizerInfo[lastName]"
                        placeholder="Representative last name"
                        label="Representative last name"
                        type="text"
                        errorMessage="Enter representative last name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={clubOwner?.organizerInfo?.lastName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormGroup htmlFor="organizerCountry">
                      <Label className="mb-1">Select Country</Label>
                      <Select
                        name="country"
                        placeholder="Select Country"
                        options={eligibleCountries?.map(x => ({
                          label: x?.name,
                          value: x?.key,
                        }))}
                        value={organizerCountry}
                        onChange={e => {
                          setOrganizerCountry(e)
                          setOrganizerLocation(null)
                        }}
                      />
                    </FormGroup>

                    <FormGroup htmlFor="organizerLocation">
                      <label htmlFor="organizerLocation">Location*</label>

                      <GooglePlacesAutocomplete
                        name="organizerLocation"
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: [organizerCountry?.value]
                          },
                        }}
                        minLengthAutocomplete={3}
                        isClearable
                        selectProps={{
                          name: "organizerInfo[location]",
                          id: "organizerLocation",
                          placeholder: "Select location",
                          value: organizerLocation,
                          onChange: e => handleOrganizerLocation(e),
                          isClearable: true,
                          required: true,
                        }}
                      />
                      <AvField
                        name="organizerInfo[location]"
                        placeholder="Location"
                        type="hidden"
                        errorMessage="Enter location"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={organizerLocation}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup htmlFor="assignedClubs">
                      <label htmlFor="assignedClubs">
                        Assigned clubs/locations
                      </label>
                      {assignedClubs && assignedClubs.map((elem) => {     
                        return (<p>{elem.label}</p>);               
                      })}
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={assignedClubs}
                        isClearable
                        isMulti
                        name="organizerInfo[assignedClubs]"
                        loadOptions={inputValue =>
                          handleOrganizerClubs(inputValue)
                        }
                        onChange={onChange}
                        className="assignedClubSelect"
                      />
                      <AvField
                        name="organizerInfo[assignedClubs]"
                        type="hidden"
                        value={assignedClubs?.reduce(
                          (acc, location) => [...acc, location.value],
                          []
                        )}
                      />
                    </FormGroup>

                    <FormGroup>
                      <AvField
                        type="select"
                        name="organizerInfo[osAccess]"
                        label="SportyaOS Access"
                        value={clubOwner?.organizerInfo?.osAccess || "false"}
                      >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <AvField
                  name="organizerInfo[clientType]"
                  type="hidden"
                  value={clubOwner?.organizerInfo?.clientType || "company"}
                />
              </>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-3 border-bottom pb-2">
              Booking Info
            </CardTitle>
            <Row>
              <Col>
                <FormGroup>
                  <AvField
                    type="select"
                    name="bookingAccess"
                    label="Booking Access Status"
                    value={clubInfo?.bookingAccess || "disabled"}
                  >
                    <option value="disabled">Disabled</option>
                    <option value="enabled">Enabled</option>
                    <option value="blocked">Blocked</option>
                  </AvField>
                </FormGroup>
              </Col>
              {/*<Col></Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
              {/*<Col>*/}
              {/*  <FormGroup>*/}
              {/*    <AvField*/}
              {/*      type="select"*/}
              {/*      name="bookingSettings[bookingEnabled]"*/}
              {/*      label="OS Booking Enabled"*/}
              {/*      value={bookingInfo?.bookingEnabled || "false"}*/}
              {/*    >*/}
              {/*      <option value="false">No</option>*/}
              {/*      <option value="true">Yes</option>*/}
              {/*    </AvField>*/}
              {/*  </FormGroup>*/}
              {/*</Col>*/}
              <Col>
                <FormGroup>
                  <AvField
                    name="bookingSettings[applicationFee]"
                    placeholder="Application fee"
                    label="Application fee (%)"
                    type="number"
                    errorMessage="Enter application fee"
                    className="form-control"
                    value={bookingInfo?.applicationFee || 0}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <AvField
                    type="select"
                    name="bookingSettings[bookInAdvance]"
                    label="Book in advance (days)"
                    value={bookingInfo?.bookInAdvance}
                    placeholder="Book in advance"
                    errorMessage="Enter book in advance"
                    className="form-control"
                  >
                    {Array.from({length: 14}, (_, i) => i + 1).map(el => (<option value={el}>{el}</option>))}
                  </AvField>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <AvField
                    type="select"
                    name="bookingSettings[minCancellationTime]"
                    label="Minimum cancellation time (hours)"
                    value={bookingInfo?.minCancellationTime}
                    placeholder="Minimum cancellation time"
                    errorMessage="Enter cancellation time"
                    className="form-control"
                  >
                    {Array.from({length: 22}, (_, i) => i + 3).map(el => (<option value={el}>{el}</option>))}
                  </AvField>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <AvField
                    name="bookingSettings[notificationsEmails]"
                    placeholder="Email"
                    label="Email notifications (split by comma):"
                    type="text"
                    className="form-control"
                    value={bookingInfo?.notificationEmails}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle>Default Events Images</CardTitle>
            <Row>
              <Col className="col-3">
                <FormGroup htmlFor="country">
                  <Select
                    name="eventCategory"
                    placeholder="Select Event Category"
                    value={category}
                    options={eventCategories?.map(x => ({
                      label: x?.name,
                      value: x?.key,
                    }))}
                    onChange={e => {
                      setCategory(e);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            {(eventCategories || []).map(({ key }) => (
              <DefaultEventAssets
                key={key}
                defaultEventImages={defaultEventImages}
                setDefaultEventImages={setDefaultEventImages}
                clubId={clubInfo?.clubId}
                category={key}
                show={key === category?.value}
              />
            ))}
          </CardBody>
        </Card>

        <FormGroup className="pb-3 mb-0 row">
          <div className="text-left col">
            <Button
              type="submit"
              color="primary"
              className="mr-1"
              disabled={loading}
            >
              Submit
            </Button>
            <Button type="reset" color="secondary" onClick={history.goBack}>
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  )
}

export default FormData
