import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import { GET_TRANSACTIONS } from "./actionTypes"

import { apiError, getTransactionsSuccess } from "./actions"

function* getTransactions({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getTransactions, filters, options)
    yield put(getTransactionsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_TRANSACTIONS, getTransactions)
}

export default eventsSaga
