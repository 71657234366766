import React, { useState } from "react"

import { Row, Col, Card, Form, CardBody, CardTitle, Button } from "reactstrap"

import FileUpload from "components/Common/FileUpload"
import { createEvent } from "pages/EventsManagement/api"
import TextEditor from "components/Common/TextEditor"

const FormUpload = ({
  id,
  eventId,
  setFieldValue,
  initialValues,
  values,
}) => {

  // set image assets from club
  const { clubId, defaultEventImages = {} } = values?.club;
  const category = values?.category;
  const defaultAssets = !id ? defaultEventImages?.[category] : defaultEventImages;

  ['logo', 'featuredImg', 'cover'].map((imageType) => {
    if (values?.[imageType] === null && defaultAssets?.[imageType === 'featuredImg' ? 'featured' : imageType]) {
      setFieldValue("club", {
        ...values?.club,
        defaultEventImages: {
          ...defaultAssets,
          [imageType === 'featuredImg' ? 'featured' : imageType]: null,
        }
      });
    }
  })

  return (<>
    {(id || (!id && defaultAssets?.logo && !values?.logo)) && (
      <Row>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>
                  {defaultAssets?.logo && values?.logo === "" ? 'Default Logo Fetched from Club' : 'Upload Logo (Recommended resolution 180 x 180)'}
                </CardTitle>
                <Form>
                  <div className="logoWrap">
                    <FileUpload
                      fieldName="logo"
                      defaultPhoto={values?.logo}
                      setFieldValue={setFieldValue}
                      url={`events/${eventId}`}
                      onChange={filename => setFieldValue("logo", filename)}

                      // default event images
                      {...defaultAssets?.logo && values?.logo === "" && {
                        url: `clubs/${clubId}/${category}`,
                        defaultPhoto: defaultAssets?.logo,
                      }}

                      // if not event id
                      {...!id && {
                        className: 'prevent-click',
                        readOnly: true,
                      }}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-6">
            <Card>
              <CardBody>
                <CardTitle>
                  {defaultAssets?.featured && values?.featuredImg === "" ? 'Default Featured Image Fetched from Club' : 'Upload featured (Recommended resolution 380 x 210)'}
                </CardTitle>
                <Form>
                  <div className={`featuredWrap ${!id ? 'prevent-click' : ''}`}>
                    <FileUpload
                      fieldName="featuredImg"
                      setFieldValue={setFieldValue}
                      defaultPhoto={values?.featuredImg}
                      url={`events/${eventId}`}
                      onChange={filename => setFieldValue("featuredImg", filename)}

                      // default event images
                      {...defaultAssets?.featured && values?.featuredImg === "" && {
                        url: `clubs/${clubId}/${category}`,
                        defaultPhoto: defaultAssets?.featured,
                      }}

                      // if not event id
                      {...!id && {
                        className: 'prevent-click',
                        readOnly: true,
                      }}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col className="col-12">
            <Card className="overflowScroll">
              <CardBody>
                <CardTitle>
                  {defaultAssets?.cover && values?.cover === "" ? 'Default Cover Image Fetched from Club' : 'Upload cover (Recommended resolution 1200 x 500)'}
                </CardTitle>
                <Form>
                  <div className={`coverWrap  ${!id ? 'prevent-click' : ''}`}>
                    <FileUpload
                      fieldName="cover"
                      setFieldValue={setFieldValue}
                      defaultPhoto={values?.cover}
                      url={`events/${eventId}`}
                      onChange={filename => setFieldValue("cover", filename)}

                      // default event images
                      {...defaultAssets?.cover && values?.cover === "" && {
                        url: `clubs/${clubId}/${category}`,
                        defaultPhoto: defaultAssets?.cover,
                      }}

                      // if not event id
                      {...!id && {
                        className: 'prevent-click',
                        readOnly: true,
                      }}
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>{" "}
      </Row>
    )}

    <Row>
      <Col className="col-12">
        <CardTitle>Event Description</CardTitle>
        <TextEditor
          defaultValue={initialValues.description}
          onChange={state =>
            setFieldValue("description", JSON.stringify(state))
          }
        />
      </Col>
    </Row>
  </>);
}

export default FormUpload
