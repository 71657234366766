export const GET_SESSION = "GET_SESSION"
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS"

export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"

export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"

export const API_ERROR = "AUTH_API_ERROR"
