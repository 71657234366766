import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Label,
  Row,
  Badge,
} from "reactstrap"

import { Link } from "react-router-dom"
import Select from "react-select"
import { Formik, Form } from "formik"
import moment from "moment"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import { getEvents, getPenalties } from "../EventsManagement/actions"
import { updatePenaltiesStatus } from "../EventsManagement/components/Tabs/Competitions/Tabs/Penalties/api"
import AdvancedFilters from "./AdvancedFilters"

const PenaltiesManagement = ({
  fetchEvents,
  fetchPenalties,
  events,
  penaltiesManager,
}) => {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(true)
  const [addPenaltyModal, setAddPenaltyModal] = useState(false)

  const toggle = () => setAddPenaltyModal(!addPenaltyModal)

  const initialOptions = {
    page: 1,
    limit: 15,
  }

  const [options, setOptions] = useState(initialOptions)

  const pageOptions = {
    page: options?.page,
    sizePerPage: 15,
    totalSize: penaltiesManager?.length,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const [activeFilters, setActiveFilters] = useState([])
  const [eventNameFilter, setEventNameFilter] = useState("")

  useEffect(() => {
    fetchEvents({ search: eventNameFilter }, options)
    fetchPenalties(options, activeFilters)
  }, [eventNameFilter, fetchEvents, options, fetchPenalties, activeFilters])

  const listColumns = () => [
    {
      dataField: "penaltyId",
      text: "Penalty ID",
      formatter: (x, y) => (
        <Badge color="warning">
          <Link
            to={`/events/${y?.event?.id}/competitions/${y?.competition?.id}/${y?._id}/add-penalties`}
          >
            <p className="mb-0 p-1 text-white">{y?._id}</p>
          </Link>
        </Badge>
      ),
    },
    {
      dataField: "penaltyDate",
      text: "Penalty Date",
      formatter: (x, y) => (
        <p className="mb-0">
          {moment(y?.startDate).format("MMMM Do YYYY, h:mm a")}
        </p>
      ),
    },
    {
      dataField: "eventId",
      text: "Event ID",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <Link target="_blank" to={`/events/${y?.event?.id}/details`}>
            <Badge color="primary">
              <p className="mb-0 p-1">{y?.event?.eventId}</p>
            </Badge>
          </Link>
        </div>
      ),
    },
    {
      dataField: "competitionId",
      text: "Competition ID",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <Link
            target="_blank"
            to={`/events/${y?.event?.id}/competitions/${y?.competition?.id}/details`}
          >
            <Badge color="primary">
              <p className="mb-0 p-1">{y?.competition?.competitionId}</p>
            </Badge>
          </Link>
        </div>
      ),
    },
    {
      dataField: "competitionLevel",
      text: "Competition Level",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">wip</div>
      ),
    },
    {
      dataField: "drawType",
      text: "Draw Type",
      formatter: (x, y) => <p className="mb-0">{y?.competition?.gameType}</p>,
    },
    {
      dataField: "playerName",
      text: "Player Name",
    },
    {
      dataField: "playerId",
      text: "Player ID",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <Link target="_blank" to={`/accounts/players/${y?.playerId}`}>
            <Badge color="primary">
              <p className="mb-0 p-1">{y?.userId}</p>
            </Badge>
          </Link>
        </div>
      ),
    },
    {
      dataField: "penaltyType",
      text: "Penalty Type",
      formatter: (x, y) => <p className="mb-0">{y?.type}</p>,
    },
    {
      dataField: "penaltyReason",
      text: "Penalty Reason",
      formatter: (x, y) => <p className="mb-0">{y?.reason}</p>,
    },
    {
      dataField: "points",
      text: "Points",
    },
    {
      dataField: "banWeeks",
      text: "BAN Weeks",
      formatter: (x, y) => (
        <div className="d-flex justify-content-center">
          <Badge color="danger">
            <p className="mb-0 p-1">{y?.banned}</p>
          </Badge>
        </div>
      ),
    },
    {
      dataField: "admin",
      text: "Admin",
      formatter: (x, y) => <p className="mb-0">{y?.adminName}</p>,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: (x, y) => {
        let updateType = y?.status === "DISABLED" ? "enabled" : "disabled"
        return (
          <div className="d-flex justify-content-center">
            <Badge
              onClick={async () => {
                await updatePenaltiesStatus(y?.userId, y?._id, updateType)
                await fetchPenalties(options, activeFilters)
              }}
              color="primary"
            >
              <p className="mb-0 p-1">
                {y?.status === "DISABLED" ? "enable" : "disable"}
              </p>
            </Badge>
          </div>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <h4>Penalties Management</h4>
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={penaltiesManager?.penalties || []}
                        columns={listColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <div className="filter-area">
                              <Row className="mb-3 d-flex justify-content-between">
                                {/* add penalty button & modal */}
                                <AddPenaltyModal
                                  addPenaltyModal={addPenaltyModal}
                                  setEventNameFilter={setEventNameFilter}
                                  toggle={toggle}
                                  events={events}
                                />
                                <Col>
                                  <div className="text-sm-right">
                                    <Link
                                      onClick={() => setAddPenaltyModal(true)}
                                      className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      Add Penalty
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div
                                className={`justify-content-between ${
                                  showAdvancedFilters ? "d-flex" : "d-none"
                                }`}
                              >
                                <Formik
                                  initialValues={{
                                    search: "",
                                    drawType: "",
                                    penaltyType: "",
                                  }}
                                >
                                  {({
                                    touched,
                                    initialValues,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    values,
                                  }) => {
                                    setActiveFilters(values)
                                    return (
                                      <Form className="w-100">
                                        <AdvancedFilters
                                          touched={touched}
                                          initialValues={initialValues}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          values={values}
                                        />
                                      </Form>
                                    )
                                  }}
                                </Formik>
                              </div>
                            </div>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={(type, data) =>
                                      setOptions({
                                        ...options,
                                        page: data?.page,
                                      })
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>

                                {penaltiesManager?.penalties?.length == 0 && (
                                  <NoPenalties />
                                )}
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

const NoPenalties = () => {
  return (
    <Card>
      <CardBody>
        <p className="mb-0">There are no penalties yet</p>
      </CardBody>
    </Card>
  )
}

const AddPenaltyModal = ({
  addPenaltyModal,
  toggle,
  events,
  setEventNameFilter,
}) => {
  //selected
  const [event, setEvent] = useState()
  const [competition, setCompetition] = useState()

  //options
  let competitionOptions
  if (event) {
    competitionOptions = event
  }

  //function to reset selected event/competition
  const resetFields = () => {
    setEvent()
    setCompetition()
  }

  return (
    <Modal
      isOpen={addPenaltyModal}
      toggle={() => {
        resetFields()
        toggle()
      }}
    >
      <ModalHeader
        toggle={() => {
          resetFields()
          toggle()
        }}
      >
        Add Penalty
      </ModalHeader>
      <ModalBody>
        <Label>Select Event*</Label>
        <Select
          onInputChange={e => setEventNameFilter(e)}
          onChange={e => {
            setEvent(e?.value)
            setCompetition()
          }}
          options={events?.results?.map(event => ({
            label: `${event?.name + ` ID: ${event?.eventId}`}`,
            value: event,
          }))}
        />
        <Label className="mt-2">Select Competition*</Label>
        <Select
          isDisabled={!event ? true : false}
          onChange={e => setCompetition(e?.value)}
          options={competitionOptions?.competitions?.map(comp => ({
            label: `${comp?.competitionId} - Level ${comp?.level} - ${comp?.gameType} `,
            value: comp,
          }))}
        />
      </ModalBody>
      <ModalFooter>
        <Link
          to={`/events/${event?._id}/competitions/${competition?._id}/add-penalties`}
        >
          <Button
            color="success"
            disabled={!competition || !event ? true : false}
            onClick={() => {
              resetFields()
            }}
          >
            Add
          </Button>
        </Link>
        <Button
          color="secondary"
          onClick={() => {
            resetFields()
            toggle()
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStatetoProps = ({
  events: { events = [], penaltiesManager = [] } = {},
}) => ({
  events,
  penaltiesManager,
})

export default connect(mapStatetoProps, {
  fetchEvents: (filters, options) => getEvents(filters, options),
  fetchPenalties: (filters, options) => getPenalties(filters, options),
})(PenaltiesManagement)
