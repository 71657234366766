export const GET_MATCHES = "GET_MATCHES"
export const GET_MATCHES_SUCCESS = "GET_MATCHES_SUCCESS"

export const GET_MAIN_DRAW = "GET_MAIN_DRAW"
export const GET_MAIN_DRAW_SUCCESS = "GET_MAIN_DRAW_SUCCESS"

export const GET_LOGS = "GET_LOGS"
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS"

export const API_ERROR = "EVENTS_API_ERROR"
