import React, {useState} from "react"
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, UncontrolledTooltip} from "reactstrap"
import { Link } from "react-router-dom"
import images from "assets/images"
import SweetAlert from "react-bootstrap-sweetalert"

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {deleteClubLead} from "./api";
import moment from "moment";

const handleDelete = async (id) => {
  const result = await deleteClubLead(id, { data: { id }});

  if (result?.success) {
    toastr.success('Club lead was successfully deleted.', 'Club lead deleted');
    // props.history.push("/accounts/clubs-leads");
  } else {
    toastr.error('Club lead could not be deleted.', 'Error');
  }
};

const listColumns = () => [
  {
    dataField: "clubName",
    text: "Name",
    sort: false,
    formatter: (cellContent, data) => (
      <>
        <h5 className="font-size-14 mb-0">
          <Link to={`/accounts/clubs-leads/${data.id}`} className="text-dark">
            {data.clubName}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
    sort: false,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: false,
  },
  {
    dataField: "location",
    text: "Location",
    formatter: (cellContent, data) => (
      <>
        <span className='text-capitalize'>{data.location?.city}, {data.location?.country}</span>
      </>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, data) => (
      <>
        <span className={`badge badge-${data.status === 'closed' ? 'success' : 'info' } font-size-12`}>{data.status}</span>
      </>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    sort: true,
    formatter: (cellContent, data) => {
      return (
        <>
          {moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </>
      )
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    formatter: (cellContent, data) => {
      return (
        <>
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="card-drop"
              tag="i"
            >
              <i className="mdi mdi-dots-horizontal font-size-18"/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="text-center p-0">
                <Link to={`/accounts/clubs-leads/${data.id}`} className="d-block dropdown-item">View details</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )
    },
  },
]

export default listColumns
