import React, { useEffect, useState } from "react"
import { Container, TabContent, TabPane, Row } from "reactstrap"
import { connect } from "react-redux"
import { getEventDetails as getEventDetailsAction } from "store/actions"

import PhasesTabs from "./tabs"

const PhasesDetails = ({ match, eventDetails = [], getEventDetails }) => {
  const [activeTabState, setActiveTab] = useState("details")

  const { id, phaseId, competitionId } = match.params

  const activeTab = match.params.tab || activeTabState

  const changeTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    getEventDetails(id)
  }, [getEventDetails, id])

  const [event] = eventDetails || []

  const [competitionDetails] =
    (event && event.competitions?.filter(({ _id }) => _id === competitionId)) ||
    []

  const [phaseDetails] =
    (competitionDetails &&
      competitionDetails.phases?.filter(({ _id }) => _id === phaseId)) ||
    []

  const ActiveTab = PhasesTabs?.filter(({ url }) => url === activeTab)[0]
    .Component

  return (
    <div className="page-content">
      {phaseDetails?.phaseId ? (
        <h4>{"Phase - " + phaseDetails?.phaseId}</h4>
      ) : (
        <h4>Add new Phase </h4>
      )}
      <Container fluid>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={activeTab}>
            <Row className="mt-2">
              <ActiveTab
                noPageClass
                phaseDetails={phaseDetails}
                eventDetails={eventDetails[0]}
                match={match}
              />
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

const mapStateToProps = ({ events: { eventDetails = [] } = {} }) => ({
  eventDetails,
})

export default connect(mapStateToProps, {
  getEventDetails: getEventDetailsAction,
})(PhasesDetails)
