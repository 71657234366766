import Entries from "./Entries"
import CompetitionDetails from "../CompetitionDetails"
import Draw from "./Draw"
import Phases from "./Phases"
import Matches from "./Matches"
import Penalties from "./Penalties"
import Points from "./Points"

const CompetitionTabs = [
  { url: "details", label: "Details", Component: CompetitionDetails },
  { url: "entries", label: "Entries", Component: Entries },
  { url: "phases", label: "Phases", Component: Phases },
  { url: "draw", label: "Lucky Draw", Component: Draw },
  { url: "penalties", label: "Penalties", Component: Penalties },
  { url: "matches", label: "Main Draw", Component: Matches },
  { url: "points", label: "Points", Component: Points },
]

export default CompetitionTabs
