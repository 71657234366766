import { Badge } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"

const projectColumns = (userId) => [
  {
    dataField: "id",
    text: "#",
    sort: true,
    formatter: (x, y) => (
      <Link target="_blank" to={`/events/${y?.eventId}/details`}>
        <Badge color="primary" className="p-1">
          <p className="mb-0">{y?.eventId}</p>
        </Badge>
      </Link>
    ),
  },
  {
    dataField: "eventDate",
    text: "Event Date",
    sort: true,
    formatter: (x, y) => (
      <p className="mb-0">
        {moment(y?.startDate).format("MMMM Do YYYY")} -{" "}
        {moment(y?.endDate).format("MMMM Do YYYY")}
      </p>
    ),
  },
  {
    dataField: "event",
    text: "Event",
    sort: true,
    formatter: (x, y) => (
      <Link target="_blank" to={`/events/${y?._id}/details`}>
        <p className="mb-0 text-dark">{y?.name}</p>
      </Link>
    ),
  },
  {
    dataField: "category",
    text: "Category",
  },
  {
    dataField: "competitionId",
    text: "Competition ID",
    formatter: (x, y) => (
      <Link
        target="_blank"
        to={`/events/${y?.eventId}/competitions/${y?._id}/entries`}
      >
        <Badge color="primary" className="p-1">
          <p className="mb-0">{y?.competitionId}</p>
        </Badge>
      </Link>
    ),
  },
  {
    dataField: "level",
    text: "Level",
    formatter: (x, y) => <p className="mb-0 text-dark">{y?.level}</p>,
  },
  {
    dataField: "draw",
    text: "Draw",
    formatter: (x, y) => <p className="mb-0 text-dark">{y?.gameType}</p>,
  },
  {
    dataField: "listType",
    text: "List Type",
    formatter: (x, y) => (
      <p className="mb-0 text-dark">
        {y?.mainList?.find(slot => slot.userId === userId)
          ? "Main"
          : y?.waitingList?.find(slot => slot.userId === userId)
          ? "Waiting"
          : y?.preRegistrationList?.find(slot => slot.userId === userId)
          ? "PreRegistration"
          : "-"}
      </p>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (x, y) => (
      <p className="mb-0 text-dark">
        {y?.mainList?.length > 0
          ? y?.mainList[0]?.amount + " " + y?.mainList[0]?.currency
          : y?.waitingList?.length > 0
          ? y?.waitingList[0]?.amount + " " + y?.waitingList[0]?.currency
          : y?.preRegistrationList[0]?.amount +
            " " +
            y?.preRegistrationList[0]?.currency}
      </p>
    ),
  },
  {
    dataField: "createdAt",
    text: "Entry date",
    formatter: (x, y) => (
      <p className="mb-0">
        {moment(y?.currentPhase?.startDate).format("MMMM Do YYYY")}
      </p>
    ),
  },
]

export default projectColumns
