import React, { useContext, useEffect } from 'react';
import io from 'socket.io-client';

import { store } from './store';
import { socket as setSocket } from './actions';

const Socket = ({ connectionUrl }) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  useEffect(() => {
    const socket = io(connectionUrl, {
      transports: ['websocket'],
      // upgrade: false,
    });
    socket.on('connect', (client) => {
      setSocket({ socketClientId: socket.id, socket })(dispatch);
    });
  }, [ connectionUrl, dispatch ]);
  return <></>;
};

export default Socket;
