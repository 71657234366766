import React from "react"

import { FormGroup, Label } from "reactstrap"
import Select from "react-select"
import { getSettings } from "../../Authentication/actions"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Closed", value: "closed" },
]

const FilterList = ({ filters, setFilters, locations }) => {
  const locationOptions = locations?.map(location => {
    return {
      label: location.name
        ? location.name
        : location?.city +
          ", " +
          location?.country +
          " (" +
          location?.total +
          ")",
      value: `${location.city ?? "null"}, ${location.country}`,
    }
  })

  function handleFilter(data) {
    const name = this?.name || data.target.name
    if (data) {
      const value = data.value || data.target.value

      setFilters({ ...filters, [name]: value })
    } else {
      const updatedFilters = { ...filters }
      delete updatedFilters[name]

      setFilters(updatedFilters)
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Location</Label>
        <Select
          placeholder="Select"
          name="location"
          onChange={handleFilter}
          options={locationOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Status</Label>
        <Select
          placeholder="Select"
          name="status"
          onChange={handleFilter}
          options={statusOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>From</Label>
        <div className="">
          <input
            name="fromDate"
            className="form-control"
            type="date"
            onChange={handleFilter}
          />
        </div>
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>To</Label>
        <div className="">
          <input
            name="toDate"
            className="form-control"
            type="date"
            onChange={handleFilter}
          />
        </div>
      </FormGroup>
    </React.Fragment>
  )
}

export default withRouter(FilterList)
