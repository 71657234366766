import {
  API_ERROR,
  GET_REPORTS_MANAGEMENT,
  GET_REPORTS_MANAGEMENT_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  reports: [],
  totalLength: 0,
  loading: false,
}

const reports = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORTS_MANAGEMENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_REPORTS_MANAGEMENT_SUCCESS:
      state = {
        ...state,
        reports: action.payload?.reports,
        totalLength: action.payload?.length,
        loading: false,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default reports
