import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  CHANGE_ADMINS_FILTERS,
  GET_ADMIN_INFO,
  GET_ADMIN_INFO_SUCCESS,
  API_ERROR
} from "./actionTypes"

export const getAdminInfo = id => {
  return {
    type: GET_ADMIN_INFO,
    id,
  }
}

export const getAdminInfoSuccess = accountInfo => {
  return {
    type: GET_ADMIN_INFO_SUCCESS,
    payload: accountInfo,
  }
}

export const getAdmins = (filters, options) => {
  return {
    type: GET_ADMINS,
    filters,
    options,
    payload: [],
  }
}

export const getAdminsSuccess = accounts => {
  return {
    type: GET_ADMINS_SUCCESS,
    payload: accounts,
  }
}

export const changeAdminsFilters = (filters) => {
  return {
    type: CHANGE_ADMINS_FILTERS,
    payload: filters,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
