import React, { useState } from "react"
import { Button } from "reactstrap"
import Dropzone from "react-dropzone"
import { post } from "helpers/api_helper"

const uploadPhoto = async (files, setPhoto, onChange, url) => {
  const formData = new FormData()
  ;[...files].forEach(file => formData.append("file", file))
  const { data: { filename } = {} } = await post(`/assets/${url}`, formData)
  setPhoto(filename)
  onChange && onChange(filename)
}

const FileUpload = ({
  onChange,
  setFieldValue,
  fieldName,
  defaultPhoto,
  url,
  pdf,
  id,
  ...props
}) => {
  const [photo, setPhoto] = useState()

  return (
    <div className={`FileUpload ${props.className || ""}`}>
      <div className="FileUpload__content">
        <div className="d-flex justify-content-between">
          {fieldName && setFieldValue && defaultPhoto && !props.readOnly && (
            <Button
              type="button"
              color="danger"
              className="mb-2"
              onClick={() => {
                setFieldValue(fieldName, null)
                setPhoto(null)
              }}
            >
              {pdf ? "Remove PDF" : "Remove Image"}
            </Button>
          )}
          {pdf && defaultPhoto && (
            <a
              download
              href={`${process.env.REACT_APP_API_URL}/assets/events/${id}/${defaultPhoto}`}
              target="_blank"
            >
              Download PDF
            </a>
          )}
        </div>

        <Dropzone
          onDrop={acceptedFiles => {
            uploadPhoto(acceptedFiles, setPhoto, onChange, url)
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick mt-2" {...getRootProps()}>
                <input {...getInputProps()} />
                {props.thumbnail ? (
                  <i className="fa-2x text-muted bx bxs-cloud-upload" />
                ) : (
                  <>
                    {!(photo || defaultPhoto) && (
                      <>
                        <div className="mb-3">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4>Drop files here or click to upload.</h4>
                      </>
                    )}
                  </>
                )}
                {(photo || defaultPhoto) && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/assets/${url}/${
                      photo || defaultPhoto
                    }`}
                    alt=""
                  />
                )}
                {(photo ||
                  (defaultPhoto &&
                    (photo || defaultPhoto).includes(".pdf"))) && (
                  <div className="flex align-items-center">
                    <i className="display-4 text-muted bx bxs-cloud-upload"/>
                    {photo || defaultPhoto}
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  )
}

export default FileUpload
