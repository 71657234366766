import {
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_LOCATIONS,
  GET_PLAYERS_LOCATIONS_SUCCESS,
  GET_PLAYER_INFO,
  GET_PLAYER_INFO_SUCCESS,
  GET_PLAYER_TRANSACTIONS,
  GET_PLAYER_TRANSACTIONS_SUCCESS,
  GET_PLAYER_REFERRAL_REWARDS,
  GET_PLAYER_REFERRAL_REWARDS_SUCCESS,
  GET_PLAYER_FIRST_TASK_REWARDS,
  GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS,
  GET_PLAYER_WALLET_TRANSACTIONS,
  GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS,
  GET_PLAYER_MEMBERSHIP_LIST,
  GET_PLAYER_MEMBERSHIP_LIST_SUCCESS,
  GET_PLAYER_ACTIVITY,
  GET_PLAYER_ACTIVITY_SUCCESS,
  GET_LEVEL_CHANGE,
  GET_LEVEL_CHANGE_SUCCESS,
  CHANGE_PLAYERS_FILTERS,
  API_ERROR,
  GET_PLAYERS_LIST,
  GET_PLAYERS_LIST_SUCCESS,
  GET_PLAYER_ENTRIES,
  GET_PLAYER_ENTRIES_SUCCESS,
  GET_PLAYER_HISTORY_ENTRIES,
  GET_PLAYER_HISTORY_ENTRIES_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  players: [],
  playersLocations: [],
  playersList: [],
  playerInfo: {},
  playerTransactions: {},
  playerWalletTransactions: {},
  referralRewards: {},
  firstTaskRewards: {},
  membershipList: {},
  playerActivity: {},
  levelChange: {},
  playerEntries: [],
  playerHistoryEntries: [],
  filters: {},
  loading: false,
}

const players = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLAYER_ENTRIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYER_ENTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerEntries: action.data,
      }
      break
    case GET_PLAYER_HISTORY_ENTRIES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYER_HISTORY_ENTRIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerHistoryEntries: action.data,
      }
      break
    case GET_PLAYERS_LIST:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYER_TRANSACTIONS:
      break
    case GET_PLAYER_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerTransactions: action.payload,
      }
      break
    case GET_PLAYER_REFERRAL_REWARDS:
      break
    case GET_PLAYER_REFERRAL_REWARDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        referralRewards: action.payload,
      }
      break
    case GET_PLAYER_FIRST_TASK_REWARDS:
      break
    case GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS:
      state = {
        ...state,
        loading: false,
        firstTaskRewards: action.payload,
      }
      break
    case GET_PLAYER_WALLET_TRANSACTIONS:
      break
    case GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerWalletTransactions: action.payload,
      }
      break
    case GET_PLAYER_MEMBERSHIP_LIST:
      break
    case GET_PLAYER_MEMBERSHIP_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        membershipList: action.payload,
      }
      break
    case GET_PLAYER_ACTIVITY:
      break
    case GET_PLAYER_ACTIVITY_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerActivity: action.payload,
      }
      break
    case GET_LEVEL_CHANGE:
      break
    case GET_LEVEL_CHANGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        levelChange: action.payload,
      }
      break
    case GET_PLAYERS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        playersList: action.payload,
      }
      break
    case GET_PLAYERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        players: action.payload,
      }
      break
    case GET_PLAYERS_LOCATIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYERS_LOCATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        playersLocations: action.payload,
      }
      break
    case CHANGE_PLAYERS_FILTERS:
      state = {
        ...state,
        loading: false,
        filters: action.payload,
      }
      break
    case GET_PLAYER_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_PLAYER_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        playerInfo: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default players
