import { GET_PENALTIES, GET_PENALTIES_SUCCESS, API_ERROR } from "./actionTypes"

export const getPenalties = ({ competitionId }) => {
  return {
    type: GET_PENALTIES,
    payload: { competitionId },
  }
}

export const getPenaltiesSuccess = penalties => {
  return {
    type: GET_PENALTIES_SUCCESS,
    payload: penalties,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
