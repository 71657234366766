import { get, put, post, del, patch } from "helpers/api_helper"
import toastr from "toastr"
import moment from "moment"

export const getClubsLocations = async filter => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(filter)).toString()
    response = await get(`clubs/locations?${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getClubs = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/clubs?${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getClubInfo = async id => {
  let response
  try {
    response = await get(`/clubs/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}

export const getClubOwner = async id => {
  let response
  try {
    response = await get(`/accounts/${id}`)
  } catch (error) {
    // toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}

export const getClubOrganizers = async id => {
  let response
  try {
    response = await get(`/clubs/${id}/organizers?status=activated`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getClubArchiveInfo = async params => {
  let response
  try {
    response = await get("/clubs-archive", { params: params })
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const createClub = async data => {
  let response
  try {
    Object.keys(data?.openHours)?.map(key => {
      if (data.openHours[key].enabled && !data.openHours[key].startDate) {
        data.openHours[key].startDate = moment(8, "HH").toDate()
      }
      if (data.openHours[key].enabled && !data.openHours[key].endDate) {
        data.openHours[key].endDate = moment(22, "HH").toDate()
      }
    })
    response = await post("/admin/clubs", data)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updateClub = async (id, data) => {
  let response
  try {
    Object.keys(data?.openHours)?.map(key => {
      if (data.openHours[key].enabled && !data.openHours[key].startDate) {
        data.openHours[key].startDate = moment(8, "HH").toDate()
      }
      if (data.openHours[key].enabled && !data.openHours[key].endDate) {
        data.openHours[key].endDate = moment(22, "HH").toDate()
      }
    })
    response = await put(`/admin/clubs/${id}`, data)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const updateStatus = async (id, status) => {
  let response
  try {
    response = await patch(`/admin/clubs/${id}/status`, { status })
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const deleteClub = async id => {
  let response
  try {
    response = await del(`/admin/clubs/${id}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}
