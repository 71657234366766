import { get, post } from "helpers/api_helper"

// generate leaderboard
export const generateLeaderboard = async socketClientId => {
  let response
  try {
    response = await post(`/leaderboards/generate`, { socketClientId })
  } catch (error) {
    console.log(error)
  }
  return response
}

// rollback leaderboard
export const rollbackLeaderboard = async socketClientId => {
  let response
  try {
    response = await post(`/leaderboards/rollback`, { socketClientId })
  } catch (error) {
    console.log(error)
  }
  return response
}

// get leaderboard details
export const getLeaderboardDetails = async () => {
  let response
  try {
    response = await get(`/leaderboards/last-run`)
  } catch (error) {
    console.log(error)
  }
  return response
}
