import {
  GET_MATCHES,
  GET_MATCHES_SUCCESS,
  GET_MAIN_DRAW,
  GET_MAIN_DRAW_SUCCESS,
  API_ERROR,
  GET_LOGS,
  GET_LOGS_SUCCESS,
} from "./actionTypes"

export const getLogs = (comptId, activeFilters, activePage) => {
  return {
    type: GET_LOGS,
    payload: { comptId, filters: activeFilters, activePage },
  }
}

export const getLogsSuccess = logs => {
  return {
    type: GET_LOGS_SUCCESS,
    payload: { logs },
  }
}

export const getMatches = ({ competitionId }) => {
  return {
    type: GET_MATCHES,
    payload: { competitionId },
  }
}

export const getMatchesSuccess = matches => {
  return {
    type: GET_MATCHES_SUCCESS,
    payload: matches,
  }
}

export const getMainDraw = (competitionId, mlNumber) => {
  return {
    type: GET_MAIN_DRAW,
    payload: { competitionId, mlNumber },
  }
}

export const getMainDrawSuccess = matchesGroups => {
  return {
    type: GET_MAIN_DRAW_SUCCESS,
    payload: matchesGroups,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
