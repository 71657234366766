/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete"

const SelectGoogleAutocomplete = ({
  autocompletionRequest,
  minLengthAutocomplete,
  ...props
}) => {
  const {
    form: { setFieldTouched, setFieldValue, setFieldError },
  } = props
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement("script")
    script.async = true
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&libraries=places&language=en`
    script.onload = () => setScriptLoaded(true)

    document.head.appendChild(script)
  }, [])

  const handleChange = async address => {
    if (scriptLoaded) {
      if (!address) {
        setFieldTouched(props.field.name)
        setFieldValue(props.field.name, "")
        return false
      }

      const coords = await geocodeByPlaceId(address.value.place_id)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          return { lat, lng }
        })
      const data = {
        label: address.label.split(",")[0],
        value: {
          name: address.label,
          city:
            address.value.terms.length >= 2
              ? address.value.terms[address.value.terms.length - 2]?.value
              : null,
          country:
            address.value.terms.length >= 2
              ? address.value.terms[address.value.terms.length - 1]?.value
              : null,
          coords,
        },
      }

      setFieldTouched(props.field.name)
      setFieldValue(props.field.name, data)
      return data
    }
  }

  const handleError = error => {
    setFieldError(props.field.name, error)
  }

  const setNoMessageText = () => {
    return props?.field?.value?.length < 3
      ? `Type at least ${minLengthAutocomplete} chars to show results...`
      : "No results found."
  }

  let hasValue = props?.field?.value?.label

  return (
    <div className="form-group form-group--select">
      <label className={hasValue ? "validLocation" : ""}>{props.label}</label>

      {scriptLoaded ? (
        <GooglePlacesAutocomplete
          autocompletionRequest={autocompletionRequest}
          minLengthAutocomplete={minLengthAutocomplete}
          selectProps={{
            value: props.field.value,
            onChange: handleChange,
            onError: handleError,
            noOptionsMessage: setNoMessageText,
            ...props,
          }}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default SelectGoogleAutocomplete
