import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { isEmpty } from "lodash"

import Breadcrumbs from "components/Common/Breadcrumb"
import listColumns from "./listColumns"
import FilterList from "./FilterList"
import { getPlayersList as getPlayers, getPlayersLocations } from "./actions"
import Input from "components/Common/Input"

const Players = props => {
  const { playersList, getPlayers, getPlayersLocations, userDetails } = props
  const players = playersList
  const { SearchBar } = Search
  const pageOptions = {
    page: players.page,
    sizePerPage: players.limit,
    totalSize: players.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }
  const initialOptions = {
    page: 1,
    limit: 15,
  }
  const [advancedFilters, setAdvancedFilters] = useState(true)
  const [filters, setFilters] = useState({})
  const [options, setOptions] = useState(initialOptions)
  const [modal, setModal] = useState(false)

  useEffect(() => {
    getPlayers(filters, options)
  }, [getPlayers, filters, options])

  useEffect(() => {
    getPlayersLocations(filters)
  }, [getPlayersLocations, filters])

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.search) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      case "sort":
        const sortOption = `${data.sortField}:${data.sortOrder}`
        if (sortOption !== options.sortBy) {
          setOptions({
            ...options,
            sortBy: sortOption,
          })
        }
        break
      default:
        break
    }
  }

  // TODO - functional reset filters
  const resetFilters = () => {
    if (!isEmpty(filters)) {
      setAdvancedFilters(false)
      setFilters({})
      setOptions(initialOptions)
    }
  }

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      ...filters,
      search: e,
    })
  }, 700)

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "blocked") {
      return "disabledRow"
    }
  }

  const modalToggle = () => {
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="User Accounts"
            breadcrumbItem="Players"
            displayTotal={players.totalResults}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={players.results || []}
                        columns={listColumns(userDetails.userId, userDetails.impersonateKey)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="filter-area">
                              <Row className="d-flex justify-content-between">
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        placeholder="Search by name, account id, email or phone number"
                                        className="mb-4"
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="2">
                                  <div className="text-sm-right d-flex">
                                    {/* export newsletter-subscribed-accounts button */}{" "}
                                    {userDetails?.role === "superAdmin" && (
                                      <>
                                        <div
                                          data-tip
                                          data-for="subscribedUsersTooltip"
                                          onClick={() => modalToggle()}
                                          id="usersExportTooltip"
                                        >
                                          <div className="btn-rounded waves-effect waves-light  btn btn-warning">
                                            <i className="mdi  mdi-briefcase-download" />
                                          </div>
                                        </div>
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="usersExportTooltip"
                                        >
                                          Export subscribed users
                                        </UncontrolledTooltip>
                                      </>
                                    )}
                                    <Modal isOpen={modal} onClick={modalToggle}>
                                      <ModalHeader toggle={modalToggle}>
                                        Export subscribed users
                                      </ModalHeader>
                                      <ModalBody>
                                        Are you sure you want to export
                                        subscribed users? Is a resource
                                        consuming query and it’ll take some time
                                        to generate .csv file
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            window.open(
                                              `${process.env.REACT_APP_API_URL}/exports/newsletter-subscribed-accounts`
                                            )
                                          }}
                                        >
                                          Yes
                                        </Button>{" "}
                                        <Button
                                          color="secondary"
                                          onClick={modalToggle}
                                        >
                                          Cancel
                                        </Button>
                                      </ModalFooter>
                                    </Modal>
                                    {/* create player */}
                                    <Link
                                      to="/accounts/players/create"
                                      className="btn-rounded waves-effect waves-light mb-2 mr-1 ml-1 btn btn-success"
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      Create Player
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div
                                className={`justify-content-between ${
                                  advancedFilters ? "d-flex" : "d-none"
                                }`}
                              >
                                <FilterList
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              </div>
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    rowClasses={rowClasses}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({
  session: { userDetails } = {},
  players: { playersList } = {},
}) => ({
  playersList,
  userDetails,
})

const mapDispatchToProps = dispatch => ({
  getPlayers: (filters, options) => dispatch(getPlayers(filters, options)),
  getPlayersLocations: filters => dispatch(getPlayersLocations(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Players))
