export const GET_CLUB_LOCATIONS = "GET_CLUB_LOCATIONS"
export const GET_CLUB_LOCATIONS_SUCCESS = "GET_CLUB_LOCATIONS_SUCCESS"

export const GET_CLUBS = "GET_CLUBS"
export const GET_CLUBS_SUCCESS = "GET_CLUBS_SUCCESS"

export const GET_CLUB_INFO = "GET_CLUB_INFO"
export const GET_CLUB_INFO_SUCCESS = "GET_CLUB_INFO_SUCCESS"

export const GET_CLUB_OWNER = "GET_CLUB_OWNER"
export const GET_CLUB_OWNER_SUCCESS = "GET_CLUB_OWNER_SUCCESS"

export const GET_CLUB_ORGANIZERS = "GET_CLUB_ORGANIZERS"
export const GET_CLUB_ORGANIZERS_SUCCESS = "GET_CLUB_ORGANIZERS_SUCCESS"

export const GET_CLUB_LEAD_INFO = "GET_CLUB_LEAD_INFO"
export const GET_CLUB_LEAD_INFO_SUCCESS = "GET_CLUB_LEAD_INFO_SUCCESS"

export const GET_CLUB_ARCHIVE_INFO = "GET_CLUB_ARCHIVE_INFO"
export const GET_CLUB_ARCHIVE_INFO_SUCCESS = "GET_CLUB_ARCHIVE_INFO_SUCCESS"

export const UNSET_CLUB_OWNER = "UNSET_CLUB_OWNER"

export const API_ERROR = "CLUBS_API_ERROR"
