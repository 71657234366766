import React from "react"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment";
import { get } from "helpers/api_helper"

const listColumns = () => [
  {
    dataField: "clubId",
    text: "ID",
    sort: true,
  },
  {
    dataField: "clubName",
    text: "Club name",
    formatter: (cellContent, club) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/accounts/clubs/${club.id}`} className="text-dark">
            {club.clubName}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "ownerInfo.firstName",
    text: "Owner name",
    formatter: (cellContent, club) => (
      <>
        {club.ownerInfo.firstName} {club.ownerInfo.lastName}
      </>
    ),
  },
  {
    dataField: "ownerInfo.email",
    text: "Owner email",
  },
  {
    dataField: "ownerInfo.phone",
    text: "Owner phone",
  },
  {
    dataField: "location",
    text: "Location",
    formatter: (cellContent, club) => (
      <>
        <span className='text-capitalize'>{club.location?.name ? `${club.location?.city}, ${club.location?.country}` : 'N/A'}</span>
      </>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, club) => (
      <>
        <span className={`badge badge-${club.status === 'activated' ? 'success' :
          (club.status === 'pending' ? 'info' :
            (club.status === 'blocked' ? 'warning' : 'danger')
          ) } font-size-12`}>{club.status}</span>
      </>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    sort: true,
    formatter: (cellContent, club) => {
      return (
        <>
          {moment(club.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </>
      )
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    formatter: (cellContent, data) => {
      return (
        <>
          <UncontrolledDropdown>
            <DropdownToggle
              href="#"
              className="card-drop"
              tag="i"
            >
              <i className="mdi mdi-dots-horizontal font-size-18"/>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="text-center p-0"
                onClick={async () => {
                  try {
                    const { data: { impersonateKey, userId } } = await get("/admin/accounts/auth");                    
                    const req = new XMLHttpRequest()
                    req.onreadystatechange = function () {
                      if (
                        this.readyState == 4 &&
                        this.status == 200
                      ) {
                        window.open(process.env.REACT_APP_OS_URL)
                      }
                    }
                    req.open(
                      "POST",
                      `${process.env.REACT_APP_OS_URL}/api/auth`,
                      true
                    )
                    req.withCredentials = true;
                    req.setRequestHeader(
                      "Content-type",
                      "application/x-www-form-urlencoded"
                    )
                    req.send(
                      `hash=${impersonateKey}&userId=${userId}&email=${data?.ownerInfo?.email}`
                    )
                  } catch (e) {
                    console.log('error', e);
                  }
                }}
              >
                Impersonate
              </DropdownItem>
              <DropdownItem className="text-center p-0">
                <Link to={`/accounts/clubs/${data.id}/edit`} className="d-block dropdown-item">Edit</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )
    }
  },
]

export default listColumns
