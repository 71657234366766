import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import contactsSaga from "./contacts/saga"
import eventsSaga from "../pages/EventsManagement/saga"
import matchesSaga from "../pages/EventsManagement/components/Tabs/Competitions/Tabs/Matches/saga"
import penaltiesSaga from "../pages/EventsManagement/components/Tabs/Competitions/Tabs/Penalties/saga"
import clubsLeadsSaga from "../pages/UserAccounts/ClubBusinessLeads/saga"
import clubsSaga from "../pages/UserAccounts/ClubAccounts/saga"
import playersSaga from "../pages/UserAccounts/PlayerAccounts/saga"
import organizersSaga from "../pages/UserAccounts/OrganizerAccounts/saga"
import adminsSaga from "../pages/UserAccounts/PlatformAdmins/saga"
import transactionsSaga from "../pages/PaymentsManagement/saga"
import session from "../pages/Authentication/saga"
import leaderboardSaga from "../pages/LeaderboardsManagement/saga"
import reportsSaga from "pages/ReportsManagement/saga"
import friendlyMatchesSaga from "pages/FriendlyMatches/saga"
import bookingsSaga from "pages/BookingsManagement/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(ecommerceSaga),
    fork(chatSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(eventsSaga),
    fork(matchesSaga),
    fork(penaltiesSaga),
    fork(clubsLeadsSaga),
    fork(clubsSaga),
    fork(playersSaga),
    fork(organizersSaga),
    fork(adminsSaga),
    fork(transactionsSaga),
    fork(session),
    fork(leaderboardSaga),
    fork(reportsSaga),
    fork(friendlyMatchesSaga),
    fork(bookingsSaga),
  ])
}
