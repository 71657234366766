import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { isEmpty } from "lodash"

import Breadcrumbs from "components/Common/Breadcrumb"
import listColumns from "./listColumns"
import { getAdmins } from "./actions"
import Input from "components/Common/Input"

const Admins = props => {
  const {
    admins = {},
    getAdmins,
    userDetails: { role },
  } = props
  const { SearchBar } = Search
  const pageOptions = {
    page: admins.page,
    sizePerPage: admins.limit,
    totalSize: admins.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }
  const initialOptions = {
    page: 1,
    limit: 25,
  }
  const [filters, setFilters] = useState({})
  const [options, setOptions] = useState(initialOptions)

  useEffect(() => {
    getAdmins(filters, options)
  }, [getAdmins, filters, options])

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.search) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      case "sort":
        const sortOption = `${data.sortField}:${data.sortOrder}`
        if (sortOption !== options.sortBy) {
          setOptions({
            ...options,
            sortBy: sortOption,
          })
        }
        break
      default:
        break
    }
  }

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      search: e,
    })
  }, 700)

const rowClasses = (row, rowIndex) => {
  if (row?.status === "blocked") {
    return "disabledRow"
  }
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="User Accounts"
            breadcrumbItem="Admins"
            displayTotal={admins.totalResults}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={admins.results || []}
                        columns={listColumns(role)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="filter-area">
                              <Row>
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        className="mb-4"
                                        placeholder="Search by name, user id or email"
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="text-sm-right">
                                    {role === "superAdmin" && (
                                      <Link
                                        to="/accounts/admins/create"
                                        className="btn-rounded waves-effect waves-light mb-2 mr-2 btn btn-success"
                                      >
                                        <i className="mdi mdi-plus mr-1" />
                                        Create admin
                                      </Link>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    rowClasses={rowClasses}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({
  admins: { admins } = {},
  session: { userDetails } = {},
}) => ({
  admins,
  userDetails,
})

const mapDispatchToProps = dispatch => ({
  getAdmins: (filters, options) => dispatch(getAdmins(filters, options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Admins))
