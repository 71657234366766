import React, { useState } from "react"
import { connect } from "react-redux"
import { FormGroup, Row, Col, Label } from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import Input from "components/Common/Input"

const AdvancedFilters = ({
  touched,
  initialValues,
  handleBlur,
  handleChange,
  values,
  setFieldValue,
  locations,
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const gameTypes = [
    { label: "Singles", value: "singles" },
    { label: "Doubles", value: "doubles" },
  ];
  const matchTypes = [
    { label: "Ranked", value: "ranked" },
    { label: "Unranked", value: "unranked" },
  ];
  const statuses = [
    { label: "Open", value: "active" },
    { label: "On-Hold", value: "onHold" },
    { label: "Canceled", value: "canceled" },
    { label: "No-Match", value: "noMatch" },
    { label: "Completed", value: "completed" },
    { label: "Deleted", value: "deleted" },
  ];
  const scores = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];
  const formatDate = date => {
    return date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : '';
  }
  const locationOptions = locations?.map(location => {
    return {
      label: `${location.city ?? "All Cities"}, ${location.country} (${
        location.total
      })`,
      value: `${location.city ?? "null"}, ${location.country}`,
    }
  })
  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  return (
    <>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Search</Label>
            <Input
              placeholder="Search by player id, name, email or match id"
              type="text"
              name="search"
              {...{
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Game Type</Label>
              <Select
                placeholder="All"
                name="gameType"
                onChange={(e, x) =>
                  x?.removedValues?.length
                    ? setFieldValue("gameType", "")
                    : setFieldValue("gameType", e?.value)
                }
                options={gameTypes}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>Start Date</Label>
            <DatePicker
              placeholderText="Not set"
              selected={startDate}
              onChange={(value) => {setStartDate(value); setFieldValue("fromDate", formatDate(value))}}
              dateFormat="P"
              className="form-control"
              isClearable
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label>End Date</Label>
            <DatePicker
              placeholderText="Not set"
              selected={endDate}
              onChange={(value) => {setEndDate(value); setFieldValue("toDate", formatDate(value))}}
              dateFormat="P"
              className="form-control"
              isClearable
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
            <Label>Location</Label>
            <Select
              placeholder="All"
              name="location"
              onChange={(e, x) =>
                x?.removedValues?.length
                  ? setFieldValue("location", "")
                  : setFieldValue("location", e?.value)
              }
              options={locationOptions}
              isClearable={true}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Match Type</Label>
              <Select
                placeholder="All"
                name="matchType"
                onChange={(e, x) =>
                  x?.removedValues?.length
                    ? setFieldValue("matchType", "")
                    : setFieldValue("matchType", e?.value)
                }
                options={matchTypes}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Status</Label>
              <Select
                placeholder="All"
                name="status"
                onChange={(e, x) =>
                  x?.removedValues?.length
                    ? setFieldValue("status", "")
                    : setFieldValue("status", e?.value)
                }
                options={statuses}
                isClearable={true}
                styles={customStyles}
              />
            </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
            <Label>Score</Label>
            <Select
              placeholder="All"
              name="score"
              onChange={(e, x) =>
                x?.removedValues?.length
                  ? setFieldValue("score", "")
                  : setFieldValue("score", e?.value)
              }
              options={scores}
              isClearable={true}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({
  settings,
})

export default connect(mapStatetoProps, {})(AdvancedFilters)
