import React, { useState } from "react"
import {
  Container,
  Spinner,
  Row,
  Col,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import { get } from "helpers/api_helper"

const SystemSettings = props => {
  const [disableButton, setDisableButton] = useState(false)
  const [message, setMessage] = useState("")

  const regenerateMeili = async () => {
    setDisableButton(true)
    let response
    try {
      response = await get("/meili/indexing/accounts")
    } catch (error) {
      response = error.response
    }
    setDisableButton(false)
    setMessage(JSON.stringify(response))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>System Settings</h4>
          <Row>
            <Col>
              <Card className="mh-200">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p> Use this to regenerate the Meili Accounts </p>
                  <Button
                    color="primary mb-3"
                    className="d-flex align-items-center"
                    disabled={disableButton ? true : false}
                    onClick={() => {
                      regenerateMeili()
                    }}
                  >
                    Regenerate Meili
                    {disableButton && (
                      <Spinner
                        className="ml-2"
                        size="sm"
                        animation="grow"
                        variant="dark"
                      />
                    )}
                  </Button>
                  {message && <p>{message}</p>}
                </CardBody>
              </Card>
            </Col>{" "}
            <Col></Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SystemSettings
