import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_CLUBS,
  GET_CLUB_INFO,
  GET_CLUB_OWNER,
  GET_CLUB_ORGANIZERS,
  GET_CLUB_ARCHIVE_INFO,
  GET_CLUB_LOCATIONS,
} from "./actionTypes"

import {
  apiError,
  getClubsSuccess,
  getClubInfoSuccess,
  getClubOwnerSuccess,
  getClubOrganizersSuccess,
  getClubArchiveInfoSuccess,
  getClubsLocationsSuccess,
} from "./actions"

function* getClubsLocations({ filters }) {
  try {
    const { data } = yield call(api.getClubsLocations, filters)
    yield put(getClubsLocationsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubs({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getClubs, filters, options)
    yield put(getClubsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubInfo({ id }) {
  try {
    const { data } = yield call(api.getClubInfo, id)
    yield put(getClubInfoSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubOwner({ id }) {
  try {
    const { data } = yield call(api.getClubOwner, id)
    yield put(getClubOwnerSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubOrganizers({ id }) {
  try {
    const { data } = yield call(api.getClubOrganizers, id)
    yield put(getClubOrganizersSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubArchiveInfo({ id }) {
  try {
    const { data } = yield call(api.getClubArchiveInfo, id)
    yield put(getClubArchiveInfoSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_CLUB_LOCATIONS, getClubsLocations)
  yield takeEvery(GET_CLUBS, getClubs)
  yield takeEvery(GET_CLUB_INFO, getClubInfo)
  yield takeEvery(GET_CLUB_OWNER, getClubOwner)
  yield takeEvery(GET_CLUB_ORGANIZERS, getClubOrganizers)
  yield takeEvery(GET_CLUB_ARCHIVE_INFO, getClubArchiveInfo)
}

export default eventsSaga
