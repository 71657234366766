import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  transactions: [],
  filters: {},
  loading: false,
}

const admins = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        transactions: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default admins
