import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { ToastProvider } from 'react-toast-notifications';
import "./i18n"
import { Provider } from "react-redux"
import { StateProvider as SocketProvider } from 'components/Common/Socket/store';
import Socket from 'components/Common/Socket';
import "react-datepicker/dist/react-datepicker.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import store from "./store"

const app = (
  <Provider store={store}>
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <SocketProvider>
        <Socket connectionUrl={process.env.REACT_APP_SOCKET_URL} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SocketProvider>
    </ToastProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
