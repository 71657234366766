import CompetitionDetails from './CompetitionDetails'
import Restrictions from './Restrictions'
import FeeDetails from './FeeDetails'
import Prizes from './Prizes'

const competitionTabs = [
  { label: "Competition Details", Component: CompetitionDetails },
  { label: "Restrictions", Component: Restrictions },
  { label: "Fee Details", Component: FeeDetails },
  { label: "Prizes", Component: Prizes }
];

export default competitionTabs