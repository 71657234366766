import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMIN_INFO,
  GET_ADMIN_INFO_SUCCESS,
  CHANGE_ADMINS_FILTERS,
  API_ERROR
} from "./actionTypes"

const initialState = {
  error: "",
  admins: [],
  adminInfo: {},
  filters: {},
  loading: false,
}

const admins = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ADMINS_SUCCESS:
      state = {
        ...state,
        loading: false,
        admins: action.payload,
      }
      break
    case CHANGE_ADMINS_FILTERS:
      state = {
        ...state,
        loading: false,
        filters: action.payload,
      }
      break
    case GET_ADMIN_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ADMIN_INFO_SUCCESS:
      state = {
        ...state,
        adminInfo: action.payload,
        loading: false,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default admins
