import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKING_DETAILS,
  GET_BOOKING_DETAILS_SUCCESS,
  API_ERROR,
  UPDATE_BOOKING_DETAILS,
} from "./actionTypes";

const initialState = {
  error: "",
  bookings: [],
  bookingDetails: null,
  loading: false,
}

const bookings = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_BOOKINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        bookings: action.payload,
      }
      break
    case GET_BOOKING_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_BOOKING_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        bookingDetails: action.payload,
      }
      break
    case UPDATE_BOOKING_DETAILS:
      state = {
        ...state,
        bookingDetails: {
          ...state.bookingDetails,
          ...action.payload,
        },
      }
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default bookings
