import { GET_LEADERBOARDS } from "./actionTypes"
import * as api from "./api"
import { getLeaderboardsSuccess } from "./actions"
import { call, put, takeEvery } from "redux-saga/effects"

function* getLeaderboards() {
  try {
    const { data } = yield call(api.getLeaderboardDetails)
    yield put(getLeaderboardsSuccess(data))
  } catch (error) {
    console.log(error)
  }
}

function* leaderboardSaga() {
  yield takeEvery(GET_LEADERBOARDS, getLeaderboards)
}

export default leaderboardSaga
