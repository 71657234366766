import {
  GET_PLAYERS,
  GET_PLAYERS_SUCCESS,
  GET_PLAYERS_LOCATIONS,
  GET_PLAYERS_LOCATIONS_SUCCESS,
  CHANGE_PLAYERS_FILTERS,
  GET_PLAYER_INFO,
  GET_PLAYER_INFO_SUCCESS,
  API_ERROR,
  GET_PLAYER_TRANSACTIONS,
  GET_PLAYER_TRANSACTIONS_SUCCESS,
  GET_PLAYER_REFERRAL_REWARDS,
  GET_PLAYER_REFERRAL_REWARDS_SUCCESS,
  GET_PLAYER_FIRST_TASK_REWARDS,
  GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS,
  GET_PLAYER_WALLET_TRANSACTIONS,
  GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS,
  GET_PLAYER_MEMBERSHIP_LIST,
  GET_PLAYER_MEMBERSHIP_LIST_SUCCESS,
  GET_PLAYER_ACTIVITY,
  GET_PLAYER_ACTIVITY_SUCCESS,
  GET_LEVEL_CHANGE,
  GET_LEVEL_CHANGE_SUCCESS,
  GET_PLAYERS_LIST,
  GET_PLAYERS_LIST_SUCCESS,
  GET_PLAYER_ENTRIES,
  GET_PLAYER_ENTRIES_SUCCESS,
  GET_PLAYER_HISTORY_ENTRIES,
  GET_PLAYER_HISTORY_ENTRIES_SUCCESS,
} from "./actionTypes"

export const getPlayerInfo = id => {
  return {
    type: GET_PLAYER_INFO,
    id,
  }
}

export const getPlayerTransactions = (id, transactionsOptions) => {
  return {
    type: GET_PLAYER_TRANSACTIONS,
    id,
    transactionsOptions,
  }
}

export const getPlayerReferralRewards = (id) => {
  return {
    type: GET_PLAYER_REFERRAL_REWARDS,
    id,
  }
}

export const getPlayerFirstTaskRewards = (id) => {
  return {
    type: GET_PLAYER_FIRST_TASK_REWARDS,
    id,
  }
}

export const getPlayerWalletTransactions = (id, options) => {
  return {
    type: GET_PLAYER_WALLET_TRANSACTIONS,
    id,
    options,
  }
}

export const getMembershipList = (id, membershipOptions) => {
  return {
    type: GET_PLAYER_MEMBERSHIP_LIST,
    id,
    membershipOptions,
  }
}

export const getPlayerActivity = (id, playerActivityOptions) => {
  return {
    type: GET_PLAYER_ACTIVITY,
    id,
    playerActivityOptions,
  }
}

export const getLevelChange = (id, levelChangeOptions) => {
  return {
    type: GET_LEVEL_CHANGE,
    id,
    levelChangeOptions,
  }
}

export const getPlayerEntries = (id, playerEntriesOptions) => {
  return {
    type: GET_PLAYER_ENTRIES,
    id,
    playerEntriesOptions,
  }
}

export const getPlayerHistoryEntries = (id, playerHistoryEntriesOptions) => {
  return {
    type: GET_PLAYER_HISTORY_ENTRIES,
    id,
    playerHistoryEntriesOptions,
  }
}

export const getPlayerEntriesSuccess = data => {
  return {
    type: GET_PLAYER_ENTRIES_SUCCESS,
    data,
  }
}

export const getPlayerHistoryEntriesSuccess = data => {
  return {
    type: GET_PLAYER_HISTORY_ENTRIES_SUCCESS,
    data,
  }
}

export const getPlayerTransactionSuccess = info => {
  return {
    type: GET_PLAYER_TRANSACTIONS_SUCCESS,
    payload: info,
  }
}

export const getPlayerReferralRewardsSuccess = info => {
  return {
    type: GET_PLAYER_REFERRAL_REWARDS_SUCCESS,
    payload: info,
  }
}

export const getPlayerFirstTaskRewardsSuccess = info => {
  return {
    type: GET_PLAYER_FIRST_TASK_REWARDS_SUCCESS,
    payload: info,
  }
}

export const getPlayerWalletTransactionSuccess = info => {
  return {
    type: GET_PLAYER_WALLET_TRANSACTIONS_SUCCESS,
    payload: info,
  }
}

export const getMembershipListSuccess = info => {
  return {
    type: GET_PLAYER_MEMBERSHIP_LIST_SUCCESS,
    payload: info,
  }
}

export const getPlayerActivitySuccess = info => {
  return {
    type: GET_PLAYER_ACTIVITY_SUCCESS,
    payload: info,
  }
}

export const getLevelChangeSuccess = info => {
  return {
    type: GET_LEVEL_CHANGE_SUCCESS,
    payload: info,
  }
}

export const getPlayerInfoSuccess = accountInfo => {
  return {
    type: GET_PLAYER_INFO_SUCCESS,
    payload: accountInfo,
  }
}

export const getPlayers = (filters, options) => {
  return {
    type: GET_PLAYERS,
    filters,
    options,
    payload: [],
  }
}

export const getPlayersList = (filters, options) => {
  return {
    type: GET_PLAYERS_LIST,
    filters,
    options,
    payload: [],
  }
}

export const getPlayersListSuccess = accounts => {
  return {
    type: GET_PLAYERS_LIST_SUCCESS,
    payload: accounts,
  }
}

export const getPlayersSuccess = accounts => {
  return {
    type: GET_PLAYERS_SUCCESS,
    payload: accounts,
  }
}

export const changePlayersFilters = filters => {
  return {
    type: CHANGE_PLAYERS_FILTERS,
    payload: filters,
  }
}

export const getPlayersLocations = (filters) => {
  return {
    type: GET_PLAYERS_LOCATIONS,
    filters,
    payload: [],
  }
}

export const getPlayersLocationsSuccess = payload => {
  return {
    type: GET_PLAYERS_LOCATIONS_SUCCESS,
    payload: payload,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
