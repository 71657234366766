import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"
import { get } from "helpers/api_helper"

const listColumns = () => [
  {
    dataField: "userId",
    text: "ID",
    sort: true,
  },
  {
    dataField: "firstName",
    text: "Name",
    formatter: (cellContent, user) => (
      <>
        <h5 className="font-size-14 mb-1">
          <Link to={`/accounts/organizers/${user.id}`} className="text-dark">
            {user.role === "player"
              ? `${user.firstName} ${user.lastName ?? ""}`
              : user.clubInfo?.businessName}
          </Link>
        </h5>
      </>
    ),
  },
  {
    dataField: "email",
    text: "Email",
  },
  {
    dataField: "phone",
    text: "Phone",
  },
  {
    dataField: "location",
    text: "Location",
    formatter: (cellContent, user) => (
      <>
        <span className="text-capitalize">
          {user.organizerInfo?.location?.name || "n/a"}
        </span>
      </>
    ),
  },
  {
    dataField: "role",
    text: "Role",
    formatter: (cellContent, user) => (
      <>
        <span className="text-capitalize">{user.role}</span>
      </>
    ),
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, user) => (
      <>
        <span
          className={`badge badge-${
            user.status === "activated"
              ? "success"
              : user.status === "pending"
              ? "info"
              : user.status === "blocked"
              ? "warning"
              : "danger"
          } font-size-12`}
        >
          {user.status}
        </span>
      </>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    sort: true,
    formatter: (cellContent, user) => {
      return <>{moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss")}</>
    },
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Actions",
    formatter: (cellContent, data) => {
      return (
        <>
          <UncontrolledDropdown>
            <DropdownToggle href="#" className="card-drop" tag="i">
              <i className="mdi mdi-dots-horizontal font-size-18" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={async () => {
                  try {
                    const { data: { impersonateKey, userId } } = await get("/admin/accounts/auth");                    
                    const req = new XMLHttpRequest()
                    req.onreadystatechange = function () {
                      if (
                        this.readyState == 4 &&
                        this.status == 200
                      ) {
                        window.open(process.env.REACT_APP_OS_URL)
                      }
                    }
                    req.open(
                      "POST",
                      `${process.env.REACT_APP_OS_URL}/api/auth`,
                      true
                    )
                    req.withCredentials = true;
                    req.setRequestHeader(
                      "Content-type",
                      "application/x-www-form-urlencoded"
                    )
                    req.send(
                     `hash=${impersonateKey}&userId=${userId}&email=${data?.email}`
                    )
                  } catch (e) {
                    console.log('error', e);
                  }
                }}
                className="text-center"
              >
                Impersonate
              </DropdownItem>
              <DropdownItem className="text-center p-0">
                <Link
                  to={`/accounts/${data?.role + "s"}/${
                    data?.role === "club"
                      ? data?.organizerInfo?.assignedClubs[0]
                      : data.id
                  }/edit`}
                  className="d-block dropdown-item"
                >
                  Edit user
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )
    },
  },
]

export default listColumns
