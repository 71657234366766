import moment from "moment"
import { Link } from "react-router-dom"

const getLocalDate = (date = new Date(), timeZone = 'Europe/Bucharest') => {
  return new Date((typeof date === 'string' ? new Date(date) : date)
    .toLocaleString('en-US', { timeZone }))
}

const listColumns = ({
  setModal,
  setModalMatchId,
}) => [
  {
    dataField: "matchId",
    text: "Match ID",
    formatter: (cellContent, match) => <p className="mb-0">{`FM${match?.matchId.toString().padStart(5, '0')}`}</p>,
  },
  {
    dataField: "startDate",
    text: "Start Date and Time",
    formatter: (cellContent, match) => {
      const localDate = getLocalDate(match.startDate, match.timezone);
      return (
        <div className="d-flex flex-column">
          <p className="mb-0">{moment(localDate).format("YYYY-MM-DD")}{!match.pastMatch && moment(localDate).format(" HH:mm")}</p>
        </div>
      )
    },
  },
  {
    dataField: "gameType",
    text: "Game Type",
    formatter: (cellContent, match) => <p className="mb-0 text-capitalize">{match.gameType}</p>,
  },
  {
    dataField: "createdAt",
    text: "Date Created",
    formatter: (cellContent, match) => {
      return (
        <div className="d-flex flex-column">
          <p className="mb-0">{moment(match.createdAt).format("YYYY-MM-DD HH:mm")}</p>
        </div>
      )
    },
  },
  {
    dataField: "createdBy",
    text: "Created By",
    formatter: (cellContent, match) => {
      return <Link to={`/accounts/players/${match?.player?.id}`} className="text-dark" target="_blank">
      {match?.player?.firstName} {match?.player?.lastName} #{match?.player?.userId}
    </Link>;
    },
  },
  {
    dataField: "ownerLevel",
    text: "Owner Level",
    formatter: (cellContent, match) => {
      return <p className="mb-0">{match?.gameType === 'singles' ? match?.player.gameLevelSingle : match?.player.gameLevelDouble}</p>;
    },
  },
  {
    dataField: "participants",
    text: "Participants",
    formatter: (cellContent, match) => {
      return <>
        {match?.playerPartner?.userId && <><Link to={`/accounts/players/${match?.playerPartner?.id}`} className="text-dark" target="_blank">
          {match?.playerPartner?.firstName} {match?.playerPartner?.lastName} #{match?.playerPartner?.userId}
        </Link><br /></>}
        <Link to={`/accounts/players/${match?.opponent?.id}`} className="text-dark" target="_blank">
          {match?.opponent?.firstName} {match?.opponent?.lastName} #{match?.opponent?.userId}
        </Link>
        {match?.opponentPartner?.userId && <><br /><Link to={`/accounts/players/${match?.opponentPartner?.id}`} className="text-dark" target="_blank">
          {match?.opponentPartner?.firstName} {match?.opponentPartner?.lastName} #{match?.opponentPartner?.userId}
        </Link></>}
      </>;
    },
  },
  {
    dataField: "location",
    text: "Location",
    formatter: (cellContent, match) => {
      return <p className="mb-0">{`${match?.location?.country}, ${match?.location?.city}`}</p>;
    },
  },
  {
    dataField: "matchType",
    text: "Match Type",
    formatter: (cellContent, match) => {
      return <p className="mb-0">{match?.ranked ? 'Ranked' : 'Unranked'}</p>;
    },
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (cellContent, match) => {
      const statuses = {
        active: 'Open',
        canceled: 'Canceled',
        noMatch: 'No-Match',
        completed: 'Completed',
        deleted: 'Deleted',
      };
      let status = statuses[match?.status];
      if ([
          match.player?.invitation,
          match.playerPartner?.invitation,
          match.opponent?.invitation,
          match.opponentPartner?.invitation
      ].filter(status => ['declined', 'withdrawn', 'noMatch'].includes(status)).length && match?.status === 'active') {
        status = 'On-Hold';
      }
      return <p className="mb-0">{status}</p>;
    },
  },
  {
    dataField: "score",
    text: "Score",
    formatter: (cellContent, match) => {
      return <p className="mb-0">{(match?.player.score || []).length || match?.player.type || match?.opponent.type ? 'Yes' : 'No'}</p>;
    },
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: (x, y) => (
      <>
        <Link to={`/friendly-matches/${y._id}`} className="mr-3">
          <i className="bx bx-show-alt"></i>
        </Link>
        {y.status === 'deleted' ? <i className="bx bx-trash-alt"></i> :
        <a
          href="javascript:;"
          onClick={() => {
            setModal(true)
            setModalMatchId(y._id)
          }}
          className="button--disable"
        >
          <i className="bx bx-trash-alt"></i>
        </a>}
      </>
    ),
  },
]

export default listColumns
