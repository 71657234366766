import React, { useState } from "react"
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"

const TextEditor = ({ defaultValue, onChange }) => {
  const [editorState, setEditorState] = useState(null)
  const initialState = defaultValue && convertFromRaw(JSON.parse(defaultValue))
  const initialEditorState = initialState
    ? EditorState.createWithContent(initialState)
    : EditorState.createEmpty()
  return (
    <div>
      <Editor
        editorState={editorState || initialEditorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={state => {
          setEditorState(state)
          onChange && onChange(convertToRaw(state.getCurrentContent()))
        }}
        editorStyle={{ height: '250px' }}
      />
    </div>
  )
}

export default TextEditor
