import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  FormGroup,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import Breadcrumbs from "components/Common/Breadcrumb"

import listColumns from "./listColumns"
import FilterList from "./FilterList"
import { getClubsLeads, getClubLeadsLocations } from "./actions"

import Input from "components/Common/Input"

const ClubBusinessLeads = props => {
  const {
    clubsLeads,
    getClubsLeads,
    getClubLeadsLocations,
    clubLeadsLocations,
  } = props

  const { SearchBar } = Search
  const pageOptions = {
    page: clubsLeads.page,
    sizePerPage: clubsLeads.limit,
    totalSize: clubsLeads.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }
  const initialOptions = {
    page: 1,
    limit: 25,
  }
  const [advancedFilters, setAdvancedFilters] = useState(true)
  const [filters, setFilters] = useState({})
  const [options, setOptions] = useState(initialOptions)

  useEffect(() => {
    getClubsLeads(filters, options)
    getClubLeadsLocations(filters)
  }, [getClubsLeads, filters, options, getClubLeadsLocations])

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.search) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      case "sort":
        const sortOption = `${data.sortField}:${data.sortOrder}`
        if (sortOption !== options.sortBy) {
          setOptions({
            ...options,
            sortBy: sortOption,
          })
        }
        break
      default:
        break
    }
  }

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      search: e,
    })
  }, 700)

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "blocked") {
      return "disabledRow"
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="User Accounts"
            breadcrumbItem="Club Business Leads"
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={clubsLeads.results || []}
                        columns={listColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="filter-area">
                              <Row>
                                <Col sm="8">
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        className="mb-4"
                                        placeholder="Search by name, user id or email"
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="2" />
                              </Row>
                              <div
                                className={`justify-content-between ${
                                  advancedFilters ? "d-flex" : "d-none"
                                }`}
                              >
                                <FilterList
                                  locations={clubLeadsLocations}
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              </div>
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    rowClasses={rowClasses}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ClubBusinessLeads.propTypes = {
  clubsLeads: PropTypes.object,
  getClubsLeads: PropTypes.func,
}

const mapStateToProps = ({
  clubsLeads: { loading, clubsLeads, clubLeadsLocations } = {},
}) => ({
  loading,
  clubsLeads,
  clubLeadsLocations,
})

const mapDispatchToProps = dispatch => ({
  getClubsLeads: (filters, options) =>
    dispatch(getClubsLeads(filters, options)),
  getClubLeadsLocations: filters => dispatch(getClubLeadsLocations(filters)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubBusinessLeads))
