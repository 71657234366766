import { patch } from "helpers/api_helper"

export const addPoints = async ({
  socketClientId,
  competitionId,
  eventId,
  points,
  phaseId,
  endCompetition,
  eventDate,
}) => {
  let response
  try {
    response = await patch(`/matches/${eventId}/${competitionId}/save`, {
      points,
      socketClientId,
      phaseId,
      endCompetition,
      eventDate,
    })
  } catch (error) {
    console.log(error)
  }

  return response
}
