export const GET_CLUBS_LEADS = "GET_CLUBS_LEADS"
export const GET_CLUBS_LEADS_SUCCESS = "GET_CLUBS_LEADS_SUCCESS"

export const GET_CLUBS_LEADS_LOCATIONS = "GET_CLUBS_LEADS_LOCATIONS"
export const GET_CLUBS_LEADS_LOCATIONS_SUCCESS =
  "GET_CLUBS_LEADS_LOCATIONS_SUCCESS"

export const GET_CLUB_LEAD_INFO = "GET_CLUB_LEAD_INFO"
export const GET_CLUB_LEAD_INFO_SUCCESS = "GET_CLUB_LEAD_INFO_SUCCESS"

export const API_ERROR = "CLUBS_LEADS_API_ERROR"
