import { get, patch } from "helpers/api_helper"

export const getReports = async payload => {
  let response
  const params = {}

  Object.assign(params, payload?.payload[0]);
  Object.assign(params, payload?.payload[1]);

  try {
    response = await get(
      `/accounts/reports?${ new URLSearchParams(params).toString() }`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getReport = async(reportId) => {
  let response
  try {
    response = await get(`/accounts/reports/${reportId}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const updateReport = async ({
  socketClientId,
  reportId,
  status,
}) => {
  let response
  try {
    response = await patch(`/accounts/reports/${reportId}`, {
      socketClientId: socketClientId,
      status,
    })
  } catch (error) {
    console.log(error)
  }
  return response
}
