import {
  GET_ORGANIZERS,
  GET_ORGANIZERS_SUCCESS,
  GET_ORGANIZER_INFO,
  GET_ORGANIZER_INFO_SUCCESS,
  GET_OWNER_CLUB,
  GET_OWNER_CLUB_SUCCESS,
  GET_TOURNAMENTS,
  GET_TOURNAMENTS_SUCCESS,
  API_ERROR,
  GET_ORGANIZERS_LOCATIONS,
  GET_ORGANIZERS_LOCATIONS_SUCCESS,
} from "./actionTypes"

export const getOrganizersLocationsSuccess = data => {
  return {
    type: GET_ORGANIZERS_LOCATIONS_SUCCESS,
    payload: data,
  }
}

export const getOrganizersLocations = filters => {
  return {
    type: GET_ORGANIZERS_LOCATIONS,
    payload: filters,
  }
}

export const getOrganizerInfo = id => {
  return {
    type: GET_ORGANIZER_INFO,
    id,
  }
}

export const getOrganizerInfoSuccess = accountInfo => {
  return {
    type: GET_ORGANIZER_INFO_SUCCESS,
    payload: accountInfo,
  }
}

export const getOrganizers = (filters, options) => {
  return {
    type: GET_ORGANIZERS,
    filters,
    options,
    payload: [],
  }
}

export const getOrganizersSuccess = accounts => {
  return {
    type: GET_ORGANIZERS_SUCCESS,
    payload: accounts,
  }
}

export const getOwnerClub = id => {
  return {
    type: GET_OWNER_CLUB,
    id,
  }
}

export const getOwnerClubSuccess = ownerClub => {
  return {
    type: GET_OWNER_CLUB_SUCCESS,
    payload: ownerClub,
  }
}

export const getTournaments = id => {
  return {
    type: GET_TOURNAMENTS,
    id,
  }
}

export const getTournamentsSuccess = tournaments => {
  return {
    type: GET_TOURNAMENTS_SUCCESS,
    payload: tournaments,
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
