import React, { useState, useEffect, useContext } from "react"
import { Formik, Form } from "formik"
import { Link } from "react-router-dom"
import { stateToHTML } from "draft-js-export-html"
import { convertFromRaw, EditorState } from "draft-js"

import { useToasts } from "react-toast-notifications"

import { store as socketStore } from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"

import { connect } from "react-redux"

import Select from "react-select"
import Input from "components/Common/Input"
import TextEditor from "components/Common/TextEditor"

import { getEventDetails } from "../../../actions"

import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap"

import * as Yup from "yup"

import { addAnnouncement, editAnnouncement } from "./api"

const announcementInfo = Yup.object().shape({
  title: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
  notifications: Yup.string().required("Required"),
})

const AddAnouncement = ({
  eventDetails,
  match,
  fetchEvents,
  history,
  location,
}) => {
  const [modal, setModal] = useState(false)
  const { eventId, announcementId } = match.params
  const toggle = () => {
    setModal(!modal)
  }

  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const [preSelectedCompetitions, setPreSelectedCompetitions] = useState([]);
  const [competitionsOrder, setCompetitionsOrder] = useState([]);

  useEffect(() => {
    fetchEvents(eventId)
  }, [fetchEvents, eventId])

  useEffect(() => {
    const tempCompetitionsOrder = eventDetails?.competitions?.map(competition => competition.competitionId);
    setCompetitionsOrder(tempCompetitionsOrder);
    setPreSelectedCompetitions((currentAnnouncement?.competitions || (location?.state?.competitionId ? [location?.state?.competitionId] : tempCompetitionsOrder))?.map(competition => {
      const competitionDetails = eventDetails?.competitions?.find(eventCompetition => eventCompetition.competitionId === competition);
      return {
        label: `${competitionDetails?.level}${competitionDetails?.gameType === 'singles' ? 'S' : 'D'} (#${competitionDetails?.competitionId})`,
        value: competitionDetails?.competitionId,
      };
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetails])

  eventDetails = eventDetails?.[0]
  let currentAnnouncement = null;
  if (announcementId) {
    currentAnnouncement = (eventDetails?.announcements || []).find(
      y => y._id === announcementId
    )
  }

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    if (!announcementId) {
      history.push(`/events/${eventId}/announcements`)
    }

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  return (
    <div className="page-content">
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <Container fluid>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/events-management">Events</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to={`/events/${eventDetails?._id}/announcements`}>
              Event: {eventDetails?.name}
            </Link>
          </BreadcrumbItem>
          {announcementId ? (
            <BreadcrumbItem>
              Announcement: {currentAnnouncement?.title}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem>Create new Announcement</BreadcrumbItem>
          )}
        </Breadcrumb>

        <h4>{announcementId ? "Edit Announcement" : "Add Announcement"}</h4>
        <br />
        <div>
          <Formik
            key={currentAnnouncement?.title}
            initialValues={{
              title: currentAnnouncement?.title || "",
              message: currentAnnouncement?.message || "",
              notifications: currentAnnouncement?.notifications || !!location?.state?.competitionId, // location.state is coming from the competitions page Link
              selectedCompetitions: preSelectedCompetitions,
            }}
            validationSchema={announcementInfo}
            onSubmit={async values => {
              const parsedMessage = stateToHTML(
                EditorState.createWithContent(
                  convertFromRaw(JSON.parse(values?.message))
                ).getCurrentContent()
              )
              try {
                values.selectedCompetitions = preSelectedCompetitions;
                announcementId
                  ? await editAnnouncement({
                      eventId,
                      announcementId,
                      values,
                      parsedMessage,
                      socketClientId,
                    })
                  : await addAnnouncement({
                      eventId,
                      values,
                      socketClientId,
                      parsedMessage,
                    })
              } catch (error) {
                console.log(error)
              }
            }}
          >
            {({
              errors,
              touched,
              initialValues,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
            }) => (
              <Form>
                <br />
                <Row>
                  <Col xs={12}>
                    {/* Render inputs for add announcements =====> */}
                    <Card>
                      <CardBody>
                        <CardTitle>Announcement</CardTitle>
                        <div>
                          <Row>
                            <Col className="col-6">
                              <FormGroup>
                                <Input
                                  placeholder="Set title"
                                  label="Set title"
                                  type="text"
                                  name="title"
                                  {...{
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    initialValues,
                                    values,
                                  }}
                                ></Input>
                              </FormGroup>
                            </Col>
                            <Col className="col-6">
                              <FormGroup>
                                <Input
                                  placeholder="Send notifications"
                                  label="Send notifications"
                                  type="select"
                                  name="notifications"
                                  onChange={e => {
                                    setFieldValue(
                                      "notifications",
                                      e.target.value
                                    )
                                  }}
                                  {...{
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    initialValues,
                                    values,
                                  }}
                                >
                                  <option
                                    value="Select game type"
                                    selected
                                    disabled
                                  >
                                    Select notifications
                                  </option>

                                  <option value='true'>Yes</option>
                                  <option value='false'>No</option>
                                </Input>
                              </FormGroup>
                              {initialValues.notifications ||
                              values.notifications === 'true' ? (
                                <FormGroup>
                                  <Label>Select Competitions</Label>
                                  <Select
                                    isMulti
                                    name="selectedCompetitions"
                                    value={preSelectedCompetitions}
                                    onChange={state => {
                                        const stateCopy = JSON.parse(JSON.stringify(state));
                                        stateCopy.sort((a, b) => competitionsOrder.indexOf(a.value) - competitionsOrder.indexOf(b.value));
                                        setFieldValue("selectedCompetitions", stateCopy);
                                        setPreSelectedCompetitions(stateCopy);
                                      }
                                    }
                                    options={eventDetails?.competitions?.map(x => ({
                                      label: `${x?.level}${x?.gameType === 'singles' ? 'S' : 'D'} (#${x?.competitionId})`,
                                      value: x?.competitionId,
                                    }))}
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: provided => ({ ...provided, zIndex: 9 })
                                    }}
                                  />
                                </FormGroup>
                              ) : (
                                ""
                              )}
                            </Col>

                            <Col className="col-12">
                              <Label for="textEditor">Announcement Body</Label>
                              <FormGroup>
                                <TextEditor
                                  defaultValue={initialValues.message}
                                  name="message"
                                  onChange={state =>
                                    setFieldValue(
                                      "message",
                                      JSON.stringify(state)
                                    )
                                  }
                                />
                                {errors["message"] &&
                                touched &&
                                touched["message"] ? (
                                  <div className="error">
                                    {errors["message"]}
                                  </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                    {/* <===== Render inputs for add announcements */}
                  </Col>
                </Row>
                <Button color="primary" className="mb-4" type="submit">
                  Submit
                </Button>{" "}
                <Button onClick={toggle} className="mb-4" type="button">
                  Cancel
                </Button>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle}>Confirm</ModalHeader>
                  <ModalBody>
                    All information entered will be lost if you cancel the
                    process. Are you sure?
                  </ModalBody>
                  <ModalFooter>
                    <Link to={`/events/${eventId}/announcements`}>
                      <Button color="primary">Yes</Button>
                    </Link>{" "}
                    <Button color="secondary" onClick={toggle}>
                      no
                    </Button>
                  </ModalFooter>
                </Modal>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  )
}

const mapStatetoProps = ({
  events: { eventDetails = [] } = {},
}) => ({
  eventDetails,
})

export default connect(mapStatetoProps, {
  fetchEvents: getEventDetails,
})(AddAnouncement)
