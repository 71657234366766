import React, { useContext, useState, useEffect } from "react"
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import moment from "moment"
import { useToasts } from "react-toast-notifications"
import { Link } from "react-router-dom"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import { enablePhase, deletePhase } from "./api"
import { connect } from "react-redux"
import { getMatches } from "../Matches/actions"

const Phases = ({
  competitionDetails: { phases = [], level } = {},
  getEventDetails,
  match,
  events,
  eventDetails,
  fetchMatches,
  matches,
}) => {
  const [modal, setModal] = useState(false)
  const [phaseId, setPhaseId] = useState("")
  const { id, competitionId } = match.params
  const { addToast } = useToasts()

  const toggle = phId => {
    setModal(!modal)
    setPhaseId(phId)
  }

  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const NoPhases = () => (
    <Card>
      <CardBody>
        <p className="no-events mb-0">There are no phases yet</p>
      </CardBody>
    </Card>
  )

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }

    await getEventDetails(id)

    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const getPhaseEntriesNumber = matches => {
    let phaseEntries = []

    matches?.map((match, idx) => {
      if (match?.competition?.phaseId) {
        if (!phaseEntries.includes(match?.player?.userId)) {
          phaseEntries?.push(match?.player?.userId)
        }
        if (!phaseEntries.includes(match?.opponent?.userId)) {
          phaseEntries?.push(match?.opponent?.userId)
        }
      }
    })

    return phaseEntries?.length
  }

  useEffect(() => {
    fetchMatches({ competitionId })
  }, [fetchMatches, competitionId])

  return (
    <div>
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <Table className="project-list-table table-nowrap table-centered table-borderless">
        {phases?.length > 0 && (
          <thead>
            <th>Phase ID</th>
            <th>Level</th>
            <th>Game Type</th>
            <th>Draw Model</th>
            <th>ML/Size</th>
            <th>Start Date</th>
            <th>Actions</th>
          </thead>
        )}
        <tbody>
          {phases.filter(({ drawModel }) => !!drawModel).length ? (
            phases
              .filter(({ drawModel }) => !!drawModel)
              .map(
                ({
                  _id,
                  phaseId,
                  drawModel = "-",
                  startDate = new Date(),
                  mlNumber = "-",
                  mainList = "-",
                  gameType = "-",
                  status,
                }) => (
                  <tr
                    className={`Phase ${
                      status === "DISABLED"
                        ? "event--disabled"
                        : "event--enabled"
                    }`}
                  >
                    <Link
                      to={location => `${location.pathname}/${_id}/details`}
                    >
                      <td className="text-dark">
                        <Badge color="primary" className="p-1">
                          <p className="mb-0 p-1"> {phaseId}</p>
                        </Badge>
                      </td>
                    </Link>
                    <td className="text-dark">
                      <p className="mb-0"> {level}</p>
                    </td>
                    <td>{gameType}</td>
                    <td className="text-dark">{drawModel}</td>

                    <td className="text-dark">
                      {getPhaseEntriesNumber(matches) + "/" + mlNumber}
                    </td>
                    <td>{moment(startDate).format("MMMM Do YYYY, h:mm a")}</td>
                    <td className="actions">
                      <a
                        href="javascript:;"
                        onClick={() => toggle(_id)}
                        className="button--disable"
                      >
                        <i className="bx bx-trash-alt"></i>
                      </a>{" "}
                      <Link
                        to={location => `${location.pathname}/${_id}/details`}
                      >
                        <i className="bx bx-edit-alt"></i>
                      </Link>
                    </td>
                  </tr>
                )
              )
          ) : (
            <NoPhases />
          )}
        </tbody>
      </Table>
      <Link to={`/events/${id}/competitions/${competitionId}/add-phase`}>
        <Button disabled={phases?.length >= 1} color="success" type="button">
          Add phase
        </Button>
      </Link>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Remove phase confirmation</ModalHeader>
        <ModalBody>
          All information entered will be lost if you delete the phase. Are you
          sure?
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              await deletePhase(id, competitionId, phaseId, socketClientId)
              toggle()
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button color="secondary" onClick={toggle}>
            no
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

const mapStatetoProps = ({
  events: { eventDetails = {} } = {},
  matches: { matches = [] } = {},
}) => ({
  matches,
  eventDetails,
})

export default connect(mapStatetoProps, { fetchMatches: getMatches })(Phases)
