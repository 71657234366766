import React from "react"

import Input from "components/Common/Input"
import { Row, Col, FormGroup } from "reactstrap"
import DatePicker from "react-datepicker"
import { Label } from "reactstrap"
import Select from "react-select"

const Technical = ({
  errors,
  touched,
  initialValues,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  settings: {
    categories = [],
    playModes = [],
    season = [],
    balls = [],
    courtTypes = [],
  } = {},
}) => {
  const now = new Date()
  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <label className="mb-1">Start date*</label>
            <DatePicker
              selected={
                new Date(values.startDate || initialValues.startDate || now)
              }
              onChange={value => setFieldValue("startDate", value)}
              dateFormat="Pp"
              className="form-control"
              showTimeSelect
            />
            {errors && errors.startDate && touched && touched.startDate ? (
              <div className="error">{errors.startDate}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <label className="mb-1">End date*</label>
            <DatePicker
              selected={
                new Date(values.endDate || initialValues.endDate || now)
              }
              onChange={value => setFieldValue("endDate", value)}
              dateFormat="Pp"
              className="form-control"
              showTimeSelect
            />
            {errors && errors.endDate && touched && touched.endDate ? (
              <div className="error">{errors.endDate}</div>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Select category"
              label="Select category*"
              type="select"
              name="category"
              {...{ errors, initialValues, touched, handleChange, handleBlur }}
            >
              <option value="" selected disabled>
                Select category
              </option>
              {categories?.map((x, idx) => (
                <option value={x?.key} key={idx}>
                  {x?.name === "Turneul campionilor" ? "Champions" : x?.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Select playmode"
              label="Select playmode*"
              type="select"
              name="playMode"
              {...{ errors, initialValues, touched, handleChange, handleBlur }}
            >
              {playModes.map((x, id) => (
                <option value={x.key} key={id}>
                  {x.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Select season"
              label="Select season*"
              type="select"
              name="season"
              {...{ errors, initialValues, touched, handleChange, handleBlur }}
            >
              <option value="" selected disabled>
                Select season
              </option>
              {season.map((x, idx) => (
                <option value={x.key} key={idx}>
                  {x.name}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label className="mb-1">Singles max number*</Label>
            <Select
              name="singlesMaxNumber"
              defaultValue={
                initialValues?.singlesMaxNumber
                  ? {
                      value:
                        initialValues?.singlesMaxNumber &&
                        initialValues?.singlesMaxNumber,
                      label: initialValues?.singlesMaxNumber,
                    }
                  : {
                      value: 1,
                      label: 1,
                    }
              }
              placeholder="Singles max number"
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
              ]}
              onChange={e => setFieldValue("singlesMaxNumber", e.value)}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors &&
            errors["singlesMaxNumber"] &&
            touched &&
            touched["singlesMaxNumber"] ? (
              <div className="error">{errors["singlesMaxNumber"]}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Label className="mb-1">Doubles max number*</Label>
            <Select
              name="doublesMaxNumber"
              defaultValue={
                initialValues?.doublesMaxNumber
                  ? {
                      value:
                        initialValues?.doublesMaxNumber &&
                        initialValues?.doublesMaxNumber,
                      label: initialValues?.doublesMaxNumber,
                    }
                  : {
                      value: 1,
                      label: 1,
                    }
              }
              placeholder="Doubles max number"
              options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
              ]}
              onChange={e => setFieldValue("doublesMaxNumber", e.value)}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
            {errors &&
            errors["doublesMaxNumber"] &&
            touched &&
            touched["doublesMaxNumber"] ? (
              <div className="error">{errors["doublesMaxNumber"]}</div>
            ) : null}
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Number of courts"
              label="Number of courts"
              type="number"
              name="numberOfCourts"
              {...{ errors, initialValues, touched, handleChange, handleBlur }}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Select court type"
              label="Select court type*"
              type="select"
              name="courtType"
              {...{ errors, initialValues, touched, handleChange, handleBlur }}
            >
              <option value="" selected disabled>
                Select court type
              </option>
              {courtTypes.map((x, idx) => (
                <option value={x.key} key={idx}>
                  {x.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label className="mb-1">Select official ball</Label>
            <Select
              name="officialBall"
              defaultValue={
                initialValues?.officialBall && {
                  value: initialValues?.officialBall,
                  label: initialValues?.officialBall?.name,
                }
              }
              placeholder="Select official ball"
              options={balls?.map(ball => ({
                label: ball.name,
                value: ball,
              }))}
              onChange={e => setFieldValue("officialBall", e.value)}
              onBlur={setFieldTouched}
              {...{ errors, touched }}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Technical
