import React from "react"

import { Row, Col, FormGroup } from "reactstrap"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import Input from "components/Common/Input"

const EventsPhase = ({
  settings,
  setFieldValue,
  values,
  initialValues,
  errors,
  id,
}) => {
  const eventPhases = settings?.eventPhases
  const { phase, category } = values
  const now = new Date()

  const keyByOrder = order => {
    if (
      category !== "gold" &&
      category !== "platinum" &&
      category !== "champions"
    ) {
      order == 1 ? (order = 3) : order
    }
    return eventPhases.filter(
      ({ order: phaseOrder }) => phaseOrder === order + 1
    )
  }

  const specialPhase = current => {
    if (current === "preRegistrationsOpen" || current === "sortPending") {
      if (
        category === "gold" ||
        category === "platinum" ||
        category === "champions"
      ) {
        return true
      }
      return false
    }
    return true
  }

  const formatPhaseName = phase => {
    return phase.toUpperCase().split(" ").join("-")
  }

  return (
    <div className="Event_phases">
      {eventPhases
        ?.filter(({ key }) => !["ended"].includes(key))
        .map(field => {
          const currentKey = field.key

          let nextField = keyByOrder(field.order)[0]

          return (
            <>
              <Row key={currentKey}>
                {specialPhase(currentKey) ? (
                  <>
                    <Col>
                      <FormGroup>
                        <label className="mb-1">
                          {formatPhaseName(field.name) + " Start Date:"}
                        </label>
                        {(phase[field.key]?.startDate || initialValues?.phase[currentKey]?.startDate)
                          ? <DatePicker
                              selected={
                                new Date(
                                  phase[field.key]?.startDate ||
                                    initialValues?.phase[currentKey]?.startDate ||
                                    now
                                )
                              }
                              disabled={
                                phase[field.key]?.startDate === null ||
                                initialValues?.phase[currentKey]?.startDate === null
                              }
                              dateFormat="Pp"
                              className="form-control"
                              showTimeSelect
                              onChange={value =>
                                setFieldValue(
                                  `phase.` + field.key + `.startDate`,
                                  value
                                )
                              }
                            />
                          : <Input
                              type="text"
                              placeholder={field.key === 'readyToPlay' ? 'Main Draw NOT generated yet' : ''}
                              disabled
                            />
                        }                        
                      </FormGroup>
                      {specialPhase(currentKey) &&
                        ((errors?.phase || {})[currentKey] || {})[
                          "startDate"
                        ] && (
                          <div className="error">
                            {
                              ((errors?.phase || {})[currentKey] || {})[
                                "startDate"
                              ]
                            }
                          </div>
                        )}
                    </Col>
                    <Col>
                      <FormGroup>
                        <label className="mb-1">
                          {formatPhaseName(field.name) + " End Date:"}
                        </label>
                        {(phase[field.key]?.endDate || initialValues?.phase[currentKey]?.endDate)
                          ? <DatePicker
                              selected={
                                new Date(
                                  phase[field.key]?.endDate ||
                                    initialValues?.phase[currentKey]?.endDate ||
                                    now
                                )
                              }
                              disabled={
                                phase[field.key]?.endDate === null ||
                                initialValues?.phase[currentKey]?.endDate === null
                              }
                              dateFormat="Pp"
                              className="form-control"
                              showTimeSelect
                              onChange={value => {
                                setFieldValue(
                                  `phase.` + field.key + `.endDate`,
                                  value
                                )
                                if (nextField?.key) {
                                  setFieldValue(
                                    "phase." + nextField?.key + ".startDate",
                                    value
                                  )
                                }
                              }}
                            />
                          : <Input
                              type="text"
                              placeholder={field.key === 'drawPending' ? 'Main Draw NOT generated yet' : (field.key === 'resultsPending' ? 'Points NOT saved yet' : '')}
                              disabled
                            />
                        }
                        
                      </FormGroup>
                      {specialPhase(currentKey) &&
                        ((errors?.phase || {})[currentKey] || {})[
                          "endDate"
                        ] && (
                          <div className="error">
                            {
                              ((errors?.phase || {})[currentKey] || {})[
                                "endDate"
                              ]
                            }
                          </div>
                        )}
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>
            </>
          )
        })}
    </div>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({ settings })

export default connect(mapStatetoProps, null)(EventsPhase)
