import { get, put, patch, post, del } from "helpers/api_helper"
import toastr from "toastr"

export const getTransactions = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/accounts/transactions?${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getAdminInfo = async id => {
  let response
  try {
    response = await get(`/accounts/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}
