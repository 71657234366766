import React, { useState, useEffect, useContext } from "react"
import { connect } from "react-redux"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import { Formik, Form } from "formik"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import { useToasts } from "react-toast-notifications"
import { isEmpty } from "lodash"
import ListenerComponent from "components/Common/Socket/ListenerComponent"
import { store as socketStore } from "components/Common/Socket/store"
import { getFriendlyMatches } from "./actions"
import { deleteFriendlyMatch } from "./api"
import AdvancedFilters from "./AdvancedFilters"
import listColumns from "./listColumns"

const FriendlyMatches = ({
  fetchFriendlyMatches,
  friendlyMatchesState,
}) => {
  const initialOptions = {
    page: 1,
    limit: 15,
  }

  const [options, setOptions] = useState(initialOptions)

  const pageOptions = {
    page: options?.page,
    sizePerPage: 15,
    totalSize: friendlyMatchesState?.friendlyMatches?.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }

  const [activeFilters, setActiveFilters] = useState({})

  useEffect(() => {
    if (!isEmpty(activeFilters)) {
      fetchFriendlyMatches(options, activeFilters)
    }
  }, [options, activeFilters, fetchFriendlyMatches])

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [modalMatchId, setModalMatchId] = useState("")

  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    fetchFriendlyMatches(options, activeFilters)
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "deleted") {
      return "disabledRow"
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ListenerComponent
            listenFunction={listenForResponse}
            topic="friendly-matches-response"
          />
          <Card>
            <CardBody>
              <h4>Friendly Matches</h4>
              <Row>
                <Col lg="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={friendlyMatchesState?.friendlyMatches?.results || []}
                        columns={listColumns({setModal, setModalMatchId})}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <>
                            <div className="filter-area">
                              <div
                                className={'justify-content-between d-flex'}
                              >
                                <Formik
                                  initialValues={{
                                    search: ''
                                  }}
                                >
                                  {({
                                    touched,
                                    initialValues,
                                    handleBlur,
                                    handleChange,
                                    setFieldValue,
                                    values,
                                  }) => {
                                    setActiveFilters(values)
                                    return (
                                      <Form className="w-100">
                                        <AdvancedFilters
                                          touched={touched}
                                          initialValues={initialValues}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          values={values}
                                          locations={friendlyMatchesState?.friendlyMatches?.locations || []}
                                        />
                                      </Form>
                                    )
                                  }}
                                </Formik>
                              </div>
                            </div>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    rowClasses={rowClasses}
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={(type, data) =>
                                      setOptions({
                                        ...options,
                                        page: data?.page,
                                      })
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>

                                {friendlyMatchesState?.friendlyMatches?.length == 0 && (
                                  <NoFriendlyMatches />
                                )}
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {"Delete Friendly Match"}
          </ModalHeader>
          <ModalBody>
            {"Are you sure you want to delete this friendly match?"}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={async () => {
                await deleteFriendlyMatch({socketClientId, matchId: modalMatchId})
                toggle()
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button color="secondary" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
      </Modal>
      </div>
    </React.Fragment>
  )
}

const NoFriendlyMatches = () => {
  return (
    <Card>
      <CardBody>
        <p className="mb-0">There are no friendly matches yet</p>
      </CardBody>
    </Card>
  )
}

const mapStatetoProps = ({
  friendlyMatches: friendlyMatchesState = {},
}) => ({
  friendlyMatchesState,
})

export default connect(mapStatetoProps, {
  fetchFriendlyMatches: (filters, options) => getFriendlyMatches(filters, options),
})(FriendlyMatches)
