import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_CLUBS_LEADS,
  GET_CLUB_LEAD_INFO,
  GET_CLUBS_LEADS_LOCATIONS,
} from "./actionTypes"

import {
  apiError,
  getClubsLeadsSuccess,
  getClubLeadInfoSuccess,
  getClubLeadsLocationsSuccess,
} from "./actions"

function* getClubsLeadsLocations({ filters }) {
  try {
    const { data } = yield call(api.getClubsLeadsLocations, filters)
    yield put(getClubLeadsLocationsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubsLeads({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getClubsLeads, filters, options)
    yield put(getClubsLeadsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getClubLeadInfo({ id }) {
  try {
    const { data } = yield call(api.getClubLeadInfo, id)
    yield put(getClubLeadInfoSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_CLUBS_LEADS_LOCATIONS, getClubsLeadsLocations)
  yield takeEvery(GET_CLUBS_LEADS, getClubsLeads)
  yield takeEvery(GET_CLUB_LEAD_INFO, getClubLeadInfo)
}

export default eventsSaga
