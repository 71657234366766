import { get, put, patch, post, del } from "helpers/api_helper";
import toastr from "toastr";

export const getAdmins = async (filter, options) => {
  let response;
  try {
    const filters = new URLSearchParams(Object.assign(filter, options)).toString();
    response = await get(`/accounts?role=admin,superAdmin&${filters}`)
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const getAdminInfo = async (id) => {
  let response;
  try {
    response = await get(`/accounts/${id}`);
  } catch (error) {
    toastr.error(error.response.data?.message);

    response = error.response.data;
  }
  return response;
};

export const createAdmin = async (data) => {
  let response;
  try {
    response = await post('/admin/accounts', data);
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const updateAccount = async (id, data) => {
  let response;
  try {
    response = await put(`/admin/accounts/${id}`, data);
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const updateStatus = async (id, status) => {
  let response;
  try {
    response = await patch(`/admin/accounts/${id}/status`, { status });
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const resetPassword = async (email) => {
  let response;
  try {
    response = await post(`/accounts/reset-password`, { email });
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const setPassword = async (id, data) => {
  let response;
  try {
    response = await patch(`accounts/${id}/set-password`, data);
  } catch (error) {
    response = error.response.data;
  }
  return response;
};

export const deleteAdmin = async (id) => {
  let response;
  try {
    response = await del(`/admin/accounts/${id}`);
  } catch (error) {
    response = error.response.data;
  }
  return response;
};
