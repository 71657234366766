import React from "react"
import { connect } from "react-redux"
import { FormGroup, Row, Col, Label } from "reactstrap"
import Select from "react-select"
import Input from "components/Common/Input"

const statuses = [
  { label: "Unresolved", value: "unresolved" },
  { label: "Resolved", value: "resolved" },
]

const AdvancedFilters = ({
  touched,
  initialValues,
  handleBlur,
  handleChange,
  values,
  setFieldValue,
}) => {
  return (
    <>
      <Row>
        <Col md={9}>
          <FormGroup>
            <Label>Search</Label>
            <Input
              placeholder="Search by id, name, email of reporter or reported"
              type="text"
              name="search"
              {...{
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            ></Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="ml-1 mr-1 w-100 form-group">
              <Label>Report Status</Label>
              <Select
                placeholder="Report Status"
                name="status"
                onChange={(e, x) =>
                  x?.removedValues?.length
                    ? setFieldValue("status", "")
                    : setFieldValue("status", e?.value)
                }
                options={statuses}
                isClearable={true}
              />
            </FormGroup>
        </Col>
      </Row>
    </>
  )
}

const mapStatetoProps = ({ session: { settings } = {} }) => ({
  settings,
})

export default connect(mapStatetoProps, {})(AdvancedFilters)
