import axios from "axios"

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

axiosApi.defaults.withCredentials = true
axiosApi.defaults.credentials = "include"

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi({
    method: "POST",
    url,
    data,
    config,
  }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, data, config = {}) {
  return axiosApi({
    method: "DELETE",
    url,
    data,
    config,
  }).then(response => response.data)
}
