import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  Button,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import DatePicker from "react-datepicker"

import Breadcrumbs from "components/Common/Breadcrumb"
import listColumns from "./listColumns"
import FilterList from "./FilterList"
import { getTransactions } from "./actions"
import Input from "components/Common/Input"

const PaymentsManagement = props => {
  const {
    transactions = {},
    getTransactions,
    userDetails: { role },
  } = props
  const { SearchBar } = Search
  const pageOptions = {
    page: transactions.page,
    sizePerPage: transactions.limit,
    totalSize: transactions.totalResults,
    custom: true,
    hidePageListOnlyOnePage: true,
  }
  const initialOptions = {
    page: 1,
    limit: 25,
  }
  const now = new Date();
  const [filters, setFilters] = useState({})
  const [options, setOptions] = useState(initialOptions)
  const [startDate, setStartDate] = useState(new Date(now.getFullYear(), now.getMonth() - 1, 1))
  const [endDate, setEndDate] = useState(new Date(now.getFullYear(), now.getMonth(), 0))

  useEffect(() => {
    getTransactions(filters, options)
  }, [getTransactions, filters, options])

  const handleTableChange = (type, data) => {
    switch (type) {
      case "pagination":
        setOptions({
          ...options,
          page: data.page,
        })
        break
      case "search":
        if (data.searchText !== filters.search) {
          setFilters({
            ...filters,
            search: data.searchText,
          })
        }
        break
      case "sort":
        const sortOption = `${data.sortField}:${data.sortOrder}`
        if (sortOption !== options.sortBy) {
          setOptions({
            ...options,
            sortBy: sortOption,
          })
        }
        break
      default:
        break
    }
  }

  const handleChangeWithDebounce = _.debounce(async e => {
    setFilters({
      search: e,
    })
  }, 700)

  const dateFormat = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Payments management"
            breadcrumbItem="Payments"
            displayTotal={transactions.totalResults}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={transactions.results || []}
                        columns={listColumns(role)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <Label>Export from</Label>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(value) => setStartDate(value)}
                                    dateFormat="P"
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>Export to</Label>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={(value) => setEndDate(value)}
                                    dateFormat="P"
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <Label>&nbsp;</Label> <br />
                                <a
                                  href={`${process.env.REACT_APP_API_URL}/transactions/xls?startDate=${dateFormat(startDate)}&endDate=${dateFormat(endDate)}`}
                                >
                                  <Button type="button" color="primary">
                                    Export
                                  </Button>
                                </a>
                              </Col>
                            </Row>
                            <hr />
                            <div className="filter-area">
                              <Row>
                                <Col>
                                  <Label>Search by</Label>
                                  <div className="search-box mr-2 mb-2">
                                    <div className="position-relative">
                                      <Input
                                        onChange={e =>
                                          handleChangeWithDebounce(
                                            e?.target?.value
                                          )
                                        }
                                        placeholder="Name, user id or email"
                                        style={{ height: '38px', borderRadius: '4px' }}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <FilterList
                                  filters={filters}
                                  setFilters={setFilters}
                                />
                              </Row>
                            </div>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({
  transactions: { transactions } = {},
  session: { userDetails } = {},
}) => ({
  transactions,
  userDetails,
})

const mapDispatchToProps = dispatch => ({
  getTransactions: (filters, options) =>
    dispatch(getTransactions(filters, options)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentsManagement))
