import React, { useEffect } from "react"
import Select from "react-select"
import { FormGroup, Label } from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { getSettings } from "../../Authentication/actions"

const levelsOptions = [
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
]

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
]

const planOptons = [
  { label: "Free", value: "free" },
  { label: "Premium", value: "premium" },
]

const minAgeOptions = []
const maxAgeOptions = []
for (let i = 11; i <= 95; i++) {
  minAgeOptions.push({
    label: i,
    value: i,
  })
  maxAgeOptions.push({
    label: i,
    value: i,
  })
}

const statusOptions = [
  { label: "Activated", value: "activated" },
  { label: "Blocked", value: "blocked" },
  { label: "Pending", value: "pending" },
  { label: "Deleted", value: "deleted" },
]

const FilterList = ({ filters, setFilters, locations }) => {
  const locationOptions = locations?.map(location => {
    return {
      label: `${location.city ?? "All Cities"}, ${location.country} (${
        location.total
      })`,
      value: `${location.city ?? "null"}, ${location.country}`,
    }
  })

  function handleFilter(data) {
    if (data) {
      if (["minAge", "maxAge"].includes(this.name)) {
        let ages = filters.age ? filters.age.split(",") : []

        const age = [
          this.name === "minAge" ? data.value : filters.age ? ages[0] : 0,
          this.name === "maxAge" ? data.value : filters.age ? ages[1] : 100,
        ]

        setFilters({ ...filters, age: age.join() })
      } else {
        setFilters({ ...filters, [this.name]: data.value })
      }
    } else {
      const updatedFilters = { ...filters }

      if (["minAge", "maxAge"].includes(this.name)) {
        const ages = filters.age.split(",")

        const position = this.name === "minAge" ? 0 : 1
        ages[position] = this.name === "minAge" ? 0 : 100
        updatedFilters.age = ages.join()
        if (updatedFilters.age === "0,100") {
          delete updatedFilters.age
        }
      } else {
        delete updatedFilters[this.name]
      }

      setFilters(updatedFilters)
    }
  }

  return (
    <React.Fragment>
      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Level - Single</Label>
        <Select
          placeholder="Select"
          name="gameLevelSingle"
          onChange={handleFilter}
          options={levelsOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Level - Double</Label>
        <Select
          placeholder="Select"
          name="gameLevelDouble"
          onChange={handleFilter}
          options={levelsOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Gender</Label>
        <Select
          placeholder="Select"
          name="gender"
          onChange={handleFilter}
          options={genderOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Min age</Label>
        <Select
          placeholder="Select"
          name="minAge"
          onChange={handleFilter}
          options={minAgeOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Max age</Label>
        <Select
          placeholder="Select"
          name="maxAge"
          onChange={handleFilter}
          options={maxAgeOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Location</Label>
        <Select
          placeholder="Select"
          name="location"
          onChange={handleFilter}
          options={locationOptions}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Membership</Label>
        <Select
          placeholder="Select"
          name="membership"
          onChange={handleFilter}
          options={planOptons}
          isClearable={true}
        />
      </FormGroup>

      <FormGroup className="ml-1 mr-1 w-100 form-group">
        <Label>Status</Label>
        <Select
          placeholder="Select"
          name="status"
          onChange={handleFilter}
          options={statusOptions}
          isClearable={true}
        />
      </FormGroup>
    </React.Fragment>
  )
}

const mapStateToProps = ({ players }) => ({
  locations: players.playersLocations,
})

const mapDispatchToProps = dispatch => ({
  getSettings: () => dispatch(getSettings),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterList))
