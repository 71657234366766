import { call, put, takeEvery } from "redux-saga/effects"
import * as api from "./api"

// Events Redux States
import {
  GET_PLAYERS,
  GET_PLAYERS_LIST,
  GET_PLAYER_INFO,
  CHANGE_PLAYERS_FILTERS,
  GET_PLAYER_TRANSACTIONS,
  GET_PLAYER_WALLET_TRANSACTIONS,
  GET_PLAYER_MEMBERSHIP_LIST,
  GET_PLAYER_ACTIVITY,
  GET_LEVEL_CHANGE,
  GET_PLAYER_ENTRIES,
  GET_PLAYER_HISTORY_ENTRIES, 
  GET_PLAYERS_LOCATIONS,
  GET_PLAYER_REFERRAL_REWARDS,
  GET_PLAYER_FIRST_TASK_REWARDS,
} from "./actionTypes"

import {
  apiError,
  getPlayersSuccess,
  getPlayerInfoSuccess,
  changePlayersFilters,
  getPlayerTransactionSuccess,
  getPlayerWalletTransactionSuccess,
  getMembershipListSuccess,
  getPlayerActivitySuccess,
  getLevelChangeSuccess,
  getPlayersListSuccess,
  getPlayerEntriesSuccess,
  getPlayerHistoryEntriesSuccess, 
  getPlayersLocationsSuccess,
  getPlayerReferralRewardsSuccess,
  getPlayerFirstTaskRewardsSuccess,
} from "./actions"

function* getPlayers({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getPlayers, filters, options)
    yield put(getPlayersSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayersLocations({ filters, payload: [] }) {
  try {
    const { data } = yield call(api.getPlayersLocations, filters)
    yield put(getPlayersLocationsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerEntries({ id, playerEntriesOptions }) {
  try {
    const { data } = yield call(
      api.getPlayerEntriesapi,
      id,
      playerEntriesOptions
    )
    yield put(getPlayerEntriesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerHistoryEntries({ id, playerHistoryEntriesOptions }) {
  try {
    const { data } = yield call(
      api.getPlayerHistoryEntriesapi,
      id,
      playerHistoryEntriesOptions
    )
    yield put(getPlayerHistoryEntriesSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerTransaction({ id, transactionsOptions }) {
  try {
    const { data } = yield call(
      api.getPlayerTransactions,
      id,
      transactionsOptions
    )
    yield put(getPlayerTransactionSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerReferralRewards({ id }) {
  try {
    const { data } = yield call(
      api.getPlayerTransactions,
      id,
      {
        type: 'referralReward',
        limit: 1000,
      }
    )
    yield put(getPlayerReferralRewardsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerFirstTaskRewards({ id }) {
  try {
    const { data } = yield call(
      api.getPlayerTransactions,
      id,
      {
        type: 'firstTaskCompletion',
        limit: 1000,
      }
    )
    yield put(getPlayerFirstTaskRewardsSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerWalletTransaction({ id, options }) {
  try {
    const { data } = yield call(api.getPlayerWalletTransactions, id, options)
    yield put(getPlayerWalletTransactionSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getMembershipList({ id, membershipOptions }) {
  try {
    const { data } = yield call(api.getMembershipList, id, membershipOptions)
    yield put(getMembershipListSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerActivity({ id, playerActivityOptions }) {
  try {
    const { data } = yield call(
      api.getPlayerActivity,
      id,
      playerActivityOptions
    )
    yield put(getPlayerActivitySuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getLevelChange({ id, levelChangeOptions }) {
  try {
    const { data } = yield call(api.getLevelChange, id, levelChangeOptions)
    yield put(getLevelChangeSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayerInfo({ id }) {
  try {
    const { data } = yield call(api.getPlayerInfo, id)
    yield put(getPlayerInfoSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getPlayersList({ filters, options, payload: [] }) {
  try {
    const { data } = yield call(api.getPlayersList, filters, options)
    yield put(getPlayersListSuccess(data))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* eventsSaga() {
  yield takeEvery(GET_PLAYERS, getPlayers)
  yield takeEvery(GET_PLAYERS_LOCATIONS, getPlayersLocations)
  yield takeEvery(GET_PLAYER_ENTRIES, getPlayerEntries)
  yield takeEvery(GET_PLAYER_HISTORY_ENTRIES, getPlayerHistoryEntries)
  yield takeEvery(GET_PLAYERS_LIST, getPlayersList)
  yield takeEvery(GET_PLAYER_TRANSACTIONS, getPlayerTransaction)
  yield takeEvery(GET_PLAYER_REFERRAL_REWARDS, getPlayerReferralRewards)
  yield takeEvery(GET_PLAYER_FIRST_TASK_REWARDS, getPlayerFirstTaskRewards)
  yield takeEvery(GET_PLAYER_WALLET_TRANSACTIONS, getPlayerWalletTransaction)
  yield takeEvery(GET_PLAYER_MEMBERSHIP_LIST, getMembershipList)
  yield takeEvery(GET_PLAYER_ACTIVITY, getPlayerActivity)
  yield takeEvery(GET_LEVEL_CHANGE, getLevelChange)
  yield takeEvery(GET_PLAYER_INFO, getPlayerInfo)
  yield takeEvery(CHANGE_PLAYERS_FILTERS, changePlayersFilters)
}

export default eventsSaga
