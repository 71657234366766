import React, { useEffect, useContext, useState } from "react"
import { getMatches } from "../Matches/actions"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { store as socketStore } from "components/Common/Socket/store"
import { Button, Card, CardBody, Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import Input from "components/Common/Input"

import { addPoints } from "./api"
import ListenerComponent from "components/Common/Socket/ListenerComponent"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import SweetAlert from "react-bootstrap-sweetalert"

import { Link } from "react-router-dom"

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
]

const PointsList = ({
  matches = [],
  socketClientId,
  competitionId,
  eventId,
  competitionDetails,
  eventDetails,
}) => {
  const [saveModal, setSaveModal] = useState(false)

  const upperCaseFirstLetter = str => {
    if (str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    }
  }

  const mainDrawMatches = []
  const phaseMatches = []

  matches?.map(x => !x?.competition?.phaseId && mainDrawMatches?.push(x))
  matches?.map(x => x?.competition?.phaseId && phaseMatches?.push(x))

  let points = mainDrawMatches.reduce(
    (
      acc,
      { player, opponent, playerPartner, opponentPartner, competition, winner }
    ) => {
      acc[Number(player?.userId)] = {
        points:
          ((acc[Number(player?.userId)] || {}).points || 0) + player?.points,
        firstName: player?.firstName,
        lastName: player?.lastName,
        competition: competition,
        type: player?.type,
        role: "player",
        winner: winner,
        id: player?.id,
        wins:
          ((acc[Number(player?.userId)] || {}).wins || 0) +
          (winner === "player" ? 1 : 0),
        partner: playerPartner,
      }
      acc[Number(opponent?.userId)] = {
        points:
          ((acc[Number(opponent?.userId)] || {}).points || 0) +
          opponent?.points,
        firstName: opponent?.firstName,
        lastName: opponent?.lastName,
        competition: competition,
        id: opponent?.id,
        type: opponent?.type,
        role: "opponent",
        winner: winner,
        wins:
          ((acc[Number(opponent?.userId)] || {}).wins || 0) +
          (winner === "opponent" ? 1 : 0),
        partner: opponentPartner,
      }
      return acc
    },
    {}
  )

  points = Object.keys(points)
    .map(userId => ({
      userId,
      id: points[userId]?.id,
      round: points[userId]?.round,
      winner: points[userId]?.winner,
      competition: points[userId]?.competition,
      role: points[userId]?.role,
      type: points[userId]?.type,
      firstName: points[userId].firstName,
      lastName: points[userId].lastName,
      points: points[userId].points,
      phase: points[userId]?.competition?.phaseId ? true : false,
      group: points[userId]?.competition?.group,
      wins: points[userId]?.wins,
      partner: points[userId]?.partner,
    }))

    ?.sort((a, b) => b?.points - a?.points || b?.wins - a?.wins)
    ?.filter(x => x?.firstName) // ?

  //phase
  let phasePoints = phaseMatches.reduce(
    (
      acc,
      { player, opponent, playerPartner, opponentPartner, competition, winner }
    ) => {
      acc[Number(player?.userId)] = {
        points:
          ((acc[Number(player?.userId)] || {}).points || 0) + player?.points,
        firstName: player?.firstName,
        lastName: player?.lastName,
        competition: competition,
        role: "player",
        winner: winner,
        id: player?.id,
        type: player?.type,
        wins:
          ((acc[Number(player?.userId)] || {}).wins || 0) +
          (winner === "player" ? 1 : 0),
        partner: playerPartner,
      }
      acc[Number(opponent?.userId)] = {
        points:
          ((acc[Number(opponent?.userId)] || {}).points || 0) +
          opponent?.points,
        firstName: opponent?.firstName,
        lastName: opponent?.lastName,
        competition: competition,
        role: "opponent",
        winner: winner,
        id: opponent?.id,
        type: opponent?.type,
        wins:
          ((acc[Number(opponent?.userId)] || {}).wins || 0) +
          (winner === "opponent" ? 1 : 0),
        partner: opponentPartner,
      }
      return acc
    },
    {}
  )

  phasePoints = Object.keys(phasePoints)
    .map(userId => ({
      userId,
      winner: phasePoints[userId]?.winner,
      id: phasePoints[userId]?.id,
      role: phasePoints[userId]?.role,
      competition: phasePoints[userId]?.role,
      type: phasePoints[userId]?.type,
      firstName: phasePoints[userId].firstName,
      lastName: phasePoints[userId].lastName,
      points: phasePoints[userId].points,
      phase: phasePoints[userId]?.competition?.phaseId ? true : false,
      group: phasePoints[userId]?.competition?.group,
      wins: phasePoints[userId]?.wins,
      partner: phasePoints[userId]?.partner,
    }))
    ?.sort((a, b) => b?.points - a?.points || b?.wins - a?.wins)
    ?.filter(x => x?.firstName) // ?

  const listColumns = (
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleBlur,
    initialValues,
    values,
    phase
  ) => [
    {
      dataField: "Round",
      text: "Round",
      headerStyle: (colum, colIndex) => {
        return { width: "80px", textAlign: "center" }
      },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="d-flex justify-content-center">
            <p className="mb-0">
              {row?.competition?.stage === 0
                ? "Q"
                : row?.competition?.stage || rowIndex + 1}
            </p>
          </div>
        )
      },
    },
    {
      dataField: "Player ID",
      text: "Player ID",
      formatter: (
        cellContent,
        {
          firstName,
          lastName,
          userId,
          partner,
          type,
          competition,
          role,
          winner,
          id,
        }
      ) => (
        <p
          className={
            type === "BYE"
              ? "mb-0 text-danger d-flex"
              : competition?.stage === competition?.finalStage &&
                winner === role
              ? "mb-0 competitionWinners d-flex"
              : "mb-0 d-flex"
          }
        >
          {firstName + " " + lastName}
          <Link
            target="_blank"
            to={`/accounts/players/${id}`}
            className={type === "BYE" ? "ml-1 mr-1 text-danger" : "ml-1 mr-1"}
          >
            ({userId})
          </Link>{" "}
          {partner && partner?.firstName
            ? "/ " + partner?.firstName + " " + partner?.lastName
            : ""}{" "}
          {partner && partner?.firstName ? (
            <Link
              target="_blank"
              to={`/accounts/players/${partner?.id}`}
              className={type === "BYE" ? "ml-1  text-danger" : "ml-1 "}
            >
              ({partner?.userId})
            </Link>
          ) : (
            ""
          )}
        </p>
      ),
    },
    {
      dataField: "wins",
      text: "Wins",
      headerStyle: (colum, colIndex) => {
        return { width: "120px", textAlign: "center" }
      },
      formatter: (cellContent, { wins }) => (
        <div className="d-flex justify-content-center">
          <p className="mb-0">{wins}</p>
        </div>
      ),
    },
    {
      dataField: "Points",
      text: "points",
      headerStyle: (colum, colIndex) => {
        return { width: "120px", textAlign: "center" }
      },
      formatter: (
        cellContent,
        { firstName, lastName, userId, points, phase, partner }
      ) => (
        <div className="d-flex justify-content-center">
          <div className="flex shadow pointsCard align-items-center mb-2">
            <Input
              name={userId}
              defaultValue={points}
              onChange={data => {
                setFieldValue(
                  userId,
                  phase ? data.target.value + "- phase" : data.target.value
                )
                partner &&
                  setFieldValue(
                    partner?.userId,
                    phase ? data.target.value + "- phase" : data.target.value
                  )
              }}
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            />
          </div>{" "}
        </div>
      ),
    },
    {
      dataField: "systemPoints",
      text: "System Points",
      headerStyle: (colum, colIndex) => {
        return { width: "120px", textAlign: "center" }
      },
      formatter: (cellContent, { points }) => (
        <div className="d-flex justify-content-center">
          <p className="mb-0">{points}</p>
        </div>
      ),
    },
  ]

  const sortGroups = arr => {
    const results = arr?.reduce(
      (
        ac,
        {
          firstName,
          lastName,
          points,
          userId,
          group,
          phase,
          wins,
          type,
          role,
          competition,
          winner,
          partner,
          id,
        }
      ) => {
        ac[group] = [
          ...(ac[group] || []),
          {
            competition,
            firstName,
            lastName,
            points,
            userId,
            group,
            phase,
            wins,
            type,
            role,
            winner,
            partner,
            id,
          },
        ]
        return ac
      },
      {}
    )
    return results
  }

  return (
    <>
      {/* main draw - elim case */}
      <Formik initialValues={{}}>
        {({
          errors,
          values,
          touched,
          handleChange,
          handleBlur,
          initialValues,
          setFieldValue,
        }) => (
          <Form>
            {matches[0]?.competition?.drawType === "eliminatory" ? (
              <ToolkitProvider
                keyField="id"
                data={points?.filter(x => !x?.phase) || []}
                columns={listColumns(
                  setFieldValue,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values
                )}
                bootstrap4
              >
                {toolkitProps => (
                  <>
                    <h4>
                      Knockout Points allocated: Knockout eliminatory level{" "}
                      {competitionDetails?.level}{" "}
                      {upperCaseFirstLetter(eventDetails?.category)}{" "}
                      {competitionDetails?.gameType}
                    </h4>

                    <Row>
                      <Col lg="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            responsive
                            remote
                            bordered={false}
                            striped={false}
                            classes={
                              "table table align-middle table-nowrap table-hover"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            ) : (
              <>
                {/* main draw - group case */}
                {Object?.keys(sortGroups(points))?.map((el, idx) => {
                  const group = sortGroups(points)[el]
                  return (
                    <ToolkitProvider
                      keyField="id"
                      data={group?.filter(x => !x?.phase) || []}
                      columns={listColumns(
                        setFieldValue,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        initialValues,
                        values
                      )}
                      bootstrap4
                    >
                      {toolkitProps => (
                        <>
                          <h4>Group {alphabet[el - 1]} </h4>
                          <Row>
                            <Col lg="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table table align-middle table-nowrap table-hover"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ToolkitProvider>
                  )
                })}
              </>
            )}
            {/* phase - elim case */}
            {phasePoints?.length > 0 && <h2>Phase</h2>}
            {competitionDetails?.phases?.length > 0 &&
              phasePoints?.length > 0 &&
              (competitionDetails?.phases[0]?.drawModel === "eliminatory" ? (
                <ToolkitProvider
                  keyField="id"
                  data={phasePoints || []}
                  columns={listColumns(
                    setFieldValue,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    initialValues,
                    values,
                    true // phase case
                  )}
                  bootstrap4
                >
                  {toolkitProps => (
                    <>
                      <h4>
                        Knockout Phase Points allocated: Knockout eliminatory
                        level {competitionDetails?.level}{" "}
                        {upperCaseFirstLetter(eventDetails?.category)}{" "}
                        {competitionDetails?.gameType}
                      </h4>

                      <Row>
                        <Col lg="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              remote
                              bordered={false}
                              striped={false}
                              classes={
                                "table table align-middle table-nowrap table-hover"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              ) : (
                <>
                  {/* phase - group case */}
                  {Object?.keys(sortGroups(phasePoints))?.map(el => {
                    const group = sortGroups(phasePoints)[el]
                    return (
                      <ToolkitProvider
                        keyField="id"
                        data={group?.filter(x => x?.phase) || []}
                        columns={listColumns(
                          setFieldValue,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          initialValues,
                          values,
                          true // phase case
                        )}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <>
                            <h4>
                              Group {alphabet[el - 1]} Puncte alocate conform
                            </h4>
                            <Row>
                              <Col lg="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table align-middle table-nowrap table-hover"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                      </ToolkitProvider>
                    )
                  })}
                </>
              ))}{" "}
            <Button
              color="primary"
              tyep="submit"
              onClick={() => {
                setSaveModal(true)
              }}
            >
              Save
            </Button>
            {saveModal && (
              <SweetAlert
                warning
                showCancel
                title="Are you sure you want to save points for this competition?"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, Save Points"
                cancelBtnText="No"
                onConfirm={() => {
                  let phasePointsValues = {}
                  let mainDrawPointsValues = {}

                  //format phase points and push them to the phasePointsValues obj
                  Object.keys(values)?.map(key => {
                    if (values[key]?.split("-")?.length > 1) {
                      phasePointsValues = {
                        [key]: values[key]?.split("-")[0],
                        ...phasePointsValues,
                      }
                    } else {
                      mainDrawPointsValues = {
                        [key]: values[key],
                        ...mainDrawPointsValues,
                      }
                    }
                  })

                  console.log(competitionDetails)

                  //req cases
                  addPoints({
                    socketClientId,
                    eventId,
                    competitionId,
                    points: {
                      ...mainDrawPointsValues,
                    },
                    phaseId: "",
                    endCompetition: true,
                    eventDate: competitionDetails.startDate,
                  })

                  addPoints({
                    socketClientId,
                    eventId,
                    competitionId,
                    points: {
                      ...phasePointsValues,
                    },
                    phaseId:
                      competitionDetails?.phases?.length > 0
                        ? competitionDetails?.phases[0]?._id
                        : "",
                    endCompetition: false,
                    eventDate: competitionDetails.startDate,
                  })

                  setSaveModal(false)
                }}
                onCancel={() => setSaveModal(false)}
              >
                Please always make sure all scores are correctly filled in
                before saving the points.
              </SweetAlert>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

const Points = ({
  fetchMatches,
  matches,
  match: { params: { competitionId, id } = {} } = {},
  competitionDetails,
  eventDetails,
}) => {
  console.log(competitionDetails)
  console.log(eventDetails)
  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState
  useEffect(() => {
    fetchMatches({ competitionId })
  }, [fetchMatches, competitionId])
  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    await fetchMatches({ competitionId })
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }
  return (
    <div className="Points mt-2">
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="matches-response"
      />
      <Card>
        <CardBody>
          {matches.length ? (
            <PointsList
              matches={matches}
              competitionId={competitionId}
              eventId={id}
              socketClientId={socketClientId}
              competitionDetails={competitionDetails}
              eventDetails={eventDetails}
            />
          ) : (
            <p>Points are not listed yet</p>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

const mapStatetoProps = ({
  matches: { matches = [] } = {},
  session: { userDetails } = {},
}) => ({
  matches,
  userDetails,
})

export default connect(mapStatetoProps, { fetchMatches: getMatches })(Points)
