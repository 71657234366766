import React from "react"
import Select from "react-select"
import { FormGroup, Label, Col } from "reactstrap"

const orderTypeOptions = [
  { label: "Event Registration", value: "eventRegistration" },
  { label: "Upgrade Membership", value: "upgradeMembership" },
  { label: "Top Up", value: "topUp" },
  { label: "Reward - Own action", value: "firstTaskCompletion" },
  { label: "Reward - Invited player", value: "referralReward" },
]

const paymentMethodOptions = [
  { label: "Virtual Wallet", value: "virtualWallet" },
  { label: "Credit Card", value: "creditCard" },
  { label: "Admin", value: "admin" },
]

const FilterList = ({ filters, setFilters }) => {
  function handleFilter(data) {
    if (data) {
      setFilters({ ...filters, [this.name]: data.value })
    } else {
      const updatedFilters = { ...filters }
      delete updatedFilters[this.name]

      setFilters(updatedFilters)
    }
  }

  return (
    <React.Fragment>
      <Col>
        <FormGroup className="ml-1 mr-1 w-100 form-group">
          <Label>Order Type</Label>
          <Select
            placeholder="Select"
            name="type"
            onChange={handleFilter}
            options={orderTypeOptions}
            isClearable={true}
          />
        </FormGroup>
      </Col>

      <Col>
        <FormGroup className="ml-1 mr-1 w-100 form-group">
          <Label>Payment Method</Label>
          <Select
            placeholder="Select"
            name="paymentType"
            onChange={handleFilter}
            options={paymentMethodOptions}
            isClearable={true}
          />
        </FormGroup>
      </Col>
    </React.Fragment>
  )
}

export default FilterList
