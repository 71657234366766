import {
  GET_ORGANIZERS,
  GET_ORGANIZERS_SUCCESS,
  GET_ORGANIZER_INFO,
  GET_ORGANIZER_INFO_SUCCESS,
  GET_OWNER_CLUB,
  GET_OWNER_CLUB_SUCCESS,
  GET_TOURNAMENTS,
  GET_TOURNAMENTS_SUCCESS,
  API_ERROR,
  GET_ORGANIZERS_LOCATIONS_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  organizers: [],
  organizerInfo: {},
  organizerClubInfo: {},
  organizersLocations: [],
  tournaments: {},
  loading: false,
}

const organizers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZERS_LOCATIONS_SUCCESS:
      state = {
        ...state,
        organizersLocations: action?.payload,
      }
    case GET_ORGANIZERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ORGANIZERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizers: action.payload,
      }
      break
    case GET_ORGANIZER_INFO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_ORGANIZER_INFO_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizerInfo: action.payload,
      }
      break
    case GET_OWNER_CLUB:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_OWNER_CLUB_SUCCESS:
      state = {
        ...state,
        loading: false,
        organizerClubInfo: action.payload,
      }
      break
    case GET_TOURNAMENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TOURNAMENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        tournaments: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default organizers
