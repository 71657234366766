import { get, del } from "helpers/api_helper"

export const getFriendlyMatches = async payload => {
  let response
  const params = {}

  Object.assign(params, payload?.payload[0]);
  Object.assign(params, payload?.payload[1]);

  try {
    response = await get(
      `/friendly-matches?${ new URLSearchParams(params).toString() }`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getFriendlyMatch = async payload => {
  let response
  try {
    response = await get(`/friendly-matches/${payload?.payload[0]}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const deleteFriendlyMatch = async ({
  socketClientId,
  matchId,
}) => {
  let response
  try {
    response = await del(`/friendly-matches/${matchId}`, {
      socketClientId: socketClientId,
    })
  } catch (error) {
    console.log(error)
  }
  return response
}
