import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { isEmpty } from 'lodash';

import { store as socketStore } from "components/Common/Socket/store"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import { getAdminInfo } from "../UserAccounts/PlatformAdmins/actions";
import { setPassword } from "../UserAccounts/PlatformAdmins/api";
import aes from 'crypto-js/aes';


const SetPassword = props => {
  const {
    getAdminInfo,
    adminInfo,
    loading,
    match: { params },
    history,
  } = props;

  const globalState = useContext(socketStore);
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState;

  useEffect(() => {
    socket &&
    socket.on(
      "accounts-response",
      async ({ success, message }) => {
        if (success) {
          history.push('/login');
          toastr.success(message);
        } else {
          toastr.error(message);
        }
      }
    )
  }, [socket, history]);

  useEffect(() => {
    getAdminInfo(params.id);
  }, [getAdminInfo, params.id]);

  if(!loading && !isEmpty(adminInfo)) {
    if (adminInfo.resetPasswordCode !== params.resetCode) {
      toastr.error('Link expired or is invalid.');
      history.push('/login');
    }
  }

  if (!loading && adminInfo === undefined) {
    toastr.error('Link expired or is invalid.');
    history.push('/login');
  }

  const handleSubmit = async (event, values) => {
    const KEY = process.env.REACT_APP_ENC_KEY
    await setPassword(params.id, {
      socketClientId,
      resetCode: params.resetCode,
      password: aes.encrypt(values.password, KEY).toString(),
    });
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"/>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Set password</h5>
                        <p>Set your password to continue.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          required
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Set password
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SetPassword.propTypes = {
  adminInfo: PropTypes.object,
  getAdminInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ admins }) => ({
  adminInfo: admins.adminInfo,
  loading: admins.loading,
})

const mapDispatchToProps = dispatch => ({
  getAdminInfo: (id) => dispatch(getAdminInfo(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SetPassword))

