import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
} from "reactstrap"
import { get } from "helpers/api_helper"
import Export from "./Export"

const ExtendedAnalytics = props => {
  const [accounts, setAccounts] = useState({
      accountsReferred: {},
      playersWithFriends: {},
      avgNetwork: {},
      countRaisedHands: 0,
      raisedHands: {
        singles: {},
        doubles: {},
        both: {},
      },
  });
  const [chats, setChats] = useState({
      countConversations: 0,
      countGroupConversations: 0
  });

  const [friendlyMatches, setFriendlyMatches] = useState({
    created: 0,
    active: 0,
    completed: 0,
    canceled: 0,
    noMatch: 0,
    rankedInvites: 0,
    unrankedInvites: 0,
    rankedPlayedMatches: 0,
    unrankedPlayedMatches: 0,
});

const [ratings, setRatings] = useState({
  active: 0,
  pending: 0,
  deleted: 0,
  ratedPlayers: {
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
  },
  totalRatedPlayers: 0,
  publicRatings: 0,
  raters: 0,
});

const [rewards, setRewards] = useState({
  playersOwnRewards: {},
  playersInvitationRewards: {},
  ownRewards: {},
  invitationRewards: {},
});

  useEffect(() => {
    get(`/accounts/statistics`).then((response) => {
      const countries = {
        Romania: 0,
        Bulgaria: 0,
        Hungary: 0,
        Moldova: 0,
        Czechia: 0,
        Bahrain: 0,
      };
      let playersWithFriends = { ...countries };
      let avgNetwork = { ...countries };
      (response.data?.playersWithFriends || []).forEach(country => {
        playersWithFriends[country._id] = country.count;
        avgNetwork[country._id] = country.avgFriendsNumber?.toFixed(2);
      });
      let accountsReferred = { ...countries };
      (response.data?.accountsReferred || []).forEach(country => accountsReferred[country._id] = country.count);
      setAccounts({
        accountsReferred,
        playersWithFriends,
        avgNetwork,
        countRaisedHands: response.data?.countRaisedHands,
        raisedHands: response.data?.raisedHands,
      });
    });

    get(`/rewards/statistics`).then((response) => {
      const currencies = {
        RON: 0,
        BGN: 0,
        HUF: 0,
        CZK: 0,
        BHD: 0,
      };
      let playersOwnRewards = { ...currencies };
      let playersInvitationRewards = { ...currencies };
      let ownRewards = { ...currencies };
      let invitationRewards = { ...currencies };
      (response.data?.playersOwnRewards || []).forEach(currency => playersOwnRewards[currency._id] = currency.count);
      (response.data?.playersInvitationRewards || []).forEach(currency => playersInvitationRewards[currency._id] = currency.count);
      (response.data?.ownRewards || []).forEach(currency => ownRewards[currency._id] = currency.count);
      (response.data?.invitationRewards || []).forEach(currency => invitationRewards[currency._id] = currency.count);
      setRewards({
        playersOwnRewards,
        playersInvitationRewards,
        ownRewards,
        invitationRewards,
      })
    });

    get(`/chat/statistics`).then((response) => {
      setChats({
        countConversations: response.data?.countConversations,
        countGroupConversations: response.data?.countGroupConversations,
      });
    });

    get(`/friendly-matches/statistics`).then((response) => {
      setFriendlyMatches(response.data);
    });

    get(`/ratings/statistics`).then((response) => {
      response.data.totalRatedPlayers = 0;
      [1, 2, 3, 4, 5].forEach(v => {
        response.data.ratedPlayers[v] = response.data.ratedPlayers[v] ?? 0;
        response.data.totalRatedPlayers += response.data.ratedPlayers[v];
      })
      setRatings(response.data);
    });
  }, []);
  
  const reports = [
    { title: 'Clubs Activity', link: '/exports/clubs-activity', datepickers: true},
    { title: '# of confirmed registrations to competitions / month', link: '/exports/confirmed-registrations-to-competitions', datepickers: true},
    { title: '# of withdrawals from competitions / month', link: '/exports/withdrawals-from-competitions', datepickers: true},
    { title: '# of Player accounts that have registered to at least one competition', link: '/exports/players-with-minimum-unique-registration', datepickers: true},
    { title: '# of new Player signups (accounts created) / month', link: '/exports/new-accounts-created-by-month', datepickers: true},
    { title: '# of Premium Subscriptions sold / type / month', link: '/exports/premium-memberships-sold-by-month', datepickers: true},
    { title: '# of Player accounts with an active/valid Premium subscription at this moment', link: '/exports/players-with-active-premium-membership'},
    { title: '# of new Player signups', link: '/exports/new-accounts-created', datepickers: true},
    { title: '# of Players with signups (account created) that registered to at least one competition within the same timeframe', link: '/exports/new-accounts-created-with-event-registration', datepickers: true},
    { title: '# of Player accounts that have registered to at least one competition / month', link: '/exports/players-with-minimum-unique-registration-by-month', datepickers: true},
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Extended Analytics and Reports</h4>
          <Row className="pb-24">
            <Col lg="3">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-0">Chats</p>
                  <h4>{chats.countConversations}</h4>
                  <p className="mb-0">Group Chats</p>
                  <h4>{chats.countGroupConversations}</h4>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-0">Total Raised Hands</p>
                  <h4>{accounts.countRaisedHands}</h4>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="h-100">
                <CardBody>
                  <Row>
                    <Col className="d-flex justify-content-center align-items-center flex-column">
                      <p className="mb-0">Raised Hands Singles</p>
                      {Object.keys(accounts.raisedHands?.singles).map(key => (<div>{`Level ${key}: ${accounts.raisedHands?.singles[key]}`}</div>))}
                      <div><strong>Total: {Object.values(accounts.raisedHands?.singles).reduce((a, b) => a + b, 0)}</strong></div>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center flex-column">
                      <p className="mb-0">Raised Hands Doubles</p>
                      {Object.keys(accounts.raisedHands?.doubles).map(key => (<div>{`Level ${key}: ${accounts.raisedHands?.doubles[key]}`}</div>))}
                      <div><strong>Total: {Object.values(accounts.raisedHands?.doubles).reduce((a, b) => a + b, 0)}</strong></div>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center flex-column">
                      <p className="mb-0">Raised Hands Both Types</p>
                      {Object.keys(accounts.raisedHands?.both).map(key => (<div>{`Level ${key}: ${accounts.raisedHands?.both[key]}`}</div>))}
                      <div><strong>Total: {Object.values(accounts.raisedHands?.both).reduce((a, b) => a + b, 0)}</strong></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="pb-24">
            <Col lg="6">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-0 ml-60">Total Friendly Matches</p>
                  <ul>
                    <li>Created: {friendlyMatches.created}
                      <ul>
                        <li>Match Invite Ranked: {friendlyMatches.rankedInvites}</li>
                        <li>Match Invite Unranked: {friendlyMatches.unrankedInvites}</li>
                        <li>Played Match Ranked: {friendlyMatches.rankedPlayedMatches}</li>
                        <li>Played Match Unranked: {friendlyMatches.unrankedPlayedMatches}</li>
                      </ul>
                    </li>
                    <li>Active: {friendlyMatches.active}</li>
                    <li>Completed: {friendlyMatches.completed}</li>
                    <li>No match: {friendlyMatches.noMatch}</li>
                    <li>Canceled: {friendlyMatches.canceled}</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card className="h-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <ul>
                    <li>
                      <li>Ratings</li>
                      <ul>
                        <li>Active: {ratings.active ?? 0}</li>
                        <li>Pending: {ratings.pending ?? 0}</li>
                        <li>Removed: {ratings.deleted ?? 0}</li>
                      </ul>
                    </li>
                    <li>Rated players: { ratings.totalRatedPlayers }
                      <ul>
                        <li>5 stars: {ratings.ratedPlayers?.[5]}</li>
                        <li>4 stars: {ratings.ratedPlayers?.[4]}</li>
                        <li>3 stars: {ratings.ratedPlayers?.[3]}</li>
                        <li>2 stars: {ratings.ratedPlayers?.[2]}</li>
                        <li>1 stars: {ratings.ratedPlayers?.[1]}</li>
                      </ul>
                    </li>
                    <li>Players with public ratings: {ratings.publicRatings ?? 0}</li>
                    <li>Players who rated: {ratings.raters ?? 0}</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="pb-24">
            <Col>
              <Card className="h-100">
                <CardBody className="d-flex align-items-center flex-column mt-3">
                  <table className="table table-bordered">
                    <thead>
                      <tr><th></th><th>RO</th><th>BG</th><th>HU</th><th>MD</th><th>CZ</th><th>BH</th></tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Accounts Referred</td>
                        <td>{accounts.accountsReferred?.Romania}</td>
                        <td>{accounts.accountsReferred?.Bulgaria}</td>
                        <td>{accounts.accountsReferred?.Hungary}</td>
                        <td>{accounts.accountsReferred?.Moldova}</td>
                        <td>{accounts.accountsReferred?.Czechia}</td>
                        <td>{accounts.accountsReferred?.Bahrain}</td>
                      </tr>
                      <tr>
                        <td>Players with Friends</td>
                        <td>{accounts.playersWithFriends?.Romania}</td>
                        <td>{accounts.playersWithFriends?.Bulgaria}</td>
                        <td>{accounts.playersWithFriends?.Hungary}</td>
                        <td>{accounts.playersWithFriends?.Moldova}</td>
                        <td>{accounts.playersWithFriends?.Czechia}</td>
                        <td>{accounts.playersWithFriends?.Bahrain}</td>
                      </tr>
                      <tr>
                        <td>Average Network Size</td>
                        <td>{accounts.avgNetwork?.Romania}</td>
                        <td>{accounts.avgNetwork?.Bulgaria}</td>
                        <td>{accounts.avgNetwork?.Hungary}</td>
                        <td>{accounts.avgNetwork?.Moldova}</td>
                        <td>{accounts.avgNetwork?.Czechia}</td>
                        <td>{accounts.avgNetwork?.Bahrain}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="h-100">
                <CardBody className="d-flex justify-content-center align-items-center flex-column">
                  <table className="table table-bordered">
                    <thead>
                      <tr><th></th><th>RON</th><th>BGN</th><th>HUF</th><th>CZK</th><th>BHD</th></tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Players w. Completed Rewards</td>
                        <td>{(rewards.playersOwnRewards.RON || 0) + (rewards.playersInvitationRewards.RON || 0)}</td>
                        <td>{(rewards.playersOwnRewards.BGN || 0) + (rewards.playersInvitationRewards.BGN || 0)}</td>
                        <td>{(rewards.playersOwnRewards.HUF || 0) + (rewards.playersInvitationRewards.HUF || 0)}</td>
                        <td>{(rewards.playersOwnRewards.CZK || 0) + (rewards.playersInvitationRewards.CZK || 0)}</td>
                        <td>{(rewards.playersOwnRewards.BDH || 0) + (rewards.playersInvitationRewards.BHD || 0)}</td>
                      </tr>
                      <tr>
                        <td><li>Players w. own rewards</li></td>
                        <td>{rewards.playersOwnRewards.RON}</td>
                        <td>{rewards.playersOwnRewards.BGN}</td>
                        <td>{rewards.playersOwnRewards.HUF}</td>
                        <td>{rewards.playersOwnRewards.CZK}</td>
                        <td>{rewards.playersOwnRewards.BHD}</td>
                      </tr>
                      <tr>
                        <td><li>Players w. invitation rewards</li></td>
                        <td>{rewards.playersInvitationRewards.RON}</td>
                        <td>{rewards.playersInvitationRewards.BGN}</td>
                        <td>{rewards.playersInvitationRewards.HUF}</td>
                        <td>{rewards.playersInvitationRewards.CZK}</td>
                        <td>{rewards.playersInvitationRewards.BHD}</td>
                      </tr>
                      <tr>
                        <td>Total Rewards #</td>
                        <td>{(rewards.ownRewards.RON || 0) + (rewards.invitationRewards.RON || 0)}</td>
                        <td>{(rewards.ownRewards.BGN || 0) + (rewards.invitationRewards.BGN || 0)}</td>
                        <td>{(rewards.ownRewards.HUF || 0) + (rewards.invitationRewards.HUF || 0)}</td>
                        <td>{(rewards.ownRewards.CZK || 0) + (rewards.invitationRewards.CZK || 0)}</td>
                        <td>{(rewards.ownRewards.BHD || 0) + (rewards.invitationRewards.BHD || 0)}</td>
                      </tr>
                      <tr>
                        <td><li>Own rewards</li></td>
                        <td>{rewards.ownRewards.RON}</td>
                        <td>{rewards.ownRewards.BGN}</td>
                        <td>{rewards.ownRewards.HUF}</td>
                        <td>{rewards.ownRewards.CZK}</td>
                        <td>{rewards.ownRewards.BHD}</td>
                      </tr>
                      <tr>
                        <td><li>Invitation rewards</li></td>
                        <td>{rewards.invitationRewards.RON}</td>
                        <td>{rewards.invitationRewards.BGN}</td>
                        <td>{rewards.invitationRewards.HUF}</td>
                        <td>{rewards.invitationRewards.CZK}</td>
                        <td>{rewards.invitationRewards.BHD}</td>
                      </tr>
                      <tr>
                        <td>Average Rewarded Network Size</td>
                        <td>{(rewards.playersInvitationRewards.RON ? rewards.invitationRewards.RON / rewards.playersInvitationRewards.RON : 0).toFixed(2)}</td>
                        <td>{(rewards.playersInvitationRewards.BGN ? rewards.invitationRewards.BGN / rewards.playersInvitationRewards.BGN : 0).toFixed(2)}</td>
                        <td>{(rewards.playersInvitationRewards.HUF ? rewards.invitationRewards.HUF / rewards.playersInvitationRewards.HUF : 0).toFixed(2)}</td>
                        <td>{(rewards.playersInvitationRewards.CZK ? rewards.invitationRewards.CZK / rewards.playersInvitationRewards.CZK : 0).toFixed(2)}</td>
                        <td>{(rewards.playersInvitationRewards.BHD ? rewards.invitationRewards.BHD / rewards.playersInvitationRewards.BHD : 0).toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>
                    Exports
                  </CardTitle>
                  {reports.map(report => <Export title={report.title} link={report.link} datepickers={report.datepickers} />)}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExtendedAnalytics
