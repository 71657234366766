import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { Link } from "react-router-dom"
import moment from "moment"

const listColumns = role => [
  {
    dataField: "paymentId",
    text: "Order ID",
    formatter: (cellContent, transaction) => (
      <strong>{transaction.paymentId}</strong>
    ),
  },
  {
    dataField: "createdAt",
    text: "Date",
    sort: true,
    formatter: (cellContent, transaction) => {
      return (
        <div className="d-flex flex-column align-items-center">
          <p className="mb-0">
            {moment(transaction.createdAt).format("YYYY-MM-DD")}
          </p>
          <p className="mb-0">
            {moment(transaction.createdAt).format("HH:mm:ss")}
          </p>
        </div>
      )
    },
  },
  {
    dataField: "type",
    text: "Order type",
    formatter: (col, row) => {
      return <span className="contentEllipsis">{col}</span>
    },
  },
  {
    dataField: "",
    text: "Player Name",
    formatter: (cellContent, transaction) => {
      return (
        <Link to={`/accounts/players/${transaction?.user?.id}`}>
          {transaction?.user?.firstName} {transaction?.user?.lastName}
          {" - "}
          {transaction?.user?.userId}
        </Link>
      )
    },
  },
  {
    dataField: "admin",
    text: "Owner",
    formatter: (cellContent, order) => {
      return (
        <p className="mb-0">
          {cellContent?.id ? (
            <Link to={`/accounts/admins/${cellContent?.id}`}>
              {cellContent?.userId}
            </Link>
          ) : (
            "-"
          )}
        </p>
      )
    },
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    formatter: (cellContent, transaction) => (
      <>
        <span>{transaction.amount + " " + transaction.currency}</span>
      </>
    ),
  },
  {
    dataField: "paymentType",
    text: "Payment method",
  },
  {
    dataField: "competitionId",
    text: "Competition ID",
    formatter: (cellContent, transaction) => {
      return transaction.competitionId ? (
        <Link
          to={`/events/${transaction.eventId}/competitions/${transaction.competitionId}`}
        >
          {transaction.competitionId}
        </Link>
      ) : (
        <>-</>
      )
    },
  },
  {
    dataField: "eventName",
    text: "Order details",
    formatter: (cellContent, transaction) => {
      return transaction?.products.length ? (
        <>
          <p>Products:</p>
          {transaction?.products?.map((prod, key) => (
            transaction.eventId 
            ?  <p>
                <Link key={key} to={`/events/${transaction.eventId}/details`}>
                {prod.name}
                {prod.name &&
                prod.price &&
                ` - ${prod.price} ${transaction.currency}`}
                </Link>
              </p> 
            : <p>
                {prod.name}
                {prod.name &&
                prod.price &&
                ` - ${prod.price} ${transaction.currency}`}
              </p>
          ))}
        </>
      ) : (
        <>-</>
      )
    },
  },
]

export default listColumns
