import { get } from "helpers/api_helper"
import toastr from "toastr"

export const getOrganizersLocations = async filter => {
  let response
  try {
    const filters = new URLSearchParams(Object.assign(filter)).toString()
    response = await get(`/accounts/locations?isOrganizer=true&${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getOrganizers = async (filter, options) => {
  let response
  try {
    const filters = new URLSearchParams(
      Object.assign(filter, options)
    ).toString()
    response = await get(`/accounts?isOrganizer=true&role=player,club&${filters}`)
  } catch (error) {
    response = error.response.data
  }
  return response
}

export const getOrganizerInfo = async id => {
  let response
  try {
    response = await get(`/accounts/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}

export const getOwnerClub = async id => {
  let response
  try {
    response = await get(`/clubs/owners/${id}`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}

// export const getOwnerClub = async id => {
//   let response
//   try {
//     response = await get(`/clubs/owners/${id}`)
export const getTournaments = async id => {
  let response
  try {
    response = await get(`/organizers/${id}/events`)
  } catch (error) {
    toastr.error(error.response.data?.message)

    response = error.response.data
  }
  return response
}
