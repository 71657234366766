import React, { useContext, useEffect, useState } from "react"
import { Container } from "reactstrap"
import toastr from "toastr"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { createPlayer } from "./api"
import FormData from "./FormData"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getSettings } from "../../Authentication/actions"
import { store as socketStore } from "../../../components/Common/Socket/store"
import { isEmpty } from "lodash"

const CreatePlayer = ({ settings, history }) => {
  const [isLoading, setIsLoading] = useState(false)
  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message)
        setIsLoading(false)
        return
      }

      toastr.success(message)
      history.goBack()
    }

    if (socket) {
      socket.removeAllListeners("accounts-response")
      socket.on("accounts-response", listenForResponse)

      return () => {
        socket.removeAllListeners("accounts-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  const handleSubmit = async (event, values) => {
    setIsLoading(true)

    // set location
    values.location = values.location.value

    // set membership
    if (isEmpty(values.membership?.id)) {
      values.membership.id = null
    }

    const response = await createPlayer({
      socketClientId,
      ...values,
    })

    if (!response.success) {
      toastr.error(response.message || "Account could not be created.")
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Players"
            path="/accounts/players"
            breadcrumbItem="Create player"
          />

          <FormData
            handleSubmit={handleSubmit}
            settings={settings}
            history={history}
            loading={isLoading}
            eligibleCountries={settings?.eligibleCountries}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ session }) => ({
  settings: session.settings,
})

const mapDispatchToProps = dispatch => ({
  getSettings: () => dispatch(getSettings),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatePlayer))
