export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"

export const GET_ADMIN_INFO = "GET_ADMIN_INFO"
export const GET_ADMIN_INFO_SUCCESS = "GET_ADMIN_INFO_SUCCESS"

export const CHANGE_ADMINS_FILTERS = "CHANGE_ADMINS_FILTERS"

export const API_ERROR = "CLUBS_API_ERROR"

