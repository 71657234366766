import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import { Container, Spinner } from "reactstrap"
import toastr from "toastr"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import FormData from "./FormData"

import { getPlayerInfo } from "./actions"
import { updatePlayer } from "./api"
import { getSettings } from "../../Authentication/actions"
import { store as socketStore } from "../../../components/Common/Socket/store"

const EditPlayer = props => {
  const {
    getPlayerInfo,
    playerInfo,
    getSettings,
    settings,
    loading,
    match: { params },
    history,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const globalState = useContext(socketStore)
  const {
    state: { socket: { socketClientId, socket } = {} } = {},
  } = globalState

  useEffect(() => {
    const listenForResponse = async ({ success, message }) => {
      if (!success) {
        toastr.error(message)
        setIsLoading(false)
        return
      }

      toastr.success(message)
      history.goBack()
    }

    if (socket) {
      socket.removeAllListeners("accounts-response")
      socket.on("accounts-response", listenForResponse)

      return () => {
        socket.removeAllListeners("accounts-response")
      }
    }
  }, [socketClientId]) // eslint-disable-line

  const handleSubmit = async (event, values) => {
    setIsLoading(true)
    // set location
    values.location = values?.location?.value

    // set organizer location
    if (!isEmpty(values.organizerInfo?.location)) {
      values.organizerInfo.location = values.organizerInfo.location.value
    }

    if (!playerInfo) {
      // set membership
      if (isEmpty(values.membership?.id)) {
        values.membership.id = null
      } else {
        const [membership] = settings.memberships.filter(
          v => v.id === values.membership.id
        )
        values.membership = membership
        values.membership.plan = membership.type
      }
    }

    const { communicationPreferences } = values

    const response = await updatePlayer(params.id, {
      socketClientId,
      communicationPreferences: {
        dailyLimit: 10,
        newsletter: formatvalue(communicationPreferences?.newsletter),
        partnerMatches: formatvalue(communicationPreferences?.partnerMatches),
        playersTournaments: formatvalue(
          communicationPreferences?.playersTournaments
        ),
        privateMessages: formatvalue(communicationPreferences?.privateMessages),
        siteInbox: formatvalue(communicationPreferences?.siteInbox),
      },
      ...values,
    })

    if (!response.success) {
      toastr.error(response.message || "Account could not be created.")
      setIsLoading(false)
    }
  }

  const formatvalue = str => {
    if (str === "true" && typeof str === "string") {
      return true
    }
    if (str === "false" && typeof str === "string") {
      return false
    } else {
      return str
    }
  }

  useEffect(() => {
    getPlayerInfo(params.id)
  }, [getPlayerInfo, getSettings, params.id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Players"
            path="/accounts/players"
            breadcrumbItem="Edit player"
            displayTotal={`#${playerInfo?.userId}`}
          />
          {loading || (isEmpty(playerInfo) && !isEmpty(params.id)) ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <FormData
              handleSubmit={handleSubmit}
              playerInfo={playerInfo}
              settings={settings}
              history={history}
              loading={isLoading}
              eligibleCountries={settings?.eligibleCountries}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

EditPlayer.propTypes = {
  playerInfo: PropTypes.object,
  getPlayerInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ players, session }) => ({
  loading: players.loading,
  playerInfo: players.playerInfo,
  settings: session.settings,
})

const mapDispatchToProps = dispatch => ({
  getPlayerInfo: id => dispatch(getPlayerInfo(id)),
  getSettings: () => dispatch(getSettings),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditPlayer))
