import { Badge } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"

const formatValidity = productName => {
  let validity = productName.split("-")[1];
  return validity.substring(0, validity.indexOf("Membership")) + "M";
}

const membershipColumns = plans => [
  {
    dataField: "createdAt",
    text: "Add Date",
    formatter: cellContent => (
      <>{moment(cellContent).format("YYYY-MM-DD HH:mm")}</>
    ),
  },
  {
    dataField: "membershipType",
    text: "Membership Type",
    formatter: (cellContent, order) => (
      <>
        {order?.products[0]?.name === "summerMembership" ? (
          <Badge color="warning">
            <p className="p-2 mb-0 font-weight-bold">Summer</p>
          </Badge>
        ) : order?.products[0]?.name === "undefinedMembership" ? (
          <Badge color="success">
            <p className="p-2 mb-0 font-weight-bold">Free</p>
          </Badge>
        ) : (
          <Badge color="dark">
            <p className="p-2 mb-0 font-weight-bold">Premium</p>
          </Badge>
        )}
      </>
    ),
  },
  {
    dataField: "validity",
    text: "Validity",
    formatter: (cellContent, order) => (
      <Badge color="success">
        <p className="mb-0 p-2">
          {order?.products[0]?.name?.split("-")?.length > 1
            ? formatValidity(order.products[0].name)
            : "-"}
        </p>
      </Badge>
    ),
  },
  {
    dataField: "expirationDate",
    text: "Expiration Date",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        {moment.utc(order?.products[0]?.validUntil).format("YYYY-MM-DD HH:mm")}
      </p>
    ),
  },
  {
    dataField: "payment",
    text: "Payment",
    formatter: (cellContent, order) => (
      <p className="mb-0">
        {order?.paymentType === "creditCard"
          ? "Online"
          : order?.paymentType === "virtualWallet"
          ? "Virtual Wallet"
          : "Admin"}
      </p>
    ),
  },
  {
    dataField: "admin",
    text: "Owner",
    formatter: (cellContent, order) => {
      return (
        <p className="mb-0">
          {cellContent?.id ? (
            <Link to={`/accounts/admins/${cellContent?.id}`}>
              {" "}
              {cellContent?.firstName} {cellContent?.lastName}
              {" - "}
              {cellContent?.userId}
            </Link>
          ) : (
            "Player"
          )}
        </p>
      )
    },
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (cellContent, order) => (
      <Badge color="success">
        <p className="mb-0 p-2">
          {order?.amount} {order?.currency}
        </p>
      </Badge>
    ),
  },
  {
    dataField: "orderId",
    text: "Order Id",
    formatter: (cellContent, order) => <p className="mb-0">{order?.id}</p>,
  },
]

export default membershipColumns
