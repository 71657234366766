import { GET_PENALTIES, GET_PENALTIES_SUCCESS, API_ERROR } from "./actionTypes"

const initialState = {
  error: "",
  penalties: [],
  loading: false,
}

const penalties = (state = initialState, action) => {
  switch (action.type) {
    case GET_PENALTIES:
      state = {
        ...state,
        loading: true,
      }

    case GET_PENALTIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        penalties: action.payload,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default penalties
