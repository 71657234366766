import { get, post, put, del, patch } from "helpers/api_helper"

export const getPenalties = async payload => {
  let response

  const pageOptions = payload?.payload[0]
  const filterOptions = payload?.payload[1]

  const { drawType, penaltyType } = filterOptions

  try {
    response = await get(
      `/penalties?search=${filterOptions?.search}&limit=${pageOptions?.limit}&page=${pageOptions?.page}&drawType=${drawType}&type=${penaltyType}`
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getEventsLocationsApi = async (filters, time) => {
  let response
  try {
    const allFilters = new URLSearchParams(Object.assign(filters)).toString()
    response = await get(`/events/locations?${allFilters}&timeframe=${time}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getEvents = async (filters, options, time) => {
  let response

  try {
    var obj1 = JSON.parse(filters)
    var obj2 = JSON.parse(options)
    obj2 = {
      page: obj2?.page,
      limit: time === "current" ? 100 : 15,
    }
    const filterParams = new URLSearchParams(
      Object.assign(obj1, obj2)
    ).toString()
    response = await get(`/events?${filterParams}&timeframe=${time}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const getEventDetails = async id => {
  let response
  try {
    response = await get(`/events/${id}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const createEvent = async data => {
  let response
  try {
    response = await post("/admin/events", data)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const updateEventStatus = async (id, type, socketClientId) => {
  let response
  try {
    response = await put(`/admin/events/${id}/status/${type}`, {
      socketClientId,
    })
  } catch (error) {
    console.log(error)
  }
  return response
}

export const deleteEvent = async id => {
  let response
  try {
    response = await del(`/admin/events/${id}`)
  } catch (error) {
    console.log(error)
  }
  return response
}

export const createCompetition = async ({ id, ...data }) => {
  let response
  try {
    response = await post(`/admin/events/${id}/competitions`, data)
  } catch (error) {
    console.log(error)
  }

  return response
}

export const deleteCompetition = async (id, competitionId, socketClientId) => {
  let response

  try {
    response = await del(
      `/admin/events/${id}/competitions/${competitionId}/status`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const enableCompetition = async (
  id,
  competitionId,
  type,
  socketClientId
) => {
  let response

  try {
    response = await patch(
      `/admin/events/${id}/competitions/${competitionId}/status/${type}`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const increaseWildeCards = async ({
  id,
  socketClientId,
  competitionId,
}) => {
  let response

  try {
    response = await patch(
      `/admin/events/${id}/competitions/${competitionId}/wildCards/plus`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const decreaseWildeCards = async ({
  id,
  socketClientId,
  competitionId,
}) => {
  let response

  try {
    response = await patch(
      `/admin/events/${id}/competitions/${competitionId}/wildCards/minus`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const editCompetition = async ({ id, competitionId, ...data }) => {
  let response

  try {
    response = await patch(
      `/admin/events/${id}/competitions/${competitionId}`,
      data
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const addEntry = async ({
  preRegistrationList,
  competitionId,
  id,
  socketClientId,
  wild,
  player: { data: player } = {},
  partner: { data: partner } = {},
}) => {
  let response

  let slotType = wild ? "WILD_CARD" : "STANDARD"

  try {
    response = await post(
      `/admin/events/${id}/competitions/${competitionId}/${
        preRegistrationList ? "preEntries" : `entries/${slotType}`
      }`,
      { player, partner, socketClientId }
    )
  } catch (error) {
    console.log(error)
  }
  return response
}

export const deleteEntry = async ({
  competitionId,
  eventId,
  socketClientId,
  entryId,
}) => {
  let response
  try {
    response = await del(
      `/admin/events/${eventId}/competitions/${competitionId}/entries/${entryId}`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const moveEntry = async ({
  competitionId,
  eventId,
  entryId,
  list,
  socketClientId,
}) => {
  let response

  try {
    response = await put(
      `/admin/events/${eventId}/competitions/${competitionId}/entries/${entryId}/${list}`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const setLuckyDraw = async ({
  competitionId,
  eventId,
  socketClientId,
  phaseId,
}) => {
  let response

  try {
    response = await post(
      `/admin/events/${eventId}/competitions/${competitionId}/draw${
        phaseId ? `/${phaseId}` : ""
      }`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const setMainDraw = async ({
  competitionId,
  eventId,
  socketClientId,
  phaseId,
}) => {
  let response

  try {
    response = await post(
      `/admin/events/${eventId}/competitions/${competitionId}/mainDraw${
        phaseId ? `/${phaseId}` : ""
      }`,
      { socketClientId }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}

export const updateLuckyDraw = async ({
  competitionId,
  eventId,
  socketClientId,
  ...details
}) => {
  let response

  try {
    response = await patch(
      `/admin/events/${eventId}/competitions/${competitionId}/draw`,
      { socketClientId, ...details }
    )
  } catch (error) {
    console.log(error)
  }

  return response
}
