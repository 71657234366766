import React, { useContext } from "react"
import { useToasts } from "react-toast-notifications"

import { store as socketStore } from "components/Common/Socket/store"
import ListenerComponent from "components/Common/Socket/ListenerComponent"

import { stateToHTML } from "draft-js-export-html"
import { EditorState, convertFromRaw } from "draft-js"

import { Link } from "react-router-dom"

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Card,
  CardBody,
  Row,
  Badge,
  Col,
} from "reactstrap"

import moment from "moment"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

import { connect } from "react-redux"

import { deleteAnnouncement, changeStatus } from "../../Tabs/Competitions/api"

import * as actions from "../../../actions"

const NoAnnouncements = () => {
  return <p className="mb-0">No announcements yet</p>
}

const Announcements = ({
  socketClientId,
  eventDetails,
  getEventDetails,
}) => {
  const allActiveCompetitions = eventDetails?.competitions?.reduce((filtered, c) => {
    if (!['MAINTENANCE', 'DISABLED', 'DELETED'].includes(c.status)) {
      filtered.push(c.competitionId);
    }
    return filtered;
  }, []);
  const listColumns = () => [
    {
      dataField: "Title",
      text: "Title",
      formatter: (cellContent, data) => (
        <>
          <Link to={`/add-announcement/${eventDetails?._id}/${data?._id}`}>
            <h6 className="mb-0">{data.title || "-"}</h6>
          </Link>
        </>
      ),
    },
    {
      dataField: "Message preview",
      text: "Message preview",
      formatter: (cellContent, data) => {
        let editorContent
        if (!data?.message) return "-"

        if (data?.message) {
          const initState = convertFromRaw(JSON.parse(data?.message))
          editorContent = EditorState.createWithContent(initState)
        }

        return (
          <>
            <div
              className="ellips"
              dangerouslySetInnerHTML={{
                __html: stateToHTML(editorContent.getCurrentContent()),
              }}
            />
          </>
        )
      },
    },
    {
      dataField: "Status",
      text: "Status",
      formatter: (cellContent, data) => (
        <h6 className="mb-0">
          {data?.status === "PENDING" || data?.status === "ENABLED"
            ? "ENABLED"
            : "DISABLED"}
        </h6>
      ),
    },
    {
      dataField: "Notification",
      text: <p className="mb-0 text-center">Notification</p>,
      formatter: (cellContent, user) => (
        <div className="d-flex justify-content-center">
          <span className={`mb-0 p-1${user?.notifications ? ' text-success' : ' text-danger'}`}>{user?.notifications ? "Yes" : "No"}</span>
          <span className="mb-0 p-1">
            {user?.notifications ? `${user?.competitions?.length ? allActiveCompetitions.every(c => user?.competitions?.includes(c)) ? `-\xa0\xa0All` :  user?.competitions?.map((announcementCompetitionId) => {
            const competitionDetails = eventDetails.competitions.find(competition => competition.competitionId === announcementCompetitionId);
            return `${competitionDetails?.level}${competitionDetails?.gameType === 'singles' ? 'S' : 'D'} (#${competitionDetails?.competitionId})`;
          }).join(', ') : ''}` : ''}
          </span>
        </div>
      ),
    },
    {
      dataField: "Date added",
      text: "Date added",
      formatter: (cellContent, user) => (
        <h6 className="mb-0">
          {moment(user?.createdAt).format("YYYY-MM-DD HH:mm a")}
        </h6>
      ),
    },

    {
      dataField: "By user",
      text: "By user",
      formatter: (cellContent, user) => (
        <h6 className="mb-0">{user.createdBy || "-"}</h6>
      ),
    },
    {
      dataField: "Actions",
      text: "Actions",
      formatter: (cellContent, data) => {
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle href="#" className="card-drop" tag="i">
                <i className="mdi mdi-dots-horizontal font-size-18" />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="text-center p-0" target="_blank">
                  <Link
                    className="d-block dropdown-item p-0"
                    to={`/add-announcement/${eventDetails?._id}/${data._id}`}
                  >
                    Edit
                  </Link>
                </DropdownItem>
                <DropdownItem className="text-center p-0">
                  <Link
                    onClick={async () => {
                      await deleteAnnouncement(
                        eventDetails?._id,
                        data._id,
                        socketClientId
                      )
                      await getEventDetails(eventDetails?._id)
                    }}
                    className="d-block dropdown-item p-0"
                  >
                    Remove
                  </Link>
                </DropdownItem>
                <DropdownItem className="text-center p-0">
                  <Link
                    onClick={async () => {
                      await changeStatus(
                        eventDetails?._id,
                        data._id,
                        data.status === "PENDING" || data.status === "ENABLED"
                          ? "disable"
                          : "enable",
                        socketClientId
                      )
                      await getEventDetails(eventDetails?._id)
                    }}
                    className="d-block dropdown-item p-0"
                  >
                    {data.status === "DISABLED" ? "Enable" : "Disable"}
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        )
      },
    },
  ]

  const rowClasses = (row, rowIndex) => {
    if (row?.status === "DISABLED") {
      return "disabledRow"
    }
  }

  return (
    <ToolkitProvider
      keyField="id"
      data={[...eventDetails.announcements].reverse()}
      columns={listColumns()}
      bootstrap4
    >
      {toolkitProps => (
        <div className="table-responsive">
          <BootstrapTable
            responsive
            remote
            bordered={false}
            striped={false}
            rowClasses={rowClasses}
            classes={"table table align-middle table-nowrap table-hover"}
            headerWrapperClasses={"thead-light"}
            {...toolkitProps.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  )
}

const Announcement = ({ events, getEventDetails }) => {
  const { eventDetails } = events
  const { addToast } = useToasts()
  const globalState = useContext(socketStore)
  const { state: { socket: { socketClientId } = {} } = {} } = globalState

  const announcements = eventDetails[0] || []

  const listenForResponse = async ({ success, message }) => {
    if (!success) {
      return addToast(message, {
        appearance: "error",
        autoDismiss: true,
      })
    }
    return addToast(message, {
      appearance: "success",
      autoDismiss: true,
    })
  }

  return (
    <Card className="mt-2">
      <ListenerComponent
        listenFunction={listenForResponse}
        topic="events-response"
      />
      <CardBody>
        <Row className="mb-3">
          <Col>
            <h1>Announcements</h1>
          </Col>
          <Col>
            <div className="text-sm-right">
              <Link
                to={`/add-announcement/${announcements._id}`}
                className="btn-rounded waves-effect waves-light mb-2  btn btn-success"
              >
                <i className="mdi mdi-plus mr-1" />
                Add Announcement
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {!announcements?.announcements?.length ? (
              <NoAnnouncements />
            ) : (
              <Announcements
                socketClientId={socketClientId}
                getEventDetails={getEventDetails}
                eventDetails={announcements}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const mapStatetoProps = ({ events: events = [] }) => ({
  events,
})

export default connect(mapStatetoProps, {
  getEventDetails: actions.getEventDetails,
})(Announcement)
