import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import SetPassword from "../pages/Authentication/SetPassword"

// Communication
import Chat from "../pages/Chat/Chat"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Player
import PlayerAccounts from "../pages/UserAccounts/PlayerAccounts/index"
import EditPlayer from "../pages/UserAccounts/PlayerAccounts/editAccount"
import CreatePlayer from "../pages/UserAccounts/PlayerAccounts/createAccount"
import PlayerProfile from "../pages/UserAccounts/PlayerAccounts/UserProfile"

// Clubs
import ClubAccounts from "../pages/UserAccounts/ClubAccounts/index"
import EditClub from "../pages/UserAccounts/ClubAccounts/editClub"
import CreateClub from "../pages/UserAccounts/ClubAccounts/createClub"
import ClubProfile from "../pages/UserAccounts/ClubAccounts/ClubProfile"

// Organizers
import OrganizerAccounts from "../pages/UserAccounts/OrganizerAccounts/index"
import OrganizerProfile from "../pages/UserAccounts/OrganizerAccounts/ProfileInfo"
// import EditOrganizer from "../pages/UserAccounts/OrganizerAccounts/editAccount"
// import CreateOrganizer from "../pages/UserAccounts/OrganizerAccounts/createAccount"

import ClubBusinessLeads from "../pages/UserAccounts/ClubBusinessLeads/index"
import ClubBusinessLeadsInfo from "../pages/UserAccounts/ClubBusinessLeads/clubLeadInfo"

// Admins
import AdminAccounts from "../pages/UserAccounts/PlatformAdmins/index"
import AdminProfile from "../pages/UserAccounts/PlatformAdmins/adminProfile"
import CreateAdmin from "../pages/UserAccounts/PlatformAdmins/createAdmin"
import EditAdmin from "../pages/UserAccounts/PlatformAdmins/editAdmin"

import EventsManagement from "../pages/EventsManagement/index"
import BookingsManagement from "../pages/BookingsManagement"
import PaymentsManagement from "../pages/PaymentsManagement/index"
import ExtendedAnalytics from "../pages/ExtendedAnalytics/index"
import PenaltiesManagement from "../pages/PenaltiesManagement/index"
import ReportsManagement from "../pages/ReportsManagement"
import PromoZones from "../pages/PromoZones/index"
import LeaderboardsManagement from "../pages/LeaderboardsManagement/index"
import BusinessModels from "../pages/BusinessModels/index"
import SystemSettings from "../pages/SystemSettings/index"
import ResultsScores from "../pages/ResultsScores/index"
import Event from "pages/EventsManagement/components/Event"
import Competition from "../pages/EventsManagement/components/Tabs/Competitions/Competition"
import AddAnouncement from "../pages/EventsManagement/components/Tabs/Competitions/AddAnouncement"
import PhaseDetails from "../pages/EventsManagement/components/Tabs/Competitions/Tabs/Phases/Components/Details"
import PhasesDetails from "pages/EventsManagement/components/Tabs/Competitions/Tabs/Phases/Components"
import AddPenalties from "pages/EventsManagement/components/Tabs/Competitions/Tabs/Penalties/AddPenalties"
import FriendlyMatches from "pages/FriendlyMatches"
import FriendlyMatchDetails from "pages/FriendlyMatches/Details"
import BookingDetails from "../pages/BookingsManagement/components/BookingInfo";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // players
  { path: "/accounts/players", component: PlayerAccounts },
  { path: "/accounts/players/create", component: CreatePlayer },
  { path: "/accounts/players/:id", component: PlayerProfile },
  { path: "/accounts/players/:id/edit", component: EditPlayer },

  // clubs
  { path: "/accounts/clubs", component: ClubAccounts },
  { path: "/accounts/clubs/create", component: CreateClub },
  { path: "/accounts/clubs/create/:id", component: CreateClub },
  { path: "/accounts/clubs/:id", component: ClubProfile },
  { path: "/accounts/clubs/:id/edit", component: EditClub },

  // clubs leads
  { path: "/accounts/clubs-leads", component: ClubBusinessLeads },
  { path: "/accounts/clubs-leads/:id", component: ClubBusinessLeadsInfo },

  // organizers
  { path: "/accounts/organizers", component: OrganizerAccounts },
  { path: "/accounts/organizers/:id", component: OrganizerProfile },
  // { path: "/accounts/organizers/create", component: CreateOrganizer },
  // { path: "/accounts/organizers/:id/edit", component: EditOrganizer },

  // admins
  { path: "/accounts/admins", component: AdminAccounts },
  { path: "/accounts/admins/create", component: CreateAdmin },
  { path: "/accounts/admins/:id", component: AdminProfile },
  { path: "/accounts/admins/:id/edit", component: EditAdmin },

  { path: "/events-management", component: EventsManagement },
  { path: "/events/:id?/:tab?", component: Event },

  // Phase details
  {
    path: "/events/:id?/competitions/:competitionId?/phases/:phaseId/:tab?",
    component: PhasesDetails,
  },
  {
    path: "/events/:id?/competitions/:competitionId?/add-phase",
    component: PhaseDetails,
  },
  {
    path: "/events/:id?/competitions/:competitionId?/add-penalties",
    component: AddPenalties,
  },
  {
    path: "/events/:id?/competitions/:competitionId?/:penaltyId?/add-penalties",
    component: AddPenalties,
  },

  // edit competition
  {
    path: "/events/:id?/competitions/:competitionId?/:tab?",
    component: Competition,
  },

  { path: "/add/:id?/competition", component: Competition },
  { path: "/add-announcement/:eventId?", component: AddAnouncement },
  {
    path: "/add-announcement/:eventId?/:announcementId?",
    component: AddAnouncement,
  },
  { path: "/bookings-management", component: BookingsManagement },
  { path: "/bookings/:id", component: BookingDetails },
  { path: "/payments-management", component: PaymentsManagement },
  { path: "/extended-analytics", component: ExtendedAnalytics },
  { path: "/penalties-management", component: PenaltiesManagement },
  { path: "/reports-management", component: ReportsManagement },
  { path: "/promo-zones", component: PromoZones },
  { path: "/rankings-management", component: LeaderboardsManagement },
  { path: "/business-models", component: BusinessModels },
  { path: "/system-settings", component: SystemSettings },
  { path: "/results-scores", component: ResultsScores },
  { path: "/friendly-matches", component: FriendlyMatches },
  { path: "/friendly-matches/:id", component: FriendlyMatchDetails },

  //chat
  { path: "/chat/:id", component: Chat },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/accounts/:id/set-password/:resetCode", component: SetPassword },
]

export { userRoutes, authRoutes }
