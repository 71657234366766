import React, { useEffect, useState } from "react"
import {
  ListGroup,
  ListGroupItem,
  Badge,
  Card,
  CardBody,
  Label,
  FormGroup,
} from "reactstrap"
import { connect } from "react-redux"
import { getLogs } from "../../Tabs/Matches/actions"
import { Field, Formik, Form } from "formik"
import Log from "./Log"
import ReactPaginate from "react-paginate"

const filters = [
  { label: "Subscribe ML", name: "subscribeML" },
  { label: "Subscribe WL", name: "subscribeWL" },
  { label: "Subscribe SL", name: "subscribeSL" },
  { label: "Unsubscribe ML", name: "unsubscribeML" },
  { label: "Unsubscribe WL", name: "unsubscribeWL" },
  { label: "Unsubscribe SL", name: "unsubscribeSL" },
  { label: "Promote", name: "promote" },
  { label: "Reserve", name: "reserve" },
]

const ActionLogs = ({
  getCompetitionLogs,
  matches,
  filteredCompetition,
  competitionId,
  ml,
  wl,
}) => {
  const [activeFilters, setActiveFilters] = useState([])
  const [activePage, setActivePage] = useState(0)

  useEffect(() => {
    getCompetitionLogs(competitionId, activeFilters, activePage)
  }, [getCompetitionLogs, competitionId, activeFilters, activePage, ml, wl])

  const { logs } = matches.logs

  return (
    <Card>
      <CardBody>
        <Label>Filters</Label>
        <Formik
          initialValues={{
            subscribeML: true,
            subscribeWL: true,
            unsubscribeML: true,
            unsubscribeWL: true,
            subscribeSL: true,
            unsubscribeSL: true,
            promote: true,
            reserve: true,
          }}
        >
          {({ values }) => {
            setActiveFilters(values)
            return (
              <div className="d-flex justify-content-between">
                {filters?.map(field => (
                  <FormGroup key={field?.name} className="d-flex flex-column">
                    <Label>{field?.label}</Label>
                    <Field type="checkbox" name={field?.name} />
                  </FormGroup>
                ))}
              </div>
            )
          }}
        </Formik>
        <div className="actionLogsHeader">
          <h5 className="mb-0 text-white">Action Logs</h5>
        </div>
        <ListGroup className="actionLogsList">
          {logs?.length === 0 && (
            <ListGroupItem>There are no logs yet</ListGroupItem>
          )}
          {logs?.map((log, idx) => (
            <Log
              filteredCompetition={filteredCompetition}
              log={log}
              key={idx}
            />
          ))}
        </ListGroup>
        <div className="d-flex  justify-content-center mt-2 reactPagination">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={logs?.length && logs[0]?.length / 40}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={e => setActivePage(e.selected)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({ matches: matches = {} }) => ({ matches })

export default connect(mapStateToProps, {
  getCompetitionLogs: (id, activeFilters, activePage) =>
    getLogs(id, activeFilters, activePage),
})(ActionLogs)
