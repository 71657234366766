import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

import * as actions from "../../pages/Authentication/actions"
import {Spinner} from "reactstrap";

const allowedRoles = ["admin", "superAdmin"]

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  getClientDetails,
  getSession,
  getSettings,
  userDetails = null,
  settings = null,
  ...rest
}) => {
  const initilized = userDetails === null
  const settingsInitilized = settings === null
  
  // Fetch User Details
  useEffect(() => {
    const onMount = async () => {
      if (isAuthProtected && initilized) {
        getSession()
      }
    }
    onMount()
  }, [isAuthProtected, getSession, initilized])
  
  // Fetch settings
  useEffect(() => {
    const onMount = async () => {
      if (settingsInitilized) {
        getSettings()
      }
    }
    onMount()
  }, [settingsInitilized, getSettings])

  return (
    <Route
      {...rest}
      render={props => {
        if (
          userDetails !== null &&
          isAuthProtected &&
          !(userDetails && allowedRoles.includes(userDetails.role))
        ) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (userDetails === null && isAuthProtected) {
          return <>
            <div className="align-items-center d-flex justify-content-center min-vh-100">
              <Spinner className="mt-5 mb-5" color="primary"/>
            </div>
          </>
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

const mapStateToProps = ({ session: { userDetails, settings } = {} }) => ({ userDetails, settings })

export default connect(mapStateToProps, {
  getSession: actions.getSession,
  getSettings: actions.getSettings,
})(AuthMiddleware)
