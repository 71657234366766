import React from "react"

import Input from "components/Common/Input"
import { Row, Col, FormGroup } from "reactstrap"

import DatePicker from "react-datepicker"

const CompetitionDetails = ({
  errors,
  touched,
  handleChange,
  handleBlur,
  initialValues,
  values,
  setFieldValue,
  settings,
}) => {
  const {
    levels = [],
    gameTypes = [],
    drawModels = [],
    matchTypes = [],
    setTypes = [],
  } = settings || {}

  return (
    <div>
      <Row>
        <Col>
          <FormGroup>
            <Input
              placeholder="Select level"
              label="Select level*"
              type="select"
              name="level"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Select level
              </option>
              {levels.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Select game type"
              label="Select game type*"
              type="select"
              name="gameType"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Select game type
              </option>
              {gameTypes.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup>
            <Input
              placeholder="Select draw model"
              label="Select draw model*"
              type="select"
              name="drawModel"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Select draw model
              </option>

              {drawModels.map(({ key, name }) => (
                <option value={key}>{name}</option>
              ))}
            </Input>
          </FormGroup>

          {values.drawModel === "groups" && (
            <FormGroup>
              <Input
                placeholder="Select # of groups"
                label="Select # of groups*"
                type="select"
                name="numberOfGroups"
                {...{
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  initialValues,
                  values,
                }}
              >
                <option value="" selected disabled>
                  Select # of groups
                </option>
                {new Array(18).fill().map((el, index) => (
                  <option value={index + 1}>{index + 1}</option>
                ))}
              </Input>
            </FormGroup>
          )}

          <FormGroup>
            <Input
              placeholder="Set # of entries on MainList"
              label="Set # of entries on MainList*"
              type="number"
              name="mainListEntries"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            />
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <Input
              placeholder="Set ELO"
              label="Set ELO*"
              type="select"
              name="elo"
              {...{
                errors,
                touched,
                handleChange,
                handleBlur,
                initialValues,
                values,
              }}
            >
              <option value="" selected disabled>
                Set ELO
              </option>

              <option value={false}>No</option>

              <option value={true}>Yes</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <label className="mb-1">Start date*</label>
            <DatePicker
              selected={
                new Date(
                  values.startDate || initialValues.startDate || new Date()
                )
              }
              onChange={value => setFieldValue("startDate", value)}
              dateFormat="Pp"
              className="form-control"
              showTimeSelect
            />
          </FormGroup>
          {/* time for the first game in the comp */}
        </Col>
      </Row>
    </div>
  )
}

export default CompetitionDetails
