import { GET_LEADERBOARDS, GET_LEADERBOARDS_SUCCESS } from "./actionTypes"

export const getLeaderboards = () => {
  return {
    type: GET_LEADERBOARDS,
  }
}

export const getLeaderboardsSuccess = data => {
  return {
    type: GET_LEADERBOARDS_SUCCESS,
    payload: data,
  }
}
