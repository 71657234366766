import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  FormGroup,
  Button,
  Spinner,
  Label,
} from "reactstrap"

import Select from "react-select"

import { AvForm, AvField } from "availity-reactstrap-validation"

import toastr from "toastr"
import "toastr/build/toastr.min.css"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getClubLeadInfo } from "./actions"
import { updateClubLead } from "./api"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete"

const ClubBusinessLeadInfo = props => {
  const {
    getClubLeadInfo,
    clubLeadInfo,
    loading,
    match: { params },
    session,
  } = props

  const { settings } = session
  const [location, setLocation] = useState("")
  const [country, setCountry] = useState(
    settings?.eligibleCountries?.filter(
      x => x?.name === clubLeadInfo?.location?.country
    )
  )

  useEffect(() => {
    getClubLeadInfo(params.id)
  }, [getClubLeadInfo, params.id])

  useEffect(() => {
    if (clubLeadInfo?.location) {
      setLocation({
        label: clubLeadInfo.location?.name,
        value: clubLeadInfo.location,
      })
    }
  }, [clubLeadInfo, country])

  const handleLocation = async event => {
    if (isEmpty(event)) {
      setLocation("")
      return false
    }

    const coords = await geocodeByPlaceId(event.value.place_id)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        return { lat, lng }
      })
    setLocation({
      label: event.label,
      value: {
        name: event.label,
        placeId: event.value.place_id,
        city:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 2]?.value
            : null,
        country:
          event.value.terms.length >= 2
            ? event.value.terms[event.value.terms.length - 1]?.value
            : null,
        coords,
      },
    })
  }

  const handleSubmit = async (event, values) => {
    try {
      // set location
      values.location = values?.location?.value

      const response = await updateClubLead(params.id, values)

      if (response) {
        if (!response.success) {
          toastr.error(response.message)
        } else {
          toastr.success("Club lead was successfully updated.")
          props.history.push(`/accounts/clubs-leads`)
        }
      }
    } catch (e) {
      toastr.error(
        e.response.data?.message || "Club lead could not be updated."
      )
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Club Lead Info"
            breadcrumbItem="Club Business Leads"
          />
          {loading ? (
            <div className="text-center">
              <Spinner className="mt-5 mb-5" color="primary" />
            </div>
          ) : (
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">Club Information</CardTitle>
                      <AvField
                        name="clubName"
                        placeholder="Club Name / Brand Name"
                        label="Club Name / Brand Name"
                        type="text"
                        errorMessage="Enter Club Name / Brand Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={clubLeadInfo.clubName}
                      />

                      <FormGroup>
                        <AvField
                          name="sportType"
                          placeholder="Sport type"
                          label="Sport"
                          type="text"
                          errorMessage="Enter sport type"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={clubLeadInfo.sportType}
                        />
                      </FormGroup>

                      <AvField
                        name="noCourts"
                        placeholder="Courts"
                        label="Courts"
                        type="number"
                        errorMessage="Enter number of courts"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={clubLeadInfo.noCourts}
                      />

                      <FormGroup htmlFor="country">
                        <Label className="mb-1">Select Country</Label>
                        <Select
                          name="country"
                          placeholder="Select Country"
                          defaultValue={{
                            label: settings?.eligibleCountries?.filter(
                              x => x?.name === clubLeadInfo?.location?.country
                            )[0]?.name,
                            value: settings?.eligibleCountries?.filter(
                              x => x?.name === clubLeadInfo?.location?.country
                            )[0]?.key,
                          }}
                          options={settings?.eligibleCountries?.map(x => ({
                            label: x?.name,
                            value: x?.key,
                          }))}
                          onChange={e => setCountry(e.value.toUpperCase())}
                        />
                      </FormGroup>
                      <FormGroup htmlFor="location">
                        <label htmlFor="location">Location*</label>
                        <GooglePlacesAutocomplete
                          name="location"
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: [
                                country?.[0]?.key
                                  ? country?.[0]?.key?.toUpperCase()
                                  : country,
                              ],
                            },
                          }}
                          minLengthAutocomplete={3}
                          isClearable
                          selectProps={{
                            placeholder: "Select location",
                            value: location,
                            onChange: e => handleLocation(e),
                            isClearable: true,
                            required: true,
                          }}
                        />

                        <AvField
                          name="location"
                          placeholder="Location"
                          type="hidden"
                          errorMessage="Enter location"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          value={location}
                        />
                      </FormGroup>

                      <AvField
                        type="select"
                        name="status"
                        label="Status"
                        value={clubLeadInfo.status}
                        required
                      >
                        <option>Select status</option>
                        <option value="pending">Pending</option>
                        <option value="closed">Closed</option>
                      </AvField>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={6}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-3">
                        Club Account Owner Information
                      </CardTitle>

                      <FormGroup>
                        <AvField
                          name="firstName"
                          placeholder="Firstname"
                          label="Firstname"
                          type="text"
                          errorMessage="Enter Owner Firstname"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={clubLeadInfo.firstName}
                        />
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          name="lastName"
                          placeholder="Lastname"
                          label="Lastname"
                          type="text"
                          errorMessage="Enter Owner Lastname"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom01"
                          value={clubLeadInfo.lastName}
                        />
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          name="email"
                          label="Email"
                          placeholder="Enter Valid Email"
                          type="email"
                          errorMessage="Invalid Email"
                          className="form-control"
                          validate={{
                            required: { value: true },
                            email: { value: true },
                          }}
                          value={clubLeadInfo.email}
                        />
                      </FormGroup>

                      <FormGroup>
                        <AvField
                          name="phone"
                          label="Phone"
                          placeholder="Enter Phone"
                          type="number"
                          errorMessage="Enter Only Number"
                          validate={{
                            required: { value: true },
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only Numbers",
                            },
                            maxLength: {
                              value: 12,
                              errorMessage: "Max 12 chars.",
                            },
                          }}
                          value={clubLeadInfo.phone}
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <FormGroup className="pb-3 mb-0 row">
                <div className="text-left col-md-6">
                  <Button type="submit" color="info" className="mr-1">
                    Update club lead
                  </Button>
                  <Link
                    to="/accounts/clubs-leads"
                    className="btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
                <div className="text-right col-md-6">
                  <Link
                    to={`/accounts/clubs/create/${params.id}`}
                    className="btn btn-outline-success"
                  >
                    Create club account
                  </Link>
                </div>
              </FormGroup>
            </AvForm>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

ClubBusinessLeadInfo.propTypes = {
  clubLeadInfo: PropTypes.object,
  getClubLeadInfo: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ clubsLeads, session }) => ({
  loading: clubsLeads.loading,
  clubLeadInfo: clubsLeads.clubLeadInfo,
  session: session,
})

const mapDispatchToProps = dispatch => ({
  getClubLeadInfo: id => dispatch(getClubLeadInfo(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClubBusinessLeadInfo))
