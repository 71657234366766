import {
  API_ERROR,
  GET_FRIENDLY_MATCHES,
  GET_FRIENDLY_MATCHES_SUCCESS,
  GET_FRIENDLY_MATCH,
  GET_FRIENDLY_MATCH_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  friendlyMatches: {},
  friendlyMatch: {},
  loading: false,
}

const friendlyMatches = (state = initialState, action) => {
  switch (action.type) {
    case GET_FRIENDLY_MATCHES:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FRIENDLY_MATCHES_SUCCESS:
      state = {
        ...state,
        friendlyMatches: action.payload,
        loading: false,
      }
      break
    case GET_FRIENDLY_MATCH:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FRIENDLY_MATCH_SUCCESS:
      state = {
        ...state,
        friendlyMatch: action.payload,
        loading: false,
      }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default friendlyMatches
